import { session as userSession } from "../helpers/sessionValues";
import { diffBetweenTwoTimeStampsInSeconds } from "../helpers/dateHelper";
import { setApiUrl } from "../branding/brandconfig";
import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { v1 as uuidv1 } from "uuid";

export class Auth {
  constructor(expiresAt) {
    this.expiresAt = expiresAt;
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  clearRenewInterval() {
    clearInterval(this.renewInterval);
  }

  getBearerToken(toolingToken) {
    const hasValidToken =
      toolingToken || userSession.getSessionItem("token_type");
    if (!hasValidToken) return false;
    let bearer =
      hasValidToken.charAt(0).toUpperCase() + hasValidToken.substr(1);
    if (!toolingToken) {
      userSession.setSessionItem("token_type", bearer);
    }
    return bearer + " " + userSession.getSessionItem("access_token");
  }

  getIdToken() {
    return userSession.getSessionItem("access_token");
  }

  renewExpireAt() {
    if (!!this.isAuthenticated()) {
      this.setRefreshToken();
    } else {
      this.clearSession();
    }
  }

  checkForAuthentication() {
    if (!this.getIdToken() || !this.isAuthenticated()) {
      this.clearSession();
    }
  }

  getTimeDiffereneInMiliSecond() {
    let expiryAt = parseInt(userSession.getSessionItem("expires_at")),
      timenow = moment.utc(Date.now());
    let differenceInSeconds = diffBetweenTwoTimeStampsInSeconds(
      timenow.valueOf(),
      expiryAt
    );

    if (!!differenceInSeconds && differenceInSeconds >= 0) {
      return diffBetweenTwoTimeStampsInSeconds(timenow, expiryAt) * 1000;
    }
    return 3500000;
  }

  timedRefresh(time) {
    const _this = this;
    const fixedTime = time || this.getTimeDiffereneInMiliSecond();
    let interval = time || fixedTime;
    this.renewInterval = setInterval(() => {
      _this.renewExpireAt();
    }, interval);
    interval = fixedTime;
  }

  isAuthenticated() {
    let expiresAt = !!userSession.getSessionItem("expires_at")
      ? userSession.getSessionItem("expires_at")
      : this.expiresAt;
    const isValidSession = moment.utc() < expiresAt;
    if (!isValidSession) {
      userSession.clearSession();
    }
    return isValidSession;
  }

  clearSession() {
    this.signOut(function() {});
  }

  getFullName() {
    let fullName =
      userSession.getSessionItem("firstName") +
      " " +
      userSession.getSessionItem("lastName");
    return fullName;
  }

  setExpireInTime(response) {
    if (!!response && !!response["expires_in"]) {
      this.expiresAt = parseInt(response["expires_in"]) * 1000 + moment.utc();
      userSession.setSessionItem("expires_at", this.expiresAt);
      this.timedRefresh();
    } else if (!!response && !!response["expires_at"]) {
      const expiration_time = moment.utc(response["expires_at"]);
      userSession.setSessionItem("expires_at", expiration_time.valueOf());
      this.timedRefresh();
    } else {
      this.signOut();
    }
  }

  getCountDown(apiUrl, toolingToken) {
    const promise = new Promise((resolve, reject) => {
      const tokenValue =
        toolingToken || userSession.getSessionItem("access_token");
      const apiURL = apiUrl || userSession.getSessionItem("apiUrl");
      const url = apiURL + "tokeninfo?token=" + tokenValue;

      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: tokenValue
        }
      })
        .then(result => resolve(result))
        .catch(err => reject(err));
    });

    return promise;
  }

  async signIn(values, Callback) {
    await setApiUrl().then(data => {
      let loginData = {
        username: values.username,
        password: values.password,
        clientId: this.generateClientid(),
        clientSecret: this.generateSecret(),
        fa2code: values.additional
      };
      const url = data.apiUrl + "login";
      const serialiseData = this.serialize(loginData);

      axios({
        method: "post",
        url: url,
        data: serialiseData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
        .then(authResult => {
          if (!authResult.data.error) {
            userSession.setSessionItems(authResult.data);
            userSession.setSessionItem("client_secret", loginData.clientSecret);
            userSession.setSessionItem("client_id", loginData.clientId);
            this.setExpireInTime(authResult.data);
            Callback(authResult.data);
          } else {
            Callback(authResult.data.description || "Not a valid account");
            userSession.clearSession();
          }
        })
        .catch(error => {
          Callback(
            error.response.data.description || error.response.data.message
          );
          userSession.clearSession();
        });
    });
  }

  setRefreshToken() {
    let refreshData = {
      client_id: userSession.getSessionItem("client_id"),
      client_secret: userSession.getSessionItem("client_secret"),
      refresh_token: userSession.getSessionItem("refresh_token")
    };
    const promise = new Promise((resolve, reject) => {
      const url = userSession.getSessionItem("apiUrl") + "token/refresh";
      const serialiseData = refreshData;
      const token = authClient.getBearerToken();
      axios({
        method: "post",
        url: url,
        data: serialiseData,
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(result => {
          userSession.setSessionItems(result.data);
          userSession.setSessionItem(
            "client_secret",
            userSession.getSessionItem("client_secret")
          );
          userSession.setSessionItem(
            "client_id",
            userSession.getSessionItem("client_id")
          );
          this.setExpireInTime(result.data);
          resolve(result.data);
        })
        .catch(err => reject(false));
    });
    return promise;
  }

  signOut(Callback) {
    userSession.clearSession();
    if (!!Callback) {
      Callback();
    }
  }

  generateSecret = function() {
    return uuidv4();
  };

  generateClientid = function() {
    return uuidv1();
  };

  serialize = function(values) {
    var string = [];
    for (var value in values)
      if (values.hasOwnProperty(value) && !!values[value]) {
        string.push(
          encodeURIComponent(value) + "=" + encodeURIComponent(values[value])
        );
      } else {
        string.push(encodeURIComponent(value) + "= ");
      }
    return string.join("&");
  };
}

const authClient = new Auth();

export default authClient;
