import {
  ERROR_IN_DISTRIBUTION,
  DELETE_DISTRIBUTION,
  GET_DISTRIBUTION,
  GET_DISTRIBUTIONS,
  UPDATE_DISTRIBUTION,
  ADD_DISTRIBUTION,
  RESET_DISTRIBUTIONS,
  TOP_DIST_IN_MONTH,
  GET_ALL_DISTRIBUTIONS
} from "./types";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import {
  apiPostCall,
  encodeMap,
  createPostObject,
  apiGetCall,
  apiGetCallAndMerge
} from "../actions/baseActions";

export function resetAllDistribution() {
  return function(dispatch) {
    dispatch({
      type: GET_ALL_DISTRIBUTIONS,
      data: null
    });
  };
}

export function resetDistribution() {
  return function(dispatch) {
    dispatch({
      type: GET_DISTRIBUTIONS,
      data: null
    });
  };
}

export function resetTopDistribution() {
  return function(dispatch) {
    dispatch({
      type: TOP_DIST_IN_MONTH,
      data: null
    });
  };
}

export function topDistributionInMonth(customerId, payload) {
  let url = userSession.getSessionItem("apiUrl") + customerId + "/query/table";
  var topListPostObject = createPostObject(
    payload.startDate,
    payload.endDate,
    "top(*." + payload.topListType + ", '" + payload.topListField + "', '11')"
  );

  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_DISTRIBUTION,
    typeSuccess = TOP_DIST_IN_MONTH;
  const postObject = encodeMap(topListPostObject).join("&"),
    contentType = "application/x-www-form-urlencoded";
  const callback = function() {};
  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

function getFinalCDNUrl(customerId, type) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions";
  return url;
}

export function getAllDistributionGroups(customerId, actionType, callback) {
  let url = getFinalCDNUrl(customerId),
    typeSuccess = actionType;
  const token = authClient.getBearerToken(),
    method = "get",
    typeError = ERROR_IN_DISTRIBUTION;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function applyFilteredData(object, typeSuccess) {
  return function(dispatch) {
    dispatch({
      type: typeSuccess,
      data: object
    });
  };
}

export function getDistributions(customerId, type) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_DISTRIBUTION,
    typeSuccess = GET_DISTRIBUTIONS,
    method = "get";
  const callback = function() {};
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function getDistribution(customerId, type, originId, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    originId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_DISTRIBUTION,
    typeSuccess = GET_DISTRIBUTION,
    method = "get";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function deleteDistribution(customerId, type, originId, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    originId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_DISTRIBUTION,
    typeSuccess = DELETE_DISTRIBUTION,
    method = "DELETE";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function updateDistribution(
  customerId,
  type,
  originId,
  methd,
  payload,
  callback
) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    originId;

  const token = authClient.getBearerToken(),
    method = methd,
    typeError = ERROR_IN_DISTRIBUTION,
    typeSuccess = UPDATE_DISTRIBUTION;
  const postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

export function addDistribution(customerId, type, methd, payload, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type;

  const token = authClient.getBearerToken(),
    method = methd,
    typeError = ERROR_IN_DISTRIBUTION,
    typeSuccess = ADD_DISTRIBUTION;
  const postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

export function resetList() {
  return function(dispatch) {
    dispatch({
      type: RESET_DISTRIBUTIONS,
      data: null
    });
  };
}
