import moment from "moment";

export function getLastTwelveMonths() {
  return new Promise(resolve => {
    let twelveMonths = [];
    for (var i = 0; i < 12; i++) {
      let negativeI = Math.abs(i);
      let today = new Date();
      let startDate = today.setMonth(today.getMonth() - negativeI);
      twelveMonths.push(startDate);
    }
    resolve(twelveMonths);
  });
}

export function getStartEndTimeInADay(selectedDate, isCurrentMonth) {
  let date = new Date(selectedDate);
  let currentDay = new Date(selectedDate);
  let previousDay = new Date(currentDay.setDate(currentDay.getDate() - 1));
  let finalDate = {};
  finalDate.startDate = formatDateTimeWithHours(previousDay.getTime());
  finalDate.endDate = formatDateTimeWithHours(date.getTime());
  return finalDate;
}

export function getDateAndTime(time) {
  var date = new Date(parseInt(time));
  return date.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit"
  });
}

export function convertToMonthDay(tempDate) {
  if (!!tempDate) {
    const tempDate1 = tempDate.indexOf(" ");
    const tempDate2 = tempDate.lastIndexOf(" ");
    const finalDate = tempDate.substr(tempDate1 + 1, tempDate2 - tempDate1 - 1);
    return finalDate;
  }
}

export function getOneMonthDateRange(selectedDate) {
  const date = !!selectedDate ? new Date(selectedDate) : new Date(),
    y = date.getFullYear(),
    m = date.getMonth();

  const monthStart = new Date(y, m, 1);
  const monthEnd = new Date(y, m + 1, 1);
  const thisMonthStart = formatToHyphanateDate(monthStart);
  const thisMonthEnd = formatToHyphanateDate(monthEnd);
  return {
    monthStart: thisMonthStart,
    monthEnd: thisMonthEnd
  };
}

function isMonthCurrent(selectedMonth) {
  let currentMonth = new Date().getMonth();
  if (selectedMonth === currentMonth) {
    return true;
  }
  return false;
}

export function getMonthRangeFromEndDate(selectedDate) {
  const date = !!selectedDate ? new Date(selectedDate) : new Date(),
    y = date.getFullYear(),
    m = date.getMonth(),
    d = isMonthCurrent(m) ? date.getDate() : new Date(y, m + 1, 0).getDate(),
    sm = isMonthCurrent(m) ? m - 1 : m;

  const monthStart = new Date(y, sm, 1);
  const monthEnd = new Date(y, m, d);
  const thisMonthStart = formatToHyphanateDate(monthStart);
  const thisMonthEnd = formatToHyphanateDate(monthEnd);
  return {
    monthStart: thisMonthStart,
    monthEnd: thisMonthEnd
  };
}

export function shortByDate(array, key, fallBackKey) {
  if (!array || array === null) return array;
  array.sort(function(a, b) {
    const comparatorB = b[key] || b[fallBackKey];
    const comparatorA = a[key] || a[fallBackKey];
    return new Date(comparatorB).getTime() - new Date(comparatorA).getTime();
  });
  return array;
}

export function shortByDateWithTime(array, key) {
  if (!array) return array;
  array.sort(function(a, b) {
    const comparatorB = b[key] || "01/01/1970, 06:13:20";
    const comparatorA = a[key] || "01/01/1970, 06:13:20";
    return new Date(comparatorA).getTime() - new Date(comparatorB).getTime();
  });

  return array;
}

export function getUTCString(date) {
  return new Date(date).toUTCString();
}

export function differenceInDays(start, end) {
  const dateSmall = new Date(start);
  const dateBig = new Date(end);
  const diffTime = Math.abs(dateBig - dateSmall);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function diffBetweenTwoTimeStampsInSeconds(t1, t2) {
  let d1 = new Date(t1),
    d2 = new Date(t2);
  let difference = Math.abs(d1 - d2) / 1000,
    differenceInDays = Math.floor(difference / 86400),
    differnceInHours = Math.floor(difference / 3600) % 24,
    differnceInMinutes = Math.floor(difference / 60) % 60,
    differenceInSeconds = Math.floor(difference / 1000);

  let daysToSeconds = differenceInDays * 24 * 60 * 60,
    hoursToSeconds = differnceInHours * 60 * 60,
    minutesToSeconds = differnceInMinutes * 60;

  let totalDiffInSeconds =
    daysToSeconds + hoursToSeconds + minutesToSeconds + differenceInSeconds;

  return totalDiffInSeconds;
}

export function formatToHyphanateDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getFullNameMonth(timestamp) {
  let monthNumber = new Date(timestamp).getMonth();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  return months[monthNumber];
}

export function getShortNameMonth(timestamp) {
  let monthNumber = new Date(timestamp).getMonth();
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ];
  return months[monthNumber];
}

export function getFullYear(timestamp) {
  return new Date(timestamp).getFullYear();
}

export function setDateToFirstDayOfMonth(timestamp) {
  let dt = new Date(timestamp);
  dt.setDate("01");
  return dt;
}

export function getISOStringMoment() {
  let timenow = moment.utc(Date.now());
  return timenow.toISOString();
}

export const formatDateTimeWithHours = function(datetime) {
  let date = new Date(datetime);
  const dateString = JSON.stringify(date);
  let dateIndex = dateString.lastIndexOf(".") - 1;
  const formattedDate = dateString.substr(1, dateIndex);
  return formattedDate;
};

export const formatDateTime = function(datetime) {
  let date = new Date(datetime);
  const dateObj = {
    date: new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    )
  };
  const dateString = JSON.stringify(dateObj);
  const jsonDate = JSON.parse(dateString).date;
  let dateIndex = jsonDate.lastIndexOf(".");
  const formattedDate = jsonDate.substr(0, dateIndex);
  return formattedDate;
};
