import {
  GET_USER_DETAILS,
  UPDATE_USER_PASSWORD,
  ERROR_IN_USER_DETAILS,
  GET_SPECIFIC_USER_DETAILS,
  SWITCH_USER_DETAILS,
  USER_2FA_TOGGLE,
  GET_USERS,
  DELETE_USER,
  ADD_USERS,
  USER_ENABLED_TOGGLE,
  EDIT_USER
} from "../actions/types";

const initialState = {
  data: null,
  error: null,
  switchUserData: null,
  User2FA: null,
  userEnabled: null,
  users: null,
  userPassword: null,
  deletedSelectedUser: null,
  addedUser: null,
  editSelectedUser: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_USER_DETAILS:
      return {
        ...state,
        data: action.data
      };
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        userPassword: action.userPassword
      };
    case GET_SPECIFIC_USER_DETAILS:
      return {
        ...state,
        data: action.data
      };
    case SWITCH_USER_DETAILS:
      return {
        ...state,
        switchUserData: action.switchUserData
      };
    case USER_2FA_TOGGLE:
      return {
        ...state,
        user2FA: action.user2FA
      };
    case USER_ENABLED_TOGGLE:
      return {
        ...state,
        userEnabled: action.userEnabled
      };
    case GET_USERS:
      return {
        ...state,
        users: action.users
      };
    case DELETE_USER:
      return {
        ...state,
        deletedSelectedUser: action.deletedSelectedUser
      };
    case EDIT_USER:
      return {
        ...state,
        editSelectedUser: action.editSelectedUser
      };
    case ADD_USERS:
      return {
        ...state,
        addedUser: action.addedUser
      };
    case ERROR_IN_USER_DETAILS:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}
