import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { REDIRECT_MESSAGE } from "../../common/helpers/loader";

class Error404 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    componentWillUnmount(){
        this.setState({
            redirect: false
        })
    }

    redirectToHome() {
        let _this = this; 
        return setTimeout(function () {
            _this.setState({
                redirect: true
            })
        }, 10000);
    }

    render() {
        const { redirect } = this.state;

        if (!redirect) {
            this.redirectToHome();
        }
        if (!!redirect) {
            return <Redirect to="/" />
        }
        return REDIRECT_MESSAGE;
    }
}


export default Error404;