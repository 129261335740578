import React from "react";
import {
  RenderButtonClick,
  RenderSwitchWithToolTip,
  RenderInputWithToolTip,
  RenderAutoSelectWithToolTip,
  ClickableTooltip
} from "../../common/helpers/renderElement";
import { session as userSession } from "../helpers/sessionValues";
import authClient from "../helpers/auth";
import { Divider } from "@material-ui/core";
import update from "immutability-helper";
import Typography from "@material-ui/core/Typography";
import axios from "axios";

export const SIMPLE = "simple";
export const ADVANCED = "advanced";
export const ORIGIN_GROUP = "origin-group";
export const OBJECT_STORAGE = "object-storage";

const ORIGINS = {
  ENABLED: "Enable/disable origin.",
  DESCRIPTION: "Description of this origin.",
  HOST:
    "The IP or hostname of the pull origin. Do not put in the protocol here, the protocol is defined in the policy.",
  HTTPPORT: "Non standard HTTP port to use, e.g. 8080.",
  HTTPSPORT: "Non standard  HTTPS port to use, e.g. 8443.",
  SUBDIRECTORY:
    "Path or subdirectory to use on the origin. This is where the CDN will fetch the content from but can be omitted from end-user requests.",
  PATH:
    "Path or subdirectory to use on the origin. This is where the CDN will fetch the content from but can be omitted from end-user requests.",
  SECRET: "AWS V2 Secret Access Key.",
  BUCKET: "Object storage bucket name.",
  ACCESSKEY: "AWS V2 Access Key ID.",
  BALANCING: "Load-balancing method used for the origin group.",
  FAILTIMEOUT:
    "Time-out in seconds after which a request is considered to be failed and the next upstream should be tried.",
  KEEPALIVE:
    "Enable or disable keepAlive to origin(s). Note that the keep-alive settings need to be in sync between the CDN and its origin(s).",
  MEMBERS: "Members that belong to this origin group.",
  KEEPALIVEMSG:
    "Amount of seconds to keep connection alive to origin(s). Note that the keep-alive settings need to be in sync between the CDN and its origin(s).",
  ORIGINHOST: "Origin host or IP.",
  ORIGIN: "Origin member description.",
  STATUS: "Status of origin group member."
};

const styles = {
  rightDomains: {
    right: "-37px"
  }
};

export const simpleDataMapper = self => {
  return {
    customerId: self.state.customerId,
    created: self.state.created,
    description: self.state.originDescription,
    host: self.state.host,
    id: self.state.id,
    type: self.state.type,
    updated: null,
    enabled: true
  };
};

export const advancedDataMapper = self => {
  return {
    customerId: self.state.customerId,
    created: self.state.created,
    description: self.state.originDescription,
    host: self.state.host,
    httpPort: self.state.httpPort,
    httpsPort: self.state.httpsPort,
    path: self.state.path,
    id: self.state.id,
    type: self.state.type,
    updated: null,
    enabled: true
  };
};

export const objectStorageDataMapper = self => {
  return {
    customerId: self.state.customerId,
    accessKeyId: self.state.accessKeyId,
    bucket: self.state.bucket,
    created: self.state.created,
    description: self.state.originDescription,
    host: self.state.host,
    id: self.state.id,
    secretAccessKey: self.state.secretAccessKey,
    type: self.state.type,
    updated: null,
    enabled: true
  };
};

export const groupsDataMapper = self => {
  return {
    balancingMethod: self.state.balancingMethod,
    customerId: self.state.customerId,
    description: self.state.originDescription,
    failTimeOut: self.state.failTimeOut,
    id: self.state.id,
    keepAlive: self.state.keepAlive,
    type: self.state.type,
    keepAliveSeconds: self.state.keepAliveSeconds,
    members: self.state.members,
    enabled: true
  };
};

export const simpleVMMapper = (self, data) => {
  self.setState({
    customerId: data.customerId,
    created: data.created,
    originDescription: data.description,
    host: data.host,
    id: data.id,
    type: data.type,
    updated: data.updated
  });
};

export const advancedVMMapper = (self, data) => {
  self.setState({
    customerId: data.customerId,
    created: data.created,
    originDescription: data.description,
    host: data.host,
    httpPort: data.httpPort,
    httpsPort: data.httpsPort,
    path: data.path,
    id: data.id,
    type: data.type,
    updated: data.updated
  });
};
export const objectStorageVMMapper = (self, data) => {
  self.setState({
    customerId: data.customerId,
    accessKeyId: data.accessKeyId,
    bucket: data.bucket,
    created: data.created,
    originDescription: data.description,
    host: data.host,
    id: data.id,
    secretAccessKey: data.secretAccessKey,
    type: data.type,
    updated: data.updated
  });
};
export const groupsVMMapper = (self, data) => {
  self.setState({
    balancingMethod: data.balancingMethod,
    customerId: data.customerId,
    originDescription: data.description,
    failTimeOut: data.failTimeOut,
    id: data.id,
    keepAlive: data.keepAlive,
    type: data.type,
    keepAliveSeconds: data.keepAliveSeconds,
    members: data.members
  });
};

export const initSimpleVMMapper = (self, data) => {
  self.setState({
    customerId: data.customerId,
    originDescription: data.description,
    host: data.host,
    type: data.type
  });
};

export const initAdvancedVMMapper = (self, data) => {
  self.setState({
    customerId: data.customerId,
    created: data.created,
    originDescription: data.description,
    host: data.host,
    httpPort: data.httpPort,
    httpsPort: data.httpsPort,
    path: data.path,
    id: data.id,
    type: data.type,
    updated: data.updated
  });
};
export const initObjectStorageVMMapper = (self, data) => {
  self.setState({
    customerId: data.customerId,
    accessKeyId: data.accessKeyId,
    bucket: data.bucket,
    created: data.created,
    originDescription: data.description,
    host: data.host,
    id: data.id,
    secretAccessKey: data.secretAccessKey,
    type: data.type,
    updated: data.updated
  });
};
export const initGroupsVMMapper = (self, data) => {
  self.setState({
    balancingMethod: data.balancingMethod,
    customerId: data.customerId,
    originDescription: data.description,
    failTimeOut: data.failTimeOut,
    id: data.id,
    keepAlive: data.keepAlive,
    type: data.type,
    keepAliveSeconds: data.keepAliveSeconds,
    members: data.members
  });
};

export function simpleEditFormControls(self) {
  return (
    <React.Fragment>
      <div className="margin-top-16 clear-both">
        <Typography variant="h3" align="left">
          Basic Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="Description of the origin."
          id="originDescription"
          name="originDescription"
          type="text"
          required
          error={!self.state.originDescription}
          value={self.state.originDescription}
          label="Description"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.DESCRIPTION}
        />
        <RenderInputWithToolTip
          placeholder="The origin host / IP, without the protocol or port."
          id="host"
          name="host"
          type="text"
          required
          error={!self.state.host}
          value={self.state.host}
          label="Host"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.HOST}
        />
      </div>
    </React.Fragment>
  );
}

export function advancedEditFormControls(self) {
  return (
    <React.Fragment>
      <div className="margin-top-16 clear-both">
        <Typography variant="h3" align="left">
          Basic Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="Description of the origin."
          id="originDescription"
          name="originDescription"
          type="text"
          required
          error={!self.state.originDescription}
          value={self.state.originDescription}
          label="Description"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.DESCRIPTION}
        />
      </div>
      <div className="margin-top-16 clear-both">
        <Typography variant="h3" align="left">
          URL Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="The origin host / IP, without the protocol or port."
          id="host"
          name="host"
          type="text"
          required
          error={!self.state.host}
          value={self.state.host}
          label="Host"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.HOST}
        />
        <RenderInputWithToolTip
          placeholder="Non standard HTTP port to use."
          id="httpPort"
          name="httpPort"
          type="text"
          required
          error={!self.state.httpPort}
          value={self.state.httpPort}
          label="HTTP port"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.HTTPPORT}
        />
        <RenderInputWithToolTip
          placeholder="Non standard HTTPS port to use."
          id="httpsPort"
          name="httpsPort"
          type="text"
          required
          error={!self.state.httpsPort}
          value={self.state.httpsPort}
          label="HTTPs port"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.HTTPSPORT}
        />
        <RenderInputWithToolTip
          placeholder="Sub directory or path on the origin."
          id="path"
          name="path"
          type="text"
          required
          error={!self.state.path}
          value={self.state.path}
          label="Path / subdirectory"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.PATH}
        />
      </div>
    </React.Fragment>
  );
}

export function objectStorageEditFormControls(self) {
  return (
    <React.Fragment>
      <div className="margin-top-16 clear-both">
        <Typography variant="h3" align="left">
          Basic Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="Description of the origin."
          id="originDescription"
          name="originDescription"
          type="text"
          required
          value={self.state.originDescription}
          error={!self.state.originDescription}
          label="Description"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.DESCRIPTION}
        />
        <RenderInputWithToolTip
          placeholder="The origin host / IP, without the protocol or port."
          id="host"
          name="host"
          type="text"
          required
          error={!self.state.host}
          value={self.state.host}
          label="Host"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.HOST}
        />
      </div>
      <div className="margin-top-16 clear-both">
        <Typography variant="h3" align="left">
          Key Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="AWS V2 Access Key ID here."
          id="accessKeyId"
          name="accessKeyId"
          type="text"
          value={self.state.accessKeyId}
          label="Access Key ID"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.ACCESSKEY}
        />
        <RenderInputWithToolTip
          placeholder="Object Storage Bucket to use."
          id="bucket"
          name="bucket"
          type="text"
          value={self.state.bucket}
          label="Bucket"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.BUCKET}
        />
        <RenderInputWithToolTip
          placeholder="AWS V2 Secret access key."
          id="secretAccessKey"
          name="secretAccessKey"
          type="password"
          value={self.state.secretAccessKey}
          error={!self.state.secretAccessKey}
          required
          label="Secret access key"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.SECRET}
        />
      </div>
    </React.Fragment>
  );
}

export function groupsEditFormControls(self) {
  const { classes } = self.props;

  return (
    <React.Fragment>
      <div className="margin-top-16 clear-both">
        <Typography variant="h3" align="left">
          Basic Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="Description of the origin."
          id="originDescription"
          name="originDescription"
          type="text"
          required
          value={self.state.originDescription}
          error={!self.state.originDescription}
          label="Description"
          handleChange={self.handleChange}
          toolTipTitle={ORIGINS.DESCRIPTION}
        />
      </div>
      <div className="margin-top-16 clear-both overflow-hidden">
        <Typography variant="h3" align="left">
          Pool Settings
        </Typography>
        <div className="auto-complete order2 auto-complete__tooltip">
          <RenderAutoSelectWithToolTip
            className="margin-top-16"
            id="balancingMethod"
            name="balancingMethod"
            value={self.state.balancingMethod}
            required
            label="Loadbalancing method"
            handleChange={(selection, name) =>
              self.handleAutoSelectChange(selection, name)
            }
            items={[
              {
                value: "roundrobin",
                text: "Round-robin"
              },
              {
                value: "consistent",
                text: "Consistent"
              },
              {
                value: "sticky",
                text: "Sticky"
              },
              {
                value: "failover",
                text: "Failover"
              }
            ]}
            toolTipTitle={ORIGINS.BALANCING}
          />
        </div>
        <RenderInputWithToolTip
          placeholder="Time-out in seconds after which an upstream is deemed to be down."
          id="failTimeOut"
          name="failTimeOut"
          type="number"
          required
          value={self.state.failTimeOut}
          error={checkForValidValue(self, "failTimeOut", 1, 15)}
          label="Fail time-out"
          handleChange={self.handleChange}
          helperText="Fail timeout has to be between 1 and 15 seconds."
          toolTipTitle={ORIGINS.FAILTIMEOUT}
          ShiftUp={true}
        />
        <RenderSwitchWithToolTip
          label="Keep Alive"
          classes={classes}
          name="enabled"
          checked={self.state.keepAlive}
          handleRadioChange={event => {
            self.resetValueForKey(event.target.checked, "keepAliveSeconds", 0);
            self.handleRadioChange("keepAlive", event);
          }}
          value={self.state.keepAlive}
          className="margin-top-16"
          toolTipTitle={ORIGINS.KEEPALIVE}
        />

        {!!self.state.keepAlive && (
          <RenderInputWithToolTip
            placeholder="Amount of seconds a keep-alive session will keep being used."
            id="keepAliveSeconds"
            name="keepAliveSeconds"
            type="text"
            required
            value={self.state.keepAliveSeconds}
            label="Keep Alive Seconds"
            handleChange={self.handleChange}
            min={1}
            max={30}
            error={checkForValidValue(self, "keepAliveSeconds", 1, 30)}
            helperText="KeepAlive time has to be between 1 and 30 seconds."
            toolTipTitle={ORIGINS.KEEPALIVEMSG}
            ShiftUp
          />
        )}
        <Divider className="margin-top-default margin-bottom-default" />
        {!!self.state.members
          ? createMembersFields(self, self.state.members)
          : ""}
        <div className="margin-bottom-16"></div>
        <RenderButtonClick
          name="addMembers"
          color="primary"
          onClick={() => addItem(self, self.state.members)}
          variant="contained"
          type="button"
          size="large"
          align="right"
          disabled={false}
          label="Add member"
        />
      </div>
    </React.Fragment>
  );
}

function checkForValidValue(self, key, min, max) {
  const value = parseInt(self.state[key]);
  if (value >= min && value <= max) {
    return false;
  } else {
    return true;
  }
}
export function createMembersFields(self, data) {
  return (
    <React.Fragment>
      <label className="fullWidth display-block-inline position-relative">
        Members
        <div className="toolTip margin-none" style={styles.rightDomains}>
          <ClickableTooltip toolTipTitle={ORIGINS.MEMBERS} />
        </div>
      </label>
      {data.map((elm, index) => {
        return (
          <React.Fragment key={"field" + index}>
            {!!index ? <Divider className="margin-top-default" /> : ""}
            <RenderInputWithToolTip
              placeholder="The origin host / IP, without the protocol or port."
              id="originHost"
              name="originHost"
              type="text"
              disabled
              value={self.state.members[index].originHost}
              label="Origin Host"
              handleChange={() =>
                self.handleStateChange(
                  self,
                  index,
                  "originHost",
                  elm.originHost
                )
              }
              toolTipTitle={ORIGINS.ORIGINHOST}
            />
            <br />
            <div className="auto-complete order1 auto-complete__tooltip">
              <RenderAutoSelectWithToolTip
                id="origin"
                name="origin"
                value={self.state.members[index].originId}
                placeholderSuffix="origin"
                label="Origin"
                className="fixed-height"
                handleChange={(selected, name) =>
                  handleAutoSelectStateChange(selected, self, index, "originId")
                }
                items={getTextValueFormat(self.state.simpleOrigins)}
                required
                toolTipTitle={ORIGINS.ORIGIN}
              />
            </div>
            <br />
            <div className="auto-complete order2 margin-top-16 auto-complete__tooltip">
              <RenderAutoSelectWithToolTip
                id="status"
                name="status"
                className="fixed-height last"
                value={self.state.members[index].status}
                label="Status"
                placeholderSuffix="Status"
                handleChange={(selected, name) =>
                  handleAutoSelectStateChange(selected, self, index, "status")
                }
                items={[
                  {
                    value: "active",
                    text: "Active"
                  },
                  {
                    value: "backup",
                    text: "Backup"
                  }
                ]}
                required={self.checkForErrorAndReset(
                  self.state.members[index],
                  "status",
                  "balancingMethod",
                  () => {
                    resetStateValue(self, index, "status");
                    self.toggleNotification(
                      "Select other value, because selection 'Backup' is invalid if load balancing has either 'consistent'/'sticky'",
                      "warning",
                      "Close"
                    );
                  }
                )}
                toolTipTitle={ORIGINS.STATUS}
              />
            </div>
            <br />
            {index > 1 && (
              <RenderButtonClick
                name="removeMembers"
                color="secondary"
                onClick={() => removeItem(self, self.state.members, index)}
                variant="contained"
                type="button"
                size="large"
                align="right"
                disabled={false}
                label="Remove"
              />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

function getOriginHost(self, originId) {
  const simpleOrigins = self.state.simpleOrigins;
  const simpleOrigin = simpleOrigins.filter(value => {
    return originId === value.id;
  });

  return simpleOrigin[0].host;
}

function resetStateValue(self, index, key) {
  let updatedValue;
  const members = self.state.members;
  updatedValue = update(members[index], {
    [key]: { $set: null }
  });

  const newData = update(members, {
    $splice: [[index, 1, updatedValue]]
  });
  self.setState({ members: newData });
}

function handleAutoSelectStateChange(selected, self, index, key) {
  const members = self.state.members;
  let updatedValue;
  const value = !!selected ? selected.value : null;

  if (key === "originId" && !!value) {
    updatedValue = update(members[index], {
      originId: { $set: value },
      originHost: { $set: getOriginHost(self, value) }
    });
  } else {
    updatedValue = update(members[index], {
      [key]: { $set: value }
    });
  }

  const newData = update(members, {
    $splice: [[index, 1, updatedValue]]
  });
  self.setState({ members: newData });
}

function removeItem(self, obj, index) {
  if (obj.length > 1) {
    obj.splice(index, 1);
  }
  self.setState({ members: obj });
}

function addItem(self, obj) {
  if (obj.length < 10) {
    obj.push({
      originHost: "",
      originId: "",
      status: ""
    });
  }
  self.setState({ members: obj });
}

function getTextValueFormat(obj) {
  const arrObj = obj || [],
    object = [];
  for (let ii = 0, jj = arrObj.length; ii < jj; ii++) {
    object.push({
      value: arrObj[ii].id,
      text: arrObj[ii].description
    });
  }
  return object;
}
export function getSimpleOrigins(customerId, callback) {
  axios.defaults.headers.common["Authorization"] = authClient.getBearerToken();
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/origins/simple";

  axios.get(url).then(result => {
    if (!!result) {
      callback(result.data.response);
    }
  });
}
