import React from "react";
import Notification from "../../common/helpers/notification";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { RESELLER } from "../../common/constants/roles";
import { getUserDetails } from "../../actions/userDetailsAction";
import Typography from "@material-ui/core/Typography";
import AmChartCardMultiple from "../Cards/AMChartCardMultiple";
import Grid from "@material-ui/core/Grid";
import {
  drawMultiLineStatusCode2XChart,
  drawMultiLineStatusCode4XChart,
  drawMultiLineStatusCode5XChart
} from "../../actions/chartsActions";
import {
  getAllDistributionGroups,
  topDistributionInMonth,
  resetTopDistribution
} from "../../actions/distributionAction";
import { Paper } from "@material-ui/core";
import { placeholderChartDynMessage } from "../../common/helpers/loader";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import StatisticsBase from "../Statistics/StatisticsBase";
import {
  groupByDropdown,
  groupByDropdownMcdnPremium,
  dateRangeDefinitionObject,
  dateRangeUnitObject,
  granularityDropdown
} from "../Statistics/statisticsConstants";

const REQUEST_COUNT_2XX = "2xx request count";
const REQUEST_COUNT_4XX = "4xx request count";
const REQUEST_COUNT_5XX = "5xx request count";

class StatusCodes extends StatisticsBase {
  callActive = false;
  start = "-48 hours";
  end = new Date().getTime();
  groupBy = null;

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      multiChartItems2XData: null,
      multiChartItems4XData: null,
      multiChartItems5XData: null
    };
  }

  componentDidUpdate(prevProps) {
    const { switchUserData, userData, allDistributions } = this.props;
    if (!!userData) {
      let customerId = !!switchUserData
        ? switchUserData.id
        : userData.customerId;

      if (!!allDistributions && !this.multiCount) {
        this.multiCount = 1;
        this.drawMultiLineStatusCode2XChart(customerId);
        this.drawMultiLineStatusCode4XChart(customerId);
        this.drawMultiLineStatusCode5XChart(customerId);
      }
    }
  }

  async drawMultiLineStatusCode2XChart(customerId, singleTarget) {
    const _this = this,
      unitCategory = "percentage";
    const endpoint = _this.getCustomerType(RESELLER);
    const granularity = _this.setGranularityAsPerDate(
      this.hasDateUnChangedButFormatted
    );
    this.hasDateUnChangedButFormatted = true;

    const payload = _this.setPayloadStatistics("status_2xx", granularity);

    await this.props.drawMultiLineStatusCode2XChart(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(_this, res, unitCategory);
        _this.setState({
          multiChartItems2XData: chartItem
        });
      }
    );
  }

  async drawMultiLineStatusCode4XChart(customerId, singleTarget) {
    const _this = this,
      unitCategory = "percentage";
    const endpoint = _this.getCustomerType(RESELLER);
    const granularity = _this.setGranularityAsPerDate(true);
    const payload = _this.setPayloadStatistics("status_4xx", granularity);

    await this.props.drawMultiLineStatusCode4XChart(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(_this, res, unitCategory);

        _this.setState({
          multiChartItems4XData: chartItem
        });
      }
    );
  }

  async drawMultiLineStatusCode5XChart(customerId, singleTarget) {
    const _this = this,
      unitCategory = "percentage";
    const endpoint = _this.getCustomerType(RESELLER);
    const granularity = _this.setGranularityAsPerDate(true);
    const payload = _this.setPayloadStatistics("status_5xx", granularity);
    await this.props.drawMultiLineStatusCode5XChart(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(_this, res, unitCategory);

        _this.setState({
          multiChartItems5XData: chartItem
        });
      }
    );
  }

  resetChart() {
    this.setState({
      multiChartItems2XData: null,
      multiChartItems4XData: null,
      multiChartItems5XData: null
    });
    return true;
  }

  updateCharts(customerId) {
    this.resetChart();
    this.drawMultiLineStatusCode2XChart(customerId, false);
    this.drawMultiLineStatusCode4XChart(customerId, false);
    this.drawMultiLineStatusCode5XChart(customerId, false);
    return true;
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      selectedDistribution,
      multiChartItems2XData,
      multiChartItems4XData,
      multiChartItems5XData,
      clearValue,
      groupBy,
      dateRangeUnit,
      dateRangeDefinition,
      expandedDateRange,
      dateRangeStartDate,
      dateRangeEndDate,
      granularity
    } = this.state;
    let {
      open,
      userData,
      role,
      allDistributions,
      switchUserData,
      entinterface
    } = this.props;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    if (
      entinterface !== null &&
      !!this.hasMatchingEntitlementWithoutPromise("mcdn-premium")
    ) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!!userData && role !== RESELLER && !this.callActive) {
      this.getAllDistributionGroupsLocal(customerId);
      this.callActive = true;
    }

    const dateRangeObject = {
      dateRangeUnit: dateRangeUnit,
      dateRangeUnitObject: dateRangeUnitObject,
      dateRangeDefinition: dateRangeDefinition,
      dateRangeDefinitionObject: dateRangeDefinitionObject,
      expandedDateRange: expandedDateRange,
      dateRangeStartDate: dateRangeStartDate,
      dateRangeEndDate: dateRangeEndDate
    };

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" color="initial" className="page-title">
              Statistics
            </Typography>
            {RESELLER !== role && (
              <Typography variant="h4" color="initial">
                Customize and view performance statistics
              </Typography>
            )}
          </Grid>
        </Grid>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        {
          <React.Fragment>
            {
              <Paper className="padding-left-default padding-right-default padding-bottom-default margin-bottom-16 margin-top-16">
                <div className="margin-top-16 margin-bottom-16 fullwidth pull-left">
                  <Typography variant="h3" color="initial">
                    Status codes
                  </Typography>
                </div>
                <div className="margin-top-16 margin-bottom-16">
                  {this.getDateRangeComponent(dateRangeObject, customerId)}
                </div>
                <div className="clear-both">
                  {allDistributions &&
                    this.getDistributionDropDown(
                      selectedDistribution,
                      allDistributions,
                      customerId
                    )}
                  {!this.hasMatchingEntitlementWithoutPromise("mcdn-premium") &&
                    this.getDropdownComponent(
                      this.checkForEntitlementGroupBy(
                        groupByDropdown,
                        ["cdn-all", "cdn-shield"],
                        4
                      ),
                      "groupBy",
                      groupBy,
                      "Group by",
                      "0",
                      customerId
                    )}
                  {!!this.hasMatchingEntitlementWithoutPromise(
                    "mcdn-premium"
                  ) &&
                    this.getDropdownComponent(
                      this.checkForEntitlementGroupBy(
                        groupByDropdownMcdnPremium,
                        ["cdn-all", "cdn-shield"],
                        3
                      ),
                      "groupBy",
                      groupBy,
                      "Group by",
                      "0",
                      customerId
                    )}
                  {this.getDropdownComponent(
                    this.checkForEntitlementGranularity(
                      granularityDropdown,
                      "mcdn-premium"
                    ),
                    "granularity",
                    granularity,
                    "Granularity",
                    "0",
                    customerId,
                    "",
                    clearValue
                  )}
                  {!expandedDateRange && (
                    <Button
                      color="primary"
                      onClick={() => this.updateCharts(customerId)}
                      variant="contained"
                      size="medium"
                      className="margin-left__device-4 btn-update"
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </Paper>
            }
            {!!multiChartItems2XData && !!multiChartItems2XData.length ? (
              <AmChartCardMultiple
                data={multiChartItems2XData[0].data}
                xAxisText={multiChartItems2XData[0].xAxisText}
                valueY={multiChartItems2XData[0].valueY}
                categoryX={multiChartItems2XData[0].categoryX}
                yAxisText={REQUEST_COUNT_2XX}
                id="chartdiv_multiple1"
                unit={multiChartItems2XData[0].unit}
                additionalData={multiChartItems2XData[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(multiChartItems2XData, null, 540)
            )}

            <div className="margin-top-16"> </div>
            {!!multiChartItems4XData && !!multiChartItems4XData.length ? (
              <AmChartCardMultiple
                data={multiChartItems4XData[0].data}
                xAxisText={multiChartItems4XData[0].xAxisText}
                valueY={multiChartItems4XData[0].valueY}
                categoryX={multiChartItems4XData[0].categoryX}
                yAxisText={REQUEST_COUNT_4XX}
                id="chartdiv_multiple2"
                unit={multiChartItems4XData[0].unit}
                additionalData={multiChartItems4XData[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(multiChartItems4XData, null, 540)
            )}

            <div className="margin-top-16"> </div>
            {!!multiChartItems5XData && !!multiChartItems5XData.length ? (
              <AmChartCardMultiple
                data={multiChartItems5XData[0].data}
                xAxisText={multiChartItems5XData[0].xAxisText}
                valueY={multiChartItems5XData[0].valueY}
                categoryX={multiChartItems5XData[0].categoryX}
                yAxisText={REQUEST_COUNT_5XX}
                id="chartdiv_multiple3"
                unit={multiChartItems5XData[0].unit}
                additionalData={multiChartItems5XData[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(multiChartItems5XData, null, 540)
            )}
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  allDistributions: state.distributions.allDistributions,
  switchUserData: state.user.switchUserData,
  entinterface: state.menuItem.entinterface
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    getAllDistributionGroups,
    topDistributionInMonth,
    resetTopDistribution,
    drawMultiLineStatusCode2XChart,
    drawMultiLineStatusCode4XChart,
    drawMultiLineStatusCode5XChart
  }
)(StatusCodes);
