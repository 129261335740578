import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Header from "../../components/header/header";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Notifications from "@material-ui/icons/Notifications";
import Help from "@material-ui/icons/Help";
import Settings from "@material-ui/icons/Settings";
import classNames from "classnames";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import SideMenu from "./SideMenu";
import authClient from "../../common/helpers/auth";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NormalBreadcrumbs from "../../components/Breadcrums/breadcrums";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import "./sidebar.scss";

const drawerWidth = 260;
const drawerClosedWidth = 53;

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    zIndex: 99,
    position: "relative"
  },
  opened: {
    zIndex: "0!important"
  },
  grow: {
    flexGrow: 1
  },
  toolbar: {
    height: 64
  },
  buttonClose: {
    float: "right",
    marginTop: 8,
    marginRight: 8
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    position: "absolute",
    left: 206
  },
  hide: {
    display: "none"
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    color: "#333",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 1),
      color: "#111"
    },
    marginRight: 15,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    },
    border: "1px solid #ddd"
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    marginTop: 6,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  username: {
    padding: "16px",
    outline: "none"
  },
  paper: {
    boxShadow: "0 0 35px 0 rgba(154,161,171,.15)",
    borderRight: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1,
    position: "relative",
    boxShadow: "0 0 35px 0 rgba(154,161,171,.15)",
    [theme.breakpoints.down("sm")]: {
      zIndex: 1
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: drawerClosedWidth,
    [theme.breakpoints.up("sm")]: {
      width: drawerClosedWidth
    },
    [theme.breakpoints.down("sm")]: {
      width: 0
    }
  },
  pageTitle: {
    "&.opened": {
      marginLeft: drawerWidth + 47
    },
    "&.closed": {
      marginLeft: drawerClosedWidth + 44,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 46
      }
    }
  }
});

class Sidebar extends Component {
  role = "";
  matchedIndex = [];

  state = {
    left: true,
    anchorEl: false,
    documentation: false,
    settings: false,
    notifications: false
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: false });
  };

  handleDrawerClose = () => {
    this.setState({ left: false });
  };

  handleDrawerOpen = () => {
    this.setState({ left: true });
  };

  handleRightDrawerFalse = (event, key) => {
    const target = event.target || event.srcElement;
    let isValidElement = false,
      attr = target.getAttribute("class");
    if (
      !!target.getAttribute("class") &&
      attr.indexOf("drawer-backDrop") !== -1
    ) {
      isValidElement = true;
    }

    if (isValidElement) {
      this.setState({
        settings: false,
        documentation: false,
        anchorEl: false,
        notifications: false,
        [key]: false
      });
    }
  };

  handleRightDrawerToggle = (event, key) => {
    this.setState({
      settings: false,
      documentation: false,
      anchorEl: false,
      notifications: false,
      [key]: !this.state[key]
    });
  };

  handleDrawerToggle = () => {
    localStorage.setItem("menuOpen", !this.state.left);
    this.setState({ left: !this.state.left });
  };

  getCustomerId() {
    const customerId = !!localStorage.SwitchCustomerDetail
      ? JSON.parse(localStorage.SwitchCustomerDetail).id
      : JSON.parse(localStorage.customerDetails).customerId;
    return customerId;
  }

  getrenderRightMenuContent(fullName, email, classes) {
    const { anchorEl, documentation, settings, notifications } = this.state;
    const { switchUserData } = this.props;

    if (!!notifications) {
      return (
        <React.Fragment>
          <Typography variant="h3" className={classes.username} align="left">
            <span className="inactive">Notifications</span>
            <Link to="/notifications" className="subheading pull-right">
              View all
            </Link>
          </Typography>
          <Divider />
        </React.Fragment>
      );
    }
    if (!!anchorEl) {
      return (
        <React.Fragment>
          <Typography variant="h3" className={classes.username} align="left">
            <span className="inactive display-block">Welcome,</span>
            <br />
            {fullName}
            {!!email && <br />}
            {!!email && (
              <a
                href={"mailto:" + email}
                className="inactive display-block height-8"
              >
                {email}
              </a>
            )}
          </Typography>
          <Divider />
          <Link to="/profile">
            <MenuItem onClick={this.handleMenuClose} component="span">
              <i className="material-icons default">person</i>
              {!!switchUserData ? "User " : "My "} Profile
            </MenuItem>
          </Link>
          <Divider />
          <MenuItem
            onClick={() => {
              authClient.signOut(() => window.history.go("/login"));
            }}
            component="span"
            className="logout"
          >
            Log out
          </MenuItem>
        </React.Fragment>
      );
    }

    if (!!documentation) {
      return (
        <React.Fragment>
          <Typography variant="h3" className={classes.username} align="left">
            <span className="inactive">Documentation</span>
          </Typography>
          <Divider />
          <Link
            to={"/swagger-ui?customerId=" + this.getCustomerId()}
            target="_blank"
          >
            <MenuItem onClick={this.handleMenuClose} component="span">
              <i className="material-icons default">work</i>
              API documentation
            </MenuItem>
          </Link>
        </React.Fragment>
      );
    }

    if (!!settings) {
      return (
        <React.Fragment>
          <Typography variant="h3" className={classes.username} align="left">
            <span className="inactive">Settings</span>
          </Typography>
          <Divider />
          <Link to="/user-management">
            <MenuItem onClick={this.handleMenuClose} component="span">
              <i className="material-icons default">settings</i>
              User management
            </MenuItem>
          </Link>
        </React.Fragment>
      );
    }
  }

  render() {
    let fullName, email, renderRightMenuContent, renderRightMenuBoolean;
    const {
      classes,
      userData,
      role,
      locParams,
      switchUserData,
      label
    } = this.props;
    const {
      anchorEl,
      left,
      documentation,
      settings,
      notifications
    } = this.state;
    const path = window.location.pathname;
    const hideMenu = false;
    const notHome = path === "/dashboard";

    if (!!userData) {
      fullName = userData.firstName + " " + userData.lastName;
      email = userData.email;
      this.role = role;
    }

    renderRightMenuContent = this.getrenderRightMenuContent(
      fullName,
      email,
      classes
    );
    renderRightMenuBoolean =
      !!anchorEl || !!documentation || !!settings || !!notifications;

    const selfOrImpersonatedUser = !!switchUserData ? switchUserData : userData;

    const menuItems = (
      <React.Fragment>
        <SideMenu
          permission={this.role}
          userDetails={selfOrImpersonatedUser}
          userData={userData}
          path={path}
          mini={left}
        />
      </React.Fragment>
    );

    const renderRightMenu = (
      <Drawer
        variant="persistent"
        className={classNames({
          "drawer drawer-body": true,
          "drawer-backDrop": !!renderRightMenuBoolean
        })}
        anchor="right"
        onClick={e => this.handleRightDrawerFalse(e, "anchorEl")}
        open={renderRightMenuBoolean}
      >
        <div
          className={classNames({
            "transition-right": true,
            "side-nav-right": true,
            opened: !!settings
          })}
        >
          {!!settings && renderRightMenuContent}
        </div>
        <div
          className={classNames({
            "transition-right": true,
            "side-nav-right": true,
            opened: !!documentation
          })}
        >
          {!!documentation && renderRightMenuContent}
        </div>
        <div
          className={classNames({
            "transition-right": true,
            "side-nav-right": true,
            opened: !!anchorEl
          })}
        >
          {!!anchorEl && renderRightMenuContent}
        </div>
        {hideMenu && (
          <div
            className={classNames({
              "transition-right": true,
              "side-nav-right": true,
              opened: !!notifications
            })}
          >
            {!!notifications && renderRightMenuContent}
          </div>
        )}
      </Drawer>
    );

    return (
      <React.Fragment>
        <div
          className={classNames(classes.appBar, {
            [classes.root]: true
          })}
        >
          <CssBaseline />
          <AppBar
            position="fixed"
            color="default"
            className={classes.appBar + " appBar"}
            elevation={0}
          >
            <div className="header-bg"></div>
            <Toolbar disableGutters>
              <Header toggle={left} label={this.props.label} />
              <IconButton
                onClick={() => this.handleDrawerToggle()}
                className={classNames(classes.menuButton, {
                  selected: !!left,
                  "button-icon": true
                })}
                aria-label="Open drawer"
                title={!!left ? "Close navigation" : "Open navigation"}
              >
                {!left && <MenuIcon />}
                {!!left && <i className="material-icons">menu_open</i>}
              </IconButton>
              <div className={classes.grow} />
              <div className="sidebar-container__right">
                {hideMenu && (
                  <Divider orientation="vertical" className="divider-default" />
                )}
                {hideMenu && (
                  <IconButton
                    aria-haspopup="true"
                    onClick={e =>
                      this.handleRightDrawerToggle(e, "notifications")
                    }
                    color="inherit"
                    className={classNames({
                      selected: !!notifications,
                      "button-icon": true
                    })}
                  >
                    <Notifications />
                  </IconButton>
                )}
                <Divider orientation="vertical" className="divider-default" />
                <IconButton
                  aria-haspopup="true"
                  onClick={e => this.handleRightDrawerToggle(e, "settings")}
                  color="inherit"
                  className={classNames({
                    selected: !!settings,
                    "button-icon": true
                  })}
                >
                  <Settings />
                </IconButton>
                <Divider orientation="vertical" className="divider-default" />
                <IconButton
                  aria-haspopup="true"
                  onClick={e =>
                    this.handleRightDrawerToggle(e, "documentation")
                  }
                  color="inherit"
                  className={classNames({
                    selected: !!documentation,
                    "button-icon": true
                  })}
                >
                  <Help />
                </IconButton>
                <Divider orientation="vertical" className="divider-default" />
                <IconButton
                  aria-haspopup="true"
                  onClick={e => this.handleRightDrawerToggle(e, "anchorEl")}
                  color="inherit"
                  className={classNames({
                    selected: !!anchorEl,
                    "button-icon": true,
                    "profile-menu": true
                  })}
                >
                  <AccountCircle />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderRightMenu}
        </div>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: left,
            [classes.drawerClose]: !left,
            sidebar: true,
            opened: left,
            closed: !left
          })}
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: left,
              [classes.drawerClose]: !left
            })
          }}
          open={left}
        >
          <div className={classes.toolbar}>
            <IconButton
              className={classes.buttonClose}
              color="primary"
              onClick={this.handleDrawerClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          {menuItems}
        </Drawer>
        <Grid
          container
          spacing={0}
          className={classNames(classes.appBar, {
            opened: left,
            closed: !left,
            absoluteElm: true,
            home: !!notHome
          })}
        >
          {!!notHome && (
            <Grid item xs={8}>
              <Typography
                variant="h3"
                className={classNames(classes.pageTitle, {
                  opened: left,
                  closed: !left,
                  "page-title": true
                })}
                align="left"
              >
                {fullName && "Welcome, "}
                <span color="inactive" data-name="customer">
                  {fullName}
                  {fullName && "!"}
                </span>
              </Typography>
            </Grid>
          )}
          <Grid item xs={!notHome ? 12 : 4} align="right">
            <NormalBreadcrumbs
              opened={left}
              label={label}
              impersonate={!!switchUserData}
              locParams={locParams}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  locParams: state.configuration.locParams,
  switchUserData: state.user.switchUserData
});

export default connect(mapStateToProps)(withStyles(styles)(Sidebar));
