import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Notification, {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { getUserDetails } from "../../actions/userDetailsAction";
import {
  getPolicies,
  updatePolicy,
  deletePolicy,
  setDistributionId,
  initPolicies,
  resetPolicies
} from "../../actions/policyAction";
import { setParams } from "../../actions/configurationAction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import {
  stableSort,
  getSorting,
  tableStyles
} from "../../common/helpers/tableRulesAndStyles";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import FormDialogBase from "../Dialog/FormDialogBase";
import FormFieldsEmbeded from "../Dialog/FormFieldsEmbed";
import { Redirect } from "react-router-dom";
import { RESELLER } from "../../common/constants/roles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import shallowCompare from "react-addons-shallow-compare";
import {
  policyEditFormControls,
  policyViewFormControls,
  policyVMMapper,
  policyDataMapper,
  policyMultiPremiumVMMapper,
  setEmptyMultiPolicies,
  setEmptyPolicyObject,
  SINGLE_CDN,
  PRIVATE_CDN,
  SHIELD_CDN,
  MULTI_CDN_VOLUME,
  MULTI_CDN_PREMIUM,
  policyMultiPremiumDataMapper
} from "../../common/constants/policies";

import AlertDialog from "../Dialog/AlertDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import Create from "@material-ui/icons/Create";
import BaseComponent from "../../common/base/BaseComponent";
import { NORESPONSE, tableLoaderEmpty } from "../../common/helpers/loader";
import {
  cdnVMMapper,
  cdnShieldVMMapper,
  cdnEditFormControls,
  cdnViewFormControls,
  commonDataMapper,
  shieldcdnDataMapper,
  getCertificates
} from "../../common/constants/distribution";
import {
  getDistribution,
  addDistribution,
  updateDistribution
} from "../../actions/distributionAction";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  setAvailableCountryList,
  setSelectedCountryList
} from "../../actions/countryListActions";
import { countryList } from "../../common/constants/countryList";
import {
  sortStringCountryFunction,
  sortFunction
} from "../../common/helpers/sharedHelper";
import PageviewIcon from "@material-ui/icons/Pageview";

const EDIT_POLICY = "Edit policy";
const ADD_POLICY = "Add policy";
const MULTICDN_VOLUME = "multi-cdn-volume";
const MULTICDN_PREMIUM = "multi-cdn-premium";

export const policiesTableRows = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  { id: "path", numeric: false, disablePadding: false, label: "Path" },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

export const multiPoliciesTableRows = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

class Policies extends BaseComponent {
  static TOTAL_TABS = 6;
  distCallOnce = false;
  scrollElements;
  elementsTopValues = [];
  hasSelectionCoutriesInit = false;
  certificateInProgress = false;
  policyTableConfig = {
    order: "asc",
    orderBy: "description",
    selected: [],
    page: 0,
    rowsPerPage: 5,
    isLoading: false,
    pageOptions: [],
    openDialog: false,
    openFormDialog: false,
    dialogFormName: EDIT_POLICY,
    progressStatus: 0,
    validation: false,
    dialogViewType: "edit",
    redirectPolicyPage: "",
    maxWidth: "lg",
    selectedPolicy: null,
    customerId: null,
    origins: null,
    originGroups: null,
    singleCDNs: null,
    shieldCDNs: null,
    cdnType: null,
    tabValue: 0,
    pageTabValue: 0,
    isMandatoryView: false,
    isMultiView: false,
    enabled: true,
    isEditFormView: false,
    descriptionPolicy: null,
    certificates: null,
    hasPolicyUpdated: false,
    tlsSettings: {
      tlsType: null,
      certificateId: "",
      supportOlderCiphers: false
    }
  };

  constructor(props) {
    super(props, Policies.TOTAL_TABS);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleDistributionListing = this.handleDistributionListing.bind(this);
    this.getPolicies = this.getPolicies.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.setAvailableCountryValues = this.setAvailableCountryValues.bind(this);
    this.setSelectedCountryValues = this.setSelectedCountryValues.bind(this);
    this.state = { ...initialState(), ...this.policyTableConfig };
    this.certificateInProgress = false;
  }

  componentWillMount() {
    const { getUserDetails, setParams, match, role } = this.props;

    if (this.getLoggedInCustomerRole(role) === RESELLER) {
      return <Redirect to="/" />;
    }

    getUserDetails();
    this.idleTimeCheck();
    if (!!match) {
      setParams(match.params);
    }
  }

  componentWillUnmount() {
    this.props.initPolicies();
    this.props.hideNotification();
    this.props.resetPolicies();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !!nextProps.userData &&
      !!nextProps.switchUserData &&
      nextProps.role !== RESELLER
    ) {
      this.getSelectedDistribution(nextProps.switchUserData.id);
    }
    if (
      !!nextProps.userData &&
      !nextProps.switchUserData &&
      nextProps.role !== RESELLER
    ) {
      this.getSelectedDistribution(nextProps.userData.customerId);
    }
  }

  isSelected = (index, obj) => {
    return this.state[obj].indexOf(index) !== -1;
  };

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  handleDistributionListing(e) {
    e.preventDefault();
    const { type } = this.props.match.params;
    const url = "/configuration/distributions/" + type;
    this.setState({
      redirectToDistributionPage: url
    });
  }

  setDialogHeaderPolicyAndCustomer(header, policy, customerId) {
    this.setState({
      dialogFormName: header,
      dialogViewType: "edit",
      selectedPolicy: policy,
      customerId: customerId
    });
  }

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.checked });
  };

  handleAdd(customerId) {
    if (!customerId) return false;
    const { type, distributionId } = this.props.match.params;

    const policyIndex = 0;
    let cdnType = this.getCDNType(type);
    this.setState({
      tabValue: 0,
      progressStatus: 0,
      cdnType: cdnType,
      isEditFormView: true
    });
    let policy;
    if (cdnType === MULTI_CDN_PREMIUM) {
      policy = setEmptyMultiPolicies(this, cdnType);
    } else {
      policy = setEmptyPolicyObject(this, cdnType);
    }
    this.setDialogHeaderPolicyAndCustomer(ADD_POLICY, policy, customerId);
    this.getOrigins(customerId, cdnType);
    this.getOriginGroups(customerId);
    this.getSingleCDN(customerId);
    this.getShieldCDN(customerId);

    if (cdnType !== MULTI_CDN_PREMIUM) {
      this.setSelectedAndAvailableCountryList(policyIndex, policy);
    }
    this.handleFormDialogToggle();
    this.getLatestPolicies(customerId, cdnType, distributionId);
  }

  getUniqueArrayValue(list, matchArr) {
    let newArr = list;
    const shortMachingIndex = this.getMatchingIndex(list, matchArr);
    for (let ii = shortMachingIndex.length - 1; ii >= 0; ii--) {
      newArr.splice(shortMachingIndex[ii], 1);
    }
    return newArr;
  }

  getMatchingIndex(list, matchArr) {
    let machingIndex = [];
    for (let kk = 0, ll = matchArr.length; kk < ll; kk++) {
      list.filter((item, index) => {
        if (item.alpha2 === matchArr[kk].countryCode) {
          machingIndex.push(index);
        }
        return machingIndex;
      });
    }
    machingIndex.sort(sortFunction);
    const shortMachingIndex = machingIndex;
    return shortMachingIndex;
  }

  getMatchedArrayValue(list, matchArr) {
    let newArr = [];
    const shortMachingIndex = this.getMatchingIndex(list, matchArr);
    for (let ii = shortMachingIndex.length - 1; ii >= 0; ii--) {
      newArr.push(list[shortMachingIndex[ii]]);
    }
    return newArr;
  }

  setSelectedAndAvailableCountryList(policyIndex, policy) {
    const policyObj = !!policy ? policy : this.props.policies[policyIndex];
    let geoAclCountries = policyObj.securitySettings.geoAclSettings;
    const shortCountryList = countryList.sort(sortStringCountryFunction);
    const tempAvaialbleCountryList = Object.assign([], shortCountryList);
    const tempSelectedCountryList = !!policy
      ? []
      : Object.assign([], shortCountryList);
    let avaialbleCountryList = this.getUniqueArrayValue(
      tempAvaialbleCountryList,
      geoAclCountries
    );
    let selectedCountryList = this.getMatchedArrayValue(
      tempSelectedCountryList,
      geoAclCountries
    );
    const sortedAvaialbleCountries = avaialbleCountryList.sort(
      sortStringCountryFunction
    );
    const sortedSelectedCountries = selectedCountryList.sort(
      sortStringCountryFunction
    );

    const { setSelectedCountryList, setAvailableCountryList } = this.props;

    if (!!setSelectedCountryList && !!setAvailableCountryList) {
      setSelectedCountryList(sortedSelectedCountries);
      setAvailableCountryList(sortedAvaialbleCountries);
    }
  }

  updatePolicyViewModel(self, cdnType, policyIndex, copyArr) {
    if (cdnType !== MULTI_CDN_PREMIUM) {
      self.setSelectedAndAvailableCountryList(policyIndex);
      policyVMMapper(self, copyArr);
    } else {
      policyMultiPremiumVMMapper(self, copyArr);
    }
  }

  handleEdit(customerId, id) {
    const { hasPolicyUpdated } = this.state;
    if (!customerId || !id || !!hasPolicyUpdated) return false;
    const { type, distributionId } = this.props.match.params;
    let policyIndex = 0;
    let cdnType = this.getCDNType(type);
    const newArr = this.props.policies.filter((value, index) => {
      if (value.id === id) {
        policyIndex = index;
      }
      return value.id === id;
    });
    // ToDo: remove description
    if (!!newArr && !!newArr.length && !this.state.openFormDialog) {
      const copyArr = JSON.parse(JSON.stringify(newArr[0]));
      this.updatePolicyViewModel(this, cdnType, policyIndex, copyArr);
      this.setState({
        tabValue: 0,
        progressStatus: 100
      });
      this.setDialogHeaderPolicyAndCustomer(EDIT_POLICY, newArr[0], customerId);
      this.getOrigins(customerId, cdnType);
      this.getShieldCDN(customerId);
      if (cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM) {
        this.getSingleCDN(customerId);
        this.getOriginGroups(customerId);
        this.handleFormDialogToggle();
      } else {
        if (!this.state.openFormDialog) {
          this.setState({
            openFormDialog: true
          });
        }
      }
      this.getLatestPolicies(customerId, cdnType, distributionId);
    }
  }

  removeDescription(data) {
    for (let item in data) {
      if (item === "description") {
        delete data.item;
      }
    }
  }

  getDataMapper(self, type) {
    if (type !== SHIELD_CDN) {
      return commonDataMapper(self, type);
    } else {
      return shieldcdnDataMapper(self, type);
    }
  }

  async handleDistSubmit(e, customerId) {
    e.preventDefault();
    if (!!this.checkForRequiredFieldsAllViews()) {
      const { type, distributionId } = this.props.match.params;
      const _this = this;
      _this.setState({
        isLoading: true,
        dialogViewType: "edit",
        cdnType: this.getCDNType(type)
      });
      const payload = this.getDataMapper(this, this.state.cdnType);
      const { cdnType } = this.state;
      const method = "PUT";
      await _this.props.updateDistribution(
        customerId,
        cdnType,
        distributionId,
        method,
        payload,
        function(result) {
          _this.notificationDistUpdate(
            result,
            cdnType,
            distributionId,
            "edited",
            _this
          );
        }
      );
    }
  }

  async handleSubmit(e, customerId) {
    e.preventDefault();
    if (!!this.checkForRequiredFieldsAllViews()) {
      const _this = this;
      const { type, distributionId } = this.props.match.params;
      _this.setState({
        isLoading: true,
        dialogViewType: "edit",
        cdnType: this.getCDNType(type)
      });

      const { cdnType } = this.state;
      let policyId = this.state.policyId;

      let payload;
      if (type === MULTICDN_PREMIUM) {
        payload = policyMultiPremiumDataMapper(this);
      } else {
        payload = policyDataMapper(this);
      }
      if (!!payload && this.state.policyId) {
        let method = "PUT";
        await _this.props.updatePolicy(
          customerId,
          cdnType,
          distributionId,
          policyId,
          method,
          payload,
          function(result) {
            _this.notificationPolicyUpdate(
              result,
              cdnType,
              distributionId,
              "edited",
              _this
            );
            _this.getLatestPolicies(customerId, cdnType, distributionId);
          }
        );
      } else {
        let method = "POST";
        await _this.props.updatePolicy(
          customerId,
          cdnType,
          distributionId,
          policyId,
          method,
          payload,
          function(result) {
            if (typeof result === "object") {
              _this.setState({
                policyId: result.id
              });
            }
            _this.notificationPolicyUpdate(
              result,
              cdnType,
              distributionId,
              "added",
              _this
            );
            _this.getLatestPolicies(customerId, cdnType, distributionId);
          }
        );
      }
    }
  }

  getLatestPolicies(customerId, type, distributionId) {
    this.props.resetPolicies();
    this.props.getPolicies(customerId, type, distributionId);
  }

  async handleDelete(customerId, selected = null) {
    if (!customerId || !selected) return false;
    const { type, distributionId } = this.props.match.params,
      _this = this,
      policyId =  selected instanceof Array ? selected.join() : selected;

    _this.setState({
      isLoading: true,
      cdnType: this.getCDNType(type)
    });
    const { cdnType } = this.state;
    await this.props.deletePolicy(
      customerId,
      cdnType,
      distributionId,
      policyId,
      function(result) {
        if (typeof result === "object") {
          _this.notificationPolicyUpdate(
            { id: policyId, customerId: customerId },
            cdnType,
            distributionId,
            "deleted",
            _this
          );
          _this.getLatestPolicies(customerId, cdnType, distributionId);
        } else {
          const message = result || this.defaultErrorMessage;
          _this.notificationPolicyUpdate(
            message,
            cdnType,
            distributionId,
            "deleted",
            _this
          );
        }
        _this.handleDialogToggle();
      }
    );
  }

  notificationDistUpdate(result, type, distributionId, actionType, self) {
    if (typeof result === "object") {
      const message =
        "Distribution: " + result.id + " is successfully " + actionType + ".";
      self.toggleNotification(message, "success", "Close");
      self.setState({
        tabValue: 0,
        selected: [],
        isEditFormView: false
      });
    } else {
      const message = result;
      self.toggleNotification(message, "error", "Close");
    }
    self.setState({
      isLoading: false,
      isEditFormView: false
    });
  }

  notificationPolicyUpdate(result, type, distributionId, actionType, self) {
    if (typeof result === "object") {
      const message =
        "Policy: " + result.id + " is successfully " + actionType + ".";
      self.toggleNotification(message, "success", "Close");
      if (
        !!self.state.openFormDialog &&
        type !== MULTI_CDN_PREMIUM &&
        type !== MULTI_CDN_VOLUME
      ) {
        self.handleFormDialogToggle();
      } else {
        this.setState({
          hasPolicyUpdated: true
        });
      }
      self.setState({
        tabValue: 0,
        selected: [],
        isEditFormView: false
      });
    } else {
      const message = result;
      self.toggleNotification(message, "error", "Close");
    }
    self.setState({
      isLoading: false,
      isEditFormView: false
    });
  }

  setAvailableCountryValues(values) {
    if (!!this.hasSelectionCoutriesInit) {
      this.setState({
        availableCountryValues: values
      });
    }
  }

  setSelectedCountryValues(values) {
    if (!!this.hasSelectionCoutriesInit) {
      this.setState({
        selectedCountryValues: values
      });
    }
  }

  getCDNType(type) {
    let cdnType = type;
    if (type === MULTICDN_VOLUME) {
      cdnType = MULTI_CDN_VOLUME;
    }
    if (type === MULTICDN_PREMIUM) {
      cdnType = MULTI_CDN_PREMIUM;
    }
    return cdnType;
  }

  getElementsCoordinates() {
    let elementsTopValues = [];
    let elementsHeadingTopValues = [];
    this.scrollElements = document.querySelectorAll('[data-scroll="true"]');
    for (let element of this.scrollElements) {
      elementsTopValues.push(element.getBoundingClientRect());
      if (!!element.querySelector("h3")) {
        elementsHeadingTopValues.push(
          element.querySelector("h3").getBoundingClientRect()
        );
      }
    }
    return elementsHeadingTopValues;
  }
  getIndexOfPositiveNumber(elementsPosition) {
    for (let ii = 0, jj = elementsPosition.length; ii < jj; ii++) {
      if (elementsPosition[ii].top >= 311) {
        if (!ii) {
          return ii;
        } else {
          return ii - 1;
        }
      }
    }
  }

  onScroll(e) {
    if (e.target.getAttribute("class") === "dialog-form-body") {
      let visibleElementIndex = 0;
      this.elementsTopValues = this.getElementsCoordinates();
      visibleElementIndex = this.getIndexOfPositiveNumber(
        this.elementsTopValues
      );
      if (!!visibleElementIndex) {
        this.setState({
          tabValue: visibleElementIndex
        });
      }
    }
  }

  setMandatoryField(cdnType) {
    if (cdnType === MULTI_CDN_PREMIUM || cdnType === MULTI_CDN_VOLUME) {
      this.setState({
        isMandatoryView: true,
        isMultiView: true
      });
    }
  }

  getPolicies(customerId) {
    const { match, getPolicies, setDistributionId } = this.props;
    if (!!match) {
      var { type, distributionId } = match.params;
    }
    const cdnType = this.setCDNType(type);
    this.setMandatoryField(cdnType);
    const { oldDist, policies } = this.props;
    if (
      (!policies || !policies.length || oldDist !== distributionId) &&
      !!customerId
    ) {
      getPolicies(customerId, cdnType, distributionId);
      setDistributionId(distributionId);
    }
  }

  getTabs(cdnType) {
    const tabs = [
      { label: "Basic Settings" },
      { label: "Cache Settings" },
      { label: "Pull Origin Settings" },
      { label: "Delivery settings" }
    ];

    if (cdnType !== MULTI_CDN_PREMIUM) {
      tabs.push({ label: "Security Settings" });
    }

    if (
      !this.state.isMandatoryView &&
      cdnType !== MULTI_CDN_VOLUME &&
      cdnType !== MULTI_CDN_PREMIUM
    ) {
      tabs.push({ label: "Rewrites" });
    }

    Policies.TOTAL_TABS = tabs.length;
    this.tabs = Policies.TOTAL_TABS;

    return tabs;
  }

  setCDNType(type) {
    this.setState({
      cdnType: this.getCDNType(type)
    });
    const { cdnType } = this.state;

    return cdnType;
  }

  returnShortText(cdnType) {
    switch (cdnType) {
      case SINGLE_CDN:
        return "Single CDN";
      case MULTI_CDN_VOLUME:
        return "Multi CDN > Volume";
      case MULTI_CDN_PREMIUM:
        return "Multi CDN > Premium";
      case SHIELD_CDN:
        return "Sheild CDN";
      case PRIVATE_CDN:
        return "PRivate CDN";
      default:
        return "Single CDN";
    }
  }
  getSelectedDistribution(customerId) {
    if (!this.distCallOnce) {
      if (!customerId) return false;
      this.distCallOnce = true;
      const { type, distributionId } = this.props.match.params,
        _this = this;
      _this.setCDNType(type);
      const cdnType = _this.getCDNType(type);
      _this.getDistributionAndScrollTop(customerId, cdnType, distributionId);
    }
  }

  getDistributionAndScrollTop(customerId, cdnType, distributionId) {
    const _this = this;
    this.props.getDistribution(customerId, cdnType, distributionId, function(
      result
    ) {
      _this.getVMMapper(cdnType, result);
    });
  }

  getVMMapper(type, result) {
    if (type !== SHIELD_CDN) {
      cdnVMMapper(this, result);
    } else {
      cdnShieldVMMapper(this, result);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleTabChange = (event, value) => {
    this.setState({ tabValue: value });
    const container = document.querySelector(".react-scroll-view-container");
    if (!!container) {
      const topPos = container.querySelector("[tab = '" + value + "']")
        .offsetTop;
      document.querySelector(".dialog-form-body").scrollTop = topPos;
    }
  };

  handleVerticalTabChange = (event, pageTabValue) => {
    this.setState({ pageTabValue: pageTabValue });
    if (pageTabValue === 1) {
      const { switchUserData, userData } = this.props;
      const customerId = !!switchUserData
        ? switchUserData.id
        : userData.customerId;
      this.getPolicies(customerId);
    }
  };

  setSelectedCountryList(list) {
    this.props.setSelectedCountryList(list);
    this.setGeoAclSettings(list);
  }

  setAvailableCountryList(list) {
    this.props.setAvailableCountryList(list);
  }

  setGeoAclSettings(list) {
    if (!list) return false;
    let geoAclSettingsArray = [];
    let geoAclDefault =
      this.state.cdnType === MULTI_CDN_VOLUME
        ? "reject"
        : this.state.securitySettings.geoAclDefault;
    for (let ii = 0, jj = list.length; ii < jj; ii++) {
      geoAclSettingsArray.push({
        action: geoAclDefault === "reject" ? "allow" : "reject",
        countryCode: list[ii].value
      });
    }
    this.setState({
      securitySettings: {
        ...this.state.securitySettings,
        geoAclSettings: geoAclSettingsArray
      }
    });
  }

  setFormControls(self, type, isEditFormView, isReadOnly) {
    if (!!isEditFormView) {
      return cdnEditFormControls(self, type, isReadOnly);
    } else {
      return cdnViewFormControls(self, type, isReadOnly);
    }
  }

  formatPageHeading(type, object, selector) {
    // make sure selector order is correct
    let string = !!type ? this.returnShortText(type) : "";
    if (!!object && !!selector) {
      for (let ii = 0, jj = selector.length; ii < jj; ii++) {
        if (ii === jj - 1) {
          string += " (" + object[selector[ii]] + ") ";
        } else {
          string += " / " + object[selector[ii]];
        }
      }
    }
    return string;
  }

  getCertificates(customerId) {
    const _this = this;
    getCertificates(customerId, function(result) {
      if (!!result && typeof result === "object") {
        _this.setState({
          certificates: result
        });
      }
    });
  }

  getDialogContent(self, readOnly, isMultiView, cdnType, isEditFormView) {
    return (!!readOnly || !!isMultiView) && !isEditFormView
      ? policyViewFormControls(self, cdnType)
      : policyEditFormControls(self, cdnType);
  }
  getCertificateName(id) {
    const { certificates } = this.state;
    if (!certificates) {
      return "No value";
    }
    const certificateObj = certificates.filter(
      certificate => certificate.id === id
    );
    return (!!certificateObj[0] && !!certificateObj[0].description) ? certificateObj[0].description : "No value";
  }

  checkifNotMultiPolicy(cdnType, policies) {
    if (cdnType === MULTI_CDN_VOLUME || cdnType === MULTI_CDN_PREMIUM) {
      if (typeof policies === "object" && !!policies.length) {
        return true;
      }
    }
    return false;
  }

  getEditButton() {
    return (
      <p className="margin-right-16 margin-top-16 margin-left-16 margin-bottom-16">
        <Button
          variant="outlined"
          fullWidth
          size="large"
          type="button"
          color="primary"
          autoFocus
          onClick={e => this.handleToggleAttribute("isEditFormView")}
        >
          Edit
        </Button>
      </p>
    );
  }

  getPagination(records, pageOptions, rowsPerPage, page) {
    if (typeof records !== "object" || !records.length) return "";

    return (
      <TablePagination
        rowsPerPageOptions={pageOptions}
        component="div"
        count={records.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onPageChange={this.handleChangePage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    );
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      selected,
      order,
      orderBy,
      pageOptions,
      rowsPerPage,
      page,
      redirectToDistributionPage,
      openFormDialog,
      dialogFormName,
      maxWidth,
      isLoading,
      selectedPolicy,
      openDialog,
      isEditFormView,
      cdnType,
      tabValue,
      pageTabValue,
      isMandatoryView,
      isMultiView,
      isReadOnly
    } = this.state;

    let {
      open,
      userData,
      classes,
      policies,
      role,
      switchUserData,
      distribution
    } = this.props;

    let dialogContent,
      customerId,
      distributionContent,
      readOnly,
      statusObj,
      configPending;

    if (this.getLoggedInCustomerRole(role) === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    if (!!redirectToDistributionPage) {
      return <Redirect to={redirectToDistributionPage} />;
    }

    customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!!distribution && !!Object.keys(distribution).length) {
      statusObj = distribution.status;
      configPending = statusObj.status === "pending";

      readOnly = distribution.custom || configPending || false;
      distributionContent =
        this.state.tlsSettings || cdnType === SHIELD_CDN ? (
          <div className="react-scroll-view-container fullWidth">
            {this.setFormControls(this, cdnType, isEditFormView, readOnly)}
          </div>
        ) : (
          ""
        );
    }

    if (!!customerId && !this.certificateInProgress) {
      this.certificateInProgress = true;
      this.getCertificates(customerId, cdnType);
    }

    const hasPolicyDescription =
      !!selectedPolicy &&
      (selectedPolicy.hasOwnProperty("descriptionPolicy") ||
        selectedPolicy.hasOwnProperty("description"));

    if (
      !!hasPolicyDescription &&
      cdnType !== MULTI_CDN_PREMIUM &&
      cdnType !== MULTI_CDN_VOLUME
    ) {
      dialogContent = this.getDialogContent(
        this,
        readOnly,
        isMultiView,
        cdnType
      );
    }

    if (
      !!cdnType &&
      !!policies &&
      !!policies.length &&
      cdnType.indexOf("multi-cdn") !== -1 &&
      !!pageTabValue &&
      !openFormDialog
    ) {
      this.handleEdit(customerId, policies[0].id);
    }

    return (
      <React.Fragment>
        <Typography variant="h3" color="initial" className="page-title">
          Configuration
        </Typography>
        <Typography variant="h4" color="initial">
          Manage your Distributions, origins and certificates
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />

        {cdnType !== MULTI_CDN_PREMIUM &&
          cdnType !== MULTI_CDN_VOLUME &&
          !!selectedPolicy && (
            <FormDialogBase
              openFormDialog={openFormDialog}
              formHeading={dialogFormName}
              cancelFormText="Close"
              maxWidth={maxWidth}
              formSubmit={e => this.handleSubmit(e, customerId)}
              submitDataInProgress={isLoading}
              cancelFormAction={() => this.handleFormDialogToggle()}
              dialogContent={dialogContent}
              tabs={this.getTabs(cdnType)}
              tabValue={tabValue}
              hideTab={isMandatoryView}
              onScroll={e => this.onScroll(e)}
              paddingNode={true}
              showMandatory={true}
              handleTabChange={this.handleTabChange}
              confirmFormText="Save"
              classes={classes}
            />
          )}
        <AlertDialog
          openDialog={openDialog}
          heading="Confirm"
          message={"Are you sure you want to remove policy: " + selected + "?"}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          cancelBtnAction={() => this.handleDialogToggle()}
          confirmBtnAction={() => this.handleDelete(customerId, selected)}
        />
        <Paper className="margin-top-16 position-relative height-min-300">
          <Grid container>
            <Grid item xs={12} className="subheading">
              <IconButton
                className="heading-icon"
                aria-label="Back"
                color="primary"
                onClick={this.handleDistributionListing}
                xs={2}
              >
                <i className="material-icons">arrow_back</i>
              </IconButton>
              {!!distribution && (
                <Typography
                  xs={10}
                  variant="h6"
                  color="initial"
                  className="margin-16 heading-title"
                >
                  {this.formatPageHeading(cdnType, distribution, [
                    "description",
                    "id"
                  ])}
                </Typography>
              )}
            </Grid>
          </Grid>
          <div className={classes.tabRoot + " position-absolute fullheight"}>
            <Tabs
              value={pageTabValue}
              indicatorColor="primary"
              textColor="primary"
              variant="standard"
              tab={"tab" + pageTabValue}
              onChange={this.handleVerticalTabChange}
              className={classes.tabs + " tab"}
            >
              <Tab className="tab-item" label="Distribution settings" />
              <Tab className="tab-item" label="Policy settings" />
            </Tabs>
          </div>
          {distribution === null && !pageTabValue && (
            <div className="pull-right position-relative  text-center">
              {tableLoaderEmpty}
            </div>
          )}
          {!!distribution &&
            !Object.keys(distribution).length &&
            !pageTabValue && (
              <div className="pull-right position-relative text-center">
                {NORESPONSE}
              </div>
            )}
          {!!distribution && !pageTabValue && (
            <div
              className={
                "pull-right content-left margin-bottom-16 " +
                (!isEditFormView ? "readview" : "editable")
              }
            >
              {distributionContent}
              {!!isEditFormView && distributionContent && (
                <p className="margin-right-16 margin-top-16 margin-left-16 margin-bottom-16">
                  <Button
                    variant="outlined"
                    fullWidth
                    size="large"
                    type="submit"
                    color="primary"
                    autoFocus
                    disabled={isLoading}
                    onClick={e => this.handleDistSubmit(e, customerId)}
                  >
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <CircularProgress
                        size={22}
                        thickness={6}
                        color="secondary"
                      />
                    )}
                  </Button>
                </p>
              )}
              {!readOnly &&
                !isEditFormView &&
                distributionContent &&
                this.getEditButton()}
            </div>
          )}

          {policies === null && !!pageTabValue && (
            <div className="pull-right margin-top-16 position-relative  text-center">
              {tableLoaderEmpty}
            </div>
          )}
          {!!policies && !!pageTabValue && (
            <React.Fragment>
              <div className="pull-right">
                <EnhancedTableToolbar
                  numSelected={0}
                  handleDeleteClick={() => this.handleDialogToggle(selected)}
                  background={true}
                  subheading=""
                  toolTipTitle="Add policy"
                  handleAddClick={() => this.handleAdd(customerId)}
                  disabledAdd={
                    this.checkifNotMultiPolicy(cdnType, policies) || !!readOnly
                  }
                  hideDefaultOptions={false}
                />

                {(cdnType === MULTI_CDN_PREMIUM ||
                  cdnType === MULTI_CDN_VOLUME) && (
                  <React.Fragment>
                    {!policies.length && !isEditFormView && (
                      <div className="pull-left margin-top-16 margin-left-16">
                        No policy found, click on add button to create one.
                      </div>
                    )}

                    {(!!policies.length || !!isEditFormView) && (
                      <FormFieldsEmbeded
                        openFormDialog={openFormDialog}
                        formHeading={dialogFormName}
                        cancelFormText="Close"
                        maxWidth={maxWidth}
                        formSubmit={e => this.handleSubmit(e, customerId)}
                        submitDataInProgress={isLoading}
                        cancelFormAction={() => this.handleFormDialogToggle()}
                        dialogContent={this.getDialogContent(
                          this,
                          readOnly,
                          isMultiView,
                          cdnType,
                          isEditFormView
                        )}
                        tabs={this.getTabs(cdnType)}
                        tabValue={tabValue}
                        hideTab={isMandatoryView}
                        onScroll={e => this.onScroll(e)}
                        paddingNode={true}
                        showMandatory={true}
                        handleTabChange={this.handleTabChange}
                        confirmFormText={isEditFormView ? "Save" : ""}
                        classes={classes}
                        readOnlyClass={!isEditFormView ? "normal" : ""}
                      />
                    )}
                    {!readOnly && !isEditFormView && !!policies.length && (
                      <div className="embeded-edit">
                        {this.getEditButton("isEditFormView")}
                      </div>
                    )}
                  </React.Fragment>
                )}
                {cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM && (
                  <React.Fragment>
                    <div className={classes.tableWrapper}>
                      <Table
                        className={classes.table + " table"}
                        aria-labelledby="tableTitle"
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          showSelectAll={false}
                          hideCheckBox={false}
                          hideNumbers={true}
                          rows={
                            cdnType !== MULTI_CDN_VOLUME &&
                            cdnType !== MULTI_CDN_PREMIUM
                              ? policiesTableRows
                              : multiPoliciesTableRows
                          }
                          onRequestSort={this.handleRequestSort}
                          rowCount={policies.length}
                        />
                        <TableBody>
                          {!!policies &&
                            stableSort(policies, getSorting(order, orderBy))
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((n, index) => {
                                return (
                                  <TableRow
                                    tabIndex={-1}
                                    className={!!readOnly ? "read-only" : ""}
                                    title={!!readOnly ? "Read only" : ""}
                                    key={n.id + index}
                                  >
                                    <TableCell>
                                      {!!readOnly && (
                                        <i className="material-icons lock">
                                          lock
                                        </i>
                                      )}
                                      {n[policiesTableRows[0].id]}
                                    </TableCell>
                                    {cdnType !== MULTI_CDN_VOLUME &&
                                      cdnType !== MULTI_CDN_PREMIUM && (
                                        <TableCell>
                                          {n[policiesTableRows[1].id]}
                                        </TableCell>
                                      )}
                                    <TableCell className="actions">
                                      {!readOnly && (
                                        <IconButton
                                          aria-label="Edit"
                                          onClick={() =>
                                            this.handleEdit(customerId, n.id)
                                          }
                                        >
                                          <Create color="secondary" />
                                        </IconButton>
                                      )}
                                      {!!readOnly && (
                                        <IconButton
                                          aria-label="View"
                                          onClick={() =>
                                            this.handleEdit(customerId, n.id)
                                          }
                                        >
                                          <PageviewIcon
                                            title="View details"
                                            color="primary"
                                          />
                                        </IconButton>
                                      )}
                                      {!readOnly && (
                                        <IconButton
                                          aria-label="Delete"
                                          onClick={event => {
                                            this.handleDialogToggle(n.id);
                                            this.setSelected(n.id);
                                          }}
                                        >
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          {!policies.length && (
                            <TableRow style={{ height: 49 }}>
                              <TableCell colSpan={6}>{NORESPONSE}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </div>
                    {!!policies &&
                      this.getPagination(
                        policies,
                        pageOptions,
                        rowsPerPage,
                        page
                      )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  policies: state.policies.policies,
  oldDist: state.policies.distributionId,
  distribution: state.distributions.distribution,
  distributions: state.distributions.distributions,
  switchUserData: state.user.switchUserData,
  availableCountryList: state.countryList.availableCountryList,
  selectedCountryList: state.countryList.selectedCountryList
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    getPolicies,
    initPolicies,
    resetPolicies,
    updatePolicy,
    deletePolicy,
    getDistribution,
    setDistributionId,
    setParams,
    updateDistribution,
    addDistribution,
    setAvailableCountryList,
    setSelectedCountryList
  }
)(withStyles(tableStyles)(Policies));
