import React from "react";
import BaseComponent from "../../common/base/BaseComponent";
import {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import {
  formatDateTimeWithHours,
  differenceInDays
} from "../../common/helpers/dateHelper";
import { dashboardChartVMMapper } from "../../common/constants/chart";
import { LINECHART } from "../../common/constants/chart";

import { RenderAutoSelect } from "../../common/helpers/renderElement";
import DateRange from "../DateRange/DateRange";
import { unitCategoryObject } from "../Statistics/statisticsConstants";

export default class StatisticsBase extends BaseComponent {
  role = "";
  start = "-48 hours";
  end = new Date().getTime();
  hasDateUnChangedButFormatted = false;
  multiCount = 0;
  callActive = false;
  allDistributions = null;
  dateRangeToday = new Date();
  dateRangeStaticDate = new Date();
  dateRangeTwoDaysBack = this.dateRangeStaticDate.setDate(
    this.dateRangeStaticDate.getDate() - 2
  );
  unitAndgranphDisplayText = this.getUnitAndGraphTextAsPerSelection(
    "bandwidth"
  );

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.state = {
      ...initialState(),
      totalsResult: [],
      selectedDistribution: "*",
      metric: "bandwidth",
      groupBy: null,
      dateRangeUnit: "hours",
      dateRangeDefinition: "-48 hours",
      granularity: null,
      clearValue: false,
      expandedDateRange: false,
      dateRangeStartDate: this.dateRangeTwoDaysBack,
      dateRangeEndDate: this.dateRangeToday
    };
  }

  componentWillMount() {
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    const { resetTopDistribution, hideNotification } = this.props;
    if (!!resetTopDistribution) {
      resetTopDistribution();
    }
    hideNotification();
  }

  getTableTitle(text) {
    if (!!text) {
      return text.charAt(0).toUpperCase() + text.substr(1);
    }

    return " ";
  }

  checkForValidAvgMetric(tableDetails) {
    return (
      tableDetails.metric !== "cachehit" && tableDetails.metric !== "bandwidth"
    );
  }

  updateTableHeadingAndColumn() {
    const { groupBy } = this.state;
    const groupByRaw =
      !!groupBy && groupBy !== "none" ? groupBy : "distribution";
    const metric = this.unitAndgranphDisplayText[0].metric;
    const unit = this.unitAndgranphDisplayText[0].unit;
    const tableDetails = {
      metric: metric,
      unit: unit
    };
    this.tableRows[1].label =
      metric.charAt(0).toUpperCase() +
      metric.substr(1) +
      (!this.checkForValidAvgMetric(tableDetails) ? " (avg)" : "");
    this.tableRows[0].label =
      groupByRaw.charAt(0).toUpperCase() + groupByRaw.substr(1);
    return tableDetails;
  }

  setDefaultTableHeading(metric, unit) {
    this.tableRows[1].label = metric.charAt(0).toUpperCase() + metric.substr(1);
    this.tableRows[0].label = metric.charAt(0).toUpperCase() + metric.substr(1);
    return {
      metric: metric,
      unit: unit
    };
  }

  getTotalResults(customerId, metric, unitCategory, isReseller) {
    const _this = this;
    const endpoint = _this.getCustomerType(isReseller);
    const granularity = _this.setStartEndDates(true);
    const groupBy = this.state.groupBy || "distribution";
    const payload = _this.setPayloadStatistics(metric, granularity, groupBy);
    _this.props.getTotalResults(customerId, payload, endpoint, function(res) {
      const masterArray = _this.getTotalResultsView(_this, res);
      if (!!masterArray && !!Object.keys(masterArray).length) {
        const grandTotal = _this.getGrandTotalAndFormat(
          masterArray.data,
          unitCategory,
          metric
        );
        let resultTotals = _this.mergeKeyAndValue(
          masterArray,
          "name",
          "value",
          groupBy
        );
        _this.setState({
          totalsResult: resultTotals,
          grandTotal: grandTotal
        });
      } else {
        _this.setState({
          totalsResult: [],
          grandTotal: 0
        });
      }
    });
  }

  setDateRangeView() {
    this.setState({
      expandedDateRange: !this.state.expandedDateRange
    });
  }

  getMultiChartView(_this, res, unitCategory, yAxisText) {
    let response = res[0] || res;
    let chartItem;
    if (_this.isValidObject(response)) {
      const dataAndKey = _this.formatDataForChart(
        response,
        "value",
        "timestamp"
      );

      chartItem = dashboardChartVMMapper(
        _this,
        dataAndKey.data,
        "",
        "",
        "timestamp",
        "value",
        LINECHART,
        dataAndKey.keys,
        "",
        {
          unitCategory: unitCategory,
          singleTarget: dataAndKey.keys.length === 1 ? false : true
        },
        yAxisText
      );
    } else {
      chartItem = [];
    }

    return chartItem;
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  getUnitAndGraphTextAsPerSelection(metric) {
    const matchedObject = unitCategoryObject.filter(
      item => item.metric === metric
    );
    return matchedObject;
  }

  getDateRangeComponent(dateRangeObject, customerId) {
    return (
      <DateRange
        dateRangeUnit="dateRangeUnit"
        dateRangeUnitValue={dateRangeObject.dateRangeUnit}
        dateRangeUnitObject={dateRangeObject.dateRangeUnitObject}
        dateRangeUnitLabel="Unit"
        dateRangeDefinition="dateRangeDefinition"
        dateRangeDefinitionValue={dateRangeObject.dateRangeDefinition}
        dateRangeDefinitionObject={dateRangeObject.dateRangeDefinitionObject}
        definitionIndex={1}
        expandView={dateRangeObject.expandedDateRange}
        setExpandView={() => this.setDateRangeView()}
        dateRangeDefinitionLabel="Definition"
        dateRangeStartDate={dateRangeObject.dateRangeStartDate}
        dateRangeEndDate={dateRangeObject.dateRangeEndDate}
        onCustomDateChange={dateRange =>
          this.handleDateChangeWithHours(dateRange)
        }
        onUnitChange={(selected, name, cKey, cValue) =>
          this.handleUnitSelection(
            selected,
            name,
            cKey,
            cValue,
            this,
            this.state
          )
        }
        onCategoryChange={(selected, name) =>
          this.handleCategorySelection(
            selected,
            name,
            this,
            this.state,
            customerId
          )
        }
        onClickBtnDateRange={() => this.updateCharts(customerId)}
      />
    );
  }

  handleUnitSelection(selected, name, cKey, cValue, self, parent) {
    parent[name] = selected.value; //updating value
    if (!!cKey && !!cValue) {
      parent[cKey] = cValue; // category
      this.handleHourChange(cValue);
      this.hasDateUnChangedButFormatted = false;
    }
    self.setState({ parent, granularity: null, clearValue: true });
  }

  handleCategorySelection(selected, name, self, parent, customerId) {
    parent[name] = selected.value; //updating value
    self.setState({ parent, granularity: null, clearValue: true });
    this.hasDateUnChangedButFormatted = false;
    this.handleHourChange(selected.value);
  }

  handleStateChange(e, self, parent, key, customerId) {
    parent[key] = e.target.value; //updating value
    self.setState({ parent });
  }

  getSelectedValue(selected, fallback) {
    const val = !!selected && !!selected.value ? selected.value : fallback;
    return val;
  }

  handleDistributionChange(selected, key, self, parent, customerId) {
    const val = this.getSelectedValue(selected, "*");
    parent[key] = val;
    parent["selectedDistribution"] = val; //updating value
    self.setState({ parent });
  }

  handleHourChange(value) {
    this.start = value;
    this.end = new Date().getTime();
    this.isCustomDate = false;
    const dateRangeRaw = this.universalDateAdjuster(this.start);
    this.setDatesForDateRangeForDisplay(dateRangeRaw);
  }

  handleDateChange(item) {
    this.start = formatDateTimeWithHours(item.startDate);
    this.end = formatDateTimeWithHours(item.endDate);
    this.isCustomDate = true;
  }

  handleDateChangeWithHours(item) {
    this.start = formatDateTimeWithHours(item.startDate);
    this.end = formatDateTimeWithHours(item.endDate);
    this.isCustomDate = true;
    this.setDatesForDateRangeForDisplay(item);
  }

  setDatesForDateRangeForDisplay(date) {
    this.setState({
      dateRangeStartDate: date.startDate,
      dateRangeEndDate: date.endDate
    });
  }

  setHours(h) {
    var d = new Date();
    d.setHours(d.getHours() - h);
    return d.getTime();
  }
  setMonth(m) {
    var d = new Date();
    d.setMonth(d.getMonth() - m);
    return d.getTime();
  }
  setDays(d) {
    var dt = new Date();
    dt.setDate(dt.getDate() - d);
    return dt.getTime();
  }
  setMinutes(m) {
    var d = new Date();
    d.setMinutes(d.getMinutes() - m);
    return d.getTime();
  }

  minutesAdjuster(defValue) {
    let startDate;

    if (defValue < 0) {
      startDate = this.setMinutes(-defValue);
    } else {
      startDate = this.setMinutes(defValue);
    }

    return startDate;
  }

  hoursAdjustor(defValue) {
    let startDate;

    if (defValue < 0) {
      startDate = this.setHours(-defValue);
    } else {
      startDate = this.setHours(defValue);
    }

    return startDate;
  }

  daysAdjustor(defValue) {
    let startDate;

    if (defValue < 0) {
      startDate = this.setDays(-defValue);
    } else {
      startDate = this.setDays(defValue);
    }

    return startDate;
  }

  timeAdjustor(startDate, timeAdjusterStart, endDate, timeAdjusterEnd) {
    const timeAdjustement = this.setStartTimeByValue(
      startDate,
      timeAdjusterStart
    );
    const timeAdjustementEnd = this.setEndTimeByValue(endDate, timeAdjusterEnd);

    return { start: timeAdjustement, end: timeAdjustementEnd };
  }
  universalDateAdjuster(str) {
    if (!!parseInt(new Date(str).getTime())) {
      return {
        startDate: new Date(str),
        endDate: this.end
      };
    }
    let timeDefinition = str.split(" ");
    const defValue = parseInt(timeDefinition[0]);
    let startDate,
      endDate = new Date().getTime();

    if (!!defValue || defValue === 0) {
      let unit = timeDefinition[1] || "hour";
      if (!!unit.includes("minute")) {
        startDate = this.minutesAdjuster(defValue);
      }

      if (!!unit.includes("hour")) {
        startDate = this.hoursAdjustor(defValue);
      }

      if (timeDefinition.length === 2) {
        if (!!unit.includes("day")) {
          startDate = this.daysAdjustor(defValue);
        }
      }

      if (timeDefinition.length > 3) {
        const endDefValue = parseInt(timeDefinition[4]);
        const timeAdjusterStart = timeDefinition[2];
        const timeAdjusterEnd = timeDefinition[6] || timeAdjusterStart;

        if (!!unit.includes("day")) {
          startDate = this.daysAdjustor(defValue);

          if (!!endDefValue) {
            endDate = this.daysAdjustor(endDefValue);
          }
          const timeAdjustment = this.timeAdjustor(
            startDate,
            timeAdjusterStart,
            endDate,
            timeAdjusterEnd
          );
          startDate = timeAdjustment.start;
          endDate = timeAdjustment.end;
        }

        if (!!unit.includes("week")) {
          if (defValue === 0) {
            startDate = this.getWeekFirstDay(new Date().getTime());
          }
          if (defValue < 0) {
            startDate = this.setDays(-defValue);
            startDate = this.getWeekFirstDay(startDate);
          }

          if (!!endDefValue || endDefValue === 0) {
            if (endDefValue === 7) {
              endDate = this.getWeekLastDay(new Date().getTime());
            }
            if (endDefValue < 0) {
              endDate = this.setDays(-endDefValue);
              endDate = this.getWeekLastDay(endDate);
            }
          }
          const timeAdjustment = this.timeAdjustor(
            startDate,
            timeAdjusterStart,
            endDate,
            timeAdjusterEnd
          );
          startDate = timeAdjustment.start;
          endDate = timeAdjustment.end;
        }

        if (!!unit.includes("month")) {
          if (defValue === 0) {
            startDate = this.getMonthFirstDay(new Date().getTime());
          }
          if (defValue < 0) {
            startDate = this.setDays(-defValue);
            startDate = this.getMonthFirstDay(startDate);
          }

          if (!!endDefValue || endDefValue === 0) {
            if (endDefValue === 30) {
              //TODO: handling february
              endDate = this.getMonthLastDay(new Date().getTime());
            }
            if (endDefValue < 0) {
              endDate = this.setDays(-endDefValue);
              endDate = this.getMonthLastDay(endDate);
            }
          }
          const timeAdjustment = this.timeAdjustor(
            startDate,
            timeAdjusterStart,
            endDate,
            timeAdjusterEnd
          );
          startDate = timeAdjustment.start;
          endDate = timeAdjustment.end;
        }

        if (!!unit.includes("year")) {
          if (defValue === 0) {
            startDate = this.getYearFirstDay(new Date().getTime());
          }
          if (defValue < 0) {
            startDate = this.setDays(-defValue);
            startDate = this.getYearFirstDay(startDate);
          }

          if (!!endDefValue || endDefValue === 0) {
            if (endDefValue === 365) {
              endDate = this.getYearLastDay(new Date().getTime());
            }
            if (endDefValue < 0) {
              endDate = this.setDays(-endDefValue);
              endDate = this.getYearLastDay(endDate);
            }
          }
          const timeAdjustment = this.timeAdjustor(
            startDate,
            timeAdjusterStart,
            endDate,
            timeAdjusterEnd
          );
          startDate = timeAdjustment.start;
          endDate = timeAdjustment.end;
        }
      }
    } else {
      startDate = this.setHours(48);
    }

    return {
      startDate: startDate,
      endDate: endDate
    };
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  getYearFirstDay(d) {
    let date = new Date(d);
    date.setDate(1);
    date.setMonth(0);
    return date;
  }

  getYearLastDay(d) {
    let date = new Date(d);
    date.setDate(31);
    date.setMonth(11);
    return date;
  }

  getMonthFirstDay(d) {
    let date = new Date(d);
    date.setDate(1);
    return date;
  }

  getMonthLastDay(d) {
    var d = new Date(d);
    const fy = d.getFullYear();
    const fm = d.getMonth() + 1;
    let daysInMonth = this.daysInMonth(fm, fy);
    d.setDate(daysInMonth);
    return d;
  }

  getWeekFirstDay(d) {
    d = new Date(d);
    let day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getWeekLastDay(d) {
    d = new Date(d);
    let ld = d.getDate() - (d.getDay() - 1) + 6;
    return new Date(d.setDate(ld));
  }

  getOffsetDetails(offset) {
    const lOffset = parseInt(offset);
    const timeCollection = { h: 0, m: 0, s: 0, ms: 0 };
    if (!!offset && offset >= 0) {
      timeCollection.h = -((lOffset - (lOffset % 60)) / 60);
      timeCollection.m = -(lOffset % 60);
    }
    if (!!offset && offset < 0) {
      timeCollection.h = -((lOffset - (lOffset % 60)) / 60);
      timeCollection.m = -(lOffset % 60);
    }
    return timeCollection;
  }

  setStartTimeByValue(timestamp, value) {
    let date = new Date(timestamp);
    if (value === "full") {
      const tc = this.getOffsetDetails(date.getTimezoneOffset());
      date.setHours(tc.h, tc.m, tc.s, tc.ms);
    }
    return date;
  }

  setEndTimeByValue(timestamp, value) {
    let date = new Date(timestamp);
    if (value === "full") {
      const tc = this.getOffsetDetails(date.getTimezoneOffset());
      date.setDate(date.getDate() + 1);
      date.setHours(tc.h, tc.m - 1, tc.s, tc.ms);
    }
    return date;
  }

  granularityAsPerCustomDate(startDate, endDate) {
    let granularity = "5m";
    const diffInDays = differenceInDays(startDate, endDate);
    if (diffInDays <= 3) {
      granularity = "5m";
    } else if (diffInDays > 3 && diffInDays <= 7) {
      granularity = "15m";
    } else if (diffInDays > 7 && diffInDays <= 31) {
      granularity = "1h";
    } else if (diffInDays > 31) {
      granularity = "1d";
    }
    return granularity;
  }

  handleHourChangeNewEndPoint(value, callback) {
    const dateRangeObject = this.universalDateAdjuster(value);
    this.start = formatDateTimeWithHours(dateRangeObject.startDate);
    this.end = formatDateTimeWithHours(dateRangeObject.endDate);
    callback(this.start, this.end);
    this.isCustomDate = false;
  }

  getDistributionDropDown(selectedDistribution, allDistributions, customerId) {
    return (
      <span className="auto-complete margin-left-6 order2 border-effect">
        <RenderAutoSelect
          className="width-200"
          id="allDistributions"
          name="allDistributions"
          value={selectedDistribution}
          defaultValue="0"
          placeholderSuffix={"distribution"}
          handleChange={(selection, name) =>
            this.handleDistributionChange(
              selection,
              name,
              this,
              this.state,
              customerId
            )
          }
          items={this.getTextValueFormat(allDistributions, [
            "id",
            "description"
          ])}
          label="Distribution"
        />
      </span>
    );
  }

  getDropdownComponent(
    object,
    name,
    value,
    label,
    defaultIndex,
    customerId,
    newClass,
    clearValue
  ) {
    return (
      !!object && (
        <span className={"auto-complete border-effect " + newClass}>
          <RenderAutoSelect
            className="width-200 margin-bottom-16"
            id={name}
            defaultValue={defaultIndex}
            name={name}
            placeholderSuffix={label.toLowerCase()}
            value={value}
            clearValue={clearValue}
            handleChange={(selection, name) =>
              this.handleAutoSelectChange(
                selection,
                name,
                this,
                this.state,
                customerId,
                clearValue
              )
            }
            items={object}
            label={label}
          />
        </span>
      )
    );
  }
}
