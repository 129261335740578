import { lighten } from "@material-ui/core/styles/colorManipulator";
import purple from "@material-ui/core/colors/purple";

export const MAX_HEIGHT = 24;
export function createTableData(id, rest) {
  return { id: id, ...rest };
}

export function scrollInToView(value) {
  let elm = document.querySelector("#root");
  if (!!elm) {
    elm.className = "";
    setTimeout(function() {
      window.scrollTo(0, 0);
    }, 300);
  }
}
export function desc(a, b, orderBy, object) {
  if (!!object && orderBy === object.name) {
    return getSortedStringData(
      a[orderBy][object.sortBy],
      b[orderBy][object.sortBy]
    );
  }
  return getSortedData(a[orderBy], b[orderBy]);
}

function getSortedStringData(a, b) {
  return a.localeCompare(b);
}

function normalSort(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

function getSortedData(a, b) {
  return normalSort(a, b);
}

export function stableSort(array, cmp) {
  if (!array || !array.length || typeof array === "string") return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function getSorting(order, orderBy, object) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy, object)
    : (a, b) => -desc(a, b, orderBy, object);
}

export const tableStyles = theme => ({
  tabRoot: {
    display: "inline-block",
    width: "100%",

    "&~div": {
      width: "100%",
      marginTop: 49
    }
  },
  tabs: {
    borderBottom: "1px solid #e8e8e8"
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 1020,
    position: "relative"
  },
  tableWrapper: {
    overflowX: "auto"
  },
  colorSwitchBase: {
    color: purple[300],
    "&$colorChecked": {
      color: purple[500],
      "& + $colorBar": {
        backgroundColor: purple[500]
      }
    }
  },
  alignCenter: {
    textAlign: "center"
  },
  colorBar: {},
  colorChecked: {},
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#52d869"
      }
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  iOSChecked: {
    transform: "translateX(15px)",
    "& + $iOSBar": {
      opacity: 1,
      border: "none"
    }
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: "solid 1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  iOSIcon: {
    width: 24,
    height: 24
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1]
  },
  formControl: {
    width: "100%"
  },
  group: {
    margin: `${theme.spacing(1)}px 0`
  }
});

export const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.primary,
    "&>button": {
      "box-shadow":
        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
      "box-sizing": "border-box",
      "min-height": 36,
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    }
  },
  title: {
    flex: "0 0 auto"
  }
});
