import {
  ERROR_IN_INVALIDATION,
  GET_INVALIDATION,
  GET_INVALIDATION_LIST
} from "./types";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import {
  apiPostCall,
  apiGetCall,
  DEFAULT_ERROR_MESSAGE
} from "../actions/baseActions";

export function resetInvalidationList() {
  return function(dispatch) {
    dispatch({
      type: GET_INVALIDATION_LIST,
      data: null
    });
  };
}

export function getInvalidationList(customerId, start, count) {
  let url =
    userSession.getSessionItem("apiUrl") +
    customerId +
    "/invalidate?start=" +
    start +
    "&count=" +
    count;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_INVALIDATION,
    typeSuccess = GET_INVALIDATION_LIST,
    method = "GET";
  const callback = function() {};
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function getInvalidation(customerId, invalidationId, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    customerId +
    "/invalidate/" +
    invalidationId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_INVALIDATION,
    typeSuccess = GET_INVALIDATION,
    method = "GET";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function Invalidate(customerId, payload, callback, diffrentiator) {
  let url = userSession.getSessionItem("apiUrl") + customerId + "/invalidate";
  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_INVALIDATION,
    typeSuccess = GET_INVALIDATION_LIST,
    postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    function(response) {
      const action = method === "POST" ? "added" : "edited";
      let type = "success";
      console.log(response);
      let message = `Successfully ` + action + ` new record: ${response.id}`;
      if (response !== "GENERAL-UNKNOWN") {
        if (!!diffrentiator) {
          message =
            "The invalidation list was accepted. The unique ID of this invalidation is " +
            response.id +
            ".";
        }
      } else {
        message = !!response ? response : DEFAULT_ERROR_MESSAGE;
        type = "error";
      }
      callback(message, type);
    }
  );
}
