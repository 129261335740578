import React from "react";
import { connect } from "react-redux";
import Notification, {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { getUserDetails } from "../../actions/userDetailsAction";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import { applyFilteredData } from "../../actions/distributionAction";
import { RenderSearchTableInput } from "../../common/helpers/renderElement";
import {
  getOrigins,
  resetOrigins,
  deleteOrigin,
  getOrigin,
  updateOrigin,
  addOrigin
} from "../../actions/originsAction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import AlertDialog from "../Dialog/AlertDialog";
import FormDialogBase from "../Dialog/FormDialogBase";
import {
  simpleDataMapper,
  simpleEditFormControls,
  advancedEditFormControls,
  objectStorageEditFormControls,
  groupsEditFormControls,
  simpleVMMapper,
  advancedVMMapper,
  objectStorageVMMapper,
  groupsVMMapper,
  advancedDataMapper,
  objectStorageDataMapper,
  groupsDataMapper,
  getSimpleOrigins,
  initSimpleVMMapper,
  initAdvancedVMMapper,
  initObjectStorageVMMapper,
  initGroupsVMMapper,
  SIMPLE,
  ADVANCED,
  ORIGIN_GROUP,
  OBJECT_STORAGE
} from "../../common/constants/origin";

import {
  stableSort,
  getSorting,
  tableStyles
} from "../../common/helpers/tableRulesAndStyles";

import { Redirect } from "react-router-dom";
import { RESELLER } from "../../common/constants/roles";
import { NORESPONSE, tableLoaderEmpty } from "../../common/helpers/loader";
import BaseComponent from "../../common/base/BaseComponent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Create from "@material-ui/icons/Create";
import { shortByDate } from "../../common/helpers/dateHelper";

export const originTableRows = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  { id: "host", numeric: false, disablePadding: false, label: "Host" },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

class Origins extends BaseComponent {
  callOnce = 0;
  currentEndPoint = "";
  tableListFull;

  constructor(props) {
    super(props);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.state = {
      ...initialState(),

      order: "desc",
      orderBy: "updated",
      selected: [],
      tableSearchText: "",
      page: 0,
      rowsPerPage: 10,
      isLoading: false,
      pageOptions: [],
      openDialog: false,
      openFormDialog: false,
      dialogFormName: "Edit origin",
      progressStatus: 100,
      validation: false,
      dialogViewType: "edit",
      value: 0,
      simpleOrigins: null,
      maxWidth: "lg"
    };
  }

  componentWillMount() {
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    const { resetOrigins, hideNotification } = this.props;
    if (!!resetOrigins) {
      resetOrigins();
    }
    hideNotification();
  }

  componentWillReceiveProps(nextProps) {
    const { type } = nextProps.match.params;
    const prevParams = this.props.match.params;
    this.currentEndPoint = this.getRelativeType(type);
    if (prevParams.type !== type) {
      this.setState({
        page: 0,
        tableSearchText: ""
      });
      this.callOnce = 0;
      this.tableListFull = null;
    }
  }

  getRelativeType(type) {
    switch (type) {
      case SIMPLE:
        return "simple";
      case ADVANCED:
        return "advanced";
      case OBJECT_STORAGE:
        return "objectstorage";
      case ORIGIN_GROUP:
        return "groups";
      default:
        return "simple";
    }
  }

  getOriginalType(type) {
    switch (type) {
      case "simple":
        return SIMPLE;
      case "advanced":
        return ADVANCED;
      case "objectstorage":
        return OBJECT_STORAGE;
      case "groups":
        return ORIGIN_GROUP;
      default:
        return "simple";
    }
  }

  getDTOMapper(self, type) {
    switch (type) {
      case SIMPLE:
        return simpleDataMapper(self);
      case ADVANCED:
        return advancedDataMapper(self);
      case OBJECT_STORAGE:
        return objectStorageDataMapper(self);
      case ORIGIN_GROUP:
        return groupsDataMapper(self);
      default:
        return simpleDataMapper(self);
    }
  }

  getVMMapper(type, result) {
    switch (type) {
      case SIMPLE:
        simpleVMMapper(this, result);
        break;
      case ADVANCED:
        advancedVMMapper(this, result);
        break;
      case "objectstorage":
        objectStorageVMMapper(this, result);
        break;
      case "groups":
        groupsVMMapper(this, result);
        break;
      default:
        simpleVMMapper(this, result);
    }
  }

  isSelected = (index, obj) => {
    return this.state[obj].indexOf(index) !== -1;
  };

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
    return true;
  }

  async getOrigins(customerId, type) {
    const _this = this;
    _this.props.getOrigins(customerId, type);
    // if origin group then get the simple origins too
    if (type === "groups") {
      await getSimpleOrigins(customerId, results => {
        if (typeof results === "object") {
          this.setState({
            simpleOrigins: results
          });
          return true;
        }
      });
    }
  }

  checkForErrorAndReset(orgState, value, comparator, callback) {
    const elmValue = orgState[value];
    if (!elmValue) return true;
    if (
      elmValue === "backup" &&
      (this.state[comparator] === "consistent" ||
        this.state[comparator] === "sticky")
    ) {
      callback();
      return true;
    }
    return false;
  }
  notificationAndListUpdate(result, type, actionType, self) {
    if (typeof result === "object") {
      const message =
        "Origin: " + result.id + " is successfully " + actionType + ".";
      self.toggleNotification(message, "success", "Close");
      self.closeDialog();
      self.props.resetOrigins();
      self.props.getOrigins(result.customerId, type);
    } else {
      const message = result;
      self.toggleNotification(message, "error", "Close");
    }
    self.setState({
      isLoading: false
    });
    return true;
  }

  async handleDelete(customerId, selected) {
    if (!customerId || !selected) return false;
    const type = this.currentEndPoint,
      _this = this;
    _this.setState({
      isLoading: true
    });
    await this.props.deleteOrigin(customerId, type, selected, function(result) {
      if (typeof result === "object") {
        const message = "Origin: " + result.message + " successfully.";
        _this.toggleNotification(message, "success", "Close");
        _this.props.resetOrigins();
        _this.props.getOrigins(customerId, type);
      } else {
        const message = result || this.defaultErrorMessage;
        _this.toggleNotification(message, "error", "Close");
      }
      _this.setState({
        isLoading: false,
        selected: []
      });
      _this.handleDialogToggle();
    });
  }

  closeDialog() {
    this.setState({
      selected: []
    });
    this.handleFormDialogToggle();
  }
  async handleSubmit(e, customerId) {
    e.preventDefault();
    if (!!this.checkForRequiredFieldsAllViews()) {
      const _this = this;
      _this.setState({
        isLoading: true,
        dialogViewType: "edit"
      });
      const oType = this.getOriginalType(this.currentEndPoint);
      const payload = this.getDTOMapper(this, oType);
      let type = this.currentEndPoint,
        originId = this.state.selected;
      if (this.state.dialogFormName === "Edit origin") {
        let method = "PUT";
        await _this.props.updateOrigin(
          customerId,
          type,
          originId,
          method,
          payload,
          function(result) {
            _this.notificationAndListUpdate(result, type, "edited", _this);
          }
        );
      } else {
        let method = "POST";
        _this.props.addOrigin(customerId, type, method, payload, function(
          result
        ) {
          _this.notificationAndListUpdate(result, type, "added", _this);
        });
      }
    }
  }

  resetValueForKey(selectedFieldValue, key, value) {
    if (!selectedFieldValue) {
      this.setState({
        [key]: value
      });
    }
  }
  async handleEdit(customerId, id) {
    if (!customerId || !id) return false;
    this.setState({
      dialogFormName: "Edit origin",
      dialogViewType: "edit",
      selected: [id]
    });
    const type = this.currentEndPoint,
      _this = this;
    await _this.props.getOrigin(customerId, type, id, function(result) {
      _this.getVMMapper(type, result);
      _this.handleFormDialogToggle();
    });
  }
  handleAdd(customerId, type) {
    if (!customerId) return false;
    this.setState({
      dialogFormName: "Add new origin",
      dialogViewType: "edit"
    });
    const rType = this.getOriginalType(type);
    this.initVMMapper(customerId, rType);
  }
  initVMMapper(customerId, type) {
    if (type === SIMPLE) {
      const data = {
        customerId: customerId,
        originDescription: "New simple",
        host: "",
        type: null
      };

      initSimpleVMMapper(this, data);
      this.handleFormDialogToggle();
    }

    if (type === ADVANCED) {
      const data = {
        customerId: customerId,
        originDescription: "New advanced",
        host: "",
        httpPort: "",
        httpsPort: "",
        path: "/",
        type: null
      };

      initAdvancedVMMapper(this, data);
      this.handleFormDialogToggle();
    }

    if (type === OBJECT_STORAGE) {
      const data = {
        customerId: customerId,
        originDescription: "New object storage",
        accessKeyId: "",
        bucket: "",
        host: "",
        secretAccessKey: null
      };

      initObjectStorageVMMapper(this, data);
      this.handleFormDialogToggle();
    }

    if (type === ORIGIN_GROUP) {
      const data = {
        customerId: customerId,
        originDescription: "New origin group",
        balancingMethod: null,
        failTimeOut: 0,
        keepAlive: true,
        keepAliveSeconds: 0,
        members: [
          {
            originHost: "",
            originId: "",
            status: ""
          },
          {
            originHost: "",
            originId: "",
            status: ""
          }
        ]
      };

      initGroupsVMMapper(this, data);
      this.handleFormDialogToggle();
    }
  }

  setEditFormControls(self, type) {
    const oType = this.getOriginalType(type);
    switch (oType) {
      case SIMPLE:
        return simpleEditFormControls(self);
      case ADVANCED:
        return advancedEditFormControls(self);
      case OBJECT_STORAGE:
        return objectStorageEditFormControls(self);
      case ORIGIN_GROUP:
        return groupsEditFormControls(self);
      default:
    }
  }

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.checked });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleAutoSelectChange(selected, name) {
    const value = !!selected ? selected.value : null;
    this.setState({ [name]: value });
  }

  returnShortText(type) {
    switch (type) {
      case "simple":
        return "Simple";
      case "advanced":
        return "Advanced";
      case "groups":
        return "Origin Group";
      case "objectstorage":
        return "Object Storage";
      default:
        return "Simple";
    }
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      selected,
      order,
      orderBy,
      pageOptions,
      rowsPerPage,
      page,
      openDialog,
      isLoading,
      dialogFormName,
      openFormDialog,
      dialogViewType,
      maxWidth,
      tableSearchText
    } = this.state;

    let { open, userData, role, classes, origins, switchUserData } = this.props;
    let dialogContent, customerId;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!!customerId && !!this.currentEndPoint && !this.callOnce) {
      this.callOnce = 1;
      this.getOrigins(customerId, this.currentEndPoint);
    }

    dialogContent =
      dialogViewType === "edit" ? (
        <div className="react-scroll-view-container fullWidth">
          {this.setEditFormControls(this, this.currentEndPoint)}
        </div>
      ) : (
        ""
      );

    const originsSorted = shortByDate(origins, "updated", "created");

    return (
      <React.Fragment>
        <Typography variant="h3" color="initial" className="page-title">
          Configuration
        </Typography>
        <Typography variant="h4" color="initial">
          Manage your distributions, origins and certificates
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        <FormDialogBase
          openFormDialog={openFormDialog}
          formHeading={dialogFormName}
          cancelFormText="Close"
          maxWidth={maxWidth}
          formSubmit={e => this.handleSubmit(e, customerId)}
          submitDataInProgress={isLoading}
          cancelFormAction={() => this.closeDialog()}
          dialogContent={dialogContent}
          paddingNode={true}
          confirmFormText="Save"
        />
        <AlertDialog
          openDialog={openDialog}
          heading="Confirm"
          message={"Are you sure you want to remove origin: " + selected + "?"}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          cancelBtnAction={() => this.handleDialogToggle()}
          confirmBtnAction={() => this.handleDelete(customerId, selected)}
        />
        <Paper className="margin-top-default">
          <Typography variant="h4" color="initial" className="margin-16">
            Origins {">"}
            <span> {this.formatPageHeading(this.currentEndPoint)}</span>
          </Typography>
          {originsSorted !== null && (
            <EnhancedTableToolbar
              numSelected={0}
              background={true}
              subheading=""
              toolTipTitle="Add origin"
              handleAddClick={() =>
                this.handleAdd(customerId, this.currentEndPoint)
              }
              hideDefaultOptions={false}
            />
          )}
          {!originsSorted && tableLoaderEmpty}
          {!!originsSorted && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <RenderSearchTableInput
                  onChange={e =>
                    this.handleSearchTable(e, originsSorted, "GET_ORIGINS")
                  }
                  onClear={name => {
                    this.handleClearSearch(name, "GET_ORIGINS");
                  }}
                  className="table-search-field"
                  value={tableSearchText}
                />
                <Table
                  className={classes.table + " table"}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    showSelectAll={false}
                    hideCheckBox={false}
                    rows={originTableRows}
                    hideNumbers={true}
                    onRequestSort={this.handleRequestSort}
                    rowCount={originsSorted.length}
                  />
                  <TableBody>
                    {stableSort(originsSorted, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((n, index) => {
                        return (
                          <TableRow tabIndex={-1} key={n.id + index}>
                            <TableCell>{n[originTableRows[0].id]}</TableCell>
                            <TableCell>{n[originTableRows[1].id]}</TableCell>
                            <TableCell className="actions">
                              <IconButton
                                aria-label="Edit"
                                onClick={() =>
                                  this.handleEdit(customerId, n.id)
                                }
                              >
                                <Create color="secondary" />
                              </IconButton>
                              <IconButton
                                aria-label="Delete"
                                onClick={event => {
                                  this.handleDialogToggle(n.id);
                                  this.setSelected(n.id);
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {!!originsSorted && !originsSorted.length && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={originTableRows.length}>
                          {NORESPONSE}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={pageOptions}
                component="div"
                count={originsSorted.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  origins: state.origins.origins,
  origin: state.origins.origin,
  switchUserData: state.user.switchUserData
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    getOrigins,
    resetOrigins,
    hideNotification,
    getUserDetails,
    deleteOrigin,
    getOrigin,
    updateOrigin,
    addOrigin,
    applyFilteredData
  }
)(withStyles(tableStyles)(Origins));
