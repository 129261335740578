import React, { PureComponent } from "react";
import SimpleDialog from "../Dialog/SimpleDialog";
import { RenderLockStaticButton } from "../../common/helpers/renderElement";

class TwoFactorAuthComp extends PureComponent {
  constructor(props) {
    super(props);
    this.activate2FA = this.activate2FA.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.state = {
      openDialog: false,
      dialogToggleFlag: false,
      dialogContent: "",
      message: "",
      type: "",
      label: "",
      color: "inherit",
      userAccount: "",
      userQRKey: "",
      userQRImg: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive !== this.props.isActive) {
      this.activate2FA(this.props.details);
    }
  }

  handleDialogClose() {
    this.setState((state) => ({
      dialogToggleFlag: !state.dialogToggleFlag,
      reopen: !state.reopen,
    }));
  }

  activate2FA(userData) {
    const _this = this;
    let fa2Account = userData.email + ":" + userData.label;
    let qrCode =
      "https://chart.googleapis.com/chart?chs=200x200&chld=M%%7C0&cht=qr&chl=otpauth://totp/" +
      fa2Account +
      "%3Fsecret%3D" +
      userData.fa2Secret;
    _this.setState({
      userAccount: fa2Account,
      userQRKey: userData.fa2Secret,
      userQRImg: qrCode,
      dialogToggleFlag: true,
    });
    userData.fa2Active = true;
  }

  render() {
    const { userQRImg, userAccount, userQRKey, dialogToggleFlag, reopen } =
      this.state;
    const { isActive } = this.props;

    const dialogContent = (
      <React.Fragment>
        <h3 className="margin-none">Two-factor authentication information</h3>
        <p className="margin-top-none">
          In order to generate 2FA secret codes you need to use an authenticator
          app on your phone (e.g Google Authenticator.) You can scan the next
          bar code :
        </p>
        <img src={userQRImg} width="40%" alt="qrcode" />
        <br />
        <p>
          or enter data manually:
          <br />
          Account: {userAccount}
          <br />
          Key: {userQRKey}
        </p>
      </React.Fragment>
    );

    return (
      <>
        <SimpleDialog
          fullWidth
          dialogToggleFlag={dialogToggleFlag}
          handleDialogClose={this.handleDialogClose}
          dialogContent={dialogContent}
        />
        <div className="container-block secondFactorButton fullwidth">
          <RenderLockStaticButton
            label="2FA authentication"
            open={isActive}
            toolTipTitle="Post account activation, we automatically enable 2FA for extra safety"
            reopen={reopen}
            onReopen={this.handleDialogClose}
            reOpenTitle="Reopen Two-factor authentication dialog"
          />
        </div>
      </>
    );
  }
}

export default TwoFactorAuthComp;
