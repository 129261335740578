import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  RenderSwitchWithToolTip,
  RenderButtonClick,
  RenderSelectMultipleWithToolTip,
  RenderInputWithToolTip,
  RenderSwitch,
  RenderInputWithOutLabel,
  ClickableTooltip,
  RenderReadOnlyInput,
  RenderSwitchWithOutToolTip,
  RenderAutoSelectWithToolTip,
  RenderAutoSelect
} from "../../common/helpers/renderElement";
import { session as userSession } from "../helpers/sessionValues";
import authClient from "../helpers/auth";
import Typography from "@material-ui/core/Typography";

import CountryListSelection from "../../components/Country/CountryListSelection";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { checkMinimumLength } from "../helpers/sharedHelper";
import axios from "axios";
import AlertDialog from "../../components/Dialog/AlertDialog";

export const MULTI_CDN_VOLUME = "multi-cdn/volume";
export const MULTI_CDN_PREMIUM = "multi-cdn/premium";
export const SINGLE_CDN = "single-cdn";
export const PRIVATE_CDN = "private-cdn";
export const SHIELD_CDN = "shield-cdn";

export const POLICIES_TOOLTIP = {
  PASSCOOKIE:
    "This will pass the cookie header as set by the origin, caution: will potentially have a big impact on cache-ability.",
  PASSVARY:
    "This will pass the Vary header as set by the origin and will take it into account when caching assets. Caution: will potentially have a big impact on cache-ability.",
  PASSTHROUGHALLHEADERS:
    "This will pass through the headers as set by the origin, vary and cookies will need to be enabled separately.",
  PATH:
    "Path or Regex to match this policy with. Multi-CDN only supports a single root policy.",
  DISCRIPTION: "The description of the policy, for easy reference.",
  DEFAULT_TTL:
    "The default TTL for caching the content in the event the origin is not sending any TTL or if the TTL is not honored.",
  HOST_HEADER:
    "The host header that should be send to the origin. By default it uses the hostname of the origin.",
  TTL_OVERRIDE:
    "If the TTL is not inherited, use this TTL to send to the end-users.",
  SECRET: "Simple token secret. At least 4 characters.",
  AESKEY: "Encrypted token AES key",
  SHAKEY: "Encrypted token SHA key",
  PATH_COMPONENT:
    "Number of path components to calculate the path with. Default is all (-1)",
  UPS_HEADER: "Name of the header to be send to the origin.",
  UPS_VALUE: "Value of the header to be send to the origin.",
  REWRITES: "Rewrite rules for setting specific rewrites on the CDN.",
  PASS_HEADER:
    "Header names that should be cached and passed to the end-users. By default headers that are not required are stripped.",
  SUBNET: "IP ACL subnet to match the rule on.",
  STATIC_HEADER: "The name of the static header to be send to end-users.",
  STATIC_VALUE: "The value of the static header to be send to the end-users.",
  REFERRER: "The referrer to match the rule to. For example: *example.org*.",
  ORIGIN_ID: "The identifier or the origin.",
  REWRITES_FLAG: "Rewrite rule flag.",
  ORIGIN_GROUP_ID: "The origin group identifier.",
  SHIELD_ID: "The shielding distribution identifier.",
  PROTOCOL: "Protocol to be used for the CDN to connect to the origin.",
  GEOACT: "GEO ACL",
  GEOACT_SETTINGS: "GEO ACL settings",
  COUNTRY_LIST: "GEO ACL Country list",
  IPACT: "IP ACL",
  IPACT_SETTINGS: "IP ACL settings",
  REFERRERACL: "Referer ACL",
  REFERRERACL_ACTION: "Referer ACL action",
  METHOD: "The method of the token mechanism to use.",
  TOKEN_VENDOR: "The vendor of the token mechanism to use.",
  REGEX: "The rewrite rule regex to match on.",
  REPLACEMENT: "The rewrite rule replacement expression.",
  ALLOWED_METHODS:
    "Allowed methods to pass through the CDN. Typically only GET will be cached.",
  TRANSFER_RATE:
    "The maximum transfer rate after configured amount of data has been consumed by the client.",
  BURST_SIZE: "The amount of data the client can consume without any limit.",
  BYPASS: "Bypassing the cache in its entirety. No caching will be performed.",
  SEGMENTED:
    "Use segmented downloading of files that are not yet cached. In order to speed up larger files that are not yet cached.",
  CACHEQUERYSTRING:
    "Add the query string parameters to the cache. If this is enabled items with different parameters will be treated as a separate cached item!",
  HONORORIGINAL: "Honor the TTL as set by the origin.",
  STALE: "Use stale cached content in the event of origin unavailability.",
  COMPRESSED: "Use compression to connect to the origin.",
  XFF: "Pass X-Forwarded-For header to the origin.",
  FORCEHTTPS: "Force the use of HTTPS by issuing redirects to HTTP requests.",
  TTLINHERIT: "Inherit the TTL from the cached item.",
  COMPRESSION: "Compression of content on the CDN.",
  RATE_LIMIT:
    "Limit the speed for a particular end-user after specified parameters are met.",
  GEOACL: "GEO blocking based upon country to restrict access to content.",
  IPACL: "IP blocking based upon IP subnets to restrict access to content.",
  REFERRAL_ACL: "Referer ACL to restrict access to content.",
  TOKEN: "Enable secured URL access.",
  CLIENTIP: "Use the client ip as part of the token calculation.",
  EMBED_INTO_PATH:
    "Embed the token into the path as apposed to using query string parameters.",
  ONLY_MANDATORY: "Disable this option to see only required fields",
  DISABLE_MANDATORY: "Enable this option to see expanded view"
};

export const policyDataMapper = self => {
  return {
    allowedMethods: self.state.allowedMethods,
    cacheSettings: self.state.cacheSettings,
    created: self.state.created,
    customerId: self.state.customerId,
    deliverySettings: self.state.deliverySettings,
    description: self.state.descriptionPolicy || self.state.description,
    id: self.state.policyId || self.state.id,
    path: self.state.path,
    pullOriginSettings: self.state.pullOriginSettings,
    rewrites: self.state.rewrites,
    securitySettings: self.state.securitySettings,
    updated: null
  };
};

export const policyMultiPremiumDataMapper = self => {
  return {
    cacheSettings: self.state.cacheSettings,
    created: self.state.created,
    customerId: self.state.customerId,
    deliverySettings: self.state.deliverySettings,
    description: self.state.descriptionPolicy || self.state.description,
    id: self.state.policyId || self.state.id,
    pullOriginSettings: self.state.pullOriginSettings,
    rewrites: self.state.rewrites,
    updated: null
  };
};

export const policyMultiPremiumVMMapper = (self, data) => {
  let policy = data;
  policy.created = data.created;
  policy.updated = data.updated;
  policy.descriptionPolicy = data.description;
  policy.policyId = data.id;
  delete data["description"];
  delete data["id"];
  self.setState(policy);
};

export const policyVMMapper = (self, data) => {
  let policy = data;
  policy.created = data.created;
  policy.updated = data.updated;
  policy.descriptionPolicy = data.description;
  policy.policyId = data.id;
  delete data["description"];
  delete data["id"];
  self.setState(policy, function() {
    setTimeout(() => policyCountryVMMapper(self, data), 2000);
  });
};

function policyCountryVMMapper(self, data) {
  self.setState({
    securitySettings: {
      ...data.securitySettings,
      geoAclSettings: data.securitySettings.geoAclSettings
    }
  });
}

export const setEmptyMultiPolicies = (self, type) => {
  const data = [
    {
      created: null,
      customerId: self.state.customerId,
      description: null,
      hash: "",
      type: type,
      deliverySettings: {
        ttlInherit: true,
        ttlOverride: 0
      },
      pullOriginSettings: {
        originId: null,
        shieldId: null,
        protocol: "HTTP"
      },
      cacheSettings: {
        cacheQueryString: false,
        honorOriginTtl: true,
        stale: true,
        defaultTtl: 86400
      },
    }
  ];
  policyMultiPremiumVMMapper(self, data[0]);
  return data[0];
};

export const setEmptyPolicyObject = (self, type) => {
  const data = [
    {
      allowedMethods: ["GET"],
      cacheSettings: {
        cacheQueryString: false,
        defaultTtl: 86400,
        honorOriginTtl: true,
        stale: true,
        opentest:true,
      },
      created: null,
      customerId: self.state.customerId,
      deliverySettings: {
        forceHttps: false,
        ttlInherit: true,
        ttlOverride: 0,
        compress: false,
        passThroughHeaders: [],
        staticHeaders: []
      },
      description: null,
      pullOriginSettings: {
        compressed: false,
        hostHeader: null,
        originId: null,
        protocol: "HTTP",
        xff: true,
      },
      securitySettings: {
        geoAcl: false,
        geoAclSettings: [],
        ipAcl: false,
        ipAclSettings: [],
        referrerAcl: false,
        referrerAclSettings: []
      },
    }
  ];

  if (type === SINGLE_CDN) {
    data[0].pullOriginSettings.shieldId = null;
  }
  if (type !== MULTI_CDN_VOLUME) {
    return setSingleCDN(self, data[0]);
  }
  if (type === MULTI_CDN_VOLUME) {
    policyVMMapper(self, data[0]);
    return data[0];
  }
};

function setSingleCDN(self, data) {
  const singleCDN = {
    cacheSettings: {
      ...data.cacheSettings,
      byPass: false,
      segmented: true
    },
    path: "",
    pullOriginSettings: {
      ...data.pullOriginSettings,
      originGroupId: null,
      upstreamHeaders: [],
      passCookie: false,
      passVary: false
    },
    rewrites: [],
    deliverySettings: {
      ...data.deliverySettings,
      burstSize: 0,
      rateLimit: false,
      transferRate: 0,
      passThroughAllHeaders: false
    },
    securitySettings: {
      ...data.securitySettings,
      geoAclDefault: null,
      ipAclDefault: null,
      referrerAclDefault: null,
      token: false,
      tokenSettings: {
        aesKey: null,
        clientIp: false,
        embedIntoPath: false,
        method: null,
        numberOfPathComponents: -1,
        secret: null,
        shaKey: null,
        vendor: null
      }
    }
  };

  const returnedObj = Object.assign({}, data, singleCDN);
  policyVMMapper(self, returnedObj);
  return returnedObj;
}

export function getOriginsFormControls(self, cdnType) {
  return (
    <React.Fragment>
      {!self.state.origins &&
        !self.state.originGroups &&
        !self.state.shieldCDNs && (
          <Typography variant="h6" align="left">
            No origings found, please create a new origin!
          </Typography>
        )}

      {self.state.origins && (
        <div className="auto-complete order1 auto-complete__tooltip">
          <RenderAutoSelectWithToolTip
            id="originId"
            name="originId"
            value={self.state.pullOriginSettings.originId}
            label="Pull Origin"
            className="fixed-height"
            placeholderSuffix="Pull Origin"
            clearValue={!self.state.pullOriginSettings.originId}
            required={
              !self.state.pullOriginSettings.originId &&
              !self.state.pullOriginSettings.originGroupId &&
              !self.state.pullOriginSettings.shieldId
            }
            handleChange={(selected, name) =>
              handleOriginValidation(
                selected,
                self,
                self.state.pullOriginSettings,
                "originId",
                "required",
                cdnType
              )
            }
            items={getTextValueFormat(self.state.origins)}
            toolTipTitle={POLICIES_TOOLTIP.ORIGIN_ID}
          />
        </div>
      )}

      <br />
      {self.state.originGroups &&
        cdnType !== MULTI_CDN_VOLUME &&
        cdnType !== MULTI_CDN_PREMIUM && (
          <React.Fragment>
            <div className="auto-complete order2 margin-top-16 auto-complete__tooltip">
              <RenderAutoSelectWithToolTip
                id="originGroupId"
                name="originGroupId"
                className="fixed-height"
                value={self.state.pullOriginSettings.originGroupId}
                clearValue={!self.state.pullOriginSettings.originGroupId}
                label="Origin group"
                placeholderSuffix="Origin group"
                required={
                  !self.state.pullOriginSettings.originId &&
                  !self.state.pullOriginSettings.originGroupId &&
                  !self.state.pullOriginSettings.shieldId
                }
                handleChange={(selected, name) =>
                  handleOriginValidation(
                    selected,
                    self,
                    self.state.pullOriginSettings,
                    "originGroupId",
                    "required",
                    cdnType
                  )
                }
                items={getTextValueFormat(self.state.originGroups)}
                tab="2"
                toolTipTitle={POLICIES_TOOLTIP.ORIGIN_GROUP_ID}
              />
            </div>
            <br />
          </React.Fragment>
        )}
      {self.state.shieldCDNs && cdnType !== SHIELD_CDN && (
        <div className="auto-complete  margin-top-16 auto-complete__tooltip">
          <RenderAutoSelectWithToolTip
            id="shieldId"
            name="shieldId"
            placeholderSuffix="Shield distribution"
            value={self.state.pullOriginSettings.shieldId}
            clearValue={!self.state.pullOriginSettings.shieldId}
            label="Shield distribution"
            className="fixed-height last disable__control"
            required={
              !self.state.pullOriginSettings.originId &&
              !self.state.pullOriginSettings.originGroupId &&
              !self.state.pullOriginSettings.shieldId
            }
            handleChange={(selected, name) =>
              handleOriginValidation(
                selected,
                self,
                self.state.pullOriginSettings,
                "shieldId",
                "required",
                cdnType
              )
            }
            items={getTextValueFormat(self.state.shieldCDNs)}
            tab="2"
            toolTipTitle={POLICIES_TOOLTIP.SHIELD_ID}
          />
        </div>
      )}
      <br />
    </React.Fragment>
  );
}

export function getOriginsFormReadOnlyControls(self, cdnType) {
  return (
    <React.Fragment>
      {!self.state.origins &&
        !self.state.originGroups &&
        !self.state.shieldCDNs && (
          <Typography variant="h6" align="left">
            No origings found, please create a new origin!
          </Typography>
        )}

      {self.state.origins &&
      self.state.pullOriginSettings.originId && (
        <RenderReadOnlyInput
          id="originId"
          name="originId"
          value={self.setMatchingDesciption(self.state.pullOriginSettings.originId, self.state.origins) || ""}
          label="Pull Origin"
          tab="2"
        />
      )}
      <br />
      {self.state.originGroups &&
      self.state.pullOriginSettings.originGroupId && 
        self.state.origins && 
        cdnType !== MULTI_CDN_VOLUME &&
        cdnType !== MULTI_CDN_PREMIUM && (
          <React.Fragment>
            <RenderReadOnlyInput
              id="originGroupId"
              name="originGroupId"
              value={self.setMatchingDesciption(self.state.pullOriginSettings.originGroupId, self.state.origins) || ""}
              label="Origin group"
              tab="2"
            />
            <br />
          </React.Fragment>
        )}
      {self.state.shieldCDNs &&
        cdnType !== SHIELD_CDN &&
        self.state.origins && 
        self.state.pullOriginSettings.shieldId && (
          <RenderReadOnlyInput
            id="shieldId"
            name="shieldId"
            value={self.setMatchingDesciption(self.state.pullOriginSettings.shieldId, self.state.origins) || ""}
            label="Shield distribution"
            tab="2"
          />
        )}
      <br />
    </React.Fragment>
  );
}

export function mandatoryRadioView(self, classes) {
  const mandatoryRadioField = (
    <div className="advance-view pull-right">
      <span className="margin-right-16 inactive">
        {!self.state.isMandatoryView && POLICIES_TOOLTIP.ONLY_MANDATORY}
        {!!self.state.isMandatoryView && POLICIES_TOOLTIP.DISABLE_MANDATORY}.
      </span>
      <label>Advanced</label>
      <RenderSwitch
        label="Advanced"
        name="advanced"
        checked={!self.state.isMandatoryView}
        id="advanced"
        handleRadioChange={() => self.switchView()}
        value={!self.state.isMandatoryView}
        className="switch-ios"
        classes={classes}
      />
    </div>
  );

  return mandatoryRadioField;
}

export function policyEditFormControls(self, cdnType) {
  const { classes } = self.props;

  const mandatoryRadioField = mandatoryRadioView(self, classes);
  return (
    <React.Fragment>
      {mandatoryRadioField}
      <div className="react-scroll-view-container fullWidth">
        <div className="fullwidth" data-scroll="true" tab="0">
          <Typography variant="h3" align="left">
            Basic Settings
          </Typography>
          {cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM && (
            <RenderInputWithToolTip
              placeholder="Path/regex for policy."
              id="path"
              name="path"
              type="text"
              required
              error={!self.state.path}
              value={self.state.path}
              label="Path / regex"
              handleChange={e => handleStateChange(e, self, self.state, "path")}
              toolTipTitle={POLICIES_TOOLTIP.PATH}
              tab="0"
            />
          )}
          <RenderInputWithToolTip
            placeholder="Description of the policy."
            id="descriptionPolicy"
            name="descriptionPolicy"
            type="text"
            required
            error={!self.state.descriptionPolicy}
            value={self.state.descriptionPolicy}
            label="Description"
            handleChange={e =>
              handleStateChange(e, self, self.state, "descriptionPolicy")
            }
            toolTipTitle={POLICIES_TOOLTIP.DISCRIPTION}
            tab="0"
          />
          {cdnType !== MULTI_CDN_PREMIUM && !self.state.isMandatoryView && (
            <RenderSelectMultipleWithToolTip
              id="allowedMethods"
              name="allowedMethods"
              value={self.state.allowedMethods}
              label="The Allowed Methods for this policy."
              multiple
              className="container-odd margin-top-16"
              handleChange={e =>
                handleStateChange(e, self, self.state, "allowedMethods")
              }
              items={methods}
              toolTipTitle={POLICIES_TOOLTIP.ALLOWED_METHODS}
            />
          )}
        </div>
        {!self.state.isMandatoryView && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="1">
            <Typography variant="h3" align="left">
              Cache Settings
            </Typography>
            <FormControl component="fieldset" fullWidth>
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithToolTip
                    label="Bypass caching rules"
                    classes={classes}
                    name="enabled"
                    checked={self.state.cacheSettings.byPass}
                    handleRadioChange={e =>
                      resetCacheSettings(
                        e,
                        self,
                        self.state.cacheSettings,
                        "byPass"
                      )
                    }
                    value={self.state.cacheSettings.byPass}
                    toolTipTitle={POLICIES_TOOLTIP.BYPASS}
                  />
                )}
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithToolTip
                    label="Segmented downloads"
                    classes={classes}
                    name="segmented"
                    checked={self.state.cacheSettings.segmented}
                    handleRadioChange={e =>
                      handleStateRadioChange(
                        e,
                        self,
                        self.state.cacheSettings,
                        "segmented"
                      )
                    }
                    value={self.state.cacheSettings.segmented}
                    disabled={!!self.state.cacheSettings.byPass}
                    toolTipTitle={POLICIES_TOOLTIP.SEGMENTED}
                  />
                )}
              <RenderSwitchWithToolTip
                label="Cache Query String"
                classes={classes}
                name="cacheQueryString"
                checked={self.state.cacheSettings.cacheQueryString}
                handleRadioChange={e =>
                  handleStateRadioChange(
                    e,
                    self,
                    self.state.cacheSettings,
                    "cacheQueryString"
                  )
                }
                value={self.state.cacheSettings.cacheQueryString}
                disabled={!!self.state.cacheSettings.byPass}
                toolTipTitle={POLICIES_TOOLTIP.CACHEQUERYSTRING}
              />
              <RenderSwitchWithToolTip
                label="Honor Origin TTL"
                classes={classes}
                name="honorOriginTtl"
                checked={self.state.cacheSettings.honorOriginTtl}
                handleRadioChange={e =>
                  handleStateRadioChange(
                    e,
                    self,
                    self.state.cacheSettings,
                    "honorOriginTtl"
                  )
                }
                value={self.state.cacheSettings.honorOriginTtl}
                disabled={!!self.state.cacheSettings.byPass}
                toolTipTitle={POLICIES_TOOLTIP.HONORORIGINAL}
              />
              <RenderInputWithToolTip
                placeholder="Default TTL to use"
                id="defaultTtl"
                name="defaultTtl"
                type="text"
                value={self.state.cacheSettings.defaultTtl}
                label="Default TTL"
                disabled={!!self.state.cacheSettings.byPass}
                handleChange={e =>
                  handleStateChange(
                    e,
                    self,
                    self.state.cacheSettings,
                    "defaultTtl"
                  )
                }
                toolTipTitle={POLICIES_TOOLTIP.DEFAULT_TTL}
              />
              <RenderSwitchWithToolTip
                label="Serve stale content"
                classes={classes}
                name="honorOriginTtl"
                checked={self.state.cacheSettings.stale}
                handleRadioChange={e =>
                  handleStateRadioChange(
                    e,
                    self,
                    self.state.cacheSettings,
                    "stale"
                  )
                }
                value={self.state.cacheSettings.stale}
                disabled={!!self.state.cacheSettings.byPass}
                toolTipTitle={POLICIES_TOOLTIP.STALE}
              />
            </FormControl>
          </div>
        )}
        <div className="margin-top-16 fullwidth" data-scroll="true" tab="2">
          <Typography
            variant="h3"
            align="left"
            className="margin-bottom-default"
          >
            Pull Origin Settings
          </Typography>
          <FormControl component="fieldset" fullWidth>
            {getOriginsFormControls(self, cdnType)}
            {!self.state.isMandatoryView && (
              <React.Fragment>
                <div className="auto-complete auto-complete__tooltip">
                  <RenderAutoSelectWithToolTip
                    id="protocol"
                    name="protocol"
                    clearValue={!self.state.pullOriginSettings.protocol}
                    placeholderSuffix="protocol"
                    value={self.state.pullOriginSettings.protocol}
                    label="Protocol"
                    required={true}
                    tab="2"
                    handleChange={(selected, name) =>
                      handleAutoSelectChange(
                        selected,
                        self,
                        self.state.pullOriginSettings,
                        "protocol"
                      )
                    }
                    items={protocol}
                    toolTipTitle={POLICIES_TOOLTIP.PROTOCOL}
                  />
                </div>
                <br />
                {cdnType !== MULTI_CDN_PREMIUM && (
                  <>
                  <RenderSwitchWithToolTip
                    label="Compressed"
                    classes={classes}
                    name="compressed"
                    checked={self.state.pullOriginSettings.compressed}
                    handleRadioChange={e => handleStateRadioChange(
                      e,
                      self,
                      self.state.pullOriginSettings,
                      "compressed"
                    )}
                    value={self.state.pullOriginSettings.compressed}
                    toolTipTitle={POLICIES_TOOLTIP.COMPRESSED} />
                    <AlertDialog
                      openDialog={modalStatusOpen &&self.state.pullOriginSettings.compressed}
                      heading="Confirm"
                      message={"Please be aware that enabling origin compression would disable segmentation downloads from origin."}
                      cancelBtnText="Cancel"
                      confirmBtnText="Confirm"
                      cancelBtnAction={e => handleStateRadioChange(
                        e,
                        self,
                        self.state.pullOriginSettings,
                        "compressed"
                      )}
                      confirmBtnAction={e => handleStateModal(
                        e,
                        self,
                        self.state.pullOriginSettings,)}
                      />
                    </>
                )}

                {cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderInputWithToolTip
                    placeholder="Host to use as host header"
                    id="hostHeader"
                    name="hostHeader"
                    type="text"
                    value={self.state.pullOriginSettings.hostHeader}
                    label="Host Header"
                    handleChange={e =>
                      handleStateChange(
                        e,
                        self,
                        self.state.pullOriginSettings,
                        "hostHeader"
                      )
                    }
                    toolTipTitle={POLICIES_TOOLTIP.HOST_HEADER}
                  />
                )}
                {cdnType !== MULTI_CDN_PREMIUM && <br />}

                {cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithToolTip
                    label="X-Forwarded-For"
                    classes={classes}
                    name="xff"
                    checked={self.state.pullOriginSettings.xff}
                    handleRadioChange={e =>
                      handleStateRadioChange(
                        e,
                        self,
                        self.state.pullOriginSettings,
                        "xff"
                      )
                    }
                    value={self.state.pullOriginSettings.xff}
                    toolTipTitle={POLICIES_TOOLTIP.XFF}
                  />
                )}
              </React.Fragment>
            )}
            {cdnType !== MULTI_CDN_VOLUME &&
              cdnType !== MULTI_CDN_PREMIUM &&
              !self.state.isMandatoryView && (
                <RenderSwitchWithToolTip
                  label="Pass Cookies"
                  classes={classes}
                  name="passCookie"
                  checked={self.state.pullOriginSettings.passCookie}
                  handleRadioChange={e =>
                    handleStateRadioChange(
                      e,
                      self,
                      self.state.pullOriginSettings,
                      "passCookie"
                    )
                  }
                  value={self.state.pullOriginSettings.passCookie}
                  toolTipTitle={POLICIES_TOOLTIP.PASSCOOKIE}
                />
              )}
            {cdnType !== MULTI_CDN_VOLUME &&
              cdnType !== MULTI_CDN_PREMIUM &&
              !self.state.isMandatoryView && (
                <RenderSwitchWithToolTip
                  label="Pass Vary header"
                  classes={classes}
                  name="passVary"
                  checked={self.state.pullOriginSettings.passVary}
                  handleRadioChange={e =>
                    handleStateRadioChange(
                      e,
                      self,
                      self.state.pullOriginSettings,
                      "passVary"
                    )
                  }
                  value={self.state.pullOriginSettings.passVary}
                  toolTipTitle={POLICIES_TOOLTIP.PASSVARY}
                />
              )}
            {!self.state.isMandatoryView && (
              <React.Fragment>
                {cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM && (
                  <React.Fragment>
                    <label className="fullWidth display-block-inline position-relative overflow-hidden">
                      <span className="pull-left margin-right-16">
                        Upstream headers
                      </span>
                      <div className="margin-none pull-left">
                        <ClickableTooltip
                          place="right"
                          toolTipTitle={POLICIES_TOOLTIP.UPS_HEADER}
                        />
                      </div>
                    </label>
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      self.state.pullOriginSettings.upstreamHeaders.map(
                        (val, ind) => {
                          return (
                            <Grid
                              container
                              spacing={16}
                              key={"upstreamHeaders" + ind}
                            >
                              <Grid item xs={6}>
                                <RenderInputWithOutLabel
                                  placeholder="Header name"
                                  id="header"
                                  required
                                  name="header"
                                  type="text"
                                  value={
                                    self.state.pullOriginSettings
                                      .upstreamHeaders[ind].header
                                  }
                                  label="Header"
                                  error={
                                    !self.state.pullOriginSettings
                                      .upstreamHeaders[ind].header
                                  }
                                  tab="2"
                                  handleChange={e =>
                                    handleStateChange(
                                      e,
                                      self,
                                      self.state.pullOriginSettings
                                        .upstreamHeaders[ind],
                                      "header"
                                    )
                                  }
                                  toolTipTitle={POLICIES_TOOLTIP.UPS_HEADER}
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <RenderInputWithOutLabel
                                  placeholder="Header value"
                                  id="value"
                                  required
                                  name="value"
                                  type="text"
                                  value={
                                    self.state.pullOriginSettings
                                      .upstreamHeaders[ind].value
                                  }
                                  label="Value"
                                  error={
                                    !self.state.pullOriginSettings
                                      .upstreamHeaders[ind].value
                                  }
                                  tab="2"
                                  handleChange={e =>
                                    handleStateChange(
                                      e,
                                      self,
                                      self.state.pullOriginSettings
                                        .upstreamHeaders[ind],
                                      "value"
                                    )
                                  }
                                  toolTipTitle={POLICIES_TOOLTIP.UPS_VALUE}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label="Delete"
                                  title="Delete header"
                                  className="margin-top-16"
                                  onClick={() =>
                                    removeItem(
                                      self,
                                      self.state.pullOriginSettings
                                        .upstreamHeaders,
                                      ["pullOriginSettings"]["upstreamHeaders"],
                                      ind
                                    )
                                  }
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    <RenderButtonClick
                      name="btndomain"
                      color="primary"
                      className="margin-top-default pull-left clear-both"
                      onClick={() =>
                        addItem(
                          self,
                          self.state.pullOriginSettings.upstreamHeaders,
                          ["pullOriginSettings"]["upstreamHeaders"]
                        )
                      }
                      variant="contained"
                      type="button"
                      size="medium"
                      align="right"
                      disabled={false}
                      label="Add"
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </FormControl>
        </div>
        {!self.state.isMandatoryView && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="3">
            <Typography variant="h3" align="left">
              Delivery settings
            </Typography>
            <FormControl component="fieldset" fullWidth>
              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderSwitchWithToolTip
                  label="Force HTTPS"
                  classes={classes}
                  name="forceHttps"
                  checked={self.state.deliverySettings.forceHttps}
                  handleRadioChange={e =>
                    handleStateRadioChange(
                      e,
                      self,
                      self.state.deliverySettings,
                      "forceHttps"
                    )
                  }
                  value={self.state.deliverySettings.forceHttps}
                  toolTipTitle={POLICIES_TOOLTIP.FORCEHTTPS}
                />
              )}

              <RenderSwitchWithToolTip
                label="Inherit TTL"
                classes={classes}
                name="ttlInherit"
                checked={self.state.deliverySettings.ttlInherit}
                handleRadioChange={e =>
                  resetTTL(e, self, self.state.deliverySettings, "ttlInherit")
                }
                value={self.state.deliverySettings.ttlInherit}
                toolTipTitle={POLICIES_TOOLTIP.TTLINHERIT}
              />

              <RenderInputWithToolTip
                placeholder="TTL to use"
                id="ttlOverride"
                name="ttlOverride"
                type="text"
                value={self.state.deliverySettings.ttlOverride}
                label="TTL Override"
                disabled={!!self.state.deliverySettings.ttlInherit}
                handleChange={e =>
                  handleStateChange(
                    e,
                    self,
                    self.state.deliverySettings,
                    "ttlOverride"
                  )
                }
                toolTipTitle={POLICIES_TOOLTIP.TTL_OVERRIDE}
              />

              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderSwitchWithToolTip
                  label="Compression"
                  classes={classes}
                  name="compress"
                  checked={self.state.deliverySettings.compress}
                  handleRadioChange={e =>
                    handleStateRadioChange(
                      e,
                      self,
                      self.state.deliverySettings,
                      "compress"
                    )
                  }
                  value={self.state.deliverySettings.compress}
                  toolTipTitle={POLICIES_TOOLTIP.COMPRESSION}
                />
              )}

              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithToolTip
                    label="Rate limiting"
                    classes={classes}
                    name="rateLimit"
                    checked={self.state.deliverySettings.rateLimit}
                    handleRadioChange={e =>
                      handleStateRadioChange(
                        e,
                        self,
                        self.state.deliverySettings,
                        "rateLimit"
                      )
                    }
                    value={self.state.deliverySettings.rateLimit}
                    toolTipTitle={POLICIES_TOOLTIP.RATE_LIMIT}
                  />
                )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                !!self.state.deliverySettings.rateLimit && (
                  <React.Fragment>
                    <RenderInputWithToolTip
                      placeholder="Transfer Rate"
                      id="transferRate"
                      name="transferRate"
                      type="number"
                      value={self.state.deliverySettings.transferRate}
                      label="Transfer Rate"
                      handleChange={e =>
                        handleStateChange(
                          e,
                          self,
                          self.state.deliverySettings,
                          "transferRate"
                        )
                      }
                      toolTipTitle={POLICIES_TOOLTIP.TRANSFER_RATE}
                    />
                    <RenderInputWithToolTip
                      placeholder="Burst size"
                      id="burstSize"
                      name="burstSize"
                      type="number"
                      value={self.state.deliverySettings.burstSize}
                      label="Burst size"
                      handleChange={e =>
                        handleStateChange(
                          e,
                          self,
                          self.state.deliverySettings,
                          "burstSize"
                        )
                      }
                      toolTipTitle={POLICIES_TOOLTIP.BURST_SIZE}
                    />
                  </React.Fragment>
                )}
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithToolTip
                    label="Passthrough all headers"
                    classes={classes}
                    name="passThroughAllHeaders"
                    checked={self.state.deliverySettings.passThroughAllHeaders}
                    handleRadioChange={e =>
                      handlePassThroughAllHeaders(
                        e,
                        self,
                        self.state.deliverySettings,
                        "passThroughAllHeaders"
                      )
                    }
                    value={self.state.deliverySettings.passThroughAllHeaders}
                    toolTipTitle={POLICIES_TOOLTIP.PASSTHROUGHALLHEADERS}
                  />
                )}

              {cdnType !== MULTI_CDN_PREMIUM &&
                !self.state.deliverySettings.passThroughAllHeaders && (
                  <label className="fullWidth display-block-inline position-relative overflow-hidden">
                    <span className="pull-left margin-right-16">
                      Passthrough headers
                    </span>
                    <div className="margin-none pull-left">
                      <ClickableTooltip
                        place="right"
                        toolTipTitle={POLICIES_TOOLTIP.PASS_HEADER}
                      />
                    </div>
                  </label>
                )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                self.state.deliverySettings.passThroughHeaders.map(
                  (val, ind) => {
                    return (
                      <Grid container spacing={16} key={"passthrheaders" + ind}>
                        <Grid item xs={11}>
                          <RenderInputWithOutLabel
                            required
                            placeholder="Header name"
                            id="header"
                            name="header"
                            type="text"
                            value={
                              self.state.deliverySettings.passThroughHeaders[
                                ind
                              ].header
                            }
                            tab="3"
                            label="Header"
                            error={
                              !self.state.deliverySettings.passThroughHeaders[
                                ind
                              ].header
                            }
                            handleChange={e =>
                              handleStateChange(
                                e,
                                self,
                                self.state.deliverySettings.passThroughHeaders[
                                  ind
                                ],
                                "header"
                              )
                            }
                            toolTipTitle={POLICIES_TOOLTIP.PASS_HEADER}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            aria-label="Delete"
                            title="Delete header"
                            className="margin-top-16"
                            onClick={() =>
                              removeItem(
                                self,
                                self.state.deliverySettings.passThroughHeaders,
                                ["deliverySettings"]["passThroughHeaders"],
                                ind
                              )
                            }
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  }
                )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                !self.state.deliverySettings.passThroughAllHeaders && (
                  <RenderButtonClick
                    name="btndomain"
                    color="primary"
                    className="margin-top-default pull-left clear-both"
                    onClick={() =>
                      checkForPassThroughAllHeaders(
                        self,
                        self.state.deliverySettings,
                        self.state.deliverySettings.passThroughHeaders,
                        ["deliverySettings"]["passThroughHeaders"]
                      )
                    }
                    variant="contained"
                    type="button"
                    size="large"
                    align="right"
                    disabled={false}
                    label="Add"
                  />
                )}
              {cdnType !== MULTI_CDN_PREMIUM && (
                <label className="fullWidth display-block-inline position-relative overflow-hidden margin-top-16">
                  <span className="pull-left margin-right-16">
                    Static Headers
                  </span>
                  <div className="margin-none pull-left">
                    <ClickableTooltip
                      place="right"
                      toolTipTitle={POLICIES_TOOLTIP.STATIC_HEADER}
                    />
                  </div>
                </label>
              )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                self.state.deliverySettings.staticHeaders.map((val, ind) => {
                  return (
                    <Grid container spacing={16} key={"staticHeaders" + ind}>
                      <Grid item xs={6}>
                        <RenderInputWithOutLabel
                          required
                          placeholder="Header name"
                          id="header"
                          name="header"
                          type="text"
                          value={
                            self.state.deliverySettings.staticHeaders[ind]
                              .header
                          }
                          label="Header"
                          error={
                            !self.state.deliverySettings.staticHeaders[ind]
                              .header
                          }
                          tab="3"
                          handleChange={e =>
                            handleStateChange(
                              e,
                              self,
                              self.state.deliverySettings.staticHeaders[ind],
                              "header"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.STATIC_HEADER}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <RenderInputWithOutLabel
                          required
                          placeholder="Header value"
                          id="value"
                          name="value"
                          type="text"
                          value={
                            self.state.deliverySettings.staticHeaders[ind].value
                          }
                          label="Value"
                          error={
                            !self.state.deliverySettings.staticHeaders[ind]
                              .value
                          }
                          tab="3"
                          handleChange={e =>
                            handleStateChange(
                              e,
                              self,
                              self.state.deliverySettings.staticHeaders[ind],
                              "value"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.STATIC_VALUE}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          aria-label="Delete"
                          title="Delete row"
                          className="margin-top-16"
                          onClick={() =>
                            removeItem(
                              self,
                              self.state.deliverySettings.staticHeaders,
                              ["deliverySettings"]["staticHeaders"],
                              ind
                            )
                          }
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderButtonClick
                  name="btndomain"
                  color="primary"
                  className="margin-top-default pull-left clear-both"
                  onClick={() =>
                    addItem(
                      self,
                      self.state.deliverySettings.staticHeaders,
                      ["deliverySettings"]["staticHeaders"]
                    )
                  }
                  variant="contained"
                  type="button"
                  size="large"
                  align="right"
                  disabled={false}
                  label="Add"
                />
              )}
            </FormControl>
          </div>
        )}
        {!self.state.isMandatoryView && cdnType !== MULTI_CDN_PREMIUM && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="4">
            <Typography variant="h3" align="left">
              Security Settings
            </Typography>
            <FormControl component="fieldset" fullWidth>
              <RenderSwitchWithToolTip
                label="GEO targeting / blocking"
                classes={classes}
                name="geoAcl"
                checked={self.state.securitySettings.geoAcl}
                handleRadioChange={e =>
                  handleStateRadioChange(
                    e,
                    self,
                    self.state.securitySettings,
                    "geoAcl"
                  )
                }
                value={self.state.securitySettings.geoAcl}
                toolTipTitle={POLICIES_TOOLTIP.GEOACL}
              />
              {!!self.state.securitySettings.geoAcl && (
                <React.Fragment>
                  <label className="fullWidth display-block-inline position-relative overflow-hidden margin-bottom-default">
                    <span className="pull-left margin-right-16">
                      GEO ACL Countries
                    </span>
                    <div className="margin-none pull-left">
                      <ClickableTooltip
                        place="right"
                        toolTipTitle={POLICIES_TOOLTIP.GEOACL}
                      />
                    </div>
                  </label>

                  {cdnType !== MULTI_CDN_VOLUME &&
                    cdnType !== MULTI_CDN_PREMIUM && (
                      <div className="auto-complete">
                        <RenderAutoSelect
                          id="geoAclDefault"
                          name="geoAclDefault"
                          value={self.state.securitySettings.geoAclDefault}
                          label="Default action"
                          placeholderSuffix="Default action"
                          required
                          clearValue={
                            !self.state.securitySettings.geoAclDefault
                          }
                          handleChange={(selected, name) =>
                            handleAutoSelectChange(
                              selected,
                              self,
                              self.state.securitySettings,
                              "geoAclDefault"
                            )
                          }
                          items={geoAclDefaultValues}
                          tab="4"
                          toolTipTitle={POLICIES_TOOLTIP.GEOACT}
                        />
                      </div>
                    )}
                  {!!self.state.securitySettings.geoAclSettings && (
                    <CountryListSelection
                      leftSideMultiSelect={getTextValueFormatForCountries(
                        self.props.availableCountryList
                      )}
                      rightSideMultiSelect={getTextValueFormatForCountries(
                        self.props.selectedCountryList
                      )}
                      setRightSideValues={list =>
                        self.setSelectedCountryList(list)
                      }
                      setLeftSideValues={list =>
                        self.setAvailableCountryList(list)
                      }
                      leftSideSelectedValues={self.state.availableCountryValues}
                      rightSideSelectedValues={self.state.selectedCountryValues}
                      setLeftSideSelectedValues={values =>
                        self.setAvailableCountryValues(values)
                      }
                      setRightSideSelectedValues={values =>
                        self.setSelectedCountryValues(values)
                      }
                    />
                  )}
                </React.Fragment>
              )}
              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderSwitchWithToolTip
                  label="IP subnet targeting / blocking"
                  classes={classes}
                  name="ipAcl"
                  checked={self.state.securitySettings.ipAcl}
                  className="margin-top-16"
                  handleRadioChange={e =>
                    handleStateRadioChange(
                      e,
                      self,
                      self.state.securitySettings,
                      "ipAcl"
                    )
                  }
                  value={self.state.securitySettings.ipAcl}
                  toolTipTitle={POLICIES_TOOLTIP.IPACL}
                />
              )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                !!self.state.securitySettings.ipAcl && (
                  <React.Fragment>
                    <label className="fullWidth display-block-inline position-relative overflow-hidden margin-bottom-default">
                      <span className="pull-left margin-right-16">
                        IP subnet ACL
                      </span>
                      <div className="margin-none pull-left">
                        <ClickableTooltip
                          place="right"
                          toolTipTitle={POLICIES_TOOLTIP.IPACL}
                        />
                      </div>
                    </label>
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      cdnType !== MULTI_CDN_VOLUME && (
                        <div className="auto-complete">
                          <RenderAutoSelect
                            id="ipAclDefault"
                            name="ipAclDefault"
                            value={self.state.securitySettings.ipAclDefault}
                            label="Default action"
                            placeholderSuffix="Default action"
                            required
                            clearValue={
                              !self.state.securitySettings.ipAclDefault
                            }
                            handleChange={(selected, name) =>
                              handleAutoSelectChange(
                                selected,
                                self,
                                self.state.securitySettings,
                                "ipAclDefault"
                              )
                            }
                            items={geoAclDefaultValues}
                            tab="4"
                            toolTipTitle={POLICIES_TOOLTIP.IPACT}
                          />
                        </div>
                      )}
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      self.state.securitySettings.ipAclSettings.map(
                        (val, ind) => {
                          return (
                            <Grid container spacing={16} key={"icl" + ind}>
                              <Grid item xs={6}>
                                <div className="auto-complete">
                                  <RenderAutoSelect
                                    id="ipAclSettingssAction"
                                    name="ipAclSettingssAction"
                                    value={
                                      self.state.securitySettings.ipAclSettings[
                                        ind
                                      ].action
                                    }
                                    label="Action"
                                    className="margin-top-16"
                                    required
                                    clearValue={
                                      !self.state.securitySettings
                                        .ipAclSettings[ind].action
                                    }
                                    handleChange={(selected, name) =>
                                      handleAutoSelectChange(
                                        selected,
                                        self,
                                        self.state.securitySettings
                                          .ipAclSettings[ind],
                                        "action"
                                      )
                                    }
                                    items={geoAclDefaultValues}
                                    tab="4"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={5}>
                                <RenderInputWithOutLabel
                                  placeholder="IP subnet"
                                  id="subnet"
                                  name="subnet"
                                  type="text"
                                  value={
                                    self.state.securitySettings.ipAclSettings[
                                      ind
                                    ].subnet
                                  }
                                  label="Subnet"
                                  required
                                  error={
                                    !self.state.securitySettings.ipAclSettings[
                                      ind
                                    ].subnet
                                  }
                                  tab="4"
                                  handleChange={e =>
                                    handleStateChange(
                                      e,
                                      self,
                                      self.state.securitySettings.ipAclSettings[
                                        ind
                                      ],
                                      "subnet"
                                    )
                                  }
                                  toolTipTitle={POLICIES_TOOLTIP.SUBNET}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label="Delete"
                                  title="Delete IP ACL"
                                  className="margin-top-16"
                                  onClick={() =>
                                    removeItem(
                                      self,
                                      self.state.securitySettings.ipAclSettings,
                                      ["ipAclSettings"],
                                      ind
                                    )
                                  }
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    <RenderButtonClick
                      name="btndomain"
                      color="primary"
                      className="margin-top-16"
                      onClick={() =>
                        addItem(
                          self,
                          self.state.securitySettings.ipAclSettings,
                          ["ipAclSettings"]
                        )
                      }
                      variant="contained"
                      type="button"
                      size="large"
                      align="right"
                      disabled={false}
                      label="Add"
                    />
                  </React.Fragment>
                )}
              <RenderSwitchWithToolTip
                label="Referrer blocking"
                classes={classes}
                className="margin-top-16"
                name="referrerAcl"
                checked={self.state.securitySettings.referrerAcl}
                handleRadioChange={e =>
                  handleStateRadioChange(
                    e,
                    self,
                    self.state.securitySettings,
                    "referrerAcl"
                  )
                }
                value={self.state.securitySettings.referrerAcl}
                toolTipTitle={POLICIES_TOOLTIP.REFERRAL_ACL}
              />

              {cdnType !== MULTI_CDN_PREMIUM &&
                !!self.state.securitySettings.referrerAcl && (
                  <React.Fragment>
                    <label className="fullWidth display-block-inline position-relative overflow-hidden margin-bottom-default">
                      <span className="pull-left margin-right-16">
                        Referrer ACL
                      </span>
                      <div className="margin-none pull-left">
                        <ClickableTooltip
                          place="right"
                          toolTipTitle={POLICIES_TOOLTIP.REFERRAL_ACL}
                        />
                      </div>
                    </label>

                    {cdnType !== MULTI_CDN_PREMIUM &&
                      cdnType !== MULTI_CDN_VOLUME && (
                        <div className="auto-complete">
                          <RenderAutoSelect
                            id="referrerAclDefault"
                            name="referrerAclDefault"
                            value={
                              self.state.securitySettings.referrerAclDefault
                            }
                            label="Default action"
                            required
                            clearValue={
                              !self.state.securitySettings.referrerAclDefault
                            }
                            handleChange={(selected, name) =>
                              handleAutoSelectChange(
                                selected,
                                self,
                                self.state.securitySettings,
                                "referrerAclDefault"
                              )
                            }
                            items={geoAclDefaultValues}
                            tab="4"
                          />
                        </div>
                      )}
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      self.state.securitySettings.referrerAclSettings.map(
                        (val, ind) => {
                          return (
                            <Grid container spacing={16} key={"referral" + ind}>
                              <Grid item xs={6}>
                                <div className="auto-complete">
                                  <RenderAutoSelect
                                    id="referrerAclsAction"
                                    name="referrerAclsAction"
                                    value={
                                      self.state.securitySettings
                                        .referrerAclSettings[ind].action
                                    }
                                    label="Action"
                                    className="margin-top-16"
                                    required
                                    clearValue={
                                      !self.state.securitySettings
                                        .referrerAclSettings[ind].action
                                    }
                                    handleChange={(selected, name) =>
                                      handleAutoSelectChange(
                                        selected,
                                        self,
                                        self.state.securitySettings
                                          .referrerAclSettings[ind],
                                        "action"
                                      )
                                    }
                                    items={[{ value: "allow", text: "Allow" }]}
                                    tab="4"
                                    toolTipTitle={
                                      POLICIES_TOOLTIP.REFERRERACL_ACTION
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={5}>
                                <RenderInputWithOutLabel
                                  placeholder="Referer"
                                  id="referrer"
                                  name="referrer"
                                  type="text"
                                  required
                                  tab="4"
                                  error={
                                    !self.state.securitySettings
                                      .referrerAclSettings[ind].referrer
                                  }
                                  value={
                                    self.state.securitySettings
                                      .referrerAclSettings[ind].referrer
                                  }
                                  label="Referrer"
                                  handleChange={e =>
                                    handleStateChange(
                                      e,
                                      self,
                                      self.state.securitySettings
                                        .referrerAclSettings[ind],
                                      "referrer"
                                    )
                                  }
                                  toolTipTitle={POLICIES_TOOLTIP.REFERRER}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label="Delete"
                                  title="Delete row"
                                  className="margin-top-16"
                                  onClick={() =>
                                    removeItem(
                                      self,
                                      self.state.securitySettings
                                        .referrerAclSettings,
                                      ["referrerAclSettings"],
                                      ind
                                    )
                                  }
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        }
                      )}
                    <RenderButtonClick
                      name="btndomain"
                      color="primary"
                      className="margin-top-16"
                      onClick={() =>
                        addItem(
                          self,
                          self.state.securitySettings.referrerAclSettings,
                          ["referrerAclSettings"]
                        )
                      }
                      variant="contained"
                      type="button"
                      size="large"
                      align="right"
                      disabled={false}
                      label="Add"
                    />
                  </React.Fragment>
                )}
              {cdnType !== MULTI_CDN_PREMIUM && cdnType !== MULTI_CDN_VOLUME && (
                <React.Fragment>
                  <RenderSwitchWithToolTip
                    label="Secured (tokenized) URLs"
                    classes={classes}
                    className="margin-top-16"
                    name="token"
                    checked={self.state.securitySettings.token}
                    handleRadioChange={e =>
                      handleStateRadioChange(
                        e,
                        self,
                        self.state.securitySettings,
                        "token"
                      )
                    }
                    value={self.state.securitySettings.token}
                    toolTipTitle={POLICIES_TOOLTIP.TOKEN}
                  />

                  {!!self.state.securitySettings.token && (
                    <React.Fragment>
                      <label className="fullWidth display-block-inline position-relative overflow-hidden">
                        <span className="pull-left margin-right-16">
                          Token Settings
                        </span>
                      </label>
                      {self.state.securitySettings.tokenSettings.method ===
                        "encrypted" && (
                        <RenderInputWithToolTip
                          placeholder="AES key"
                          id="aesKey"
                          name="aesKey"
                          type="text"
                          value={
                            self.state.securitySettings.tokenSettings.aesKey
                          }
                          label="Aes Key"
                          required
                          error={checkMinimumLength(
                            self.state.securitySettings.tokenSettings.aesKey,
                            32
                          )}
                          tab="4"
                          handleChange={e =>
                            handleStateChange(
                              e,
                              self,
                              self.state.securitySettings.tokenSettings,
                              "aesKey"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.AESKEY}
                        />
                      )}
                      <div className="auto-complete order2 auto-complete__tooltip">
                        <RenderAutoSelectWithToolTip
                          id="method"
                          name="method"
                          value={
                            self.state.securitySettings.tokenSettings.method
                          }
                          label="Method"
                          items={tokenMethods}
                          required
                          clearValue={
                            !self.state.securitySettings.tokenSettings.method
                          }
                          className="margin-top-16"
                          handleChange={(selected, name) =>
                            changeTokenAutoSelectSettings(
                              selected,
                              self,
                              self.state.securitySettings.tokenSettings,
                              "method"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.METHOD}
                          tab="4"
                        />
                      </div>

                      {self.state.securitySettings.tokenSettings.method ===
                        "encrypted" && (
                        <RenderInputWithToolTip
                          placeholder="SHA key"
                          id="shaKey"
                          name="shaKey"
                          type="text"
                          value={
                            self.state.securitySettings.tokenSettings.shaKey
                          }
                          label="SHA Key"
                          className="margin-top-16"
                          required
                          error={checkMinimumLength(
                            self.state.securitySettings.tokenSettings.shaKey,
                            32
                          )}
                          handleChange={e =>
                            handleStateChange(
                              e,
                              self,
                              self.state.securitySettings.tokenSettings,
                              "shaKey"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.SHAKEY}
                          tab="4"
                        />
                      )}

                      {self.state.securitySettings.tokenSettings.method ===
                        "simple" && (
                        <RenderInputWithToolTip
                          placeholder="Simple secret"
                          id="secret"
                          name="secret"
                          type="text"
                          value={
                            self.state.securitySettings.tokenSettings.secret
                          }
                          label="Secret"
                          required
                          error={
                            !self.state.securitySettings.tokenSettings.secret
                          }
                          min={8}
                          max={125}
                          tab="4"
                          handleChange={e =>
                            handleStateChange(
                              e,
                              self,
                              self.state.securitySettings.tokenSettings,
                              "secret"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.SECRET}
                        />
                      )}
                      <div className="auto-complete auto-complete__tooltip">
                        <RenderAutoSelectWithToolTip
                          id="tokenVendor"
                          name="tokenVendor"
                          value={
                            self.state.securitySettings.tokenSettings.vendor
                          }
                          label="Vendor"
                          items={tokenVendors}
                          required
                          clearValue={
                            !self.state.securitySettings.tokenSettings.vendor
                          }
                          className="margin-top-16"
                          tab="4"
                          handleChange={(selected, name) =>
                            handleAutoSelectChange(
                              selected,
                              self,
                              self.state.securitySettings.tokenSettings,
                              "vendor"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.TOKEN_VENDOR}
                        />
                      </div>
                      {self.state.securitySettings.tokenSettings.method ===
                        "simple" && (
                        <RenderSwitchWithToolTip
                          label="Use client IP"
                          classes={classes}
                          name="clientIp"
                          checked={
                            self.state.securitySettings.tokenSettings.clientIp
                          }
                          handleRadioChange={e =>
                            handleStateRadioChange(
                              e,
                              self,
                              self.state.securitySettings.tokenSettings,
                              "clientIp"
                            )
                          }
                          value={
                            self.state.securitySettings.tokenSettings.clientIp
                          }
                          toolTipTitle={POLICIES_TOOLTIP.CLIENTIP}
                        />
                      )}

                      <RenderInputWithToolTip
                        placeholder="Number of path components"
                        id="numberOfPathComponents"
                        name="numberOfPathComponents"
                        type="number"
                        value={
                          self.state.securitySettings.tokenSettings
                            .numberOfPathComponents
                        }
                        label="Number of path components"
                        handleChange={e =>
                          handleStateChange(
                            e,
                            self,
                            self.state.securitySettings.tokenSettings,
                            "numberOfPathComponents"
                          )
                        }
                        toolTipTitle={POLICIES_TOOLTIP.PATH_COMPONENT}
                      />

                      <RenderSwitchWithToolTip
                        label="Embed into path"
                        classes={classes}
                        name="embedIntoPath"
                        checked={
                          self.state.securitySettings.tokenSettings
                            .embedIntoPath
                        }
                        handleRadioChange={e =>
                          handleStateRadioChange(
                            e,
                            self,
                            self.state.securitySettings.tokenSettings,
                            "embedIntoPath"
                          )
                        }
                        value={
                          self.state.securitySettings.tokenSettings
                            .embedIntoPath
                        }
                        toolTipTitle={POLICIES_TOOLTIP.EMBED_INTO_PATH}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </FormControl>
          </div>
        )}
        {!self.state.isMandatoryView &&
          cdnType !== MULTI_CDN_PREMIUM &&
          cdnType !== MULTI_CDN_VOLUME && (
            <div
              className="margin-top-default fullwidth rewrites"
              data-scroll="true"
              tab="5"
            >
              <h6 className="inactive margin-none font-default fullWidth display-block-inline position-relative fullwidth overflow-hidden">
                <span className="pull-left margin-right-16">Rewrites</span>
                <div className="margin-none pull-left">
                  <ClickableTooltip
                    place="right"
                    toolTipTitle={POLICIES_TOOLTIP.REWRITES}
                  />
                </div>
              </h6>

              {self.state.rewrites.map((val, ind) => {
                return (
                  <Grid container spacing={16} key={"rewrites" + ind}>
                    <Grid item xs={4}>
                      <div className="auto-complete margin-top-8 fullwidth">
                        <RenderAutoSelect
                          id="flag"
                          name="flag"
                          value={self.state.rewrites[ind].flag}
                          label="flag"
                          tab="5"
                          className="height-8 fixed-height last"
                          handleChange={(selected, name) =>
                            handleAutoSelectChange(
                              selected,
                              self,
                              self.state.rewrites[ind],
                              "flag"
                            )
                          }
                          items={rewrites.flag}
                          clearValue={!self.state.rewrites[ind].flag}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="margin-left-16 margin-right-16">
                        <RenderInputWithOutLabel
                          placeholder="Rewrite Regex to match on"
                          id={"regex" + ind}
                          name={"regex" + ind}
                          type="text"
                          value={self.state.rewrites[ind].regex}
                          label="Regex"
                          tab="5"
                          handleChange={e =>
                            handleStateChange(
                              e,
                              self,
                              self.state.rewrites[ind],
                              "regex"
                            )
                          }
                          toolTipTitle={POLICIES_TOOLTIP.REGEX}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <RenderInputWithOutLabel
                        placeholder="Rewrite replacement"
                        id="replacement"
                        name="replacement"
                        type="text"
                        value={self.state.rewrites[ind].replacement}
                        label="Replacement"
                        tab="5"
                        handleChange={e =>
                          handleStateChange(
                            e,
                            self,
                            self.state.rewrites[ind],
                            "replacement"
                          )
                        }
                        toolTipTitle={POLICIES_TOOLTIP.REPLACEMENT}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="Delete"
                        title="Delete rewrite rule"
                        className="margin-top-16"
                        onClick={() =>
                          removeItem(
                            self,
                            self.state.rewrites,
                            ["rewrites"],
                            ind
                          )
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
              <RenderButtonClick
                name="btndomain"
                color="primary"
                className="margin-top-default pull-left clear-both"
                onClick={() => addItem(self, self.state.rewrites, ["rewrites"])}
                variant="contained"
                type="button"
                size="large"
                align="right"
                disabled={false}
                label="Add"
              />
            </div>
          )}
      </div>
    </React.Fragment>
  );
}

export function policyViewFormControls(self, cdnType) {
  const { classes } = self.props;

  return (
    <React.Fragment>
      <div className="react-scroll-view-container fullWidth">
        <div className="fullwidth" data-scroll="true" tab="0">
          <Typography variant="h3" align="left">
            Basic Settings
          </Typography>
          {cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM && (
            <RenderReadOnlyInput
              placeholder="Path/regex for policy."
              id="path"
              name="path"
              value={self.state.path || "No Value"}
              label="Path / regex"
              tab="0"
            />
          )}
          <RenderReadOnlyInput
            placeholder="Description of the policy."
            id="descriptionPolicy"
            name="descriptionPolicy"
            value={self.state.descriptionPolicy || "No Value"}
            label="Description"
            tab="0"
          />
          {cdnType !== MULTI_CDN_PREMIUM && !self.state.isMandatoryView && (
            <RenderReadOnlyInput
              id="allowedMethods"
              name="allowedMethods"
              value={self.state.allowedMethods || "No Value"}
              label="The Allowed Methods for this policy."
              className="container-odd margin-top-16"
            />
          )}
        </div>
        {!self.state.isMandatoryView && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="1">
            <Typography variant="h3" align="left">
              Cache Settings
            </Typography>
            <FormControl component="fieldset" fullWidth>
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithOutToolTip
                    label="Bypass caching rules"
                    classes={classes}
                    name="enabled"
                    checked={self.state.cacheSettings.byPass}
                    value={self.state.cacheSettings.byPass}
                    disabled
                  />
                )}

              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithOutToolTip
                    label="Segmented downloads"
                    classes={classes}
                    name="segmented"
                    checked={self.state.cacheSettings.segmented}
                    value={self.state.cacheSettings.segmented}
                    disabled
                  />
                )}
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM &&
                !self.state.isMandatoryView && (
                  <RenderSwitchWithOutToolTip
                    label="Pass cookies"
                    classes={classes}
                    name="enabled"
                    checked={self.state.cacheSettings.passCookie}
                    value={self.state.cacheSettings.passCookie}
                    disabled
                  />
                )}
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM &&
                !self.state.isMandatoryView && (
                  <RenderSwitchWithOutToolTip
                    label="Pass Vary header"
                    classes={classes}
                    name="enabled"
                    checked={self.state.cacheSettings.passVary}
                    value={self.state.cacheSettings.passVary}
                    disabled
                  />
                )}

              <RenderSwitchWithOutToolTip
                label="Cache Query String"
                classes={classes}
                name="cacheQueryString"
                checked={self.state.cacheSettings.cacheQueryString}
                value={self.state.cacheSettings.cacheQueryString}
                disabled
              />
              <RenderSwitchWithOutToolTip
                label="Honor Origin TTL"
                classes={classes}
                name="honorOriginTtl"
                checked={self.state.cacheSettings.honorOriginTtl}
                value={self.state.cacheSettings.honorOriginTtl}
                disabled
              />
              <RenderReadOnlyInput
                placeholder="Default TTL to use"
                id="defaultTtl"
                name="defaultTtl"
                type="text"
                value={self.state.cacheSettings.defaultTtl || "No Value"}
                label="Default TTL"
              />
              <RenderSwitchWithOutToolTip
                label="Serve stale content"
                classes={classes}
                name="honorOriginTtl"
                checked={self.state.cacheSettings.stale}
                value={self.state.cacheSettings.stale}
                disabled
              />
            </FormControl>
          </div>
        )}
        <div className="margin-top-16 fullwidth" data-scroll="true" tab="2">
          <Typography
            variant="h3"
            align="left"
            className="margin-bottom-default"
          >
            Pull Origin Settings
          </Typography>
          <FormControl component="fieldset" fullWidth>
            {getOriginsFormReadOnlyControls(self, cdnType)}
            {!self.state.isMandatoryView && (
              <React.Fragment>
                <RenderReadOnlyInput
                  id="protocol"
                  name="protocol"
                  value={self.state.pullOriginSettings.protocol || "No value"}
                  label="Protocol"
                  tab="2"
                />
                <br />
                {cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithOutToolTip
                    label="Compressed"
                    classes={classes}
                    name="compressed"
                    checked={self.state.pullOriginSettings.compressed}
                    disabled
                    value={self.state.pullOriginSettings.compressed}
                  />
                )}
                {cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderReadOnlyInput
                    placeholder="Host to use as host header"
                    id="hostHeader"
                    name="hostHeader"
                    type="text"
                    value={
                      self.state.pullOriginSettings.hostHeader || "No value"
                    }
                    label="Host Header"
                  />
                )}
                {cdnType !== MULTI_CDN_PREMIUM && <br />}
                {cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithOutToolTip
                    label="X-Forwarded-For"
                    classes={classes}
                    name="xff"
                    checked={self.state.pullOriginSettings.xff}
                    disabled
                    value={self.state.pullOriginSettings.xff}
                  />
                )}
              </React.Fragment>
            )}
            {!self.state.isMandatoryView && (
              <React.Fragment>
                {cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM && (
                  <React.Fragment>
                    <label className="fullWidth display-block-inline position-relative overflow-hidden">
                      <span className="pull-left margin-right-16">
                        Upstream headers
                      </span>
                    </label>
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      self.state.pullOriginSettings.upstreamHeaders.map(
                        (val, ind) => {
                          return (
                            <Grid
                              container
                              spacing={16}
                              key={"upstreamHeaders" + ind}
                            >
                              <Grid item xs={6}>
                                <RenderReadOnlyInput
                                  placeholder="Header name"
                                  id="header"
                                  name="header"
                                  type="text"
                                  value={
                                    self.state.pullOriginSettings
                                      .upstreamHeaders[ind].header || "No value"
                                  }
                                  label="Header"
                                  tab="2"
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <RenderReadOnlyInput
                                  placeholder="Header value"
                                  id="value"
                                  name="value"
                                  type="text"
                                  value={
                                    self.state.pullOriginSettings
                                      .upstreamHeaders[ind].value || "No value"
                                  }
                                  label="Value"
                                  tab="2"
                                />
                              </Grid>
                              <Grid item xs={1}></Grid>
                            </Grid>
                          );
                        }
                      )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </FormControl>
        </div>
        {!self.state.isMandatoryView && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="3">
            <Typography variant="h3" align="left">
              Delivery settings
            </Typography>
            <FormControl component="fieldset" fullWidth>
              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderSwitchWithOutToolTip
                  label="Force HTTPS"
                  classes={classes}
                  name="forceHttps"
                  checked={self.state.deliverySettings.forceHttps}
                  disabled
                  value={self.state.deliverySettings.forceHttps}
                />
              )}

              <RenderSwitchWithOutToolTip
                label="Inherit TTL"
                classes={classes}
                name="ttlInherit"
                checked={self.state.deliverySettings.ttlInherit}
                value={self.state.deliverySettings.ttlInherit}
                disabled
              />

              <RenderReadOnlyInput
                placeholder="TTL to use"
                id="ttlOverride"
                name="ttlOverride"
                type="text"
                value={self.state.deliverySettings.ttlOverride || "No value"}
                label="TTL Override"
              />

              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderSwitchWithOutToolTip
                  label="Compression"
                  classes={classes}
                  name="compress"
                  checked={self.state.deliverySettings.compress}
                  value={self.state.deliverySettings.compress}
                />
              )}

              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithOutToolTip
                    label="Rate limiting"
                    classes={classes}
                    name="rateLimit"
                    checked={self.state.deliverySettings.rateLimit}
                    value={self.state.deliverySettings.rateLimit}
                  />
                )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                !!self.state.deliverySettings.rateLimit && (
                  <React.Fragment>
                    <RenderReadOnlyInput
                      placeholder="Transfer Rate"
                      id="transferRate"
                      name="transferRate"
                      value={
                        self.state.deliverySettings.transferRate || "No value"
                      }
                      label="Transfer Rate"
                    />
                    <RenderReadOnlyInput
                      placeholder="Burst size"
                      id="burstSize"
                      name="burstSize"
                      type="number"
                      value={
                        self.state.deliverySettings.burstSize || "No value"
                      }
                      label="Burst size"
                    />
                  </React.Fragment>
                )}
              {cdnType !== MULTI_CDN_VOLUME &&
                cdnType !== MULTI_CDN_PREMIUM && (
                  <RenderSwitchWithOutToolTip
                    label="Passthrough all headers"
                    classes={classes}
                    name="enabled"
                    checked={self.state.deliverySettings.passThroughAllHeaders}
                    value={self.state.deliverySettings.passThroughAllHeaders}
                    disabled
                  />
                )}
              {cdnType !== MULTI_CDN_PREMIUM && (
                <label className="fullWidth display-block-inline position-relative overflow-hidden">
                  <span className="pull-left margin-right-16">
                    Passthrough headers
                  </span>
                  <div className="margin-none pull-left"></div>
                </label>
              )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                self.state.deliverySettings.passThroughHeaders.map(
                  (val, ind) => {
                    return (
                      <Grid container spacing={16} key={"passthrheaders" + ind}>
                        <Grid item xs={11}>
                          <RenderReadOnlyInput
                            placeholder="Header name"
                            id="header"
                            name="header"
                            type="text"
                            value={
                              self.state.deliverySettings.passThroughHeaders[
                                ind
                              ].header || "No value"
                            }
                            tab="3"
                            label="Header"
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    );
                  }
                )}

              {cdnType !== MULTI_CDN_PREMIUM && (
                <label className="fullWidth display-block-inline position-relative overflow-hidden margin-top-16">
                  <span className="pull-left margin-right-16">
                    Static Headers
                  </span>
                  <div className="margin-none pull-left"></div>
                </label>
              )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                self.state.deliverySettings.staticHeaders.map((val, ind) => {
                  return (
                    <Grid container spacing={16} key={"staticHeaders" + ind}>
                      <Grid item xs={6}>
                        <RenderReadOnlyInput
                          placeholder="Header name"
                          id="header"
                          name="header"
                          type="text"
                          value={
                            self.state.deliverySettings.staticHeaders[ind]
                              .header || "No value"
                          }
                          label="Header"
                          tab="3"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <RenderReadOnlyInput
                          placeholder="Header value"
                          id="value"
                          name="value"
                          type="text"
                          value={
                            self.state.deliverySettings.staticHeaders[ind]
                              .value || "No value"
                          }
                          label="Value"
                          tab="3"
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  );
                })}
            </FormControl>
          </div>
        )}
        {!self.state.isMandatoryView && cdnType !== MULTI_CDN_PREMIUM && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="4">
            <Typography variant="h3" align="left">
              Security Settings
            </Typography>
            <FormControl component="fieldset" fullWidth>
              <RenderSwitchWithOutToolTip
                label="GEO targeting / blocking"
                classes={classes}
                name="geoAcl"
                checked={self.state.securitySettings.geoAcl}
                value={self.state.securitySettings.geoAcl}
              />
              {!!self.state.securitySettings.geoAcl && (
                <React.Fragment>
                  <label className="fullWidth display-block-inline position-relative overflow-hidden margin-bottom-default">
                    <span className="pull-left margin-right-16">
                      GEO ACL Countries
                    </span>
                    <div className="margin-none pull-left"></div>
                  </label>

                  {cdnType !== MULTI_CDN_VOLUME &&
                    cdnType !== MULTI_CDN_PREMIUM && (
                      <RenderReadOnlyInput
                        id="geoAclDefault"
                        name="geoAclDefault"
                        value={
                          self.state.securitySettings.geoAclDefault ||
                          "No value"
                        }
                        label="Default action"
                        tab="4"
                      />
                    )}
                  {!!self.state.securitySettings.geoAclSettings && (
                    <RenderReadOnlyInput
                      id="selectedCountryValues"
                      name="selectedCountryValues"
                      label="Selected countries"
                      value={self.state.selectedCountryValues || "No value"}
                      tab="4"
                    />
                  )}
                </React.Fragment>
              )}
              {cdnType !== MULTI_CDN_PREMIUM && (
                <RenderSwitchWithOutToolTip
                  label="IP subnet targeting / blocking"
                  classes={classes}
                  name="ipAcl"
                  checked={self.state.securitySettings.ipAcl}
                  className="margin-top-16"
                  value={self.state.securitySettings.ipAcl}
                />
              )}
              {cdnType !== MULTI_CDN_PREMIUM &&
                !!self.state.securitySettings.ipAcl && (
                  <React.Fragment>
                    <label className="fullWidth display-block-inline position-relative overflow-hidden margin-bottom-default">
                      <span className="pull-left margin-right-16">
                        IP subnet ACL
                      </span>
                      <div className="margin-none pull-left"></div>
                    </label>
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      cdnType !== MULTI_CDN_VOLUME && (
                        <RenderReadOnlyInput
                          id="ipAclDefault"
                          name="ipAclDefault"
                          value={
                            self.state.securitySettings.ipAclDefault ||
                            "No value"
                          }
                          label="Default action"
                          error={!self.state.securitySettings.ipAclDefault}
                          items={geoAclDefaultValues}
                          tab="4"
                        />
                      )}
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      self.state.securitySettings.ipAclSettings.map(
                        (val, ind) => {
                          return (
                            <Grid container spacing={16} key={"icl" + ind}>
                              <Grid item xs={6}>
                                <RenderReadOnlyInput
                                  id="ipAclSettingssAction"
                                  name="ipAclSettingssAction"
                                  value={
                                    self.state.securitySettings.ipAclSettings[
                                      ind
                                    ].action || "No value"
                                  }
                                  label="Action"
                                  className="margin-top-16"
                                  tab="4"
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <RenderReadOnlyInput
                                  placeholder="IP subnet"
                                  id="subnet"
                                  name="subnet"
                                  type="text"
                                  value={
                                    self.state.securitySettings.ipAclSettings[
                                      ind
                                    ].subnet || "No value"
                                  }
                                  label="Subnet"
                                  tab="4"
                                />
                              </Grid>
                              <Grid item xs={1}></Grid>
                            </Grid>
                          );
                        }
                      )}
                  </React.Fragment>
                )}
              <RenderSwitchWithOutToolTip
                label="Referrer blocking"
                classes={classes}
                className="margin-top-16"
                name="referrerAcl"
                checked={self.state.securitySettings.referrerAcl}
                value={self.state.securitySettings.referrerAcl}
              />

              {cdnType !== MULTI_CDN_PREMIUM &&
                !!self.state.securitySettings.referrerAcl && (
                  <React.Fragment>
                    <label className="fullWidth display-block-inline position-relative overflow-hidden margin-bottom-default">
                      <span className="pull-left margin-right-16">
                        Referrer ACL
                      </span>
                      <div className="margin-none pull-left"></div>
                    </label>

                    {cdnType !== MULTI_CDN_PREMIUM &&
                      cdnType !== MULTI_CDN_VOLUME && (
                        <RenderReadOnlyInput
                          id="referrerAclDefault"
                          name="referrerAclDefault"
                          value={
                            self.state.securitySettings.referrerAclDefault ||
                            "No value"
                          }
                          label="Default action"
                          tab="4"
                        />
                      )}
                    {cdnType !== MULTI_CDN_PREMIUM &&
                      self.state.securitySettings.referrerAclSettings.map(
                        (val, ind) => {
                          return (
                            <Grid container spacing={16} key={"referral" + ind}>
                              <Grid item xs={6}>
                                <RenderReadOnlyInput
                                  id="referrerAclsAction"
                                  name="referrerAclsAction"
                                  value={
                                    self.state.securitySettings
                                      .referrerAclSettings[ind].action ||
                                    "No value"
                                  }
                                  label="Action"
                                  className="margin-top-16"
                                  items={[{ value: "allow", text: "Allow" }]}
                                  tab="4"
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <RenderReadOnlyInput
                                  placeholder="Referer"
                                  id="referrer"
                                  name="referrer"
                                  type="text"
                                  tab="4"
                                  label="Referrer"
                                  value={
                                    self.state.securitySettings
                                      .referrerAclSettings[ind].referrer ||
                                    "No value"
                                  }
                                />
                              </Grid>
                              <Grid item xs={1}></Grid>
                            </Grid>
                          );
                        }
                      )}
                  </React.Fragment>
                )}
              {cdnType !== MULTI_CDN_PREMIUM && cdnType !== MULTI_CDN_VOLUME && (
                <React.Fragment>
                  <RenderSwitchWithOutToolTip
                    label="Secured (tokenized) URLs"
                    classes={classes}
                    className="margin-top-16"
                    name="token"
                    checked={self.state.securitySettings.token}
                    value={self.state.securitySettings.token}
                  />

                  {!!self.state.securitySettings.token && (
                    <React.Fragment>
                      <label className="fullWidth display-block-inline position-relative overflow-hidden">
                        <span className="pull-left margin-right-16">
                          Token Settings
                        </span>
                      </label>
                      {self.state.securitySettings.tokenSettings.method ===
                        "encrypted" && (
                        <RenderReadOnlyInput
                          placeholder="AES key"
                          id="aesKey"
                          name="aesKey"
                          type="text"
                          value={
                            self.state.securitySettings.tokenSettings.aesKey ||
                            "No value"
                          }
                          label="Aes Key"
                        />
                      )}
                      <RenderReadOnlyInput
                        id="method"
                        name="method"
                        value={
                          self.state.securitySettings.tokenSettings.method ||
                          "No value"
                        }
                        label="Method"
                        className="margin-top-16"
                        tab="4"
                      />

                      {self.state.securitySettings.tokenSettings.method ===
                        "encrypted" && (
                        <RenderReadOnlyInput
                          placeholder="SHA key"
                          id="shaKey"
                          name="shaKey"
                          type="text"
                          value={
                            self.state.securitySettings.tokenSettings.shaKey ||
                            "No value"
                          }
                          label="SHA Key"
                          className="margin-top-16"
                          tab="4"
                        />
                      )}
                      {self.state.securitySettings.tokenSettings.method ===
                        "simple" && (
                        <RenderReadOnlyInput
                          placeholder="Simple secret"
                          id="secret"
                          name="secret"
                          type="text"
                          value={
                            self.state.securitySettings.tokenSettings.secret ||
                            "No value"
                          }
                          label="Secret"
                          min={8}
                          max={125}
                          tab="4"
                        />
                      )}
                      <RenderReadOnlyInput
                        id="tokenVendor"
                        name="tokenVendor"
                        value={
                          self.state.securitySettings.tokenSettings.vendor ||
                          "No value"
                        }
                        label="Vendor"
                        items={tokenVendors}
                        className="margin-top-16"
                        tab="4"
                      />

                      {self.state.securitySettings.tokenSettings.method ===
                        "simple" && (
                        <RenderSwitchWithOutToolTip
                          label="Use client IP"
                          classes={classes}
                          name="clientIp"
                          checked={
                            self.state.securitySettings.tokenSettings.clientIp
                          }
                          value={
                            self.state.securitySettings.tokenSettings.clientIp
                          }
                        />
                      )}

                      <RenderReadOnlyInput
                        placeholder="Number of path components"
                        id="numberOfPathComponents"
                        name="numberOfPathComponents"
                        type="number"
                        value={
                          self.state.securitySettings.tokenSettings
                            .numberOfPathComponents || "No value"
                        }
                        label="Number of path components"
                      />

                      <RenderSwitchWithOutToolTip
                        label="Embed into path"
                        classes={classes}
                        name="embedIntoPath"
                        checked={
                          self.state.securitySettings.tokenSettings
                            .embedIntoPath
                        }
                        value={
                          self.state.securitySettings.tokenSettings
                            .embedIntoPath
                        }
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </FormControl>
          </div>
        )}
        {cdnType !== MULTI_CDN_PREMIUM && cdnType !== MULTI_CDN_VOLUME && (
          <div className="margin-top-16 fullwidth" data-scroll="true" tab="5">
            <h6 className="inactive margin-none font-default fullWidth display-block-inline position-relative fullwidth overflow-hidden">
              <span className="pull-left margin-right-16">Rewrites</span>
              <div className="margin-none pull-left"></div>
            </h6>

            {self.state.rewrites.map((val, ind) => {
              return (
                <Grid container spacing={16} key={"rewrites" + ind}>
                  <Grid item xs={4}>
                    <RenderReadOnlyInput
                      id="flag"
                      name="flag"
                      value={self.state.rewrites[ind].flag || "No value"}
                      label="flag"
                      tab="5"
                      className="height-8"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderReadOnlyInput
                      placeholder="Rewrite Regex to match on"
                      id={"regex" + ind}
                      name={"regex" + ind}
                      type="text"
                      value={self.state.rewrites[ind].regex || "No value"}
                      label="Regex"
                      tab="5"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <RenderReadOnlyInput
                      placeholder="Rewrite replacement"
                      id="replacement"
                      name="replacement"
                      type="text"
                      value={self.state.rewrites[ind].replacement || "No value"}
                      label="Replacement"
                      tab="5"
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function resetTTL(e, self, parent, key) {
  if (!!e.target.checked) {
    parent.ttlOverride = 0;
  }

  handleStateRadioChange(e, self, parent, key);
}

function checkForPassThroughAllHeaders(self, parent, obj, key) {
  // parent.passThroughAllHeaders = false;
  addItem(self, obj, key);
}

function handlePassThroughAllHeaders(e, self, parent, key) {
  if (!!e.target.checked) {
    parent.passThroughHeaders = [];
  }

  handleStateRadioChange(e, self, parent, key);
}

function changeTokenAutoSelectSettings(selected, self, parent, key) {
  const value = !!selected ? selected.value : null;
  if (value === "simple") {
    parent.aesKey = null;
    parent.shaKey = null;
  }
  if (value === "encrypted") {
    parent.secret = null;
  }
  handleAutoSelectChange(selected, self, parent, key);
}

function resetCacheSettings(e, self, parent, key) {
  if (!!e.target.checked) {
    parent.segmented = false;
    parent.cacheQueryString = false;
    parent.honorOriginTtl = false;
    parent.defaultTtl = 0;
    parent.stale = false;
  }
  handleStateRadioChange(e, self, parent, key);
}
let modalStatusOpen =false;
function handleStateRadioChange(e, self, parent, key) {
  e.stopPropagation();
  parent[key] = e.target.checked; //updating value
  self.setState({ parent });
  if(!modalStatusOpen){
    modalStatusOpen=true;
  }
}
function handleStateModal(e,self,parent) {
  e.stopPropagation();
  modalStatusOpen=false;
  self.setState({ parent });
}
function checkForCDNtype(cdnType) {
  let obj = {};
  if (cdnType !== MULTI_CDN_VOLUME && cdnType !== MULTI_CDN_PREMIUM) {
    obj.originGroupId = null;
  }
  if (cdnType !== SHIELD_CDN) {
    obj.shieldId = null;
  }
  return obj;
}

function handleOriginValidation(
  selected,
  self,
  parent,
  key,
  required,
  cdnType
) {
  if (!!selected && !!selected.value) {
    const val = selected.value;
    if (key === "originId" && !!val) {
      self.setState(prevState => ({
        ...prevState,
        pullOriginSettings: {
          ...prevState.pullOriginSettings,
          ...checkForCDNtype(cdnType),
          originId: val
        }
      }));
    }

    if (key === "originGroupId" && !!val) {
      self.setState(prevState => ({
        ...prevState,
        pullOriginSettings: {
          ...prevState.pullOriginSettings,
          originId: null,
          ...checkForCDNtype(cdnType),
          originGroupId: val
        }
      }));
    }

    if (key === "shieldId" && !!val) {
      self.setState(prevState => ({
        ...prevState,
        pullOriginSettings: {
          ...prevState.pullOriginSettings,
          originId: null,
          ...checkForCDNtype(cdnType),
          shieldId: val
        }
      }));
    }
    self.setState({ hasError: false });
    handleAutoSelectChange(selected, self, parent, key);
  } else {
    handleAutoSelectChange(null, self, parent, key);
    self.setState({ hasError: true });
  }
}

function handleAutoSelectChange(selected, self, parent, key, required) {
  const value = !!selected ? selected.value : null;
  parent[key] = value; //updating value
  self.setState({ hasError: false });
  self.setState({ parent });
  if (key === "geoAclDefault") {
    self.setGeoAclSettings(self.state.selectedCountryValues);
  }
}

function handleStateChange(e, self, parent, key, required) {
  parent[key] = e.target.value; //updating value
  self.setState({ hasError: false });
  self.setState({ parent });
  if (key === "geoAclDefault") {
    self.setGeoAclSettings(self.state.selectedCountryValues);
  }
}

function removeItem(self, obj, key, index) {
  if (obj.length >= 1) {
    obj.splice(index, 1);
  }
  self.getElementsCoordinates();
  self.setState({ [key]: obj });
}

function addItem(self, obj, key) {
  if (obj.length < 10) {
    obj.push({});
  }
  self.getElementsCoordinates();
  self.setState({ [key]: obj });
}

function getTextValueFormatForCountries(obj) {
  if (!obj) return;
  let object = [];
  for (let ii = 0, jj = obj.length; ii < jj; ii++) {
    object.push({
      value: obj[ii].alpha2 || obj[ii].value,
      text: obj[ii].name || obj[ii].text
    });
  }
  return object;
}

function getTextValueFormat(obj) {
  if (!obj) return;
  let object = [];
  for (let ii = 0, jj = obj.length; ii < jj; ii++) {
    object.push({
      value: obj[ii].id,
      text: obj[ii].description
    });
  }
  return object;
}

export function extendArray(arr1, arr2, pushElm) {
  if (!arr2) return arr1;
  for (var ii = 0, jj = arr2.length; ii < jj; ii++) {
    arr1.push(arr2[ii]);
  }
  return arr1;
}

export function getSelectControlValues(customerId, endpoint) {
  axios.defaults.headers.common["Authorization"] = authClient.getBearerToken();

  const url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    endpoint;

  return new Promise(function(resolve, reject) {
    axios
      .get(url)
      .then(result => {
        resolve(result.data.response);
      })
      .catch(error => {
        resolve([]);
      });
  });
}

const rewrites = {
  flag: [
    {
      value: "break",
      text: "Break"
    },
    {
      value: "last",
      text: "Last"
    },
    {
      value: "permanent",
      text: "Permanent"
    },
    {
      value: "redirect",
      text: "Redirect"
    }
  ]
};

const methods = [
  {
    value: "GET",
    text: "GET"
  },
  {
    value: "POST",
    text: "POST"
  },
  {
    value: "PUT",
    text: "PUT"
  },
  {
    value: "DELETE",
    text: "DELETE"
  },
  {
    value: "OPTIONS",
    text: "OPTIONS"
  },
  {
    value: "PATCH",
    text: "PATCH"
  }
];

const protocol = [
  {
    value: "HTTP",
    text: "HTTP"
  },
  {
    value: "HTTPS",
    text: "HTTPS"
  }
];

const tokenMethods = [
  {
    value: "simple",
    text: "Simple"
  },
  {
    value: "encrypted",
    text: "Encrypted"
  }
];

const tokenVendors = [
  {
    value: "leaseweb",
    text: "Leaseweb"
  }
];

const geoAclDefaultValues = [
  {
    value: "reject",
    text: "Reject"
  },
  {
    value: "allow",
    text: "Allow"
  }
];


