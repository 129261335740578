import Snackbar from "@material-ui/core/Snackbar";
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import idleTimeCheck from "../../common/helpers/idleTImeCheck";
import authClient from "./auth";

class Notification extends Component {
  handleClose(props) {
    if (props.buttonlabel === "Make me active") {
      authClient.clearRenewInterval();
      authClient.timedRefresh(0);
    }
    window.scrollTo(0, 0);
    props.close();
  }

  render() {
    return (
      <Snackbar
        message={this.props.description}
        varianticon={this.props.variant}
        className={this.props.variant + " notification"}
        color={this.props.variant}
        open={this.props.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={this.props.duration}
        onClose={() => this.handleClose(this.props)}
        action={[
          <Button
            key="close"
            color={this.props.buttontype}
            size={this.props.buttonsize}
            onClick={() => this.handleClose(this.props)}
          >
            {this.props.buttonlabel}
          </Button>
        ]}
      />
    );
  }
}

export const initialState = () => {
  let state = {
    error: "",
    variant: "",
    open: false
  };

  return state;
};

export function setNotificationVisibility() {
  let _self = this;
  idleTimeCheck.register(function(obj) {
    _self.setState({
      error: obj.message,
      variant: obj.variant,
      label: obj.label,
      color: "primary"
    });
    if (!!obj.open) {
      _self.props.showNotification();
    } else {
      _self.props.hideNotification();
    }
  });
}

export default Notification;
