import { ERROR_IN_ORIGIN, DELETE_ORIGIN, GET_ORIGIN, GET_ORIGINS, UPDATE_ORIGIN, ADD_ORIGIN, RESET_ORIGINS } from './types';
import { session as userSession } from '../common/helpers/sessionValues';
import authClient from '../common/helpers/auth';
import { apiPostCall, apiGetCall } from '../actions/baseActions';

export function resetOrigins() {
    return function (dispatch) {
        dispatch({
            type: GET_ORIGINS,
            data: null
        })
    }
}
export function getOrigins(customerId, type) {
    let url = userSession.getSessionItem('apiUrl') + 'configurations/'
        + customerId + '/origins/' + type;

    const token = authClient.getBearerToken(),
        typeError = ERROR_IN_ORIGIN, typeSuccess = GET_ORIGINS, method = "GET";
    const callback = function () { }
    return apiGetCall(url, token, typeError, typeSuccess, method, "data", callback);
}

export function getOrigin(customerId, type, originId, callback) {
    let url = userSession.getSessionItem('apiUrl') + 'configurations/'
        + customerId + '/origins/' + type + '/' + originId;

    const token = authClient.getBearerToken(),
        typeError = ERROR_IN_ORIGIN, typeSuccess = GET_ORIGIN, method = "GET";
    return apiGetCall(url, token, typeError, typeSuccess, method, "data", callback);
}

export function deleteOrigin(customerId, type, originId, callback) {
    let url = userSession.getSessionItem('apiUrl') + 'configurations/'
        + customerId + '/origins/' + type + '/' + originId;

    const token = authClient.getBearerToken(),
        typeError = ERROR_IN_ORIGIN, typeSuccess = DELETE_ORIGIN, method = "DELETE";
    return apiGetCall(url, token, typeError, typeSuccess, method, "data", callback);
}

export function updateOrigin(customerId, type, originId, mthod, payload, callback) {
    let url = userSession.getSessionItem('apiUrl') + 'configurations/'
        + customerId + '/origins/' + type + "/" + originId;

    const token = authClient.getBearerToken(), method = mthod,
        typeError = ERROR_IN_ORIGIN, typeSuccess = UPDATE_ORIGIN;
    const postObject = JSON.stringify(payload), contentType = "application/json";

    return apiPostCall(url, token, method, postObject, typeError, typeSuccess, contentType, "data", callback);
}

export function addOrigin(customerId, type, mthod, payload, callback) {
    let url = userSession.getSessionItem('apiUrl') + 'configurations/'
        + customerId + '/origins/' + type;

    const token = authClient.getBearerToken(), method = mthod,
        typeError = ERROR_IN_ORIGIN, typeSuccess = ADD_ORIGIN;
    const postObject = JSON.stringify(payload), contentType = "application/json";

    return apiPostCall(url, token, method, postObject, typeError, typeSuccess, contentType, "data", callback);
}

export function resetList() {
    return function (dispatch) {
        dispatch({
            type: RESET_ORIGINS,
            data: null
        })
    }
}