import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import classNames from "classnames";
import "./card.scss";

export default class Tile extends Component {
  render() {
    const { heading, subheading, last, handleClick, active } = this.props;

    return (
      <Card
        className={classNames({
          "tile-arrow button margin-top-default": true,
          active: !!active && !!handleClick,
          click: !active && !last && !!handleClick
        })}
        onClick={() => {
          !active && !!handleClick
            ? handleClick(subheading)
            : console.log("no click");
        }}
      >
        <CardContent>
          <div className="pull-left">
            <div className="tile-heading">{heading}</div>
            <div className="tile-subheading">{subheading}</div>
          </div>
        </CardContent>
      </Card>
    );
  }
}
