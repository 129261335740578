import React from "react";
import {
  showNotification,
  hideNotification,
} from "../../actions/notificationAction";
import {
  getUserDetails,
  updateUserDetails,
  updateUserPassword,
  enable2FA,
  switchUserDetails,
} from "../../actions/userDetailsAction";
import AlertDialog from "../Dialog/AlertDialog";
import Notification, {
  initialState,
  setNotificationVisibility,
} from "../../common/helpers/notification";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import {
  getCustomerOverviewDetails,
  updateCustomerProfile,
} from "../../actions/customerOverviewAction";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { apiDataMapper } from "../../common/helpers/ApiDataMapper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import { tabStyling } from "../../common/theme/tabStyling";
import FixedNotification from "../../common/helpers/fixedNotification";
import {
  RenderButtonClick,
  RenderSwitchWithToolTip,
} from "../../common/helpers/renderElement";
import { passwordCheck } from "../../common/helpers/passwordCheck";
import SimpleDialog from "../Dialog/SimpleDialog";
import { userDataMapper } from "../../common/constants/user";
import { contactDataMapper } from "../../common/constants/customer";
import BaseComponent from "../../common/base/BaseComponent";
import { deepCompareTwoSimilarObjects } from "../../common/helpers/stringHelper";
import { Divider } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{ paddingTop: 8 * 2, paddingBottom: 8 * 2 }}
    >
      {props.children}
    </Typography>
  );
}

const mappedUserProfileData = {
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
};

const mappedUserPasswordData = {
  password: "",
  confirmPassword: "",
};

class Profile extends BaseComponent {
  role = "";
  contactDataArray = [];
  profileOldObject = null;
  confPasswordMatchedString = "Both password and confirm password matched.";
  confPasswordUnMatchedString =
    "Both password and confirm password do not match.";

  constructor(props) {
    super(props);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.toggle2FAForUser = this.toggle2FAForUser.bind(this);
    this.state = {
      ...initialState(),
      value: 0,
      formView: true,
      showPassword: false,
      isLoading: false,
      isLoadingPassword: false,
      isProfile: true,
      confPasswordMatch: this.confPasswordUnMatchedString,
      isLoadingActivate: false,
      passwordWeak: false,
      passwordGood: false,
      passwordStrong: false,
      expanded: null,
      openDialog: false,
      dialogToggleFlag: false,
      isEditFormView: false,
      btnLabel: "Close",
    };
  }

  componentWillMount() {
    this.selectRightMappingData(null, this.props.userData);
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.props.hideNotification();
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.userData) {
      this.props.getUserDetails();
    }

    if (nextProps.switchUserData !== this.props.switchUserData) {
      this.setState({
        value: 0,
      });
    }

    this.selectRightMappingData(nextProps.switchUserData, nextProps.userData);
  }

  selectRightMappingData(switchUserData, userData) {
    if (!!switchUserData) {
      this.mapToUserData(switchUserData);
    } else {
      this.mapToUserData(userData);
    }
  }

  handleAccordionChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  handleDialogClose() {
    this.setState((state) => ({ dialogToggleFlag: !state.dialogToggleFlag }));
  }

  mapToUserData(userData) {
    if (!!userData) {
      this.flattenObject(userData);
      this.mapUserProfileData(userData);
      this.mapUserPasswordData(userData);
    } else {
      this.props.getUserDetails();
    }
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({
      [radio]: event.target.checked,
    });
    if (radio === "isEditFormView") {
      this.toggleView(!this.state.isEditFormView);
    }
  };

  toggleView(view) {
    this.setState({ formView: !view });
  }

  handleReadToggleAndFormView() {
    this.setState({
      formView: !this.state.formView,
      isEditFormView: !this.state.isEditFormView,
    });
  }

  handleFieldChange(e) {
    const _this = this,
      name = e.target.name;
    _this.setState({ [name]: e.target.value });
    if (name.indexOf("Password") !== -1) {
      _this.passwordStrengthCheck(name, e.target.value);
      const fieldName =
        name.indexOf("confirmPassword") !== -1
          ? "passwordPassword"
          : "confirmPasswordConfirmPassword";
      _this.checkForPasswordMatch(_this.state[fieldName], e.target.value);
    }
  }

  passwordStrengthCheck(name, value) {
    if (name.indexOf("confirmPassword") !== -1) return false;
    let strength = passwordCheck.strongPassword(value);
    this.setState({
      passwordWeak: passwordCheck.hasCharacters(value),
      passwordGood: passwordCheck.hasNumbers(value),
      passwordStrong: passwordCheck.hasSpecialChar(value) && strength,
    });
  }

  checkForPasswordMatch(password, confPassword) {
    if (password !== confPassword) {
      this.setState({
        confPasswordMatch: this.confPasswordUnMatchedString,
      });
    } else {
      this.setState({
        confPasswordMatch: this.confPasswordMatchedString,
      });
    }
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  flattenObject(contactData, parent) {
    for (let key in contactData) {
      if (typeof contactData[key] !== "object" || contactData[key] === null) {
        let keyName = !!parent
          ? key + apiDataMapper.changeCase(parent, 0)
          : key + apiDataMapper.changeCase(key, 0);
        this.contactDataArray.push({ [keyName]: contactData[key] });
        this.setState({ [keyName]: contactData[key] });
      } else {
        this.flattenObject(contactData[key], key);
      }
    }
  }

  mapUserProfileData(userData) {
    for (let key in mappedUserProfileData) {
      if (!!userData.hasOwnProperty(key)) {
        mappedUserProfileData[key] = userData[key] || "";
      }
    }
  }

  mapUserPasswordData(userData) {
    for (let key in mappedUserPasswordData) {
      if (!!userData.hasOwnProperty(key)) {
        mappedUserPasswordData[key] = userData[key] || "";
      }
    }
  }

  userConfirmationBefore2FAForUser() {
    this.setState({
      openDialog: true,
    });
  }

  async toggle2FAForUser(userData, type) {
    const _this = this;
    this.setState({ isLoadingActivate: true, openDialog: false });
    const customerId = !!localStorage.SwitchCustomerDetail
      ? userData.id
      : userData.customerId;
    if (!type) {
      await this.props.enable2FA(customerId, "enable", function (data) {
        if (!!data && typeof data === "object") {
          let fa2Account = data.email + ":" + _this.props.label;
          let qrCode =
            "https://chart.googleapis.com/chart?chs=200x200&chld=M%%7C0&cht=qr&chl=otpauth://totp/" +
            fa2Account +
            "%3Fsecret%3D" +
            data.fa2Secret;
          _this.setState({
            isLoadingActivate: false,
            userAccount: fa2Account,
            userQRKey: data.fa2Secret,
            userQRImg: qrCode,
            dialogToggleFlag: true,
          });
          userData.fa2Active = true;

          _this.toggleNotification(
            `2FA activated successfully.`,
            "success",
            "Close"
          );
        } else {
          _this.setState({ isLoading: false, dialogToggleFlag: false });
          _this.toggleNotification(data, "error", "Close");
        }
      });
    } else {
      userData.fa2Active = false;
      await _this.props.enable2FA(customerId, "disable", function (data) {
        if (typeof data === "object") {
          _this.setState({ isLoading: false, dialogToggleFlag: false });
          _this.toggleNotification(
            `2FA deactivated successfully.`,
            "success",
            "Close"
          );
        } else {
          _this.setState({ isLoading: false, dialogToggleFlag: false });
          _this.toggleNotification(data, "error", "Close");
        }
      });
    }
  }
  async updateUser() {
    if (!!this.checkForRequiredFieldsAllViews(".profile-container")) {
      let payload = userDataMapper(this);
      let _this = this;

      const unchanged = deepCompareTwoSimilarObjects(
        _this.profileOldObject,
        payload,
        "updateDate"
      );

      console.log("unchanged ", unchanged);

      if (!unchanged) {
        _this.toggleNotification(
          "No change found! Please make changes to field(s) and click on Save button!",
          "warning",
          "Close"
        );
        _this.profileOldObject = JSON.parse(JSON.stringify(payload));
        return false;
      }

      _this.profileOldObject = JSON.parse(JSON.stringify(payload));
      _this.setState({ isLoading: true });

      await _this.props.updateUserDetails(payload, function (data) {
        if (typeof data === "object") {
          _this.contactDataArray = [];
          _this.props.getUserDetails();
          _this.mapUserProfileData(data);
          _this.mapUserPasswordData(data);
          _this.setState({ isLoading: false, formView: true });
          _this.toggleNotification(
            `User data has been updated successfully.`,
            "success",
            "Close"
          );
        } else {
          _this.setState({ isLoading: false });
          _this.toggleNotification(data, "error", "Close");
        }
      });
    }
  }

  async updateUserPassword() {
    if (!!this.checkForRequiredFieldsAllViews(".password-container")) {
      let _this = this;

      _this.setState({ isLoadingPassword: true });
      const payload = {
        password: this.state.passwordPassword,
      };

      await _this.props.updateUserPassword(payload, function (data) {
        if (data === "Password updated") {
          _this.mapUserPasswordData(data);
          _this.setState({ isLoadingPassword: false });
          _this.toggleNotification(
            `User password has been updated successfully.`,
            "success",
            "Close"
          );
        } else {
          _this.setState({ isLoadingPassword: false });
          _this.toggleNotification(data, "error", "Close");
        }
      });
    }
  }

  async updateCustomer(userData) {
    if (!!this.checkForRequiredFieldsAllViews()) {
      this.setState({ isLoading: true });
      const customerId = !!localStorage.SwitchCustomerDetail
        ? userData.id
        : userData.customerId;
      let payload = contactDataMapper(this);

      let _this = this;
      await _this.props.updateCustomerProfile(
        customerId,
        payload,
        function (data) {
          if (typeof data === "object") {
            if (_this.props.switchUserData) {
              _this.updateSwitchCustomerDetails(data);
            }
            _this.props.getCustomerOverviewDetails();
            _this.setState({
              isLoading: false,
              formView: true,
              isEditFormView: false,
            });
            _this.toggleNotification(
              `Customer data has been updated successfully.`,
              "success",
              "Close"
            );
          } else {
            _this.setState({ isLoading: false });
            _this.toggleNotification(data, "error", "Close");
          }
        }
      );
    }
  }

  findFirstResellerMatch(customerData, match) {
    for (let ii = 0, jj = customerData.length; ii < jj; ii++) {
      if (customerData[ii].type === match) {
        return customerData[ii];
      }
    }
    return customerData[0];
  }

  getEditButton() {
    return (
      <RenderButtonClick
        name="Edit"
        color="primary"
        onClick={() => this.handleReadToggleAndFormView()}
        variant="contained"
        type="button"
        size="medium"
        align="right"
        label={"Edit"}
      />
    );
  }

  getConfirmationMessage(auth2FAValue) {
    return !!auth2FAValue
      ? "Are you sure about disabling 2FA?"
      : "Are you sure about enabling 2FA?";
  }

  render() {
    let profileDataFirst;
    const {
      error,
      variant,
      color,
      value,
      isLoading,
      isLoadingPassword,
      dialogToggleFlag,
      userAccount,
      userQRKey,
      userQRImg,
      btnLabel,
      formView,
      passwordStrong,
      confPasswordMatch,
    } = this.state;
    const { open, userData, customerData, classes, switchUserData } =
      this.props;

    if (!userData) {
      return this.getSecordaryLoader();
    }

    if (!!customerData && !switchUserData) {
      profileDataFirst = this.findFirstResellerMatch(
        customerData,
        "RESELLER_ADMIN"
      );
    } else if (!!customerData && !!switchUserData) {
      profileDataFirst = switchUserData;
    }

    if (!profileDataFirst) {
      return this.getSecordaryLoader();
    }

    const selectedUserDetails = switchUserData || userData;

    const dialogContent = (
      <React.Fragment>
        <h3 className="margin-none">Two-factor authentication information</h3>
        <p className="margin-top-none">
          In order to generate 2FA secret codes you need to use an authenticator
          app on your phone (e.g Google Authenticator.) You can scan the next
          bar code :
        </p>
        <img src={userQRImg} width="40%" alt="qrcode" />
        <br />
        <p>
          or enter data manually:
          <br />
          Account: {userAccount}
          <br />
          Key: {userQRKey}
        </p>
      </React.Fragment>
    );

    const isImpersonated = !!localStorage.SwitchCustomerDetail || false;

    return (
      <React.Fragment>
        <SimpleDialog
          fullWidth
          dialogToggleFlag={dialogToggleFlag}
          handleDialogClose={this.handleDialogClose}
          dialogContent={dialogContent}
        />
        <AlertDialog
          openDialog={this.state.openDialog}
          heading="Confirm"
          message={this.getConfirmationMessage(selectedUserDetails.fa2Active)}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          cancelBtnAction={() => this.handleDialogToggle()}
          confirmBtnAction={() =>
            this.toggle2FAForUser(
              selectedUserDetails,
              selectedUserDetails.fa2Active
            )
          }
        />
        <Typography variant="h4" color="initial">
          Profile
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={btnLabel}
          buttonsize="small"
        />
        <Paper
          className={classes.paperRoot + " react-scroll-view-container profile"}
        >
          <Tabs
            value={value}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className="padding-none border-default-bottom"
          >
            <Tab
              label="User Details"
              className="tab-item user-details"
              onClick={() => this.flattenObject(mappedUserProfileData)}
            />
            <Tab
              label="Contact Details"
              className="tab-item contact-details"
              onClick={() => this.flattenObject(profileDataFirst)}
            />
          </Tabs>
          <FormControl fullWidth className="padding-none">
            {value === 0 && (
              <TabContainer>
                <FixedNotification
                  variant="warning flat fullwidth"
                  message="Warning. Changing your email will change your login information!"
                />
                <FormGroup row>
                  {
                    <div className="profile-container fullwidth">
                      {Object.keys(mappedUserProfileData).map((key, index) =>
                        apiDataMapper.createFields(
                          this,
                          key,
                          mappedUserProfileData[key],
                          index,
                          "label",
                          isImpersonated
                        )
                      )}
                    </div>
                  }
                  {!formView && (
                    <div className="btn-container">
                      <RenderButtonClick
                        name="save"
                        color="primary"
                        onClick={() => this.updateUser()}
                        variant="contained"
                        type="button"
                        size="medium"
                        align="right"
                        label="Save profile"
                        disabled={isLoadingPassword}
                        submitDataInProgress={isLoading}
                      />
                    </div>
                  )}
                  {!formView && (
                    <Divider className="fullwidth margin-botton-16 margin-top-16" />
                  )}

                  {
                    <div className="password-container fullwidth display-block">
                      {Object.keys(mappedUserPasswordData).map((key, index) =>
                        apiDataMapper.createFields(
                          this,
                          key,
                          mappedUserPasswordData[key],
                          index,
                          "label",
                          isImpersonated
                        )
                      )}
                      {!formView && (
                        <span
                          className={
                            confPasswordMatch ===
                            this.confPasswordUnMatchedString
                              ? "info"
                              : "info info-success"
                          }
                        >
                          {confPasswordMatch}
                        </span>
                      )}
                    </div>
                  }
                  {!formView && (
                    <div className="btn-container">
                      <RenderButtonClick
                        name="save"
                        color="primary"
                        onClick={() => this.updateUserPassword()}
                        variant="contained"
                        type="button"
                        size="medium"
                        align="right"
                        label="Save password"
                        disabled={
                          !(
                            passwordStrong &&
                            confPasswordMatch === this.confPasswordMatchedString
                          )
                        }
                        submitDataInProgress={isLoading}
                      />
                    </div>
                  )}

                  {!formView && (
                    <Divider className="fullwidth margin-top-16 margin-botton-16" />
                  )}

                  <div className="container-block secondFactorButton fullwidth">
                    {!isImpersonated && (
                      <RenderSwitchWithToolTip
                        label={"Activate 2FA authentication"}
                        className="pull-left"
                        name="secondFactorButton"
                        checked={selectedUserDetails.fa2Active}
                        value={selectedUserDetails.fa2Active}
                        disabled={selectedUserDetails.fa2Active}
                        handleRadioChange={() =>
                          this.userConfirmationBefore2FAForUser(
                            selectedUserDetails,
                            selectedUserDetails.fa2Active
                          )
                        }
                        toolTipTitle={
                          "Kindly set 2FA and capture it in any of your favourite authenticator app. 2FA is extra security layer hence we can't disable it after activation!"
                        }
                      />
                    )}
                    {selectedUserDetails.fa2Active &&
                      !dialogToggleFlag &&
                      userQRImg && (
                        <div
                          className="link-cursor pull-left button-reopen button-reopen-switch"
                          title="Reopen Two-factor authentication dialog"
                          onClick={this.handleDialogClose}
                        >
                          <LaunchIcon />
                        </div>
                      )}
                  </div>

                  {!!formView && !isImpersonated && (
                    <div className="btn-container">{this.getEditButton()}</div>
                  )}
                  {!formView ? (
                    <div className="btn-container">
                      <RenderButtonClick
                        name="cancel"
                        onClick={() => this.toggleView(formView)}
                        color="secondary"
                        variant="contained"
                        type="button"
                        size="medium"
                        align="right"
                        label="Cancel"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </TabContainer>
            )}
            {value === 1 && (
              <TabContainer>
                <FormGroup row className="fields-fullLength">
                  {Object.keys(profileDataFirst).map((key, index) =>
                    apiDataMapper.createFields(
                      this,
                      key,
                      profileDataFirst[key],
                      index,
                      "label"
                    )
                  )}
                  {!!formView && (
                    <div className="btn-container">{this.getEditButton()}</div>
                  )}
                  {!formView ? (
                    <div className="btn-container">
                      <RenderButtonClick
                        name="cancel"
                        onClick={() => this.toggleView(formView)}
                        color="secondary"
                        variant="contained"
                        type="button"
                        size="medium"
                        align="right"
                        label="Cancel"
                      />
                      <RenderButtonClick
                        name="save"
                        color="primary"
                        onClick={() => this.updateCustomer(selectedUserDetails)}
                        variant="contained"
                        type="button"
                        size="medium"
                        align="right"
                        label="Save"
                        submitDataInProgress={isLoading}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </TabContainer>
            )}
          </FormControl>
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.notification.items,
  switchUserData: state.user.switchUserData,
  customerData: state.customerOverview.data,
  users: state.user.users,
});

export default connect(mapStateToProps, {
  showNotification,
  hideNotification,
  getUserDetails,
  getCustomerOverviewDetails,
  switchUserDetails,
  updateCustomerProfile,
  updateUserDetails,
  updateUserPassword,
  enable2FA,
})(withStyles(tabStyling)(Profile));
