import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from './types'

export function showNotification(){
    return function(dispatch){
        dispatch({
            type: SHOW_NOTIFICATION,
            visibility: true
        })
    }
}

export function hideNotification(){
    return function(dispatch){
        dispatch({
            type: HIDE_NOTIFICATION,
            visibility: false
        })
    }
}