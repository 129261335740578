import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { RESELLER } from "../../common/constants/roles";

import {
  RenderSearchTableInput,
  RenderSwitch
} from "../../common/helpers/renderElement";

import { applyFilteredData } from "../../actions/distributionAction";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import {
  createTableData,
  stableSort,
  getSorting,
  tableStyles
} from "../../common/helpers/tableRulesAndStyles";
import {
  getCustomerOverviewDetails,
  deleteSeletedCustomer,
  addCustomer,
  addSubCustomer,
  fetchCustomerContactData,
  getStatisticsTotalsData,
  resetCustomerOverview
} from "../../actions/customerOverviewAction";
import { connect } from "react-redux";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";

import "./enhancedTable.scss";
import { Button } from "@material-ui/core";
import { switchUserDetails } from "../../actions/userDetailsAction";
import AlertDialog from "../Dialog/AlertDialog";
import FormDialog from "../Dialog/FormDialog";
import { NORESPONSE, tableLoaderEmpty } from "../../common/helpers/loader";
import BaseComponent from "../../common/base/BaseComponent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "../Cards/card.scss";
import {
  getOneMonthDateRange,
  formatDateTime,
  shortByDate
} from "../../common/helpers/dateHelper";
import authClient from "../../common/helpers/auth";
import Tile from "../Cards/Tile";
import { valueFormatter } from "../../common/constants/chart";

const DATATRANSFER = "datatransfer";
const PERCENTILE = "95th";
const CUSTOMERTOTALS = "customertotals";

export let resellerConfig = {
  order: "desc",
  orderBy: "createDate",
  selected: [],
  page: 0,
  rowsPerPage: 10,
  openDialog: false,
  openFormDialog: false,
  isLoading: false,
  dataTransfer: undefined,
  percentile: undefined,
  tableSearchText: "",
  bandwidthUsed: [],
  tableData: []
};

export const resellerTableRows = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  {
    id: "bandwidth",
    numeric: false,
    disablePadding: false,
    label: "Bandwidth used"
  },
  {
    id: "toggleCustomer",
    numeric: true,
    disablePadding: false,
    label: "Enable/Disable customer"
  },
  {
    id: "impersonate",
    numeric: true,
    disablePadding: false,
    label: "Impersonate"
  },
  { id: "id", numeric: true, disablePadding: false, label: "Action(s)" }
];
var rows = resellerTableRows;

class EnhancedTable extends BaseComponent {
  dataTransfer = "0 KB";
  percentile = "0 bps";
  tableLocalData = [];
  tableListFull;

  constructor(props) {
    super();
    this.state = resellerConfig;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleToggleCustomer = this.handleToggleCustomer.bind(this);
    this.handleImpersonate = this.handleImpersonate.bind(this);
  }

  componentDidMount() {
    this.getCustomerDataAndBandWidth();
  }

  componentWillUnmount() {
    this.resetData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { customerData, role } = this.props;
    const { tableData } = this.state;
    if (
      prevProps.customerData !== customerData &&
      !!customerData &&
      !!tableData &&
      customerData.length !== tableData.length &&
      role === RESELLER
    ) {
      this.setState({
        tableData: null,
        tableSearchText: ""
      });
      this.getCustomerDataAndBandWidth();
    }
  }

  getCustomerDataAndBandWidth() {
    if (!this.props) return false;
    const { fetchCustomerContactData, customerId } = this.props;
    if (!!fetchCustomerContactData) fetchCustomerContactData();
    if (!!customerId) {
      this.getStatisticsTotalsData(customerId, "datatransfer", DATATRANSFER);
      this.getStatisticsTotalsData(customerId, "95th", PERCENTILE);
      this.getStatisticsTotalsData(
        customerId,
        "datatransfer",
        CUSTOMERTOTALS,
        "customer"
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !!nextProps.data &&
      !!this.props.data &&
      nextProps.data.length !== this.props.data.length
    ) {
      this.getStatisticsTotalsData(
        this.props.customerId,
        "datatransfer",
        CUSTOMERTOTALS,
        "customer"
      );
    }
  }

  resetData() {
    this.props.resetCustomerOverview();
    // this.setState({ tableData: [], bandwidthUsed: [] });
  }

  loadTableData() {
    const { bandwidthUsed } = this.state;
    const { data } = this.props;
    this.tableLocalData = [];
    if (!data) {
      this.setState({
        bandwidthUsed: [],
        tableData: []
      });
      this.getCustomerDataAndBandWidth();
    }

    if (!!data && !!data.length && !!bandwidthUsed.length) {
      for (let ii = 0, jj = data.length; ii < jj; ii++) {
        this.tableLocalData.push(
          createTableData(data[ii].id, {
            [resellerTableRows[0].id]: data[ii].name,
            [resellerTableRows[1].id]: data[ii].description,
            [resellerTableRows[2].id]: this.getBandwidthUserPerCustomer(
              data[ii].id
            ),
            [resellerTableRows[3].id]: data[ii].active,
            [resellerTableRows[4].id]: "Impersonate",
            email: data[ii]["billingContact"].email,
            role: data[ii].type,
            updateDate: data[ii].updateDate,
            createDate: data[ii].createDate
          })
        );
        this.setToggleCustomer(data[ii].id, data[ii].active);
      }

      this.setState({
        tableData: this.tableLocalData
      });
    }
  }

  selectItem(id, name) {
    this.setState({ selected: [id], selectCustomerName: name });
  }

  handleToggleCustomer = (radio, event, customerId, customerName) => {
    event.stopPropagation();
    const toggleValue = event.target.checked,
      _this = this;
    this.props.setConfirmationForDelete(
      toggleValue,
      customerId,
      customerName,
      function() {
        _this.setState({ [radio]: toggleValue });
      }
    );
  };

  handleAddItem(payload, customerId) {
    if (!!this.checkForRequiredFieldsAllViews()) {
      const _this = this;
      const { getCustomerOverviewDetails, switchUserData } = _this.props;
      _this.setState({ isLoading: true });
      _this.props.addSubCustomer(payload, customerId, function(data, id) {
        // error handling on form submittion
        // scroll to errored field
        if (!!id && typeof data === "object") {
          data.roleName = "CUSTOMER_ADMIN";
          _this.props.addCustomer(id, data, function(response) {
            if (typeof response === "object") {
              _this.setState({ selected: "", isLoading: false });
              if (!!switchUserData) {
                getCustomerOverviewDetails(null, null, switchUserData.id);
              } else {
                getCustomerOverviewDetails();
              }
              //
              _this.props.notification({
                error: `Customer: "${data.name}" has been successfully added.`,
                variant: "success",
                label: "Close",
                color: "inherit"
              });
              _this.handleFormDialogToggle();
            } else {
              _this.setState({ isLoading: false });
              _this.props.notification({
                error: response,
                variant: "error",
                label: "Close",
                color: "inherit"
              });
            }
          });
        } else {
          _this.props.notification({
            error: data,
            variant: "error",
            label: "Close",
            color: "inherit"
          });
          _this.setState({ isLoading: false });
        }
      });
    }
  }

  handleDeleteCustomer(id, customerId) {
    const _this = this;
    const { switchUserData, getCustomerOverviewDetails } = _this.props;
    _this.props.deleteSeletedCustomer(id.join(), customerId, function(result) {
      if (typeof result === "boolean" || typeof result === "object") {
        _this.setState({ selected: "", isLoading: false });
        if (!!switchUserData) {
          getCustomerOverviewDetails(null, null, switchUserData.id);
        } else {
          getCustomerOverviewDetails();
        }
        _this.props.notification({
          error: `Customer: ${_this.state.selectCustomerName} has been successfully deleted.`,
          variant: "success",
          label: "Close",
          color: "inherit"
        });
        _this.handleDialogToggle();
      } else {
        _this.setState({ isLoading: false });
        _this.props.notification({
          error: result,
          variant: "error",
          label: "Close",
          color: "inherit"
        });
      }
    });
  }

  getTabs() {
    const tabs = [{ label: "User details" }, { label: "Contact details" }];

    this.tabs = tabs.length;
    return tabs;
  }

  setToggleCustomer(id, value) {
    let name = id + "_switch";
    this.setState({ [name]: value });
  }

  getStatisticsTotalsData(customerId, metric, type, groupBy) {
    const range = getOneMonthDateRange(new Date());
    const monthStart = formatDateTime(new Date(range.monthStart));
    const monthEnd = formatDateTime(new Date(range.monthEnd));

    const _this = this;
    let object = {
      start: monthStart,
      end: monthEnd,
      metric: metric
    };

    if (!!groupBy) {
      object.groupBy = groupBy;
    }

    let query = authClient.serialize(object);
    if (!!query) {
      query = "?" + query;
    }
    _this.props.getStatisticsTotalsData(customerId, query, function(res) {
      const response = res[0] || res;
      if (
        typeof response === "object" &&
        !!response.series &&
        !response.series.length
      ) {
        _this.setState({
          dataTransfer: {
            heading: "0 KB",
            subheading: "Data transferred"
          },
          percentile: {
            heading: "0 bps",
            subheading: "95th percentile"
          }
        });
        // return response;
      }
      if (type === DATATRANSFER) {
        let total =
          !!response.series && !!response.series.length
            ? response.series[0].all[0].total
            : 0;
        let totalFormatted = valueFormatter([total], "datatransfer");
        _this.setState({
          dataTransfer: {
            heading: totalFormatted[0].join(" "),
            subheading: "Data transferred"
          }
        });
      }
      if (type === PERCENTILE) {
        let total =
          !!response.series && !!response.series.length
            ? response.series[0].all[0].total
            : 0;
        total = valueFormatter([total], "datathroughput");
        _this.setState({
          percentile: {
            heading: total[0].join(" "),
            subheading: "95th percentile"
          }
        });
      }
      if (type === CUSTOMERTOTALS) {
        const resp = response;
        const series = resp.series;
        if (!!series && series.length > 0) {
          _this.getBandWidthData(series);
        } else {
          _this.setState({
            bandwidthUsed: ["0 GB"]
          });
        }
        _this.loadTableData();
      }
      return response;
    });
  }

  getBandWidthData(series) {
    let bandwidthUsed = [],
      bandwidthCustId,
      badnWidthValue;
    for (let ii = 0, jj = series.length; ii < jj; ii++) {
      for (let key in series[ii]) {
        bandwidthCustId = key;
        badnWidthValue = series[ii][key][0].total;
      }
      bandwidthUsed.push({ id: bandwidthCustId, value: badnWidthValue });
    }
    this.setState({ bandwidthUsed: bandwidthUsed });
  }

  getBandwidthUserPerCustomer(id) {
    const { bandwidthUsed } = this.state;
    let bandwidthValue = bandwidthUsed.filter(item => item.id === id);
    if (!!bandwidthValue && !!bandwidthValue.length) {
      return bandwidthValue[0].value;
    }
    return 0;
  }

  handleImpersonate(id, fullName, role) {
    if (!this.props) return false;
    const selectedUser = this.props.data.filter(
      value => value.id === id && value.name === fullName
    )[0];
    localStorage.setItem("SwitchCustomerDetail", JSON.stringify(selectedUser));
    this.props.switchUserDetails(selectedUser);
    this.props.activateImpersonate(id);
    this.tableListFull = undefined;
    this.setState({
      tableData: null,
      tableSearchText: ""
    });
    const { getCustomerOverviewDetails } = this.props;
    const switchCustomerDetail = localStorage.getItem("SwitchCustomerDetail");
    if (
      !!getCustomerOverviewDetails &&
      !!switchCustomerDetail &&
      role === "reseller"
    ) {
      getCustomerOverviewDetails(null, null, id);
    }
  }

  getItemNumber(page, index, rowsPerPage) {
    const pageNumber = !!page ? rowsPerPage * page + index + 1 : index + 1;
    return pageNumber;
  }

  handleClearSearch(name, typeSuccess) {
    this.setState({
      [name]: "",
      tableData: this.tableListFull
    });
  }

  handleSearchTable(e, object) {
    if (!this.tableListFull) {
      this.tableListFull = object;
    }

    const value = e.target.value;
    this.setState({
      [e.target.name]: value
    });

    if (value.length > 2) {
      const filteredData = this.getFilterBy(object, value);
      this.setState({
        tableData: filteredData
      });
    } else {
      this.setState({
        tableData: this.tableListFull
      });
    }
  }

  render() {
    const { data, classes, contactData, customerId } = this.props;
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      isLoading,
      dataTransfer,
      percentile,
      bandwidthUsed,
      tableData,
      tableSearchText
    } = this.state;

    let customersData = !!tableData
      ? shortByDate(tableData, "createDate", "updateDate")
      : null;

    let staticTilesReseller = (
      <Grid item xs={6}>
        <Card className="tile-arrow button margin-top-default height-69">
          <CardContent>
            <div className="pull-left">
              <div className="tile-heading"></div>
              <div className="tile-subheading margin-top-8">Loading...</div>
            </div>
          </CardContent>
        </Card>
      </Grid>
    );

    return (
      <React.Fragment>
        <Grid container className="tile-container">
          {!!dataTransfer ? (
            <Grid item xs={6}>
              <Tile
                heading={dataTransfer.heading}
                subheading={dataTransfer.subheading}
              />
            </Grid>
          ) : (
            staticTilesReseller
          )}
          {!!percentile ? (
            <Grid item xs={6}>
              <Tile
                heading={percentile.heading}
                subheading={percentile.subheading}
              />
            </Grid>
          ) : (
            staticTilesReseller
          )}
        </Grid>
        <Paper className={classes.root}>
          <FormDialog
            openFormDialog={this.state.openFormDialog}
            formHeading="Create customer"
            cancelFormText="Cancel"
            confirmFormText="Save"
            hideTab={false}
            tabs={this.getTabs()}
            classes={classes}
            paddingNode={true}
            submitDataInProgress={isLoading}
            cancelFormAction={() => this.handleFormDialogToggle()}
            confirmFormAction={payload =>
              this.handleAddItem(payload, customerId)
            }
            contactData={contactData}
          />
          <AlertDialog
            openDialog={this.state.openDialog}
            heading="Confirm"
            message={
              "Are you sure, you want to remove customer: " +
              this.state.selectCustomerName
            }
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
            cancelBtnAction={() => this.handleDialogToggle()}
            confirmBtnAction={() =>
              this.handleDeleteCustomer(this.state.selected, customerId)
            }
          />
          <EnhancedTableToolbar
            numSelected={0}
            subheading="Overview of your customers"
            toolTipTitle="Add customer"
            handleAddClick={() => this.handleFormDialogToggle()}
          />

          {(!bandwidthUsed.length || !tableData) && (
            <div className="margin-top-16 position-relative  text-center">
              {tableLoaderEmpty}
            </div>
          )}
          {!!data && !!bandwidthUsed.length && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <RenderSearchTableInput
                  onChange={e =>
                    this.handleSearchTable(
                      e,
                      tableData,
                      "RECEIVED_CUSTOMER_OVERVIEW"
                    )
                  }
                  onClear={name => {
                    this.handleClearSearch(name, "RECEIVED_CUSTOMER_OVERVIEW");
                  }}
                  className="table-search-field customers"
                  value={tableSearchText}
                />

                {!!tableData && (
                  <Table
                    className={classes.table + " table"}
                    aria-labelledby="tableTitle"
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      rows={rows}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                      rowCount={tableData.length}
                      hideNumbers={true}
                    />
                    <TableBody>
                      {stableSort(customersData, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((n, index) => {
                          let radio = n.id + "_switch";
                          return (
                            !!bandwidthUsed.length &&
                            n.id !== customerId && (
                              <TableRow tabIndex={-1} key={n.id + index}>
                                <TableCell padding="none">
                                  {n[resellerTableRows[0].id]}
                                </TableCell>
                                <TableCell>
                                  {n[resellerTableRows[1].id]}
                                </TableCell>
                                <TableCell>
                                  {valueFormatter(
                                    [n[resellerTableRows[2].id]],
                                    "datatransfer"
                                  )}
                                </TableCell>
                                <TableCell>
                                  <RenderSwitch
                                    name="impersonate"
                                    id="impersonate"
                                    handleRadioChange={event =>
                                      this.handleToggleCustomer(
                                        radio,
                                        event,
                                        n.id,
                                        n.name
                                      )
                                    }
                                    checked={this.state[radio]}
                                    value={this.state[radio]}
                                    className="switch-ios"
                                    classes={classes}
                                  />
                                </TableCell>
                                <TableCell padding="none">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    className="btn-impersonate"
                                    onClick={() =>
                                      this.handleImpersonate(
                                        n.id,
                                        n.name,
                                        n.role
                                      )
                                    }
                                  >
                                    {n[resellerTableRows[4].id]}
                                  </Button>
                                </TableCell>
                                <TableCell className="actions">
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={event => {
                                      this.handleDialogToggle(n.id);
                                      this.selectItem(n.id, n.name);
                                    }}
                                  >
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          );
                        })}
                      {(!data.length || data.length === 1) && (
                        <TableRow style={{ height: 49 }}>
                          <TableCell colSpan={6}>{NORESPONSE}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                )}
              </div>
              {!!tableData && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              )}
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  data: state.customerOverview.data,
  contactData: state.customerOverview.contactData,
  customer: state.user.data,
  switchUserData: state.user.switchUserData,
  customerData: state.customerOverview.data,
  menuItem: state.menuItem.data
});

export default connect(
  mapStateToProps,
  {
    getCustomerOverviewDetails,
    switchUserDetails,
    deleteSeletedCustomer,
    addCustomer,
    addSubCustomer,
    getStatisticsTotalsData,
    fetchCustomerContactData,
    resetCustomerOverview,
    showNotification,
    hideNotification,
    applyFilteredData
  }
)(withStyles(tableStyles)(EnhancedTable));
