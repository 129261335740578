import React from "react";
import { connect } from "react-redux";
import Notification, {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import Button from "@material-ui/core/Button";
import { getUserDetails } from "../../actions/userDetailsAction";
import { getDistributionStatus } from "../../actions/distributionStatusAction";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import PageviewIcon from "@material-ui/icons/Pageview";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import {
  getDistributions,
  deleteDistribution,
  getDistribution,
  updateDistribution,
  addDistribution,
  resetDistribution,
  applyFilteredData
} from "../../actions/distributionAction";
import { switchUserDetails } from "../../actions/userDetailsAction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import AlertDialog from "../Dialog/AlertDialog";
import {
  commonDataMapper,
  cdnEditFormControls,
  shieldcdnDataMapper,
  cdnVMMapper,
  getCertificates,
  cdnShieldVMMapper,
  setEmptyDistributionObject,
  getCertificateControl,
  getsupportOlderCiphersControl,
  getTLSSettingsControl
} from "../../common/constants/distribution";
import BaseComponent from "../../common/base/BaseComponent";
import {
  stableSort,
  getSorting,
  tableStyles
} from "../../common/helpers/tableRulesAndStyles";

import { Redirect } from "react-router-dom";
import FormDialogStepper from "../Dialog/FormDialogStepper";
import {
  MULTI_CDN_VOLUME,
  MULTI_CDN_PREMIUM,
  SINGLE_CDN,
  SHIELD_CDN,
  PRIVATE_CDN,
  getOriginsFormControls,
  setEmptyPolicyObject,
  setEmptyMultiPolicies,
  policyDataMapper,
  policyMultiPremiumDataMapper
} from "../../common/constants/policies";

import { RESELLER } from "../../common/constants/roles";
import {
  NORESPONSE,
  INPROGRESS_CIRCULAR_NOCLASS,
  tableLoaderEmpty,
  styles,
  staticPlaceholderTable
} from "../../common/helpers/loader";
import { RenderSearchTableInput } from "../../common/helpers/renderElement";
import {
  getPolicies,
  initPolicies,
  updatePolicy,
  deletePolicy
} from "../../actions/policyAction";
import FormDialogBase from "../Dialog/FormDialogBase";
import {
  selectText,
  deepCompareObjects,
  getErroredField
} from "../../common/helpers/sharedHelper";
import CustomizedExpansionPanel from "../ExpansionPanels/ControlledExpansionPanels";
import { simpleEditFormControls } from "../../common/constants/origin";
import { addOrigin, deleteOrigin } from "../../actions/originsAction";
import { SIMPLE, simpleDataMapper } from "../../common/constants/origin";
import {
  certificateEditFormControls,
  certificateDataMapper
} from "../../common/constants/certificate";
import { addCertificate, deleteCertificate } from "../../actions/configurationAction";
import { shortByDate } from "../../common/helpers/dateHelper";
import Grid from "@material-ui/core/Grid";

export const distributionTableRows = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "endpoint", numeric: false, disablePadding: false, label: "CNAME" },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

export const shieldDistributionTableRows = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description"
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "endpoint", numeric: false, disablePadding: false, label: "CNAME" },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

const PANEL1 = "panel1";
const PANEL2 = "panel2";
const PANELCERTIFICATE1 = "panel_certificate1";
const PANELCERTIFICATE2 = "panel_certificate2";

const MULTICDN_VOLUME = "volume";
const MULTICDN_PREMIUM = "premium";

let distributionTableConfig = {
  order: "desc",
  orderBy: "updated",
  selected: [],
  page: 0,
  rowsPerPage: 10,
  isLoading: false,
  pageOptions: [],
  openDialog: false,
  openFormDialog: false,
  dialogFormName: "Wizard for distribution, origin and policy creation",
  progressStatus: 100,
  validation: true,
  redirectPolicyPage: "",
  dialogViewType: "edit",
  value: 0,
  distributionType: null,
  maxWidth: "lg",
  certificates: [],
  selectControls: {},
  isMandatoryView: false,
  origins: null,
  originGroups: null,
  singleCDNs: null,
  shieldCDNs: null,
  newDistributionId: null,
  newPolicyId: null,
  description: "generated Policy",
  originDescription: "Origin created by wizard",
  path: "/",
  rewrites: [],
  stepperSwitchViewTo: null,
  advancedForm: true,
  openDistributionStatusDialog: false,
  tableSearchText: "",
  tlsSettings: {
    tlsType: null,
    certificateId: "",
    supportOlderCiphers: false
  }
};

class Distribution extends BaseComponent {
  errors = {};
  count = 0;
  certificateInProgress = false;
  distributionOriginalList = [];
  stepperIndex = 0;
  originSelectedPanel = PANEL1;
  certificateSelectedPanel = PANELCERTIFICATE1;
  selectedHostName = "";
  dialogStepContent = [];
  calledOnce = false;
  globalStepCounter = null;
  submitErrorMessage =
    "OOPS! Data not ready yet! Please go back and re-try again!";

  constructor(props) {
    super(props);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleDistributionEdit = this.handleDistributionEdit.bind(this);
    this.toggleDistStausDialog = this.toggleDistStausDialog.bind(this);
    this.state = {
      ...initialState(),
      ...distributionTableConfig,
      policyStatus: null,
      originStatus: null,
      certificateStatus: null,
      distributionCreationStatus: null
    };
  }

  componentWillMount() {
    this.getDistributionType();
    const { getUserDetails } = this.props;
    getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.calledOnce = false;
    this.certificateInProgress = false;
    this.props.resetDistribution();
    this.props.hideNotification();
    this.props.initPolicies();
  }

  componentWillReceiveProps(nextProps) {
    const type = this.getType(nextProps.match.params.type);
    if (type !== this.state.distributionType && !!this.props.distributions) {
      this.setState({
        page: 0,
        tableSearchText: ""
      });
      this.props.resetDistribution();
      this.getDistributionType();
      this.calledOnce = false;
      this.tableListFull = null;
    }
  }

  getType(disType) {
    let type = disType;
    if (type === MULTICDN_VOLUME || type === "multi-cdn-volume") {
      type = MULTI_CDN_VOLUME;
    }
    if (type === MULTICDN_PREMIUM || type === "multi-cdn-premium") {
      type = MULTI_CDN_PREMIUM;
    }

    return type;
  }

  getLatestPolicies(customerId, type, distributionId) {
    if (!!customerId) {
      this.props.getPolicies(customerId, type, distributionId);
      return true;
    }
    return false;
  }

  getDistributionType() {
    const type = this.getCDNEndpoint();
    this.setState({
      distributionType: type
    });
    return true;
  }

  getCDNEndpoint() {
    let { type } = this.props.match.params;
    if (type === MULTICDN_VOLUME || type === "multi-cdn-volume") {
      type = MULTI_CDN_VOLUME;
    }
    if (type === MULTICDN_PREMIUM || type === "multi-cdn-premium") {
      type = MULTI_CDN_PREMIUM;
    }

    return type;
  }

  isSelected = (index, obj) => {
    return this.state[obj].indexOf(index) !== -1;
  };

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
    return true;
  }

  getCertificates(customerId) {
    const _this = this;
    if (!!customerId) {
      getCertificates(customerId, function(result) {
        if (!!result && typeof result === "object") {
          _this.setState({
            certificates: result
          });
        }
      });
    }
    return true;
  }

  getDistributions(customerId, type) {
    this.props.getDistributions(customerId, type);
    return true;
  }

  returnShortText(cdnType) {
    switch (cdnType) {
      case SINGLE_CDN:
        return "Single CDN";
      case MULTI_CDN_VOLUME:
        return "Multi CDN > Volume";
      case MULTI_CDN_PREMIUM:
        return "Multi CDN > Premium";
      case SHIELD_CDN:
        return "Shield CDN";
      case PRIVATE_CDN:
        return "Private CDN";
      default:
        return "Single CDN";
    }
  }
  notificationAndListUpdate(result, actionType, self, index, what) {
    const type = this.getCDNEndpoint();

    if (typeof result === "object") {
      const message = what + result.id + " is successfully " + actionType + ".";
      self.toggleNotification(message, "success", "Close");
      self.props.getDistributions(result.customerId, type);
    } else {
      const message = result;
      self.toggleNotification(message, "error", "Close");
      getErroredField(message, list => {
        if (!!list && !!list.length) {
          this.setState({ stepperSwitchViewTo: index || 0 });
          this.highlightError(list);
        } else {
          this.setState({ stepperSwitchViewTo: index || 0 });
        }
      });
    }
    self.setState({
      isLoading: false,
      selected: []
    });

    return false;
  }

  highlightError(list) {
    setTimeout(() => {
      for (let ii = 0, jj = list.length; ii < jj; ii++) {
        const elm =
          document.querySelector("." + list[ii]) ||
          document.querySelector("#" + list[ii]);
        if (!!elm) {
          elm.classList.add("text-error");
        }
      }
    }, 500);
  }

  handleDelete(customerId, selected) {
    if (!customerId || !selected) return;
    const type = this.state.distributionType,
      _this = this;
    _this.setState({
      isLoading: true
    });
    this.props.deleteDistribution(customerId, type, selected, function(result) {
      if (typeof result === "object") {
        const message = "Distribution: " + result.message + " successfully.";
        _this.toggleNotification(message, "success", "Close");
        _this.props.getDistributions(customerId, type);
      } else {
        const message = result || _this.defaultErrorMessage;
        _this.toggleNotification(message, "error", "Close");
      }
      _this.setState({
        isLoading: false,
        selected: []
      });
      _this.handleDialogToggle();
    });
  }

  readySubmitDistribution(e, customerId, thisStep) {
    e.preventDefault();
    this.globalStepCounter = thisStep;

    if (!customerId) return false;
    const { distributionType } = this.state;
    const payload = this.getDataMapper(this, distributionType);
    let stepNumber = this.getTotalSteps(distributionType) - 3;
    this.distributionOriginalList = JSON.parse(JSON.stringify(payload));
    if (
      !!this.checkForRequiredFieldsAllViews() &&
      !deepCompareObjects(this.distributionOriginalList, payload)
    ) {
      this.submitReadyDistributionData = null;
      this.setState({ stepperSwitchViewTo: null });
      this.submitReadyDistributionData = {
        payload: payload,
        event: e,
        customerId: customerId,
        distributionType: distributionType,
        stepNumber: stepNumber
      };
    } else {
      this.setState({ stepperSwitchViewTo: stepNumber });
    }
  }

  handleSubmitDistribution() {
    if (!this.submitReadyDistributionData) return false;
    return new Promise((resolve, reject) => {
      const {
        payload,
        customerId,
        distributionType,
        stepNumber
      } = this.submitReadyDistributionData;

      const _this = this, errorText = "Error in ";

      _this.setState({
        isLoading: true,
        dialogViewType: "edit"
      });
      let type = distributionType;
      if (!!_this.state.newDistributionId) {
        let method = "PUT";
        const distributionId = _this.state.newDistributionId;
        _this.props.updateDistribution(
          customerId,
          type,
          distributionId,
          method,
          payload,
          function(result) {
            resolve({
              result,
              action: "edited",
              context: "Distribution",
              stepNumber
            });
            _this.setState({
              distributionCreationStatus: "Edited",
              selected: result.id
            })
          }
        );
      } else {
        let method = "POST";
        _this.props.addDistribution(customerId, type, method, payload, function(
          result
        ) {
          if (typeof result === "object") {
            _this.setState({ newDistributionId: result.id });
            _this.getVMMapper(type, result);

            resolve({
              result,
              action: "added",
              context: "Distribution",
              stepNumber
            });
            _this.setState({
              distributionCreationStatus: "Created"
            })
          } else {
            reject({
              result,
              action: "error",
              context: "Distribution",
              stepNumber
            });
            _this.submitReadyDistributionData.message = result; 
            _this.setState({
              distributionCreationStatus: errorText
            })
          }
        });
      }
    });
  }

  closeDialog() {
    if (!!this.props.policies) {
      this.setState({ openFormDialog: false });
    }
  }

  handleAdd(customerId, type) {
    if (!customerId) return false;
    this.setState({
      dialogViewType: "edit",
      newDistributionId: null,
      stepperSwitchViewTo: 0,
      policyStatus: null,
      certificateStatus: null,
      originStatus: null,
      distributionCreationStatus: null
    });
    if (type === MULTI_CDN_PREMIUM) {
      setEmptyMultiPolicies(this, type);
    } else {
      setEmptyPolicyObject(this, type);
    }
    this.originSelectedPanel = PANEL1;
    this.getOrigins(customerId, type);
    this.getOriginGroups(customerId);
    this.getSingleCDN(customerId);
    this.getShieldCDN(customerId);

    this.setState({
      description: "generated Policy",
      path: "/",
      rewrites: []
    });

    this.initVMMapper(customerId, type);
  }

  initVMMapper(customerId, type) {
    setEmptyDistributionObject(this, type);
    this.handleFormDialogToggle();
  }

  getDataMapper(self, type) {
    if (!self) return false;
    if (type !== SHIELD_CDN) {
      return commonDataMapper(self, type);
    } else {
      return shieldcdnDataMapper(self, type);
    }
  }

  getVMMapper(type, result) {
    if (!result) return false;
    if (type !== SHIELD_CDN) {
      cdnVMMapper(this, result);
    } else {
      cdnShieldVMMapper(this, result);
    }
  }

  setEditFormControls(self, type) {
    // third param to show mini version.
    if (!!self) {
      return cdnEditFormControls(self, type);
    }
    return false;
  }

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.checked });
    return true;
  };
  
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    return true;
  }

  handleFormDialogToggle() {
    const _this = this;
    this.submitReadyCertificateData = {};
    this.submitReadyOriginData = null;
    this.submitReadyDistributionData = null;
    this.submitReadyPolicyData = {};

    setTimeout(() => {
      this.setState({ 
        openFormDialog: !_this.state.openFormDialog, 
        policyStatus: null, 
        distributionCreationStatus: null,
        originStatus: null, 
        certificateStatus: null
      });
    }, 1000);
  }

  handleDistributionEdit(e, type, distributionId) {
    e.preventDefault();
    const tmpType = type.replace("/", "-");
    const url =
      "/configuration/distribution/" +
      tmpType +
      "/" +
      distributionId +
      "/policies";
    this.setState({
      redirectPolicyPage: url
    });
  }

  setStatusIcon(obj) {
    return this.setIconElement(styles, obj.status);
  }

  getDomains(value) {
    if (!value) return false;
    return value.join(", ");
  }

  getCreateCertificateControl(distributionType) {
    let distControls =
      this.state.tlsSettings || distributionType === SHIELD_CDN
        ? certificateEditFormControls(this, "wizard")
        : "";
    return distControls;
  }

  getDistributionControls(distributionType) {
    let distControls =
      this.state.tlsSettings || distributionType === SHIELD_CDN ? (
        <div className="react-scroll-view-container fullWidth">
          {this.setEditFormControls(this, distributionType)}
        </div>
      ) : (
        ""
      );
    return distControls;
  }

  getFinalMessage() {
    return <p>You have completed all the steps.</p>;
  }

  getTitleName(readOnly, deleting, pending) {
    let title = "";
    if (!!readOnly) {
      title = "read-only";
    }
    if (!!deleting) {
      title = "Deletion pending";
    }
    if (!!pending) {
      title = "Configuration in pending state, please wait";
    }
    return title;
  }

  getClassName(readOnly, deleting, disabled, isPending) {
    let className = "";
    if (!!readOnly) {
      className = "read-only";
    }

    if (!!isPending) {
      className = "read-only";
    }

    if (!!deleting) {
      className = "read-only deleting-row";
    }
    if (!disabled) {
      className = "read-only";
    }
    return className;
  }

  getInactiveRowIcon(readOnly, deleting,disabled) {
    let iconElement = "";
    if (!!readOnly) {
      iconElement = <i className="material-icons lock">lock</i>;
    }
    if (!!deleting) {
      iconElement = <i className="material-icons lock">delete-sweep</i>;
    }
    if (!disabled) {
      iconElement = <i className="material-icons lock">do_not_disturb</i>;
    }

    return iconElement;
  }

  readySubmitCertificate(distributionType, thisStep) {
    const { switchUserData, userData } = this.props;
    const {
      tlsSettings: { certificateId, tlsType }
    } = this.state, errorText = "Error in ";
    const customerId = !!switchUserData
      ? switchUserData.id
      : userData.customerId;
    let stepNumber = 0;
    if (!customerId || !this.certificateSelectedPanel) return false;
    this.globalStepCounter = thisStep;
    this.submitReadyCertificateData = {};
    if (this.certificateSelectedPanel === PANELCERTIFICATE1) {
      if (!!this.checkForRequiredFieldsAllViews(".selectCertificate")) {
        this.submitReadyCertificateData = {
          certificateId,
          tlsType
        };
        this.setState({
           certificateStatus: "Selected",
           stepperSwitchViewTo: stepNumber + 1
        })
      } else {
        this.setState({ stepperSwitchViewTo: stepNumber, certificateStatus: errorText });
      }
    } else if (this.certificateSelectedPanel === PANELCERTIFICATE2) {
      if (!!this.checkForRequiredFieldsAllViews(".newCertificate")) {
        const payload = certificateDataMapper(this, customerId);
        this.submitReadyCertificateData = {
          payload: payload,
          customerId: customerId,
          stepNumber: stepNumber,
          distributionType: distributionType
        };
      } else {
        this.setState({ stepperSwitchViewTo: stepNumber, certificateStatus: errorText });
      }
    }
  }

  handleSubmitCertificate() {
    const _this = this, errorText = "Error in ";
    return new Promise((resolve, reject) => {
      if (!Object.keys(this.submitReadyCertificateData).length) {
        reject({
          result: this.submitErrorMessage,
          action: "error",
          context: "Certificate",
          stepNumber: 0
        });
        _this.setState({
          certificateStatus: errorText
        })
        return false;
      } else if (!!this.submitReadyCertificateData.payload) {
        const {
          payload,
          customerId,
          stepNumber
        } = this.submitReadyCertificateData;

        _this.setState({
          isLoading: true,
          dialogViewType: "edit",
          stepperSwitchViewTo: null
        });

        _this.props.addCertificate(customerId, payload, function(result) {
          if (typeof result === "object") {
            _this.setState(
              {
                isLoading: false,
                origins: []
              },
              () => _this.getCertificates(customerId)
            );
            resolve({
              result,
              action: "created",
              context: "Certificate",
              stepNumber
            });

            // _this.submitReadyCertificateData.message = result; 

            _this.setState({
              certificateStatus: "Created"
            })

          } else {
            reject({
              result,
              action: "error",
              context: "Certificate",
              stepNumber
            });

            _this.submitReadyCertificateData.message = result; 

            _this.setState({
              certificateStatus: errorText
            })

          }
        });
      } else if (!!Object.keys(this.submitReadyCertificateData).length) {
        resolve({
          result: this.submitReadyCertificateData,
          action: "selected",
          context: "Certificate"
        });

        // _this.submitReadyCertificateData.message = "Certificate selected."; 

        _this.setState({
          certificateStatus: "Selected"
        })

      }
    });
  }

  readySubmitOrigin(distributionType, thisStep, callBack) {
    const { switchUserData, userData } = this.props;
    const customerId = !!switchUserData
      ? switchUserData.id
      : userData.customerId, errorText = "Error in ";
    this.globalStepCounter = thisStep;
    if (!customerId || !this.originSelectedPanel) return false;
    this.submitReadyOriginData = null;
    let stepNumber = this.getTotalSteps(distributionType) - 2;

    if (this.originSelectedPanel === PANEL1) {
      const _this = this;
      if(!!this.checkForRequiredFieldsAllViews(".selectOrigin")){
        this.setState({
          originStatus: "Selected"
        }, () => {
          console.log("originStatus ", _this.state.originStatus);
          callBack("originsSelected");
        })
      }else{
        this.setState({ stepperSwitchViewTo: stepNumber });
      } 
    } else if (this.originSelectedPanel === PANEL2) {
      if (!!this.checkForRequiredFieldsAllViews(".newOrigin")) {
        const payload = simpleDataMapper(this);
        this.submitReadyOriginData = {
          payload,
          customerId,
          distributionType,
          stepNumber
        };
        callBack("originsDone");
      } else {
        this.setState({ stepperSwitchViewTo: stepNumber, originStatus: errorText });
      }
    } else {
      callBack("originsSelected");
      this.setState({
        originStatus: "Selected"
      })
    }
  }

  handleSubmitOrigins() {
    if (!this.submitReadyOriginData) return false;
    return new Promise((resolve, reject) => {
      const { payload, customerId, stepNumber } = this.submitReadyOriginData;
      const _this = this, errorText = "Error in ";
      _this.setState({
        isLoading: true,
        dialogViewType: "edit",
        stepperSwitchViewTo: null
      });

      let type = SIMPLE;
      let method = "POST";
      this.selectedHostName = payload.host;
      _this.props.addOrigin(customerId, type, method, payload, function(
        result
      ) {
        if (typeof result === "object") {
          _this.setState(
            {
              pullOriginSettings: {
                ..._this.state.pullOriginSettings,
                originId: result.id,
                originGroupId: null,
                shieldId: null
              },
              origins: null,
              isLoading: false
            },
            () => _this.getOrigins(customerId, type)
          );
          resolve({
            result,
            action: "added",
            context: "Origin",
            stepNumber
          });

          // _this.submitReadyOriginData.message = result;
          _this.setState({
            originStatus: "Created"
          })
        }else{
          _this.setState({
            originStatus: errorText
          })

          reject({
            result,
            action: "error",
            context: "Origin",
            stepNumber,
            activity: "Message: "
          });

          _this.submitReadyOriginData.message = result;
        }
      });
    });
  }

  handleSubmitPolicy() {
    const { switchUserData, userData } = this.props;
    const customerId = !!switchUserData
      ? switchUserData.id
      : userData.customerId;
    if (!customerId) return false;
    let payload;
    this.submitReadyPolicyData = {};
    return new Promise((resolve, reject) => {
      const { newDistributionId } = this.state,
        method = "POST";
      const type = this.getCDNEndpoint();
      const policyId = newDistributionId;

      if (type === MULTI_CDN_PREMIUM) {
        payload = policyMultiPremiumDataMapper(this);
      } else {
        payload = policyDataMapper(this);
      }

      this.setState({
        isLoading: true
      });
      const _this = this, errorText = "Error in ";
      this.props.updatePolicy(
        customerId,
        type,
        newDistributionId,
        policyId,
        method,
        payload,
        function(result) {
          if (typeof result === "object") {
            // _this.handleFormDialogToggle();
            resolve({
              result,
              action: "added",
              context: _this,
              stepNumber: 3,
              activity: "Policy: "
            });
            _this.setState({
              policyStatus: "Created"
            })
          } else {
            reject({
              result,
              action: "error",
              context: _this,
              stepNumber: 3,
              activity: "Message: "
            });
            _this.submitReadyPolicyData.message = result;
            // _this.toggleNotification(result, "error", "Close");
            _this.setState({
              policyStatus: errorText
            })
          }
        }
      );
    });
  }

  updatePanelName = (key, panel) => {
    this.certificateSelectedPanel = PANELCERTIFICATE1;
    this.originSelectedPanel = PANEL1;
    this[key] = panel;
  };

  getSelectCreateCertificateExpansion(distributionType) {
    const { classes } = this.props;

    var expandItems = [
      {
        name: "Select certificate",
        value: (
          <div className="margin-top-16 padding-16 fullwidth selectCertificate">
            {getTLSSettingsControl(this, false, distributionType)}
            {this.state.tlsSettings.tlsType === "custom" &&
              !!this.state.certificates &&
              getCertificateControl(this, false, distributionType)}
            {!!this.state.tlsSettings && 
            (distributionType === SINGLE_CDN || distributionType ===  PRIVATE_CDN) && 
            getsupportOlderCiphersControl(this, classes)}
          </div>
        ),
        panelName: PANELCERTIFICATE1
      },
      {
        name: "Create certificate",
        value: (
          <div className="fullwidth newCertificate">
            {this.getCreateCertificateControl(distributionType)}
          </div>
        ),
        panelName: PANELCERTIFICATE2
      }
    ];
    return (
      <CustomizedExpansionPanel
        key="certificateSelectedPanel"
        expansionItems={expandItems}
        updateSelectedPanel={panel =>
          this.updatePanelName("certificateSelectedPanel", panel)
        }
        defaultPanel={this.certificateSelectedPanel}
      />
    );
  }

  getSelectCreateOriginExpansion(distributionType) {
    var expandItems = [
      {
        name: "Select origin",
        value: (
          <div className="margin-top-16 padding-16 fullwidth selectOrigin">
            {getOriginsFormControls(this, distributionType)}
          </div>
        ),
        panelName: PANEL1
      },
      {
        name: "Create origin",
        value: (
          <div className="margin-top-16 padding-16 fullwidth newOrigin">
            {simpleEditFormControls(this)}
          </div>
        ),
        panelName: PANEL2
      }
    ];
    return (
      <CustomizedExpansionPanel
        key="originSelectedPanel"
        expansionItems={expandItems}
        className="orgins_select_edit_container"
        updateSelectedPanel={panel =>
          this.updatePanelName("originSelectedPanel", panel)
        }
        defaultPanel={this.originSelectedPanel}
      />
    );
  }

  validateRequireFields(panelName, comparator, trigger, elm, stepperIndex) {
    if (
      !!panelName &&
      !!comparator &&
      this[panelName] === comparator &&
      !!trigger
    ) {
      trigger();
      return true;
    } else {
      if (!this.checkForRequiredFieldsAllViews(elm)) {
        this.setState({ stepperSwitchViewTo: stepperIndex });
      } else {
        // if(!!trigger) { trigger(); }
        this.setState({ stepperSwitchViewTo: null });
      }
    }
    return true;
  }

  getTotalSteps(distributionType) {
    let stepCount = 4;
    if (distributionType === SHIELD_CDN) {
      stepCount = 3;
    }
    return stepCount;
  }

  getDialogStepperContent(distributionType, customerId) {
    let stepCount = this.getTotalSteps(distributionType);
    let stepperContent = [
      {
        name: "Select / create certificate",
        class: "certificate",
        content: this.getSelectCreateCertificateExpansion(distributionType),
        actionNext: e => {
          this.validateRequireFields(
            "certificateSelectedPanel",
            this.certificateSelectedPanel,
            () => this.readySubmitCertificate(distributionType, 0),
            this.certificateSelectedPanel === PANELCERTIFICATE1
              ? ".selectCertificate"
              : ".newCertificate",
            0
          );
        }
      },
      {
        name: !!this.state.newDistributionId
          ? "Update distribution"
          : "Create distribution",
        class: "distribution",
        content: this.getDistributionControls(distributionType),
        actionNext: e => {
          this.readySubmitDistribution(e, customerId, stepCount - 3);
          this.validateRequireFields(
            null,
            null,
            null,
            ".stepper-body",
            stepCount - 3
          );
        }
      },
      {
        name: "Select / create origin",
        class: "origin",
        content: this.getSelectCreateOriginExpansion(distributionType),
        actionNext: () => {
          this.validateRequireFields(
            "originSelectedPanel",
            this.originSelectedPanel,
            () => this.readySubmitOrigin(
              distributionType,
              stepCount - 2,
              response => {
                this.globalStepCounter = stepCount;
                this.setState({
                    stepperSwitchViewTo: stepCount -1
                }, () => {
                  this.getReadyWithDataBeforePolicy(distributionType, response);
                })
              }
            ),
            this.originSelectedPanel === PANEL1
              ? ".selectOrigin"
              : ".newOrigin",
            stepCount - 2
          );
        }
      },
      {
        name: "Create basic policy",
        class: "policy",
        content: this.getFinalContent(),
        actionNext: () => {
          // this.prepBeforePolicyCalls();
        }
      }
    ];

    if (distributionType === SHIELD_CDN) {
      stepperContent.splice(0, 1);
    }

    return stepperContent;
  }

  getReadyWithDataBeforePolicy(distributionType, callbackStringResponse) {
    let stepNumber = this.getTotalSteps(distributionType);
    const errorText = "Error in ", {originStatus} = this.state;

    if (this.globalStepCounter === stepNumber && !!callbackStringResponse) {
          const customerId = this.getCustomerId();

          this.handleSubmitDistribution()
            .then(response => {
              var selected = response.result.id; 
              if(originStatus === 'Selected'){
                  this.handleOtherWizardCalls(errorText, customerId, selected);
              }else{
                this.handleSubmitOrigins().then(originResponse => {
                   let originId = originResponse.result.id; 
                   console.log("originId ", originId)
                    this.handleOtherWizardCalls(errorText, customerId, selected, originId);
                }).catch(error => {
                    this.handleDeleteOfCreatedEntities(customerId, distributionType, selected, errorText);
                });
              }                
              }).catch(err => {
                this.setState({
                  isLoading: false,
                  distributionCreationStatus: errorText,
                  policyStatus: errorText
                })
              })            
        }
  }

  handleDeleteOfCreatedEntities = (customerId, distributionType, distId, errorText) => {
    console.log("rest.errorText ", errorText);
    this.props.deleteDistribution(customerId, distributionType, distId, () => {
      this.setState({
        isLoading: false,
        distributionCreationStatus: "Deleted",
        policyStatus: errorText
      })
    }) 
  }

  handlePolicySuccess = (policyId, customerId, distributionType) => {
    this.setState({
      isLoading: false,
      policyId
    }, () => {
      this.props.getDistributions(customerId, distributionType);
    })
  }

  handlePolicyError = (_this, customerId, distributionType, selected, errorText) => {
    _this.props.deleteDistribution(customerId, distributionType, selected, () => {
      _this.setState({
        isLoading: false, 
        distributionCreationStatus: "Deleted",
        policyStatus: errorText
      })
    })
  }

  handleOtherWizardCalls = (errorText, customerId, ...rest) => {
    const { distributionType, certificateStatus, policyStatus } = this.state; 
    let policyId, certificateId; 
    if(certificateStatus === "Selected" || distributionType === SHIELD_CDN){
          this.handleSubmitPolicy().then(res => {
            policyId = res.result.id;
            this.handlePolicySuccess(policyId, customerId, distributionType);
          }).catch(err => {
              const distId = (rest.length === 1) ? rest.join("") : rest.selected; 
              const _this = this; 
              this.handlePolicyError(_this, customerId, distributionType, distId, errorText);
        });
    }else{
      this.handleSubmitCertificate().then(certResponse => {
        certificateId = certResponse.result.id;
        this.handleSubmitPolicy().then(res => {
          policyId = res.result.id;
          this.handlePolicySuccess(policyId, customerId, distributionType) 
          this.setState({
            certificateId
          })
        }).catch(err => {
            const _this = this; 
            this.handlePolicyError(_this, customerId, distributionType, rest.selected, errorText);
            _this.setState({
              certificateStatus: errorText
            })
          });
      });
    }    
  }

  switchStepperView = () => {
    let errorText = "Error in ", stepperIndex = 0;

    const { distributionType, 
      policyStatus, 
      originStatus, 
      distributionCreationStatus, 
      certificateStatus } = this.state; 

    if(distributionType !== SHIELD_CDN){
      if(policyStatus === errorText){
        stepperIndex = 3;
      }
      if(originStatus === errorText){
          stepperIndex = 2;
      }
      if(distributionCreationStatus === errorText){
        stepperIndex = 1;
      }
      if(certificateStatus === errorText){
        stepperIndex = 0;
      }
    }
    this.setState({
        stepperSwitchViewTo: stepperIndex
    }, function() {this.showNotificationAndRightPanel();});
  }

  showNotificationAndRightPanel(){
      const { stepperSwitchViewTo } = this.state; 
      // console.log(stepperSwitchViewTo, this.submitReadyCertificateData);
      switch(stepperSwitchViewTo){
         case 0:
            this.certificateSelectedPanel = PANELCERTIFICATE2;
         break; 
         case 1:
         break; 
         case 2:
            this.originSelectedPanel = PANEL2;
         break; 
         default:
          console.log("policy");
          // this.toggleNotification("", "error", "Close");
         break; 
      }

      this.setState({
        policyStatus: null, 
        originStatus: null, 
        distributionCreationStatus: null, 
        certificateStatus: null
      })
     
  }

  filterAsReadable(msg, key1, key2){
      if(!msg) return "OOPS! Something went wrong. Please try again!"
      // let obj = JSON.parse(msg);
      // let message = (!!key1 && !!key2) ? obj[key1][key2] : obj[key1];
      return msg;
  }

  getFinalContent(called) {
    const {
      policyStatus,
      certificateStatus,
      originStatus,
      distributionCreationStatus,
      distributionType
    } = this.state;
    
    const errorText = "Error in ";

    const dynLoader = conditional => {
      return (
        <React.Fragment>
          {!conditional && INPROGRESS_CIRCULAR_NOCLASS}
          {!!conditional && conditional === errorText && (
            <i className="material-icons font-50" style={styles.error}>
              error
            </i>
          )}
          {!!conditional && conditional !== errorText && (
            <i className="material-icons font-50" style={styles.success}>
              check_circle_outline
            </i>
          )}
        </React.Fragment>
      );
    };

    const dynMessage = (conditional, name) => {
      return (
        <React.Fragment>
          {!conditional && (
            <span className="text-center display-block status-dyn_container margin-top-16">
              Initializing {name}
            </span>
          )}
          {!!conditional && conditional === errorText && (
            <span className="text-center display-block status-error margin-top-16">
              {conditional} {name}.
            </span>
          )}
          {!!conditional && conditional !== errorText && (
            <span className="text-center display-block status-success margin-top-16">
            {conditional} {name}
            </span>
          )}
        </React.Fragment>
      );
    };

    return (
      <div className="margin-top-16">
        <div className="call-out padding-16">
          <div >
          {certificateStatus === errorText || policyStatus === errorText || originStatus === errorText || distributionCreationStatus === errorText 
              ? <div>
                    <h3 className="fullwidth">We update the ongoing processes here:</h3>
                    <ol className="padding-none margin-none">
                    {certificateStatus === errorText && distributionType !== SHIELD_CDN 
                    && !!this.submitReadyCertificateData 
                    && <li>{this.filterAsReadable(this.submitReadyCertificateData.message, "chain")}</li>}
                    {distributionCreationStatus === errorText 
                    && !!this.submitReadyDistributionData 
                    && <li>{this.filterAsReadable(this.submitReadyDistributionData.message, "domains", "existing")}</li>}
                    {originStatus === errorText 
                    && !!this.submitReadyOriginData 
                    && <li>{this.filterAsReadable(this.submitReadyOriginData.message, "invalid")}</li>}
                    {policyStatus === errorText 
                    && !!this.submitReadyPolicyData 
                    && <li>{this.filterAsReadable(this.submitReadyPolicyData.message, "pullOriginSettings", "conflict")}</li>}
                  </ol>
                  {distributionCreationStatus === "Deleted" && <div className="call-out">
                  <h3 className="fullwidth">Since we encountered error because of reason stated above, hence to avoid zombie distribution we are going to delete it automatically. If needed, you redo all the processes again! 
                    <br />
                    <span className="link-default link cursor decoration" onClick={this.switchStepperView}>Click here</span> to start again.
                    </h3>
                  </div>}
                </div>
              : <h3 className="fullwidth">Sit back and relax, meanwhile we will create below entities for you!</h3>}
          </div>
          <Grid spacing="2" container>
              
            <Grid item xs={6} className="text-right">
                  {distributionType !== SHIELD_CDN 
                  && <div className="display-block-inline width-50per">
                    <span className="loader-container">
                      {dynLoader(certificateStatus)}
                    </span>
                    {dynMessage(certificateStatus, "Certificate")}
                  </div>}

              <div className="display-block-inline width-50per">
                <span className="loader-container">
                  {dynLoader(distributionCreationStatus)}
                </span>
                {dynMessage(distributionCreationStatus, "Distribution")}
              </div>
            </Grid>
            <Grid item xs={6} className="text-left">
              
                <div className="display-block-inline width-50per  pull-left">
                  <span className="loader-container">
                    {dynLoader(originStatus)}
                  </span>
                  {dynMessage(originStatus, "Origin")}
                </div>

              <div className="display-block-inline width-50per pull-right">
                <span className="loader-container">
                  {dynLoader(policyStatus)}
                </span>
                {dynMessage(policyStatus, "Policy")}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
  getDialogWithoutStepper(distributionType) {
    return this.getDistributionControls(distributionType);
  }

  getDistributionStatus(customerId, distributionId, cdnType) {
    const _this = this;
    this.props.getDistributionStatus(
      customerId,
      distributionId,
      cdnType,
      function(res) {}
    );
    _this.toggleDistStausDialog();
  }

  getContentAsPerStatusCode(value) {
    if (!!value) {
      return this.setIconElement(styles, "completed");
    } else {
      return this.setIconElement(styles, "error");
    }
  }

  getDistStatusContent(distributionStatusObject) {
    let data = distributionStatusObject;
    if (!data && data === null) {
      return tableLoaderEmpty;
    }

    if (!!data && !data.status.length) {
      return NORESPONSE;
    }

    return !!data && !!data.status.length ? (
      <table className="container-provider margin-16">
        {data.status.map((providerItem, index) => {
          return (
            <React.Fragment key={providerItem.providerId + index}>
              {
                <tr className={"group" + this.evenOdd(index)}>
                  <td className="padding-top-default">
                    <strong>Provider: </strong>
                    {providerItem.providerType} <strong>Status: </strong>
                    {this.getContentAsPerStatusCode(providerItem.success)}
                  </td>
                </tr>
              }
              {!!providerItem.entities &&
                providerItem.entities.map(entity => (
                  <tr
                    className={"group" + this.evenOdd(index)}
                    key={entity.category + index}
                  >
                    <td className="padding-left-default">
                      <strong>Category: </strong>
                      {entity.category}{" "}
                      {this.getContentAsPerStatusCode(entity.success)}
                      {!!entity.message && (
                        <React.Fragment>
                          <p className="margin-left-16">
                            {" - "}
                            <strong>Message: </strong>
                            {entity.message + " (" + entity.updated + ")"}
                          </p>
                        </React.Fragment>
                      )}
                    </td>
                  </tr>
                ))}
            </React.Fragment>
          );
        })}
      </table>
    ) : (
      staticPlaceholderTable
    );
  }

  generateStatusMarkup(n, statusObj, cdnType, customerId) {
    if (!!statusObj && statusObj.status === "error") {
      return (
        <Button
          className="button-no-width"
          onClick={() => this.getDistributionStatus(customerId, n.id, cdnType)}
        >
          {this.setStatusIcon(statusObj)}
        </Button>
      );
    } else {
      return this.setStatusIcon(statusObj);
    }
  }

  toggleDistStausDialog() {
    this.setState({
      openDistributionStatusDialog: !this.state.openDistributionStatusDialog
    });
    return true;
  }

  getCustomerId() { 
        const { switchUserData, userData } = this.props;
        let customerId = !!switchUserData ? switchUserData.id : userData.customerId;
        return customerId;
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      selected,
      order,
      orderBy,
      pageOptions,
      rowsPerPage,
      page,
      openDialog,
      isLoading,
      dialogFormName,
      openFormDialog,
      value,
      distributionType,
      maxWidth,
      certificates,
      redirectPolicyPage,
      stepperSwitchViewTo,
      openDistributionStatusDialog,
      tableSearchText
    } = this.state;

    let {
      open,
      userData,
      role,
      classes,
      distributions,
      switchUserData,
      distributionStatus
    } = this.props;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;
    const type = this.getType(this.props.match.params.type);

    if (
      !!customerId &&
      !!distributionType &&
      !this.calledOnce &&
      type === distributionType
    ) {
      this.calledOnce = true;
      this.getDistributions(customerId, distributionType);
    }

    if (!certificates.length && !!customerId && !this.certificateInProgress) {
      this.certificateInProgress = true;
      this.getCertificates(customerId, distributionType);
    }

    if (!!redirectPolicyPage) {
      return <Redirect to={redirectPolicyPage} />;
    }

    let distributionEmpty =
      shortByDate(distributions, "updated", "created") || [];
    this.dialogStepContent = [];

    if (!this.dialogStepContent.length) {
      this.dialogStepContent = this.getDialogStepperContent(
        distributionType,
        customerId
      );
    }

    let dialogDistributionStatusContent = "";

    if (!!distributionStatus) {
      dialogDistributionStatusContent = this.getDistStatusContent(
        distributionStatus
      );
    }

    return (
      <div data-test="dist-component">
        <Typography variant="h3" color="initial" className="page-title">
          Configuration
        </Typography>
        <Typography variant="h4" color="initial">
          Manage your distributions, origins and certificates
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        {!!openFormDialog && !!this.dialogStepContent.length && (
          <FormDialogStepper
            openFormDialog={openFormDialog}
            formHeading={dialogFormName}
            cancelFormText="Close"
            maxWidth={maxWidth}
            formSubmit={e => {}}
            submitDataInProgress={isLoading}
            cancelFormAction={() => this.handleFormDialogToggle()}
            dialogContent={this.dialogStepContent}
            paddingNode={true}
            stepperSwitchViewTo={stepperSwitchViewTo}
          />
        )}
        {!dialogDistributionStatusContent.length && (
          <FormDialogBase
            openFormDialog={openDistributionStatusDialog}
            formHeading={"Distribution status dialog"}
            cancelFormText="Close"
            maxWidth={maxWidth}
            dialogContent={dialogDistributionStatusContent}
            cancelFormAction={() => this.toggleDistStausDialog()}
            paddingNode={true}
          />
        )}
        <AlertDialog
          openDialog={openDialog}
          heading="Confirm"
          message={
            "Are you sure you want to remove distribution: " + selected + "?"
          }
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          cancelBtnAction={() => this.handleDialogToggle()}
          confirmBtnAction={() => this.handleDelete(customerId, selected)}
        />
        <Paper className="margin-top-default">
          <Typography variant="h4" color="initial" className="margin-16">
            Distributions {'>'}
            {!!distributionType && (
              <span> {this.formatPageHeading(distributionType)}</span>
            )}
          </Typography>
          {distributions !== null && (
            <EnhancedTableToolbar
              numSelected={0}
              background={true}
              subheading=""
              toolTipTitle="Add distribution"
              handleAddClick={() =>
                this.handleAdd(customerId, distributionType)
              }
              hideDefaultOptions={false}
            />
          )}
          {distributions === null && tableLoaderEmpty}
          {!!distributions && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <RenderSearchTableInput
                  onChange={e =>
                    this.handleSearchTable(
                      e,
                      distributions,
                      "GET_DISTRIBUTIONS"
                    )
                  }
                  onClear={name => {
                    this.handleClearSearch(name, "GET_DISTRIBUTIONS");
                  }}
                  className="table-search-field"
                  value={tableSearchText}
                />

                <Table
                  className={classes.table + " table"}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    showSelectAll={false}
                    hideCheckBox={false}
                    hideNumbers={true}
                    rows={
                      distributionType !== SHIELD_CDN
                        ? distributionTableRows
                        : shieldDistributionTableRows
                    }
                    onRequestSort={this.handleRequestSort}
                    rowCount={distributionEmpty.length}
                  />
                  {(value === 0 ||
                    value === 1 ||
                    value === 2 ||
                    value === 3) && (
                    <TableBody>
                      {stableSort(
                        distributionEmpty,
                        getSorting(order, orderBy, {
                          name: "status",
                          sortBy: "status"
                        })
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((n, index) => {
                          const statusObj = n.status;
                          const pendingDeleting =
                            statusObj.status === "deleting";
                          const configPending = statusObj.status === "pending";
                          const readOnly = n.custom || configPending || false;
                          const disabled=n.enabled;
                          return (
                            <TableRow
                              tabIndex={-1 + index}
                              key={n.id + index}
                              title={this.getTitleName(
                                readOnly,
                                pendingDeleting,
                                configPending
                              )}
                              className={this.getClassName(
                                readOnly,
                                pendingDeleting,
                                disabled
                              )}
                            >
                              <TableCell>
                                {this.getInactiveRowIcon(
                                  readOnly,
                                  pendingDeleting,
                                  disabled
                                )}
                                {n.description}
                              </TableCell>
                              <TableCell className="status">
                                {this.generateStatusMarkup(
                                  n,
                                  statusObj,
                                  distributionType,
                                  customerId
                                )}
                              </TableCell>
                              <TableCell>{n.endpoint}</TableCell>
                              <TableCell className="actions">
                                <IconButton
                                  aria-label="View details"
                                  onClick={e =>
                                    this.handleDistributionEdit(
                                      e,
                                      distributionType,
                                      n.id
                                    )
                                  }
                                >
                                  <PageviewIcon
                                    title="View details"
                                    color="primary"
                                  />
                                </IconButton>
                                {!readOnly && !pendingDeleting && (
                                  <IconButton
                                    aria-label="Delete"
                                    title="Delete"
                                    onClick={event => {
                                      this.handleDialogToggle(n.id);
                                      this.setSelected(n.id);
                                    }}
                                  >
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {!distributions.length && (
                        <TableRow style={{ height: 49 }}>
                          <TableCell colSpan={6}>{NORESPONSE}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={pageOptions}
                component="div"
                count={distributionEmpty.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </React.Fragment>
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  policies: state.policies.policies,
  distributions: state.distributions.distributions,
  distribution: state.distributions.distribution,
  selectedTab: state.policies.cdnTab,
  switchUserData: state.user.switchUserData,
  certificates: state.configuration.certificates,
  distributionStatus: state.distributionStatus.data
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    getDistributions,
    hideNotification,
    getUserDetails,
    switchUserDetails,
    deleteDistribution,
    getDistribution,
    updateDistribution,
    addDistribution,
    resetDistribution,
    deletePolicy,
    deleteOrigin, 
    deleteCertificate, 
    getPolicies,
    initPolicies,
    updatePolicy,
    addOrigin,
    addCertificate,
    getDistributionStatus,
    applyFilteredData
  }
)(withStyles(tableStyles)(Distribution));
