import { PureComponent } from "react";
import {
  valueFormatter,
  COLUMNCHART,
  LINECHART,
  PIECHART
} from "../../common/constants/chart";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

export default class AMChartMultipleBase extends PureComponent {
  chart = {};

  componentWillUnmount() {
    if (Object.keys(this.chart).length > 0) {
      this.chart.dispose();
    }
  }

  commonTooltipBinding(
    columnSeries,
    unitCategory,
    additionalData,
    yAxisText,
    xAxisText,
    index,
    xAxis
  ) {
    if (!!columnSeries) {
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#FFFFFF");
      columnSeries.tooltip.label.fill = am4core.color("#000000");
      columnSeries.adapter.add("tooltipText", (text, target, key) => {
        const dataItem = target.tooltipDataItem;
        let formatedData = valueFormatter(
          [dataItem.values.valueY.value],
          unitCategory
        );
        const formattedValue = formatedData[0][0] + " " + formatedData[0][1];
        return `[bold]{dateX.formatDate('MMMM dt')}, {dateX.formatDate('yyyy')}[/]\n${
          !additionalData.singleTarget
            ? xAxisText[0] + " "
            : xAxisText[index] + " "
        }[bold]${yAxisText}: [/]${formattedValue}`;
      });
    }
    return false;
  }

  commonSeriesBinding(columnSeries, valueY, categoryX) {
    if (!!columnSeries) {
      columnSeries.strokeWidth = 1;
      columnSeries.fillOpacity = 0.2;
      columnSeries.dataFields.valueY = valueY;
      columnSeries.dataFields.dateX = categoryX;
      return true;
    }
    return false;
  }

  getCharts(type) {
    switch (type) {
      case LINECHART:
        return new am4charts.LineSeries();
      case COLUMNCHART:
        return new am4charts.ColumnSeries();
      case PIECHART:
        return new am4charts.PieSeries();
      default:
        return new am4charts.LineSeries();
    }
  }

  getPIEChartTooltip(pieSeries, unitCategory, yAxisText) {
    if (!!pieSeries) {
      pieSeries.tooltip.getFillFromObject = false;
      pieSeries.tooltip.background.fill = am4core.color("#FFFFFF");
      pieSeries.tooltip.label.fill = am4core.color("#000000");
      pieSeries.slices.template.adapter.add(
        "tooltipText",
        (value, target, key) => {
          const dataItem = target.tooltipDataItem;
          let formatedData = valueFormatter(
            [dataItem.values.value.value],
            unitCategory
          );
          const formattedValue = formatedData[0][0] + " " + formatedData[0][1];
          let tooltip = `[bold]{category}[/]\n[bold]${yAxisText}: [/]${formattedValue} [bold]{value.percent.formatNumber('#.#')}%[/]`;
          return tooltip;
        }
      );
    }
    return false;
  }

  commonInternalLoaderAndScrollBar(chart) {
    if (!!chart) {
      chart.cursor = new am4charts.XYCursor();
      chart.events.on("ready", () => {
        this.removeChartLoader();
      });
      this.chart = chart;
    }
    return true;
  }

  dashboardTooltip(columnSeries, unitCategory, titleText) {
    if (!!columnSeries) {
      columnSeries.tooltip.getFillFromObject = false;
      columnSeries.tooltip.background.fill = am4core.color("#FFFFFF");
      columnSeries.tooltip.label.fill = am4core.color("#000000");
      columnSeries.adapter.add("tooltipText", (text, target, key) => {
        const dataItem = target.tooltipDataItem;
        let formatedData = valueFormatter(
          [dataItem.values.valueY.value],
          unitCategory
        );
        const formattedValue = formatedData[0][0] + " " + formatedData[0][1];
        return `[bold]{categoryX}[\bold]\n[bold]${titleText}[\bold]: ${formattedValue}`;
      });
    }
    return false;
  }
  removeChartLoader() {
    let child = document.getElementById("chartLoader");
    let parent = document.querySelector(".chart-amcharts");
    setTimeout(() => {
      if (!!child && child.parentNode === parent) {
        parent.removeChild(child);
      }
    }, 1000);
    return true;
  }
}
