import {
  GET_CHART,
  GET_HOURLY_CHART,
  ERROR_IN_CHART,
  GET_MULTILINE_CHART
} from "./types";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import { apiGetCall, encodeMap } from "../actions/baseActions";

export function resetMonthlyChart() {
  return function(dispatch) {
    dispatch({
      type: GET_CHART,
      data: null
    });
  };
}

export function resetHourlyChart() {
  return function(dispatch) {
    dispatch({
      type: GET_HOURLY_CHART,
      data: null
    });
  };
}

function generateChartUrl(payload, customerId, endpoint) {
  const query = "?" + encodeMap(payload).join("&");
  const url =
    userSession.getSessionItem("apiUrl") +
    customerId +
    "/statistics" +
    endpoint +
    query;

  return url;
}

export function drawMontlyChart(customerId, payload, endpoint, callback) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawHourlyChart(customerId, payload, endpoint, callback) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_HOURLY_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineCacheChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineRequestsChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineTransferRateChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineBytesDelieveredChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawAmLineChartRequests(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);
  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawAmLineChartRequests1H(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawAmLineChartDataRate(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawAmBarChartDataTransfer(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function getTotalResults(customerId, payload, endpoint, callback) {
  const newEndpoint = endpoint + "/totals";
  const url = generateChartUrl(payload, customerId, newEndpoint);
  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = "Error_Total_Results",
    typeSuccess = "Total_Results";

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineStatusCode2XChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineStatusCode4XChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function drawMultiLineStatusCode5XChart(
  customerId,
  payload,
  endpoint,
  callback
) {
  const url = generateChartUrl(payload, customerId, endpoint);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_CHART,
    typeSuccess = GET_MULTILINE_CHART;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}
