import React, { PureComponent } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { PIECHART } from "../../common/constants/chart";
import Typography from "@material-ui/core/Typography";
import { tableLoaderEmpty, NORESPONSE } from "../../common/helpers/loader";
import AMChartMultipleBase from "./AMChartMultipleBase";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class AMPIEChartMultiple extends AMChartMultipleBase {
  initiateChartMultiple() {
    const _this = this;
    setTimeout(() => {
      const {
        data,
        valueY,
        categoryX,
        id,
        xAxisText,
        yAxisText,
        additionalData
      } = _this.props;

      if (!document.getElementById(id)) {
        this.initiateChartMultiple();
        return false;
      }

      let chart = am4core.create(id, am4charts.PieChart);
      chart.innerRadius = am4core.percent(40);
      let unitCategory = additionalData.unitCategory;
      let pieSeries;
      for (let ii = 0, jj = data.length; ii < jj; ii++) {
        pieSeries = chart.series.push(_this.getCharts(PIECHART));
        pieSeries.dataFields.value = valueY;
        pieSeries.dataFields.category = categoryX;
        pieSeries.data = data[ii];
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.ticks.template.strokeWidth = 2;
        pieSeries.slices.template.propertyFields.isActive = "isActive";
        _this.getPIEChartTooltip(pieSeries, unitCategory, yAxisText);
      }
      chart.events.on("ready", () => {
        _this.removeChartLoader();
      });
      _this.chart = chart;
    }, 1000);
  }

  render() {
    const { id, chartTitle, data, className } = this.props;

    return (
      <Card className={className}>
        <CardContent className="position-relative">
          <Typography gutterBottom component="h4" align="left">
            {chartTitle}
          </Typography>
          <div id={id} className="chart-amcharts pie">
            <div id="chartLoader" className="chart-loader">
              {!!data && !!data.length && !!id && tableLoaderEmpty}
            </div>
          </div>
          {!!data && !!data.length && !!id
            ? this.initiateChartMultiple()
            : NORESPONSE}
        </CardContent>
      </Card>
    );
  }
}

export default AMPIEChartMultiple;
