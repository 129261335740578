import { ERROR_IN_CUSTOMERS, RECEIVED_CUSTOMER_OVERVIEW, 
    GET_SPECIFIC_CUSTOMER_DETAIL, GET_STATISTICS_TOTAL } from "../actions/types";

const initialState = {
    data: '',
    contactData: '',
    isFetching: true,
    error: null, 
    resellerStastics: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RECEIVED_CUSTOMER_OVERVIEW:
            return {
                ...state,
                data: action.data,
                isFetching: false
            }
        case GET_STATISTICS_TOTAL:
            return {
                ...state,
                resellerStastics: action.data,
                isFetching: false
            }
        case GET_SPECIFIC_CUSTOMER_DETAIL:
            return {
                ...state,
                contactData: action.data,
                isFetching: false
            }
        case ERROR_IN_CUSTOMERS:
            return {
                ...state,
                error: action.error,
                isFetching: false
            }
        default:
            return state;
    }
}

