import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React from "react";
import Button from "@material-ui/core/Button";
import { Route } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import FormLabel from "@material-ui/core/FormLabel";
import classNames from "classnames";
import Switch from "@material-ui/core/Switch";
import ReactTooltip from "react-tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import AutoComplete from "../../components/AutoComplete/AutoComplete";
import { withStyles } from "@material-ui/core/styles";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import LaunchIcon from "@material-ui/icons/Launch";
import "../scss/tooltip.scss";

export const RenderPasswordField = (props) => {
  return (
    <React.Fragment>
      <TextField
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        margin={props.margin}
        type={props.type}
        value={props.value}
        onChange={props.handleChange}
        variant={props.variant || "outlined"}
        helperText={props.helperText}
        className={props.className}
        required
        error={props.error}
        fullWidth
        InputProps={props.InputProps}
      />
      <RenderError {...props} />
    </React.Fragment>
  );
};

export const RenderPasswordFieldWithTooltip = (props) => {
  return (
    <div className="form-group" key={props.name + "form-group"}>
      <div className="control">
        <RenderPasswordField {...props} />
      </div>
      {props.toolTipTitle && (
        <div className="toolTip">
          <ClickableTooltip
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export const RenderSearchTableInput = (props) => {
  const name = "tableSearchText";
  return (
    <TextField
      id={name}
      name={name}
      type="text"
      label="Search..."
      variant="outlined"
      value={props.value}
      className={props.className}
      onChange={props.onChange}
      InputProps={{
        endAdornment: !!props.value && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => props.onClear(name)}
              aria-label="icon button"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export const RenderInput = (props) => {
  return (
    <div className={"input-" + props.id + " " + props.className}>
      <InputLabel htmlFor={props.id} required={props.required}>
        {props.label}
      </InputLabel>
      <Input
        required={props.required}
        id={props.id}
        fullWidth
        autoComplete={props.autoComplete}
        name={props.id}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        type={props.type}
        value={props.value || ""}
        onChange={props.onChange}
      />
      <i className={"material-icons icon-" + props.id}>{props.icon}</i>
      {props.helperText && (
        <span className="helper-text error">{props.helperText}</span>
      )}
    </div>
  );
};

export const RenderInputWithToolTip = (props) => {
  return (
    <div className="form-group" key={props.name + "form-group"}>
      <div className="control">
        <RenderInputWithOutLabel {...props} />
      </div>
      <div className="toolTip">
        <ClickableTooltip
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          {...props}
        />
      </div>
    </div>
  );
};
export const RenderInputWithOutLabel = (props) => {
  return (
    <React.Fragment>
      <TextField
        id={props.id}
        name={props.name}
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        margin="normal"
        variant={props.variant || "outlined"}
        value={props.value || ""}
        onChange={props.handleChange}
        helperText={props.helperText}
        error={props.error}
        multiline={props.multiline}
        rows={props.rows}
        rowsMax={props.rowsMax}
        disabled={props.disabled}
        fullWidth
        data-tab={props.tab}
        min={props.min}
        max={props.max}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: props.maxLength }}
      />
      <RenderError {...props} />
    </React.Fragment>
  );
};

const RenderError = (props) => {
  return (
    !!props.required &&
    (!props.value || props.value === "none" || !!props.error) && (
      <span
        data-name={props.name}
        data-tab={props.tab}
        className={"error-bgnone pull-right required"}
      >
        This is required!
      </span>
    )
  );
};

export const ClickableTooltip = (props) => {
  return (
    <React.Fragment>
      <span
        data-tip={props.toolTipTitle}
        data-event="click focus"
        data-html={true}
        className={
          props.toolTipClassName +
          " " +
          classNames({
            "toolTip-container": true,
            "toolTip-shiftUp": props.ShiftUp,
            "toolTip-shiftDown": props.shiftDown,
          })
        }
        data-place={props.place || "left"}
        data-effect="solid"
      >
        ?
      </span>
      <ReactTooltip
        globalEventOff="click"
        type="info"
        clickable={true}
        resizeHide={false}
      />
    </React.Fragment>
  );
};

export const RenderReadOnlyInput = (props) => {
  return (
    <div className={"input-" + props.id}>
      <TextField
        id={props.id}
        name={props.name}
        label={props.label}
        defaultValue={!!props.value ? props.value.toString() : ""}
        className="field-input"
        margin="normal"
        disabled
        multiline={props.multiline}
        rows={props.rows}
        rowsMax={props.rowsMax}
        fullWidth
      />
    </div>
  );
};

export const RenderButton = (props) => (
  <Route
    render={({ history }) => (
      <Button
        className={"button-" + props.name}
        name={props.name}
        align={props.align}
        size={props.size}
        color={props.color}
        type={props.type}
        variant={props.variant}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        onClick={() => {
          history.push(props.pushlink);
        }}
      >
        {props.label}
      </Button>
    )}
  />
);

export const RenderButtonClick = (props) => {
  return (
    <Button
      className={"button-click" + props.name + " " + props.className}
      name={props.name}
      align={props.align}
      size={props.size}
      color={props.color}
      type={props.type}
      variant={props.variant}
      disabled={props.submitDataInProgress || props.disabled}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
    >
      {!props.submitDataInProgress ? (
        props.label
      ) : (
        <CircularProgress size={22} thickness={6} color="primary" />
      )}
    </Button>
  );
};

export const RenderSubmitButton = (props) => {
  return (
    <Button
      className={"button-" + props.name}
      name={props.name}
      align={props.align}
      size={props.size}
      color={props.color}
      variant={props.variant}
      type={props.type}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      id={props.name}
    >
      {props.label}
    </Button>
  );
};

export const RenderSelectWithToolTip = (props) => {
  return (
    <div className="form-group select" key={props.name + "form-group"}>
      <div className="control">
        <RenderSelect {...props} />
      </div>
      <div className="toolTip">
        <ClickableTooltip
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          {...props}
        />
      </div>
    </div>
  );
};

export const RenderAutoSelectWithToolTip = (props) => {
  return (
    <React.Fragment>
      <RenderAutoSelect {...props} />
      <div className="toolTip">
        <ClickableTooltip
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          {...props}
        />
      </div>
    </React.Fragment>
  );
};
export const RenderSelectWithOutToolTip = (props) => {
  return (
    <div className="form-group select" key={props.name + "form-group"}>
      <div>
        <RenderSelect {...props} />
      </div>
    </div>
  );
};

export const RenderAutoSelect = (props) => {
  const options = props.items.map((suggestion) => ({
    value: suggestion.value,
    label: suggestion.text,
  }));

  if (!!props.default && !props.defaultValue) {
    return (
      <div className="error-highlighter">
        <AutoComplete
          {...props}
          options={options}
          default={props.default}
          onChange={(selection, name) => props.handleChange(selection, name)}
          placeholder={"Search a " + (props.placeholderSuffix || props.label)}
        />
        <RenderError {...props} />
      </div>
    );
  }

  if (!props.default && !!props.defaultValue) {
    const defaultValue = parseInt(props.defaultValue);
    return (
      <div className="error-highlighter">
        <AutoComplete
          {...props}
          defaultValue={options[defaultValue]}
          options={options}
          onChange={(selection, name) => props.handleChange(selection, name)}
          placeholder={"Search a " + (props.placeholderSuffix || props.label)}
        />
        <RenderError {...props} />
      </div>
    );
  }

  return (
    <div className="error-highlighter">
      <AutoComplete
        {...props}
        options={options}
        onChange={(selection, name) => props.handleChange(selection, name)}
        placeholder={"Search a " + (props.placeholderSuffix || props.label)}
      />
      <RenderError {...props} />
    </div>
  );
};

export const RenderSelect = (props) => {
  return (
    <FormControl fullWidth variant="outlined" className={props.className}>
      <InputLabel htmlFor={props.id} required={props.required}>
        {props.label}
      </InputLabel>
      <Select
        multiple={props.multiple}
        value={props.value}
        onChange={props.handleChange}
        onClose={props.onClose}
        error={props.error}
        displayEmpty={props.displayEmpty}
        disabled={props.disabled}
        required={props.required}
        input={
          <OutlinedInput
            labelWidth={!!props.label ? props.label.length * 8.2 : 150}
            name={props.name}
            id={props.id}
          />
        }
      >
        {!!props.items &&
          props.items.map((elm, index) => {
            return (
              <MenuItem key={"item_" + index} value={elm.value}>
                {elm.text}
              </MenuItem>
            );
          })}
      </Select>
      <RenderError {...props} />
    </FormControl>
  );
};

export const RenderSelectMultipleWithToolTip = (props) => {
  return (
    <div className="form-group" key={props.name + "form-group"}>
      <div className="control">
        <RenderSelectMultiple {...props} />
      </div>
      <div className="toolTip">
        <ClickableTooltip
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          {...props}
        />
      </div>
    </div>
  );
};

export const RenderSelectMultiple = (props) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={props.className + " multi-select"}
    >
      <InputLabel htmlFor="select-multiple-checkbox">{props.label}</InputLabel>
      <Select
        multiple
        value={props.value}
        onChange={props.handleChange}
        onClose={props.onClose}
        input={
          <OutlinedInput
            labelWidth={!!props.label ? props.label.length * 8.2 : 150}
            name={props.name}
            id="select-multiple-checkbox"
          />
        }
        renderValue={(selected) => selected.join(", ")}
      >
        {props.items.map((elm, index) => (
          <MenuItem key={"item_" + index} value={elm.value}>
            <Checkbox checked={props.value.indexOf(elm.value) > -1} />
            <ListItemText primary={elm.value} />
          </MenuItem>
        ))}
      </Select>
      <RenderError {...props} />
    </FormControl>
  );
};

export const RenderLockStaticButton = (props) => {
  return (
    <div
      className={"form-group radio overflow-hidden" + props.className}
      key={props.name + "form-group"}
    >
      {!props.open && (
        <LockOpenIcon className="pull-left font-32 button-inactive" />
      )}
      {!!props.open && <LockIcon className="pull-left font-32 button-active" />}
      <FormLabel component="label" className="margin-top-8 pull-left">
        {props.label}
      </FormLabel>
      {props.reopen && (
        <div
          className="margin-left-16 link-cursor button-reopen"
          title={props.reOpenTitle}
          onClick={props.onReopen}
        >
          <LaunchIcon />
        </div>
      )}
      <span className="margin-left-8 margin-top-3">
        <ClickableTooltip
          disableFocusListener={true}
          disableHoverListener={true}
          disableTouchListener={true}
          place="right"
          {...props}
        />
      </span>
    </div>
  );
};

export const RenderSwitchWithToolTip = (props) => {
  return (
    <div
      className={"form-group radio " + props.className}
      key={props.name + "form-group"}
    >
      {!props.singleLine && (
        <div className="control">
          <FormLabel component="label">{props.label}</FormLabel>
          <ClickableTooltip
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            place="right"
            {...props}
          />
          <br />
          <RenderSwitch {...props} />
        </div>
      )}
      {!!props.singleLine && (
        <div className="control">
          <FormLabel component="label">{props.label}</FormLabel>
          <RenderSwitch {...props} />
          <ClickableTooltip
            disableFocusListener={true}
            disableHoverListener={true}
            disableTouchListener={true}
            place="right"
            {...props}
          />
        </div>
      )}
    </div>
  );
};

export const RenderSwitchWithOutToolTip = (props) => {
  return (
    <div
      className={"form-group radio " + props.className}
      key={props.name + "form-group"}
    >
      {!props.singleLine && (
        <div className="control">
          <FormLabel component="label">{props.label}</FormLabel>
          <br />
          <RenderSwitch {...props} />
        </div>
      )}
      {!!props.singleLine && (
        <div className="control">
          <FormLabel component="label">{props.label}</FormLabel>
          <RenderSwitch {...props} />
        </div>
      )}
    </div>
  );
};

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
      checked={props.checked || false}
      value={props.value || false}
    />
  );
});

export const RenderSwitch = (props) => {
  return (
    <React.Fragment>
      <IOSSwitch
        checked={props.checked}
        onChange={props.handleRadioChange}
        value={props.value}
        disabled={props.disabled}
      />
    </React.Fragment>
  );
};
