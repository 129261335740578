import React, { PureComponent } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { valueFormatter } from "../../common/constants/chart";
import Typography from "@material-ui/core/Typography";
import {
  tableLoaderEmpty,
  placeholderChartDynMessage
} from "../../common/helpers/loader";
import AMChartMultipleBase from "./AMChartMultipleBase";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const PERCENTAGE = "percentage";

class AmChartCardMultiple extends AMChartMultipleBase {
  initiateChartMultiple() {
    const _this = this;
    setTimeout(() => {
      const {
        data,
        valueY,
        categoryX,
        id,
        unit,
        xAxisText,
        yAxisText,
        type,
        additionalData
      } = _this.props;

      if (!document.getElementById(id)) {
        this.initiateChartMultiple();
        return false;
      }

      let chart = am4core.create(id, am4charts.XYChart);
      var xAxis = chart.xAxes.push(new am4charts.DateAxis());
      xAxis.renderer.grid.template.disabled = true;

      xAxis.skipEmptyPeriods = true;

      // Create value axis
      let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
      yAxis.renderer.grid.template.disabled = true;
      if (additionalData.unitCategory === PERCENTAGE) {
        yAxis.min = 0;
        yAxis.max = 1;
      }
      let unitCategory = additionalData.unitCategory;
      yAxis.formatLabel = value => {
        let formatedData = valueFormatter([value], unitCategory);
        return formatedData[0][0] + " " + formatedData[0][1];
      };
      yAxis.title.text = yAxisText;
      yAxis.tooltip.disabled = true;

      let columnSeries;

      for (let ii = 0, jj = data.length; ii < jj; ii++) {
        columnSeries = chart.series.push(
          this.getCharts(type || additionalData.type)
        );
        this.commonSeriesBinding(columnSeries, valueY, categoryX);
        columnSeries.data = data[ii];
        columnSeries.showOnInit = false;
        columnSeries.simplifiedProcessing = true;
        if (!additionalData.page) {
          this.commonTooltipBinding(
            columnSeries,
            unitCategory,
            additionalData,
            yAxisText,
            xAxisText,
            ii,
            xAxis
          );
        } else {
          this.dashboardTooltip(
            columnSeries,
            unitCategory,
            additionalData.titleText
          );
        }

        chart.scrollbarX = new am4charts.XYChartScrollbar();
        chart.scrollbarX.series.push(columnSeries);
      }
      this.commonInternalLoaderAndScrollBar(chart, columnSeries);
    }, 1500);
  }

  render() {
    const { id, chartTitle, data } = this.props;
    return (
      <Card>
        <CardContent className="position-relative">
          {!!chartTitle && (
            <Typography gutterBottom component="h3" align="left">
              {chartTitle}
            </Typography>
          )}

          <div id={id} className="chart-amcharts">
            <div id="chartLoader" className="chart-loader">
              {!!data && !!data.length && !!id && tableLoaderEmpty}
            </div>
          </div>
          {!!data && !!data.length && !!id
            ? this.initiateChartMultiple()
            : placeholderChartDynMessage(data)}
        </CardContent>
      </Card>
    );
  }
}

export default AmChartCardMultiple;
