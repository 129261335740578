export function formatBreadcrumText(text, comparator) {
  if (text === comparator) {
    text = "Distributions";
  }
  return formatCamelcaseAndRemoveSpeacialChar(text);
}

export function replaceSpecialCharWithSpace(string) {
  let str = string.replace(/([-_&@/])/g, " ");
  return str;
}

export const phoneValidator = (value) => {
  const phoneRegEx = /^(?=.*[0-9])[- +()0-9]+$/; 
  let phoneBool = phoneRegEx.test(value) && value.length > 10;
  return phoneBool; 
}

export function addClass(id, className) {
  const el = document.getElementById(id);
  el.classList.remove(className);
  el.classList.add(className);
}

export function addClassUsingQuery(identifier, className) {
  const el = document.querySelector(identifier);
  el.classList.remove(className);
  el.classList.add(className);
}

export function removeClassUsingQuery(identifier, className) {
  const el = document.querySelector(identifier);
  el.classList.remove(className);
}

export function deepCompareTwoSimilarObjects(oldObj, newObj, exempt) {
  if (!oldObj) return true;
  let strFinal = "";

  for (let key in oldObj) {
    if (!!newObj.hasOwnProperty(key) && newObj[key] === oldObj[key]) {
      strFinal += "true";
    } else {
      strFinal += key === exempt ? "true" : "false";
    }
  }
  return !!strFinal.match(/false/g);
}

export function deserializeQueryString(search) {
  const string = new String(search);
  if (!!string && !string.length) return {};
  let queryString = string.replace("?", "") || window.location.search.slice(1);
  let pairs = queryString.split("&");
  let result = {};

  for (let ii = 0, jj = pairs.length; ii < jj; ii++) {
    const pair = pairs[ii].split("="),
      key = pair[0],
      value = decodeURIComponent(pair[1] || "");
    Object.defineProperties(result, {
      [key]: {
        enumerable: true,
        configurable: true,
        writable: true,
        value: value
      }
    });
  }

  return result;
}

export function countryNameFormatting(text, callback, specialUpperCase) {
  let string = replaceSpecialCharWithSpace(text);
  let stringArr = string.split(" ");
  let newString = "";
  for (let ii = 0, jj = stringArr.length; ii < jj; ii++) {
    let firstCaps =
      !!specialUpperCase && specialUpperCase === stringArr[ii]
        ? stringArr[ii].toUpperCase()
        : stringArr[ii].charAt(0).toUpperCase();
    if (!!specialUpperCase && specialUpperCase !== stringArr[ii]) {
      newString +=
        (ii === 0 ? firstCaps : " " + firstCaps) + stringArr[ii].substr(1);
    } else {
      newString += ii === 0 ? firstCaps : " " + firstCaps;
    }
  }
  if (!!callback) {
    return callback(newString);
  }
  return newString;
}

export function eachWordUpperCase(text) {
  let string = replaceSpecialCharWithSpace(text);
  let stringArr = string.split(" ");
  let newString = "";
  for (let ii = 0, jj = stringArr.length; ii < jj; ii++) {
    let firstCaps = stringArr[ii].charAt(0).toUpperCase();
    newString += ii === 0 ? firstCaps : " " + firstCaps;
    newString += stringArr[ii].substr(1);
  }
  return newString;
}

export function formatCamelcaseAndRemoveSpeacialChar(text, callback) {
  let string = replaceSpecialCharWithSpace(text);
  let stringArr = string.split(" ");
  for (let ii = 0, jj = stringArr.length; ii < jj; ii++) {
    if (ii === 0) {
      string = stringArr[ii].charAt(0).toUpperCase() + stringArr[ii].substr(1);
    } else {
      string +=
        " " + stringArr[ii].charAt(0).toLowerCase() + stringArr[ii].substr(1);
    }
  }
  if (!!callback) {
    return callback(string);
  } else {
    return string;
  }
}
