import { session as userSession } from "./sessionValues";

export class UserIdleTime {
  constructor() {
    this.init = this.init.bind(this);
    this.register = this.register.bind(this);
    this.deregister = this.deregister.bind(this);
    this.checkIdleTime = this.checkIdleTime.bind(this);
  }

  init() {
    this.lastActiveTime = new Date();
    window.onkeypress = () => {
      this.lastActiveTime = new Date();
    };
    window.onscroll = () => {
      this.lastActiveTime = new Date();
    };
    window.onmousemove = () => {
      this.lastActiveTime = new Date();
    };
    window.onclick = () => {
      this.lastActiveTime = new Date();
    };
  }

  register(CallBack) {
    this.init();
    let _self = this;
    _self.interval = window.setInterval(function() {
      _self.checkIdleTime(CallBack);
    }, 30000);
  }

  deregister() {
    window.clearInterval(this.interval);
  }

  checkIdleTime(CallBack) {
    let currentTime = new Date().getTime();
    let lastActiveTime = new Date(this.lastActiveTime).getTime();
    let idleTime = Math.floor((currentTime - lastActiveTime) / 1000 / 60);
    if (idleTime > 20) {
      this.deregister();
      userSession.clearSession();
      window.location.reload();
    } else if (idleTime >= 15 && idleTime <= 19) {
      CallBack({
        variant: "warning",
        message:
          "You are idle for long time, please click on refresh button to continue usage of application. Else for security purpose we will automatically logout.",
        open: true,
        label: "Make me active"
      });
    }
    return false;
  }
}

const idleTimeCheck = new UserIdleTime();

export default idleTimeCheck;
