import { GET_STATUS, ERROR_IN_STATUS } from "./types";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import { apiGetCall } from "../actions/baseActions";

function generateStatusUrl(customerId, distributionId, cdnType) {
  ///configurations/<customerId>/distributions/shield-cdn/<distributionId>/status
  const url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    cdnType +
    "/" +
    distributionId +
    "/status";

  return url;
}

export function getDistributionStatus(
  customerId,
  distributionId,
  cdnType,
  callback
) {
  const url = generateStatusUrl(customerId, distributionId, cdnType);

  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_STATUS,
    typeSuccess = GET_STATUS;

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}
