import {
  LEASEWEB,
  SYNEDGE,
  CONTINENT8,
  EUROVISION,
  NEWLEASEWEB,
} from "../branding/companies";

const generalConfig = {
  [SYNEDGE]: {
    logoPath: "/logos/synedge-logo.png",
    faviconPath: "/favicon/synedge.ico",
    logoWidth: 90,
    logoHeight: 50,
    marginLeft:16
  },
  [NEWLEASEWEB]: {
    logoPath: "/logos/lsw-logo.svg",
    faviconPath: "/favicon/lsw.ico",
    logoWidth: 213,
    logoHeight: 51
  },
  [LEASEWEB]: {
    logoPath: "/logos/lsw-logo.svg",
    faviconPath: "/favicon/lsw.ico",
    logoWidth: 213,
    logoHeight: 51
  },
  [CONTINENT8]: {
    logoPath: "/logos/c8-logo.gif",
    faviconPath: "",
    logoWidth: 100,
    logoHeight: 43,
    marginLeft:16
  },
  [EUROVISION]: {
    logoPath: "/logos/eurovision-logo.png",
    faviconPath: "/favicon/synedge.ico",
    logoWidth: 160,
    logoHeight: 53,
    marginLeft:16
  }
};

export function filterPalette(key) {
  let palette = {
    [SYNEDGE]: {
      primary: {
        main: "#60bcc9",
        light: "#65D0E1",
        dark: "#4F99A2",
        contrastText: "#fff"
      },
      secondary: {
        main: "#5f5f5f",
        dark: "#3C3C3C",
        light: "#9B9B9B",
        contrastText: "#fff"
      },
      error: {
        main: "#e10050",
        dark: "#9d0038",
        light: "#d8034f",
        contrastText: "#fff"
      }
    },
    [LEASEWEB]: {
      primary: {
        main: "#f08000", // #26338c
        contrastText: "#fff"
      },
      secondary: {
        main: "#27334c", // #f28700
        contrastText: "#fff"
      },
      error: {
        main: "#e10050",
        contrastText: "#fff"
      }
    },
    [NEWLEASEWEB]: {
      primary: {
        main: "#f08000", // #26338c
        contrastText: "#fff"
      },
      secondary: {
        main: "#27334c", // #f28700
        contrastText: "#fff"
      },
      error: {
        main: "#e10050",
        contrastText: "#fff"
      }
    },
    [CONTINENT8]: {
      primary: {
        main: "#b32017", // #26338c
        contrastText: "#fff"
      },
      secondary: {
        main: "#a28444", // #f28700
        contrastText: "#fff"
      },
      error: {
        main: "#e10050",
        contrastText: "#fff"
      }
    },
    [EUROVISION]: {
      primary: {
        main: "#15489F",
        light: "#8eb2f0",
        dark: "#0f3371",
        contrastText: "#fff"
      },
      secondary: {
        main: "#5f5f5f",
        dark: "#3C3C3C",
        light: "#9B9B9B",
        contrastText: "#fff"
      },
      error: {
        main: "#e10050",
        dark: "#9d0038",
        light: "#d8034f",
        contrastText: "#fff"
      }
    }
  };
  let index = key || SYNEDGE;
  return palette[index];
}

export function logoCollection(key) {
  let companyLogo = generalConfig;
  let index = key || SYNEDGE;
  return companyLogo[index];
}
