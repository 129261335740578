import { GET_INVALIDATION, GET_INVALIDATION_LIST } from '../actions/types';

const initialState = {
    data: null,
    sepecificData: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_INVALIDATION_LIST:
            return {
                ...state,
                data: action.data
            }
        case GET_INVALIDATION:
            return {
                ...state,
                sepecificData: action.data
            }
        default:
            return state;
    }
}

