import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../actions/types";

const initialState = {
    visibility: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                items: action.visibility
            }
        case HIDE_NOTIFICATION:
            return {
                ...state,
                items: action.visibility
            }
        default:
            return state;
    }
}

