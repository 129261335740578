// Create mapper here
export let unit, staticAmLineChart;
export const activeQueryType = "bandwidth";
export const topLocationsTitle = "Data transferred";
export const topLocationsUnitDesc = "Data Transferred";
export const BANDWIDTH = "bandwidth";
export const DATATRANSFER = "datatransfer";
export const topLocationsUnitCategory = "number";
export const LINECHART = "Line chart";
export const COLUMNCHART = "Column chart";
export const PIECHART = "Pie chart";

export const dashboardChartVMMapper = (
  self,
  data,
  title,
  titleText,
  axisX,
  axisY,
  chartType,
  xAxisText,
  chartUnit,
  additionalData,
  yAxisText
) => {
  let chartItems = [
    {
      title: title,
      type: chartType,
      data: data,
      spacing: 6,
      category: axisX,
      titleText: titleText,
      yAxisText: yAxisText,
      valueY: axisY,
      categoryX: axisX,
      xAxisText: xAxisText,
      unit: chartUnit,
      additionalData: additionalData
    }
  ];
  return chartItems;
};

export const formatBytes = function(values) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let bytes = values[0];
  if (bytes == 0) return [[0, " Byte"]];
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
  const finalValue = (bytes / Math.pow(1000, i).toFixed(1)).toFixed(1);
  return [[finalValue, sizes[i]]];
};

export const formatBytesSingle = function(values) {
  let bytesString = "";
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (values === 0) {
    bytesString = "0 KB";
  } else {
    const c = parseInt(Math.floor(Math.log(values) / Math.log(1000)));
    if (c === 0) {
      bytesString = values + sizes[c];
    } else {
      bytesString = (values / Math.pow(1000, c)).toFixed(1) + sizes[c];
    }
  }
  return bytesString;
};

export const valueFormatter = function(value, category) {
  if (category === "datatransfer") {
    return formatBytes(value);
  } else if (category === "datathroughput") {
    return formatDataThroughput(value);
  } else if (category === "number") {
    return formatNumber(value);
  } else if (category === "percentage") {
    return formatPercentage(value);
  }
};

export const valueFormatterSingle = function(value, category) {
  if (category === "datatransfer") {
    return formatBytesSingle(value);
  } else if (category === "datathroughput") {
    return formatDataThroughputSingle(value);
  } else if (category === "number") {
    return formatNumberSingle(value);
  } else if (category === "percentage") {
    return formatPercentageSingle(value);
  }
};

export function formatDataThroughputSingle(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [
    " kbps",
    " Mbps",
    " Gbps",
    " Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps"
  ];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);
  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export const formatDataThroughput = function(values) {
  const sizes = [
    "bps",
    "Kbps",
    "Mbps",
    "Gbps",
    "Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps"
  ];
  let bytes = values[0];
  if (bytes == 0) return [[0, " bps"]];
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)), 10);
  const finalValue = (bytes / Math.pow(1000, i).toFixed(1)).toFixed(1);
  return [[finalValue, sizes[i]]];
};

export const formatNumber = function(number) {
  let numberArray = [];
  let tempNumber = [];
  let SI_POSTFIXES = ["", "k", "M", "B", "T", "P", "E"];
  let tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if(tier == 0) return number;
  let postfix = SI_POSTFIXES[tier];
  let scale = Math.pow(10, tier * 3);
  let scaled = number / scale;
  let formatted = scaled.toFixed(1) + "";
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2);
  tempNumber.push(formatted);
  tempNumber.push(postfix);
  numberArray.push(tempNumber);
  return numberArray;
};

export const formatNumberSingle = function(number) {
  let numberArrayString = "";
  let tempNumber = [];
  let SI_POSTFIXES = ["", "k", "M", "B", "T", "P", "E"];
  let tier = (Math.log10(Math.abs(number)) / 3) | 0;
  // if(tier == 0) return number;
  let postfix = SI_POSTFIXES[tier];
  let scale = Math.pow(10, tier * 3);
  let scaled = number / scale;
  let formatted = scaled.toFixed(1) + "";
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2);
  tempNumber.push(formatted);
  tempNumber.push(postfix);
  numberArrayString = tempNumber.join("");
  return numberArrayString;
};

export const formatPercentage = function(number) {
  let percentageArray = [];
  let tempPercentage = [];
  let percentage = number * 100;

  tempPercentage.push(percentage.toFixed(2));
  tempPercentage.push("%");
  percentageArray.push(tempPercentage);

  return percentageArray;
};

export const formatPercentageSingle = function(number) {
  let percentageArrayString = "";
  let tempPercentage = [];
  let percentage = number * 100;

  tempPercentage.push(percentage.toFixed(2));
  tempPercentage.push("%");
  percentageArrayString = tempPercentage.join("");

  return percentageArrayString;
};

export const formatData = function(data, unitCategory) {
  let tmpArray = [];
  for (let ii = 0, jj = data.length; ii < jj; ii++) {
    let formatedData = valueFormatter([data[ii].value], unitCategory);
    tmpArray.push({
      value: formatedData[0][0] + " " + formatedData[0][1],
      timestamp: data[ii].timestamp
    });
  }
  return tmpArray;
};
