import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularIndeterminate from "../../components/Loader/CircularLoader";
import LinearIndeterminate from "../../components/Loader/LinearLoader";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

export const styles = {
  chartMarginLeft: {
    marginLeft: "-60px"
  },
  tableMarginLeft: {
    marginLeft: "-60px"
  },
  error: {
    color: "#FF3B30"
  },
  success: {
    color: "#5cb85c"
  },
  pending: {
    color: "#fbb106"
  },
  deleting: {
    color: "#22cabb"
  }
};

export const NORESPONSE = (
  <Typography
    variant="subtitle2"
    color="primary"
    className="message top50per text-center"
  >
    There is no data to show.
  </Typography>
);
export const INPROGRESS = (
  <CircularIndeterminate color="primary" className="loader-center" />
);
export const INPROGRESSSECONDARY = (
  <CircularIndeterminate color="secondary" className="loader-center" />
);
export const INPROGRESS_CIRCULAR_NOCLASS = (
  <CircularIndeterminate color="primary" />
);
export const INPROGRESS_LINEAR = <LinearIndeterminate color="primary" />;
export const INPROGRESSSECONDARY_LINEAR = (
  <LinearIndeterminate color="secondary" />
);
export const loadingChartText = (
  <span className="loader-center-text">Loading chart...</span>
);
export const loadingTableText = (
  <span className="loader-center-text">Loading table data...</span>
);

export const loadingText = (
  <span className="loader-center-text">Loading...</span>
);

export const RELOADPAGE = (
  <React.Fragment>
    <Typography variant="subtitle2" color="primary" className="message">
      Something went wrong. Please refresh the page. If the issue persists,
      contact support.
    </Typography>
    <Button onClick={() => window.location.reload()}>Click to refresh</Button>
  </React.Fragment>
);
export const REDIRECT_MESSAGE = (
  <Paper>
    <Typography
      variant="subtitle2"
      color="primary"
      className="warning margin-bottom-default padding-16"
    >
      Looks like the selected page does not exist.
      <br />
      Click to redirect to dashboard page or you will be automatically
      redirected soon.
    </Typography>
    <Link className="margin-bottom-default display-block padding-16" to="/">
      Redirect to dashboard.
    </Link>
  </Paper>
);

export const staticPlaceholderChart = (
  <Grid container className="placeholder__chart">
    <Grid item xs={12} className="margin-top-default">
      <Paper className="text-center">{INPROGRESS}</Paper>
    </Grid>
  </Grid>
);

export const loaderNoDataTimeOut = timeout => {
  let data = INPROGRESS;
  setTimeout(() => {
    data = NORESPONSE;
  }, timeout || 8000);

  return data;
};

export function placeholderChartDynMessage(object, addClassName, height) {
  return (
    <Grid
      container
      className={"placeholder__chart fullwidth" + addClassName}
      style={{ minHeight: height + "px" }}
    >
      <Grid item xs={12}>
        {!object ? (
          <Paper className="text-center">{loaderNoDataTimeOut(1000)}</Paper>
        ) : (
          <Paper>{NORESPONSE}</Paper>
        )}
      </Grid>
    </Grid>
  );
}

export const staticPlaceholderTable = (
  <Grid container className="placeholder__chart">
    <Grid item xs={12} className="margin-top-default">
      <Paper className="text-center">{INPROGRESS}</Paper>
    </Grid>
  </Grid>
);

export const staticPlaceholderTableWithParam = (addClass, height) => (
  <Grid
    container
    className={"placeholder__chart " + addClass}
    style={{ minHeight: height + "px" }}
  >
    <Grid item xs={12} className="margin-top-16">
      <Paper className="text-center">{INPROGRESS}</Paper>
    </Grid>
  </Grid>
);

export const staticPlaceholderTableWithParamNoPadding = addClass => (
  <Grid container className={"placeholder__chart " + addClass}>
    <Grid item xs={12}>
      <Paper className="text-center">
        {INPROGRESS}
        {loadingText}
      </Paper>
    </Grid>
  </Grid>
);

export const staticPlaceholderTableWithDynMsg = (addClass, msg) => (
  <Grid container className={"placeholder__chart " + addClass}>
    <Grid item xs={12}>
      <Paper>{msg}</Paper>
    </Grid>
  </Grid>
);

export const tableLoaderEmpty = (
  <div className="loader-table__empty">{INPROGRESS}</div>
);
export const loaderDynamicMessage = (message, text) => (
  <div className="loader-table__empty">
    {message}
    {<span className="loader-center-text">{text}</span>}
  </div>
);
