import PropTypes from "prop-types";
import { Component } from "react";
import authClient from "./auth";

class LocationChangeListener extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    if (!!this.context.router) {
      this.handleLocationChange(this.context.router.history.location);
      this.unlisten = this.context.router.history.listen(
        this.handleLocationChange
      );
    }
  }

  handleLocationChange(location) {
    if (location.pathname !== "/login") {
      authClient.checkForAuthentication();
    }
  }

  render() {
    return this.props.children;
  }
}

export default LocationChangeListener;
