import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import "./loginWithToken.scss";
import { Redirect } from "react-router-dom";
import "redux";
import Notification from "../../common/helpers/notification";
import authClient from "../../common/helpers/auth";
import { setApiUrl } from "../../common/branding/brandconfig";
import { session as userSession } from "../../common/helpers/sessionValues";
import AppBar from "@material-ui/core/AppBar";
import Header from "../header/header";
import axios from "axios";
import { deserializeQueryString } from "../../common/helpers/stringHelper";
import { getUserDetailsWithoutLogin } from "../../actions/userDetailsAction";

var pendingXHRRequests = 0;
axios.interceptors.request.use(
  function(config) {
    const elm = document.querySelector(".loader-horizontal");
    pendingXHRRequests++;
    if (pendingXHRRequests !== 0 && !!elm) {
      elm.style.display = "block";
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    pendingXHRRequests--;
    removeLoader();
    return response;
  },
  function(error) {
    pendingXHRRequests--;
    removeLoader();
    return Promise.reject(error);
  }
);

function removeLoader() {
  const elm = document.querySelector(".loader-horizontal");

  if (pendingXHRRequests === 0 && !!elm) {
    elm.style.display = "none";
  }
}

class LoginWithToken extends Component {
  state = {
    redirectToReferrer: null,
    open: false,
    message: "",
    variant: "success",
    btnLabel: "Close"
  };
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);

    this.keyValuePair = deserializeQueryString(this.props.location.search);

    if (!!this.keyValuePair) {
      const token = "Bearer " + this.keyValuePair["token"];
      this.getUrlAndToken(token);
    }
  }

  componentDidMount() {
    localStorage.clear();
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return false;
    }
    this.setState({
      redirectToReferrer: false,
      open: false,
      error: "",
      variant: ""
    });
  };

  async getUrlAndToken(token) {
    await setApiUrl().then(data => {
      const apiUrl = data.apiUrl;
      const _this = this;
      authClient
        .getCountDown(apiUrl, token)
        .then(result => {
          userSession.setSessionItem("apiUrl", apiUrl);
          userSession.setSessionItems(result.data);
          authClient.setExpireInTime(result.data);
          userSession.setSessionItem("token_type", "Bearer");
          const accessToken = "Bearer " + result.data["access_token"];
          _this.props.getUserDetailsWithoutLogin(accessToken, function(res) {});
        })
        .catch(error => {
          const message =
            error.message + ", You will be redirected to login page.";
          _this.setErrorNotification(message);
          _this.setRedirect();
        });
    });
  }

  setRedirect() {
    const _this = this;
    setTimeout(() => {
      _this.setState({
        redirectToReferrer: true
      });
    }, 2000);
  }

  setErrorNotification(message) {
    this.setState({
      open: true,
      message: message,
      variant: "error"
    });
  }

  render() {
    let { redirectToReferrer, open, message, variant, btnLabel } = this.state;
    let { label, userData } = this.props;

    if (!!redirectToReferrer) {
      return <Redirect to="/" />;
    }

    if (!!userData) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <React.Fragment>
        <Notification
          open={open}
          description={message}
          variant={variant}
          close={this.handleClose}
          buttontype="primary"
          buttonlabel={btnLabel}
          buttonsize="small"
        />
        <AppBar
          position="fixed"
          color="default"
          className="appBar"
          elevation={0}
        >
          <div className="height-6">
            <Header toggle={true} label={label} />
          </div>
        </AppBar>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={8} md={7} className="login">
            <Paper xs={12} className="login-paper">
              <h3>
                You are coming from internal tooling, please wait while we
                validate your authenticity.
              </h3>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user.data
});

export default connect(
  mapStateToProps,
  {
    getUserDetailsWithoutLogin
  }
)(LoginWithToken);
