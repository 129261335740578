import {
    ERROR_IN_DISTRIBUTION,
    DELETE_DISTRIBUTION,
    GET_DISTRIBUTION,
    GET_DISTRIBUTIONS,
    UPDATE_DISTRIBUTION,
    ADD_DISTRIBUTION,
    RESET_DISTRIBUTIONS,
    GET_ALL_DISTRIBUTIONS,
    TOP_DIST_IN_MONTH
} from '../actions/types';

const initialState = {
    distributions: null,
    distribution: null,
    deleteResponse: false,
    distributionError: "",
    allDistributions: null,
    topDistInMonth: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case TOP_DIST_IN_MONTH:
            return {
                ...state,
                topDistInMonth: action.data
            }
        case GET_ALL_DISTRIBUTIONS:
            return {
                ...state,
                allDistributions: action.data
            }
        case GET_DISTRIBUTIONS:
            return {
                ...state,
                distributions: action.data
            }
        case UPDATE_DISTRIBUTION:
            return {
                ...state,
                distribution: action.data
            }
        case ADD_DISTRIBUTION:
            return {
                ...state,
                distribution: action.data
            }
        case DELETE_DISTRIBUTION:
            return {
                ...state,
                deleteResponse: action.data
            }
        case GET_DISTRIBUTION:
            return {
                ...state,
                distribution: action.data
            }
        case RESET_DISTRIBUTIONS:
            return {
                ...state,
                distributions: action.data
            }
        case ERROR_IN_DISTRIBUTION:
            return {
                ...state,
                distributionError: action.error
            }
        default:
            return state;
    }
}

