import { combineReducers } from "redux";
import notificationReducer from "./notificationReducer";
import userDetailsReducer from "./userDetailsReducer";
import menuItemReducer from "./menuItemReducer";
import customerOverviewReducer from "./customerOverviewReducer";
import invalidateReducer from "./invalidateReducer";
import configurationReducer from "./configurationReducer";
import originsReducer from "./originsReducer";
import distributionReducer from "./distributionReducer";
import policyReducer from "./policyReducer";
import chartsReducer from "./chartsReducer";
import tilesReducer from "./tilesReducer";
import versionDetailReducer from "./versionDetailReducer";
import countryListReducer from "./countryListReducer";
import distributionStatusReducer from "./distributionStatusReducer";

export default combineReducers({
  notification: notificationReducer,
  user: userDetailsReducer,
  menuItem: menuItemReducer,
  customerOverview: customerOverviewReducer,
  invalidate: invalidateReducer,
  configuration: configurationReducer,
  origins: originsReducer,
  distributions: distributionReducer,
  policies: policyReducer,
  charts: chartsReducer,
  tiles: tilesReducer,
  versionDetails: versionDetailReducer,
  countryList: countryListReducer,
  distributionStatus: distributionStatusReducer
});
