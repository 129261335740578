import React from "react";
import { Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import classNames from "classnames";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  RenderInputWithToolTip,
  RenderPasswordFieldWithTooltip,
  RenderSwitchWithToolTip,
  RenderInputWithOutLabel,
  ClickableTooltip,
} from "../../common/helpers/renderElement";
import { phoneValidator } from "../helpers/stringHelper";

export default class ApiDataMapper {
  // Form type : view & form
  // ViewType : fieldset (default), label, accordion
  createFields(self, key, prop, index, viewType, isImpersonated) {
    let type = "text";
    if (typeof prop !== "object") {
      let name = this.changeCase(key, 0);
      return (
        <div className="fullwidth" key={key}>
          {this.getFormField(self, type, key, prop, name, isImpersonated)}
        </div>
      );
    } else {
      if (!prop) return "";
      let formField = Object.keys(prop).map((nestedKey, i) => {
        let name = this.changeCase(key, 0);
        if (typeof prop[nestedKey] !== "object") {
          return this.getFormField(
            self,
            type,
            nestedKey,
            prop[nestedKey],
            name,
            isImpersonated
          );
        } else {
          return this.createFields(
            self,
            nestedKey,
            prop[nestedKey],
            i,
            viewType,
            isImpersonated
          );
        }
      });

      if (viewType === "accordion") {
        return (
          <ExpansionPanel
            key={key + index + "_accordion"}
            expanded={self.state.expanded === "panel" + index}
            onChange={self.handleAccordionChange("panel" + index)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                key={key + index + "_heading"}
                component="h6"
                color="inherit"
                className="field-group-heading"
              >
                {this.formatWord(key)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{formField}</ExpansionPanelDetails>
          </ExpansionPanel>
        );
      }

      if (viewType === "label") {
        return (
          <React.Fragment key={key + index + "_node"}>
            <Typography
              key={key + index + "_heading"}
              component="h6"
              color="inherit"
              className="field-group-heading"
            >
              {this.formatWord(key)}
            </Typography>
            {formField}
          </React.Fragment>
        );
      }
      return (
        <div className="margin-top-16 fullwidth nested" key={key}>
          <label className="fullWidth display-block-inline position-relative">
            {this.formatWord(key)}
          </label>
          {formField}
        </div>
      );
    }
  }

  changeCase(str, index) {
    let indx = index || 0;
    let string = str.charAt(indx).toUpperCase() + str.substr(indx + 1);
    return string;
  }

  formatWord(key) {
    let string = key.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
    let stringArr = string.split(" ");
    for (let ii = 0, jj = stringArr.length; ii < jj; ii++) {
      if (ii === 0) {
        string =
          stringArr[ii].charAt(0).toUpperCase() + stringArr[ii].substr(1);
      } else {
        string +=
          " " + stringArr[ii].charAt(0).toLowerCase() + stringArr[ii].substr(1);
      }
    }
    return string;
  }
  getFormField(self, type, key, prop, index, isImpersonated) {
    if (
      typeof prop == "string" &&
      key !== "password" &&
      key !== "confirmPassword" &&
      key !== "phone" &&
      key !== "email"
    ) {
      type = "text";
      return this.createTextField(self, key, index, type);
    } else if (typeof prop == "boolean") {
      type = "checkbox";
      return this.createRadioField(self, key, index, prop);
    } else if (
      (key === "password" || key === "confirmPassword") &&
      !isImpersonated
    ) {
      type = "password";
      return this.createPasswordField(self, key, index, type);
    } else if (key === "phone") {
      type = "text";
      return this.extendTextField(self, key, index, type);
    } else if (key === "email") {
      type = "email";
      return this.createTextField(self, key, index, type);
    } else {
      type = "select";
    }
  }

  checkForMatch(password, confirmPassword) {
    if (password === confirmPassword) {
      return "The Confirm password confimation match.";
    } else {
      return "The Confirm password confimation does not match.";
    }
  }

  createRadioField(self, key, index) {
    let name = key + index;
    const { classes } = self.props;

    if (!!self.state.formView || name === "activeActive") return "";

    return (
      <RenderSwitchWithToolTip
        label={this.formatWord(key)}
        classes={classes}
        key={key + index}
        name={name}
        checked={self.state[name]}
        handleRadioChange={(event) => self.handleRadioChange(name, event)}
        value={self.state[name]}
        required
        className="switch-ios"
        error={!self.state[name]}
        toolTipTitle={this.getFormattedToolTip(key, "radio")}
      />
    );
  }

  createPasswordField(self, key, index, type) {
    let name = key + index,
      isConfirmPassword = key,
      dynamicOptions = {};
    if (!!this.checkForValidInput(key)) return "";

    if (!!self.state.formView) {
      return (
        <RenderInputWithOutLabel
          id={name}
          name={name}
          type="text"
          value={self.state[name] || ""}
          key={name}
          disabled
          label={this.formatWord(key)}
          variant="standard"
          toolTipTitle={this.getFormattedToolTip(key)}
        />
      );
    }

    return (
      <React.Fragment>
        <RenderPasswordFieldWithTooltip
          key={name}
          id={name}
          label={this.formatWord(key)}
          name={name}
          placeholder={key}
          type={!!self.state.showPassword ? "text" : "password"}
          value={self.state[name] || ""}
          handleChange={self.handleFieldChange}
          toolTipTitle={key !== "password" && this.getFormattedToolTip(key)}
          {...dynamicOptions}
          className="margin-top-16"
          disabled={!!self.state.formView}
          required
          error={!self.state[name]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="icon button"
                  onClick={self.handleClickShowPassword}
                >
                  {!self.state.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isConfirmPassword !== "confirmPassword" && (
          <>
            <div className="password-container  height-8">
              <LinearProgress
                className={classNames({
                  week: true,
                  filled: !!self.state.passwordWeak,
                })}
                variant="determinate"
                value={100}
              />
              <LinearProgress
                className={classNames({
                  good: true,
                  filled: !!self.state.passwordGood,
                })}
                variant="determinate"
                value={100}
              />
              <LinearProgress
                className={classNames({
                  strong: true,
                  filled: !!self.state.passwordStrong,
                })}
                variant="determinate"
                value={100}
              />
            </div>
            <ClickableTooltip
              toolTipClassName="password-toolTip"
              toolTipTitle={`<strong>Password guide:</strong> <br /><ul>
              <li>At least 12 characters long but 14 or more is better.</li> 
              <li>A combination of uppercase letters, lowercase letters, numbers, and symbols.</li>
              <li>Allowed special characters: !#$%&'*+-/=?^_|~ </li>
              <li>Not a word that can be found in a dictionary or the name of a person, character, product, or organization.</li>
              <li>Significantly different from your previous passwords.</li>
              <li>Easy for you to remember but difficult for others to guess.</li></ul>`}
            />
          </>
        )}
        {key === "confirmPassword" && (
          <span
            className={
              (self.state.passwordStrong ? "text-success" : "text-error") +
              " text-left help-text margin-top-16 margin-bottom-none"
            }
          >
            <strong>Password rule:</strong>
            <br />
            At least 12 characters long.
            <br />A combination of uppercase letters, lowercase letters,
            numbers, and symbols.
          </span>
        )}
      </React.Fragment>
    );
  }

  extendTextField(self, key, index, type) {
    return this.createTextField(self, key, index, type);
  }

  createTextField(self, key, index, type) {
    let name = key + index,
      dynamicOptions = {};
    if (!!this.checkForValidInput(key)) return "";

    if (
      !!self.state.formView ||
      name === "updateDateUpdateDate" ||
      name === "parentParent" ||
      (!!self.state.isProfile && name === "typeType")
    ) {
      return (
        <RenderInputWithOutLabel
          id={name}
          name={name}
          type={type}
          value={self.state[name] || ""}
          key={name}
          disabled
          label={this.formatWord(key)}
          variant="standard"
          toolTipTitle={this.getFormattedToolTip(key)}
        />
      );
    }
    if (name === "phonePhone") {
      return (
        <RenderInputWithToolTip
          placeholder={key}
          id={name}
          name={name}
          type={type}
          value={self.state[name] || ""}
          key={name}
          label={this.formatWord(key)}
          {...dynamicOptions}
          errorMsg={
            phoneValidator(self.state[name])
              ? "Phone format is not recognized"
              : ""
          }
          required
          error={!phoneValidator(self.state[name])}
          handleChange={self.handleFieldChange}
          toolTipTitle={this.getFormattedToolTip(key)}
        />
      );
    }
    return (
      <RenderInputWithToolTip
        placeholder={key}
        id={name}
        name={name}
        type={type}
        value={self.state[name] || ""}
        key={name}
        label={this.formatWord(key)}
        {...dynamicOptions}
        required={name === "faxBillingContact" ? false : true}
        error={name === "faxBillingContact" ? false : !self.state[name]}
        handleChange={self.handleFieldChange}
        toolTipTitle={this.getFormattedToolTip(key)}
      />
    );
  }

  checkForValidInput(key) {
    return key === "id" || key === "customerId";
  }

  getFormattedToolTip(key, type) {
    if (type === "radio") {
      return "Toggle " + this.formatWord(key) + " details.";
    }
    return "Input your " + this.formatWord(key) + " details.";
  }
}

export const apiDataMapper = new ApiDataMapper();
