import {
  ERROR_IN_POLICY,
  DELETE_POLICY,
  GET_POLICY,
  GET_POLICIES,
  UPDATE_POLICY,
  IS_POLICY_MATCH,
  CDN_TAB
} from "./types";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import {
  apiPostCall,
  apiGetCall,
  DEFAULT_ERROR_MESSAGE
} from "../actions/baseActions";

export function resetPolicies() {
  return function(dispatch) {
    dispatch({
      type: GET_POLICIES,
      data: []
    });
  };
}

export function initPolicies() {
  return function(dispatch) {
    dispatch({
      type: GET_POLICIES,
      data: null
    });
  };
}

export function getPolicies(customerId, type, distributionId) {
  if (!type)
    return function(dispatch) {
      dispatch({
        type: ERROR_IN_POLICY,
        error: DEFAULT_ERROR_MESSAGE,
        data: []
      });
    };
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    distributionId +
    "/policies";

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_POLICY,
    typeSuccess = GET_POLICIES,
    method = "GET";
  const callback = function() {};
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function getPolicy(
  customerId,
  type,
  distributionId,
  policyId,
  callback
) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    distributionId +
    "/policies/" +
    policyId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_POLICY,
    typeSuccess = GET_POLICY,
    method = "GET";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function deletePolicy(
  customerId,
  type,
  distributionId,
  policyId,
  callback
) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    distributionId +
    "/policies/" +
    policyId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_POLICY,
    typeSuccess = DELETE_POLICY,
    method = "DELETE";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}
// customerId, newDistributionId, policyId, method, payload
export function updatePolicy(
  customerId,
  type,
  distributionId,
  policyId,
  methd,
  payload,
  callback
) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/distributions/" +
    type +
    "/" +
    distributionId +
    "/policies";

  if (methd === "PUT") {
    url += "/" + policyId;
  }

  const token = authClient.getBearerToken(),
    method = methd,
    typeError = ERROR_IN_POLICY,
    typeSuccess = UPDATE_POLICY,
    contentType = "application/json";
  const postObject = JSON.stringify(payload);

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

export function setTab(val) {
  return function(dispatch) {
    dispatch({
      type: CDN_TAB,
      tab: val
    });
  };
}

export function setDistributionId(distributionId) {
  return function(dispatch) {
    dispatch({
      type: IS_POLICY_MATCH,
      data: distributionId
    });
  };
}
