import React, { Component } from "react";
import Login from "../../components/login/login";
import LoginWithToken from "../../components/loginWithToken/loginWithToken";
import Dashboard from "../../components/dashboard/dashboard";
import Configuration from "../../components/configuration/configuration";
import Statistics from "../../components/Statistics/Statistics";
import Profile from "../../components/profile/Profile";
import ForgotPassword from "../../components/forgot/forgot";
import authClient from "../../common/helpers/auth";
import UserManagement from "../../components/UserManagement/UserManagement";
import Invalidation from "../../components/Invalidation/Invalidation";
import Swagger from "../../components/SwaggerUI/SwaggerUI";
import Certificates from "../../components/Certificates/Certificates";
import Origins from "../../components/Origins/Origins";
import Distribution from "../../components/Distribution/Distribution";
import Policies from "../../components/Policies/Policies";
import RequestStatistics from "../../components/RequestStatistics/RequestStatistics";
import StatusCodes from "../../components/StatusCodes/StatusCodes";
import MonthlyTotals from "../../components/MonthlyTotals/MonthlyTotals";
import Error404 from "../../components/Error/error404";
import Debugging from "../../components/Tools/Debugging";

import { Route, Redirect, Switch } from "react-router-dom";

const routes = [
  {
    path: "/Configuration",
    component: Configuration,
    nomatch: true,
    routes: [
      {
        path: "/configuration/certificates",
        component: Certificates,
      },
      {
        path: "/configuration/distributions/:type/",
        component: Distribution,
      },
      {
        path: "/configuration/distribution/:type/:distributionId/policies",
        component: Policies,
      },
      {
        path: "/configuration/origins/:type",
        component: Origins,
      },
    ],
  },
  {
    path: "/Statistics",
    component: Statistics,
    nomatch: true,
    routes: [
      {
        path: "/statistics/analytics",
        component: RequestStatistics,
      },
      {
        path: "/statistics/status-codes",
        component: StatusCodes,
      },
      {
        path: "/statistics/monthly-totals",
        component: MonthlyTotals,
      },
    ],
  },
  {
    path: "/tools",
    component: Statistics,
    nomatch: true,
    routes: [
      {
        path: "/tools/debug",
        component: Debugging,
      },
    ],
  },
];

class RouteService extends Component {
  render() {
    let { label, userData, role } = this.props;
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => <Login {...props} label={label} />}
        />
        <Route
          path="/login"
          render={(props) => <Login {...props} label={label} />}
        />
        <Route
          path="/loginWithToken"
          render={(props) => <LoginWithToken {...props} label="label" />}
        />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route
          path="/activate-user"
          render={(props) => (
            <ForgotPassword {...props} label={label} title="Activate user" />
          )}
        />

        <Route
          path="/forgot-2fa"
          render={(props) => (
            <ForgotPassword {...props} label={label} title="Forgot 2FA" />
          )}
        />

        <PrivateRoute
          path="/dashboard"
          component={Dashboard}
          label={label}
          userData={userData}
          role={role}
        />
        <PrivateRoute
          path="/profile"
          component={Profile}
          label={label}
          userData={userData}
          role={role}
        />
        <PrivateRoute
          path="/user-management"
          component={UserManagement}
          label={label}
          userData={userData}
          role={role}
        />
        <PrivateRoute
          path="/swagger-ui"
          component={Swagger}
          label={label}
          userData={userData}
          role={role}
        />
        <PrivateRoute
          path="/invalidate"
          component={Invalidation}
          label={label}
          userData={userData}
          role={role}
        />
        {routes.map((route, i) => (
          <RouteWithSubRoutes
            key={i}
            {...route}
            label={label}
            userData={userData}
            role={role}
            hideSideMenu={false}
          />
        ))}
        <RedirectPage label={label} userData={userData} role={role} />
      </Switch>
    );
  }
}

export function RouteWithSubRoutes({
  label,
  userData,
  role,
  hideSideMenu,
  ...route
}) {
  return (
    <Route
      path={route.path}
      render={(props) =>
        authClient.isAuthenticated() ? (
          <div className="container">
            <div className="container-body">
              <route.component
                {...props}
                routes={route.routes}
                label={label}
                userData={userData}
                role={role}
              />
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export function RedirectPage({ label, userData, role, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authClient.isAuthenticated() ? (
          <div className="container">
            <div className="container-body">
              <Error404 />
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export function PrivateRoute({
  component: Component,
  fullname,
  label,
  userData,
  role,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authClient.isAuthenticated() ? (
          <div className="container">
            <div className="container-body">
              <Component
                {...props}
                label={label}
                userData={userData}
                role={role}
              />
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default RouteService;
