import {
    AVAILABLE_COUNTRYLIST,
    SELECTED_COUNTRYLIST
} from './types';

export function setAvailableCountryList(list) {
    return function (dispatch) {
        dispatch({
            type: AVAILABLE_COUNTRYLIST,
            availableCountryList: list
        })
    }
}

export function resetAvailableCountryList() {
    return function (dispatch) {
        dispatch({
            type: AVAILABLE_COUNTRYLIST,
            availableCountryList: null
        })
    }
}

export function setSelectedCountryList(list) {
    return function (dispatch) {
        dispatch({
            type: SELECTED_COUNTRYLIST,
            selectedCountryList: list
        })
    }
}

export function resetSelectedCountryList() {
    return function (dispatch) {
        dispatch({
            type: SELECTED_COUNTRYLIST,
            selectedCountryList: null
        })
    }
}

