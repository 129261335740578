import React, { Component } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

export default class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  generateUniqueNumber(number) {
    var random = Math.floor(Math.random() * number) + 1;
    return random;
  }

  render() {
    const {
      order,
      orderBy,
      rows,
      selectAllAction,
      showSelectAll,
      rowCount,
      numSelected,
      hideSortIcon,
      hideNumbers
    } = this.props;

    const additionalTools = (
      <Checkbox
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={numSelected === rowCount}
        onChange={selectAllAction}
      />
    );

    return (
      <React.Fragment>
        <TableHead>
          <TableRow>
            {!showSelectAll && !hideNumbers && <TableCell>#</TableCell>}
            {showSelectAll && (
              <TableCell padding="none">{additionalTools}</TableCell>
            )}
            {rows.map(
              (row, index) => (
                <TableCell
                  key={
                    "cell_" +
                    this.generateUniqueNumber(100) +
                    this.generateUniqueNumber(500) +
                    index
                  }
                  padding={row.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  {!hideSortIcon && (
                    <Tooltip
                      title="Sort"
                      placement={row.numeric ? "bottom-end" : "bottom-start"}
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={this.createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  )}
                  {!!hideSortIcon && row.label}
                </TableCell>
              ),
              this
            )}
          </TableRow>
        </TableHead>
      </React.Fragment>
    );
  }
}
