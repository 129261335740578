import { valueFormatter } from "./chart";

export const BYTESDELIVERED = "bytesdelivered";
const PERCENTILE95 = "percentile95";
export const REQUESTS = "requests";
const DATATHROUGHPUT = "datathroughput";
const PERCENTAGE = "percentage";
export const CACHEHIT = "cachehit";
export const DATATRANSFER = "datatransfer";
const NUMBER = "number";
export const CACHEIT = "cacheit";

export const tilesVMMapperNew = (self, data, metric) => {
  const unitCategory = getUnitCategory(metric);
  const formattedData = valueFormatter([data.value], unitCategory);
  const tileData = {
    subheading: getSubHeadingNew(metric),
    heading: formattedData[0].join(" "),
    spacing: 3
  };
  return tileData;
};
function getUnitCategory(metric) {
  switch (metric) {
    case "datatransfer":
      return DATATRANSFER;
    case "requests":
      return NUMBER;
    case "95th":
      return DATATHROUGHPUT;
    default:
      return PERCENTAGE;
  }
}

function getSubHeadingNew(target) {
  switch (target) {
    case "datatransfer":
      return "delivered";
    case "requests":
      return "Total requests";
    case "95th":
      return "95th percentile";
    default:
      return "Cachehit";
  }
}
export function getTargetName(subheading) {
  switch (subheading) {
    case "delivered":
      return BYTESDELIVERED;
    case "Cachehit":
      return CACHEHIT;
    case "Total requests":
      return REQUESTS;
    default:
      return BYTESDELIVERED;
  }
}

export const flattenTileFormatting = data => {
  let newArray = [];
  for (let ii = 0, jj = data.length; ii < jj; ii++) {
    newArray[ii] = {
      target: data[ii].target,
      value: formatTileValue(data[ii].target, data[ii].points[0].value),
      timestamp: data[ii].points[0].timestamp
    };
  }
  return newArray;
};

export function formatTileValue(target, value) {
  let formatedValue;
  if (target === BYTESDELIVERED) {
    formatedValue = valueFormatter([value], DATATRANSFER);
  } else if (target === PERCENTILE95) {
    formatedValue = valueFormatter([value], DATATHROUGHPUT);
  } else if (target === REQUESTS) {
    formatedValue = valueFormatter([value], NUMBER);
  } else {
    formatedValue = valueFormatter([value], PERCENTAGE);
  }

  return formatedValue;
}
