import React, { Component } from "react";
import { scrollInToView } from "../../common/helpers/tableRulesAndStyles";
import { GET_ALL_DISTRIBUTIONS } from "../../actions/types";
import {
  getSelectControlValues,
  extendArray,
  MULTI_CDN_VOLUME,
  MULTI_CDN_PREMIUM
} from "../../common/constants/policies";
import { valueFormatter } from "../../common/constants/chart";
import { getFullNameMonth, getFullYear } from "../../common/helpers/dateHelper";
import { eachWordUpperCase } from "../../common/helpers/stringHelper";
import { INPROGRESSSECONDARY } from "../../common/helpers/loader";

const MANDATORY = "Please fill the required field(s)!";
const DISTLIMIT = 10;
const statusTypes = {
  completed: "completed",
  pending: "pending",
  error: "error",
  deleting: "deleting"
};

export default class BaseComponent extends Component {
  defaultErrorMessage = "Oops!! something went wrong, try again.";
  tableListFull;

  constructor(props, tabs) {
    super(props);
    this.tabs = tabs;
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }
  componentWillMount() {
    this.tableListFull = null;
  }

  componentWillUnmount() {
    localStorage.removeItem("SwitchCustomerDetail");
    localStorage.removeItem("SwitchUserDetail");
  }

  handleAutoSelectChange(selected, key, self, parent, customerId, clearValue) {
    if (!!selected && !!selected.value) {
      parent[key] = selected.value; //updating value
      self.setState({ parent, clearValue: false });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (
      !!this.state &&
      this.state.orderBy === property &&
      this.state.order === "desc"
    ) {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleCheckBoxClick = (event, id, name, obj) => {
    this.selectItem(id, name, obj);
  };

  switchView() {
    this.setState({
      isMandatoryView: !this.state.isMandatoryView
    });
  }

  getItemNumber(page, index, rowsPerPage) {
    const pageNumber = !!page ? rowsPerPage * page + index + 1 : index + 1;
    return pageNumber;
  }
  selectItem(id, obj) {
    let newSelected = [];
    if (id.toString() === this.state[obj].toString()) {
      newSelected.splice(0, 1);
    } else {
      newSelected = [id];
    }
    this.setState({ [obj]: newSelected });
  }

  handleClick = (event, id, obj) => {
    if (event.target.nodeName.toLowerCase() !== "input") {
      this.selectItem(id, obj);
    }
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
      selected: []
    });
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value,
      selected: []
    });
    scrollInToView(event.target.value);
  };

  handleRadioNestedChange = (radio, event, parent) => {
    event.stopPropagation();
    parent[radio] = event.target.checked; //updating value
    this.setState({ parent });
  }


  handleStateChange(e, self, parent, key, required) {
    parent[key] = e.target.value; //updating value
    self.setState({ parent });
  }

  handleFormDialogToggle() {
    this.setState({ openFormDialog: !this.state.openFormDialog });
  }

  toggleNotification(message, type, label) {
    this.notificationVisibility({
      error: message,
      variant: type,
      label: label,
      color: "inherit"
    });
  }

  notificationVisibility(obj) {
    this.setState(obj);
    this.props.showNotification();
  }

  getLoggedInCustomerRole(role) {
    return role || localStorage.role;
  }
  setIconElement(styles, value) {
    switch (value) {
      case statusTypes.completed:
        return (
          <i className="material-icons" style={styles.success}>
            check_circle_outline
          </i>
        );
      case statusTypes.pending:
        return (
          <i className="material-icons" style={styles.pending}>
            schedule
          </i>
        );
      case statusTypes.deleting:
        return (
          <i className="material-icons" style={styles.deleting}>
            clear-all
          </i>
        );
      case statusTypes.error:
        return (
          <i className="material-icons" style={styles.error}>
            error
          </i>
        );
      default:
        return (
          <i className="material-icons" style={styles.pending}>
            schedule
          </i>
        );
    }
  }

  evenOdd(value) {
    const rem = value % 2;
    if (!!rem) {
      return "-odd";
    } else {
      return "-even";
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return false;
    }
    this.props.hideNotification();
  };

  handleDialogToggle() {
    this.setState({ openDialog: !this.state.openDialog });
  }

  handleNext() {
    const { tabValue } = this.state;
    if (tabValue < this.tabs - 1 && this.checkForRequiredFields()) {
      this.setState({
        tabValue: tabValue + 1,
        progressStatus: (tabValue + 1) / (this.tabs - 1)
      });
      document.querySelector(".dialog-form-body").scrollTo(0, 0);
    }
  }

  handlePrevious() {
    const { tabValue } = this.state;
    if (tabValue > 0) {
      this.setState({
        tabValue: tabValue - 1,
        progressStatus: (tabValue - 1) / (this.tabs - 1)
      });
      document.querySelector(".dialog-form-body").scrollTo(0, 0);
    }
  }

  getSecordaryLoader() {
    return <div className="text-center">{INPROGRESSSECONDARY}</div>;
  }

  formatPageHeading(type) {
    let string = !!type ? this.returnShortText(type) : "";
    return string;
  }

  removeItem(self, obj, index) {
    if (obj.length >= 1) {
      obj.splice(index, 1);
    }
    self.setState({ domains: obj });
  }

  checkForValidDomain(self, index) {
    const regex = /^([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    const domain = self.state.domains[index];
    return !regex.test(domain);
  }

  addItem(self, obj) {
    if (obj.length < 10) {
      obj.push("");
    }
    self.setState({ domains: obj });
  }

  handleTabChange(event, tabValue) {
    if (tabValue > this.state.tabValue) {
      if (this.checkForRequiredFields(event)) {
        this.setState({
          tabValue,
          progressStatus: tabValue / (this.tabs - 1)
        });
      }
    } else {
      this.setState({
        tabValue,
        progressStatus: tabValue / (this.tabs - 1)
      });
    }
    const docBody = document.querySelector(".dialog-form-body");
    if (!!docBody) {
      docBody.scrollTo(0, 0);
    }
  }

  scrollToSelectedElm(coordinates) {
    let topAdjustment = 80;
    document.querySelector(".App-backgroundElm-1").scrollTop =
      coordinates.y + topAdjustment;
  }

  setRequiredSymbolAndScroll(tabValue) {
    if (!tabValue) return;
    const selectedTab = ".tab_" + tabValue;
    const elm = document.querySelector(selectedTab);
    if (elm.textContent.indexOf("*") === -1) {
      elm.textContent = elm.textContent + " *";
    }
    elm.click();
  }

  setSelected = id => {
    this.setState({
      selected: [id]
    });
  };
  checkForRequiredFields() {
    const currentView = document.querySelector(".react-scroll-view-container");
    if (!currentView) return true;
    let requiredElms = currentView.querySelectorAll("span.required");
    if (!!requiredElms.length) {
      requiredElms = currentView.querySelectorAll("span.required");
      if (!!requiredElms) {
        // this.setRequiredSymbolAndScroll(this.state.tabValue);
        this.toggleNotification(MANDATORY, "error", "Close");
        return false;
      }
      return true;
    }
  }

  updateSwitchCustomerDetails(selectedCustomer) {
    localStorage.setItem(
      "SwitchCustomerDetail",
      JSON.stringify(selectedCustomer)
    );
    this.props.switchUserDetails(selectedCustomer);
  }

  formatDataForChartPIE(response, groupBy, formatText) {
    let data = response[0] || response;
    let chartDataAndKeys;
    if (!!data && !!data.series.length) {
      chartDataAndKeys = this.extractAsKeyValuePIE(
        data.series,
        groupBy,
        formatText
      );
      return chartDataAndKeys;
    }
    return {
      keys: [],
      data: []
    };
  }

  handleClearSearch(name, typeSuccess) {
    this.setState({
      [name]: ""
    });
    this.props.applyFilteredData(this.tableListFull, typeSuccess);
  }

  async handleSearchTable(e, object, typeSuccess) {
    if (!this.tableListFull) {
      this.tableListFull = object;
    }

    const value = e.target.value;
    this.setState({
      [e.target.name]: value
    });

    if (value.length > 2) {
      await this.props.applyFilteredData(
        this.getFilterBy(object, value),
        typeSuccess
      );
    } else {
      await this.props.applyFilteredData(this.tableListFull, typeSuccess);
    }
  }

  getFilterBy(object, value) {
    let filteredElements = object
      .filter(item => {
        for (let key in item) {
          if (
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(value.toLowerCase())
          ) {
            return item[key];
          }
        }
      })
      .map(item => item);
    return filteredElements;
  }

  formatDataForTiles(response, nKey1, nKey2, metricInput, callback) {
    let data = response[0] || response;
    let chartOnlyData = [];
    if (!!data && !!data.series && !!data.series.length) {
      for (let ii = 0, jj = data.series["length"]; ii < jj; ii++) {
        for (let key in data.series[ii]) {
          chartOnlyData.push({
            [nKey1]: this.extractOnlyValue(data.series[ii][key]),
            [nKey2]: metricInput
          });
        }
      }
    } else {
      chartOnlyData.push({
        [nKey1]: 0,
        [nKey2]: metricInput
      });
    }
    callback(chartOnlyData);
  }

  extractOnlyValue(obj) {
    for (let ii = 0, jj = obj.length; ii < jj; ii++) {
      for (let key in obj[ii]) {
        return obj[ii][key];
      }
    }
  }

  getFormatAsPerUnitCategory(value, unitCategory) {
    return valueFormatter([value], unitCategory);
  }

  getGrandTotalAndFormat(array, unitCategory, metric) {
    if (!this.checkForValidAvgMetric({ metric: metric })) return null;
    let grandTotal = 0;
    for (let ii = 0, jj = array.length; ii < jj; ii++) {
      let value = array[ii][0].value || array[ii].value;
      if (!value) {
        value = 0;
      }
      grandTotal += parseFloat(value);
    }

    const string = this.getFormatAsPerUnitCategory(grandTotal, unitCategory);

    return !!string ? string.join(" ").replace(",", " ") : "";
  }

  getCustomerType(RESELLER) {
    const { role } = this.props;
    const endpoint = role === RESELLER ? "/reseller" : "/customer";
    return endpoint;
  }

  setStartEndDates(hasStartFormatted, callback) {
    if (!this.isCustomDate && !hasStartFormatted) {
      this.handleHourChangeNewEndPoint(this.start, callback);
    } else {
      if (!!callback) {
        callback(this.start, this.end);
      }
    }
  }

  setGranularityAsPerDate(hasStartFormatted) {
    let granularity = "5m";
    const _this = this;
    this.setStartEndDates(hasStartFormatted, function(startDate, endDate) {
      if (!_this.state.granularity || _this.state.granularity === "auto") {
        granularity = _this.granularityAsPerCustomDate(startDate, endDate);
      } else {
        granularity = _this.state.granularity;
      }
    });
    console.log(granularity);
    return granularity;
  }

  checkForValidDistributionAndGroupBy(payload, selectedDistribution) {
    const { allDistributions } = this.props;
    const { groupBy } = this.state;

    if (
      !!allDistributions &&
      (allDistributions.length <= DISTLIMIT || selectedDistribution === "*") &&
      (!groupBy || groupBy === "none")
    ) {
      this.setState({
        groupBy: null
      });
    }
    if (!!groupBy && groupBy !== "none") {
      payload.groupBy = groupBy;
    }
  }

  checkForValidValue(value) {
    if (!!value && value.toLowerCase() === "none") {
      return null;
    }
    return value;
  }

  setPayloadStatistics(metric, granularity, groupBy) {
    const { selectedDistribution } = this.state;
    const payload = {
      metric: metric,
      start: this.start,
      end: this.end
    };

    if (!!granularity) {
      payload.granularity = granularity;
    }

    if (!groupBy) {
      this.checkForValidDistributionAndGroupBy(payload, selectedDistribution);
    }

    if (!!this.checkForValidValue(groupBy)) {
      payload.groupBy = this.checkForValidValue(groupBy);
    }

    if (selectedDistribution !== "*") {
      payload.distributionId = selectedDistribution;
    }

    return payload;
  }

  mergeKeysWithValue(masterArray, array, unitCategory) {
    const allValues = this.removeMultipleFirstLevelObjects(masterArray.data);
    let newMasterArray = [];
    for (let ii = 0, jj = allValues.length; ii < jj; ii++) {
      //keys[0] === value
      const value = allValues[ii][array[0]];
      //TODO: Make is dynamic : loop through array
      newMasterArray.push({
        [array[1]]: masterArray.keys[ii],
        [array[0]]: !!unitCategory
          ? this.getFormatAsPerUnitCategory(value, unitCategory)
              .join(" ")
              .replace(",", " ")
          : !!value && typeof value === "object"
          ? value.join(" ").replace(",", " ")
          : value,
        [array[2]]: masterArray.entity[ii]
      });
    }
    return newMasterArray;
  }

  getDisplayTextInTable(array, index, groupBy) {
    if (
      !!array &&
      Object.keys(array).length === 3 &&
      groupBy !== "distribution"
    ) {
      if (array.keys.length === 1) {
        return eachWordUpperCase(array.entity[index]) || array.keys[index];
      }

      return array.keys[index] || "All";
    }
    const distId = array.entity[index] || array.keys[index];
    return this.setMatchingDesciption(distId) || "All";
  }

  mergeKeyAndValue(masterArray, nKey1, nKey2, groupBy) {
    const allValues = this.removeMultipleFirstLevelObjects(masterArray.data);
    let newMasterArray = [];
    for (let ii = 0, jj = allValues.length; ii < jj; ii++) {
      const value = allValues[ii][nKey2];
      newMasterArray.push({
        [nKey1]: this.getDisplayTextInTable(masterArray, ii, groupBy),
        [nKey2]:
          !!value && typeof value === "object"
            ? value.join(" ").replace(",", " ")
            : value
      });
    }
    return newMasterArray;
  }

  handleToggleAttribute(name) {
    this.setState({
      [name]: !this.state[name]
    });
  }

  getTotalResultsView(_this, res, unitCategory) {
    let response = res[0] || res;
    let totalResult;
    if (_this.isValidObject(response)) {
      const dataAndKey = _this.formatDataForChart(
        response,
        "value",
        "name",
        unitCategory,
        true
      );
      totalResult = {
        data: dataAndKey.data,
        keys: dataAndKey.keys,
        entity: dataAndKey.entity
      };
    }
    return totalResult;
  }

  isValidObject(response) {
    return (
      typeof response === "object" &&
      !!response.series &&
      !!response.series.length
    );
  }

  getTextValueFormat(obj, keys) {
    let object = [{ value: "*", text: "All distributions" }];
    for (let ii = 0, jj = obj.length; ii < jj; ii++) {
      object.push({
        value: obj[ii][keys[0]],
        text: obj[ii][keys[1]]
      });
    }
    return object;
  }

  removeMultipleFirstLevelObjects(masterArray) {
    let flatArray = [];
    for (let ii = 0, jj = masterArray.length; ii < jj; ii++) {
      if (!!masterArray[ii][0]) {
        flatArray.push(masterArray[ii][0]);
      } else {
        flatArray.push(masterArray[ii]);
      }
    }
    return flatArray;
  }

  setMatchingDesciption(id, array) {
    const { allDistributions } = this.props;
    const arrayDist = array || allDistributions;
    let arr = arrayDist.filter(item => item.id === id);
    if (!!arr.length) {
      return (!!arr[0].description) ? arr[0].description : arr[0].id;
    }
    return "";
  }

  getDateFormatSingleArray(obj) {
    let object = [];
    for (let ii = 0, jj = obj.length; ii < jj; ii++) {
      object.push({
        value: obj[ii],
        text: getFullNameMonth(obj[ii]) + ", " + getFullYear(obj[ii])
      });
    }
    return object;
  }

  getGroupByHeading(array, key, groupBy, noFormatting) {
    const groupByFormatted =
      groupBy.charAt(0).toUpperCase() + groupBy.substr(1);
    const keyFormatted = eachWordUpperCase(key);
    array.push(keyFormatted);

    if (!!noFormatting) {
      return !!array ? keyFormatted : "";
    }

    return !!array
      ? `[bold]${groupByFormatted}:[/] ${keyFormatted}\n`
      : `[bold]${groupByFormatted}:[/] All\n`;
  }

  hasMatchingDistribution(key, noFormatting) {
    const { allDistributions } = this.props;
    let matchedDesc;
    if (!!allDistributions && key !== "all") {
      matchedDesc = allDistributions.filter(item => item.id === key);
    }

    if (!!noFormatting) {
      return !!matchedDesc && !!matchedDesc[0]
        ? matchedDesc[0].description
        : "";
    }

    return !!matchedDesc && !!matchedDesc.length
      ? `[bold]Distribution:[/] ${matchedDesc[0].description}\n`
      : `[bold]Distribution:[/] All\n`;
  }

  formatDataForChart(response, nKey1, nKey2, unitCategory, noFormatting) {
    const groupBy = this.state.groupBy || "distribution";
    let data = response[0] || response;
    if (!!data && !!data.series.length) {
      let chartOnlyData = [],
        chartOnlyKeys = [],
        chartOnlyEntity = [];
      for (let ii = 0, jj = data.series["length"]; ii < jj; ii++) {
        for (let key in data.series[ii]) {
          if (groupBy === "distribution" || groupBy === "none") {
            const matchedDes = this.hasMatchingDistribution(key, noFormatting);
            chartOnlyKeys.push(matchedDes);
          }
          if (groupBy !== "distribution" && groupBy !== "none") {
            const matchedHeading = this.getGroupByHeading(
              [],
              key,
              groupBy,
              noFormatting
            );
            chartOnlyKeys.push(matchedHeading);
          }
          chartOnlyEntity.push(key);
          chartOnlyData.push(
            this.extractAsKeyValue(
              data.series[ii][key],
              nKey1,
              nKey2,
              unitCategory
            )
          );
        }
      }
      return {
        keys: chartOnlyKeys,
        data: chartOnlyData,
        entity: chartOnlyEntity
      };
    }
  }

  extractAsKeyValuePIE(array, groupBy, formatText) {
    let keys = [];
    let values = [];
    if (!!array && !!array.length) {
      for (let ii = 0, jj = array.length; ii < jj; ii++) {
        for (let key in array[ii]) {
          values.push({
            value: array[ii][key][0].total,
            [groupBy]: key.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, " ")
          });
          keys.push(key);
        }
      }
    }
    return {
      keys: keys,
      data: [values]
    };
  }

  extractAsKeyValue(array, nKey1, nKey2, unitCategory) {
    let keyAndValue = [];
    if (!!array && !!array.length) {
      for (let ii = 0, jj = array.length; ii < jj; ii++) {
        for (let key in array[ii]) {
          keyAndValue.push({
            [nKey1]: !!unitCategory
              ? valueFormatter([array[ii][key]], unitCategory)
              : array[ii][key],
            [nKey2]: new Date(key).getTime()
          });
        }
      }
    }
    return keyAndValue;
  }

  checkForEntitlementGranularity(list, entitlement) {
    const machedEntitlement = this.hasMatchingEntitlementWithoutPromise(
      entitlement
    );
    if (machedEntitlement && !!list && !!list.length) {
      const sliced = list.slice(3);
      return sliced;
    }
    return list;
  }

  checkForEntitlementGroupBy(list, entitlement, maxLength) {
    const machedEntitlement = this.hasMatchingEntitlementWithoutPromise(
      entitlement
    );
    if (machedEntitlement && !!list && list.length <= maxLength) {
      list.push({ value: "pop", text: "POP" });
    }
    return list;
  }

  async hasMatchingEntitlement(argument) {
    return this.hasMatchingEntitlementWithoutPromise(argument);
  }

  hasMatchingEntitlementWithoutPromise(argument) {
    const arg = typeof argument === "object" ? argument : [argument];
    const entitlements = localStorage.getItem("entitlements");
    if (!!entitlements && !!arg.length) {
      for (let ii = 0, jj = arg.length; ii < jj; ii++) {
        const matched =
          localStorage.getItem("entitlements").indexOf(arg[ii]) != -1;
        if (!!matched) return matched;
      }
    } else {
      return false;
    }
  }

  checkForRequiredFieldsAllViews(container) {
    const selector = container || ".react-scroll-view-container";
    const allViews = document.querySelector(selector);
    if (!allViews) return true;
    const requiredElms = allViews.querySelectorAll("span.required");
    if (!!requiredElms.length) {
      const currentTab = parseInt(requiredElms[0].getAttribute("data-tab"));
      this.toggleNotification(MANDATORY, "error", "Close");
      this.setState({ tabValue: currentTab });
      const containerElm = requiredElms[0].parentNode.parentNode;
      if (!!containerElm) {
        containerElm.scrollIntoView();
      }
      return false;
    }
    return true;
  }

  activateImpersonate(customerId) {
    this.calledOnce = false;
    this.callActive = false;
    this.updateChart("none", customerId);
    const { getUserDetails } = this.props;
    if (!!getUserDetails) {
      getUserDetails(customerId);
    }
  }

  getAllDistributionGroupsLocal(customerId, callback) {
    const _this = this;
    const callBack = callback || function() {};

    const actionType = GET_ALL_DISTRIBUTIONS;
    _this.props.getAllDistributionGroups(
      customerId,
      actionType,
      callBack,
      function(res) {
        callBack();
      }
    );
  }

  getOrigins(customerId, type) {
    if (!this.state.origins) {
      getSelectControlValues(customerId, "/origins/simple").then(
        simpleOrigins => {
          if (type === MULTI_CDN_VOLUME || type === MULTI_CDN_PREMIUM) {
            const finalOrigins = extendArray([], simpleOrigins, true);
            this.setState({ origins: finalOrigins });
            return true;
          }
          getSelectControlValues(customerId, "/origins/advanced").then(
            advancedOrigins => {
              const mergedSimpleAdvancedOrigins = extendArray(
                simpleOrigins || [],
                advancedOrigins,
                false
              );
              getSelectControlValues(customerId, "/origins/objectstorage").then(
                values => {
                  const finalOrigins = extendArray(
                    mergedSimpleAdvancedOrigins || [],
                    values,
                    true
                  );
                  this.setState({ origins: finalOrigins });
                }
              );
            }
          );
        }
      );
    }
  }

  getOriginGroups(customerId) {
    if (!this.state.originGroups) {
      getSelectControlValues(customerId, "/origins/groups").then(values => {
        this.setState({ originGroups: values });
      });
    }
  }

  getSingleCDN(customerId) {
    if (!this.state.singleCDNs) {
      getSelectControlValues(customerId, "/distributions/single-cdn").then(
        values => {
          this.setState({ singleCDNs: values });
        }
      );
    }
  }

  getShieldCDN(customerId) {
    if (!this.state.shieldCDNs) {
      getSelectControlValues(customerId, "/distributions/shield-cdn").then(
        values => {
          this.setState({ shieldCDNs: values });
        }
      );
    }
  }

  createMapperForTable(original, key, formatter) {
    let cloned = JSON.parse(JSON.stringify(original));
    for (let ii = 0, jj = cloned.length; ii < jj; ii++) {
      if (!!cloned[ii][key] && formatter === "time") {
        cloned[ii][key] = new Date(cloned[ii][key]).getTime();
      }
    }
    return cloned;
  }
}
