import React, { Component } from "react";
import Tile from "./Tile";
import Grid from "@material-ui/core/Grid";
import { INPROGRESS } from "../../common/helpers/loader";
export default class Tiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTiles: [true, false, false, false]
    };
  }

  handleClick(subheading, index) {
    let resetter = [false, false, false, false];
    if (!!subheading && index !== resetter.length - 1) {
      this.props.onClick(subheading);
      resetter[index] = true;
      this.setState({
        activeTiles: resetter
      });
    }
    return index;
  }
  render() {
    const { items } = this.props;
    const { activeTiles } = this.state;

    if (!items) {
      return INPROGRESS;
    }

    return (
      <Grid container className="tile-container">
        {items.map((value, index) => {
          return (
            <Grid item xs={value.spacing} key={"arrow" + index}>
              <Tile
                heading={value.heading}
                active={activeTiles[index]}
                handleClick={subheading => this.handleClick(subheading, index)}
                subheading={value.subheading}
                last={index === items.length - 1}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
}
