import React from 'react';
import { connect } from 'react-redux';
import '../../appVersion';

function Footer(props) {
    const { label, appVersion } = props;
    
    return (
        <footer className="footer">
            <span className="copy">&copy; {new Date().getFullYear()} {label} </span>
            <span className="version">UI: {sessionStorage.getItem('UIVersion')}</span>
            <span className="hide-in-mobile">, </span>
            <span className="version">REST-API: {appVersion}</span>
        </footer>
    );
}

const mapStateToProps = state => ({
    appVersion: state.versionDetails.appVersion
})

export default connect(mapStateToProps, null)(Footer);
