import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

 class SimpleDialog extends Component {
    render() {
        const { fullWidth, dialogToggleFlag, handleDialogClose, dialogContent } = this.props;

        return (
            <Dialog
                fullWidth={fullWidth}
                maxWidth="sm"
                open={dialogToggleFlag}
                onClose={handleDialogClose}
                aria-labelledby="dialog-title">
                <DialogContent>
                    <DialogContentText component="div">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


export default SimpleDialog;