import React from "react";
import { connect } from "react-redux";
import Notification, {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { RenderSearchTableInput } from "../../common/helpers/renderElement";
import { getUserDetails } from "../../actions/userDetailsAction";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import { applyFilteredData } from "../../actions/distributionAction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { shortByDate } from "../../common/helpers/dateHelper";
import {
  getCertificates,
  deleteCertificate,
  addCertificate,
  getCertificate,
  resetCertification
} from "../../actions/configurationAction";
import AlertDialog from "../Dialog/AlertDialog";
import FormDialogBase from "../Dialog/FormDialogBase";
import {
  certificateEditFormControls,
  certificateViewFormControls,
  certificateDataMapper,
  certificateVMMapper
} from "../../common/constants/certificate";

import {
  stableSort,
  getSorting,
  tableStyles
} from "../../common/helpers/tableRulesAndStyles";

import { Redirect } from "react-router-dom";

import { RESELLER } from "../../common/constants/roles";
import {
  NORESPONSE,
  INPROGRESS,
  tableLoaderEmpty,
  styles
} from "../../common/helpers/loader";
import BaseComponent from "../../common/base/BaseComponent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const configurationTableRows = [
  {
    id: "commonName",
    numeric: false,
    disablePadding: false,
    label: "Certificate Name"
  },
  { id: "valid", numeric: false, disablePadding: false, label: "Valid" },
  {
    id: "validFrom",
    numeric: false,
    disablePadding: false,
    label: "Valid through"
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description"
  },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

let certificatesTableConfig = {
  tableSearchText: "",
  order: "asc",
  orderBy: "commonName",
  selected: [],
  page: 0,
  rowsPerPage: 10,
  isLoading: false,
  pageOptions: [],
  openDialog: false,
  openFormDialog: false,
  dialogFormName: "Add certificate",
  progressStatus: 0,
  validation: true,
  dialogViewType: "edit"
};

class Certificates extends BaseComponent {
  formElementsValue = {};
  oldCustomerId = "";
  tableListFull;

  constructor(props) {
    super(props);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = { ...initialState(), ...certificatesTableConfig };
  }

  componentWillMount() {
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.props.hideNotification();
    this.props.resetCertification();
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.userData) {
      let customerId = !!nextProps.switchUserData
        ? nextProps.switchUserData.id
        : nextProps.userData.customerId;
      if (!!customerId && this.hasUniqueCustomerId(customerId)) {
        this.getCertificates(customerId);
      }
    }
  }

  hasUniqueCustomerId(customerId) {
    if (this.oldCustomerId !== customerId) {
      this.oldCustomerId = customerId;
      return true;
    }
    return false;
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  getCertificates(customerId) {
    this.props.getCertificates(customerId);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.checked });
  };

  getTotalFormFields() {
    const fixedFields = 5;
    return fixedFields;
  }

  handleFormDialogToggle(type) {
    if (type === "edit") {
      this.setState({
        dialogViewType: "edit",
        certificate: "",
        privateKey: "",
        caBundle: "",
        validation: true,
        certificateDescription: ""
      });
    }
    this.setState({ openFormDialog: !this.state.openFormDialog });
  }
  isValid(value) {
    if (!!value) {
      return (
        <i className="material-icons" style={styles.success}>
          check_circle_outline
        </i>
      );
    } else {
      return (
        <i className="material-icons" style={styles.error}>
          error
        </i>
      );
    }
  }

  async handleDeleteItem(customerId, certificateId) {
    const _this = this;
    _this.setState({
      isLoading: true
    });
    await _this.props.deleteCertificate(customerId, certificateId, function(
      result
    ) {
      if (typeof result === "object" && !!result.success) {
        const message = "Certificate: " + result.message + " successfully.";
        _this.toggleNotification(message, "success", "Close");
        _this.props.getCertificates(customerId);
      } else {
        const message = result || this.defaultErrorMessage;
        _this.toggleNotification(message, "error", "Close");
      }
      _this.setState({
        isLoading: false,
        selected: []
      });
      _this.handleDialogToggle();
    });
  }

  async getCertificate(customerId, certificateId) {
    const _this = this;
    _this.setState({
      dialogFormName: "Certificate: " + certificateId,
      dialogViewType: "view"
    });
    await _this.props.getCertificate(customerId, certificateId, function(
      result
    ) {
      if (typeof result === "object") {
        certificateVMMapper(_this, result);
        _this.handleFormDialogToggle();
      } else {
        const message = result || this.defaultErrorMessage;
        _this.toggleNotification(message, "error", "Close");
      }
    });
  }

  async handleSubmit(e, customerId) {
    e.preventDefault();
    const _this = this;
    _this.setState({
      isLoading: true,
      dialogViewType: "edit"
    });
    const payload = certificateDataMapper(this, customerId);

    await _this.props.addCertificate(customerId, payload, function(result) {
      if (typeof result === "object") {
        const message = "Certificate: " + result.id + " is successfully added.";
        _this.toggleNotification(message, "success", "Close");
        _this.props.getCertificates(customerId);
      } else {
        const message = result || this.defaultErrorMessage;
        _this.toggleNotification(message, "error", "Close");
      }
      _this.setState({
        isLoading: false,
        selected: []
      });
      _this.handleFormDialogToggle("edit");
    });
  }
  render() {
    let {
      error,
      variant,
      label,
      color,
      selected,
      order,
      orderBy,
      pageOptions,
      rowsPerPage,
      page,
      openDialog,
      isLoading,
      tableSearchText,
      dialogFormName,
      openFormDialog,
      dialogViewType
    } = this.state;
    let {
      open,
      userData,
      role,
      classes,
      certificates,
      switchUserData
    } = this.props;
    let dialogContent, customerId;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    let certificateSorted = shortByDate(certificates, "updated", "created");

    if (!userData) {
      return this.getSecordaryLoader();
    }

    customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    dialogContent =
      dialogViewType === "edit"
        ? certificateEditFormControls(this)
        : certificateViewFormControls(this);

    return (
      <React.Fragment>
        <Typography variant="h3" color="initial" className="page-title">
          Configuration
        </Typography>
        <Typography variant="h4" color="initial">
          Manage your distributions, origins and certificates
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        <Paper className="margin-top-default">
          {!dialogContent.length && (
            <FormDialogBase
              openFormDialog={openFormDialog}
              formHeading={dialogFormName}
              cancelFormText="Close"
              maxWidth={"lg"}
              formSubmit={e => this.handleSubmit(e, customerId)}
              submitDataInProgress={isLoading}
              cancelFormAction={() => this.handleFormDialogToggle()}
              dialogContent={dialogContent}
              confirmFormText={dialogViewType === "edit" ? "Save" : ""}
            />
          )}
          <AlertDialog
            openDialog={openDialog}
            heading="Confirm"
            message={
              "Are you sure you want to remove certificate: " + selected + "?"
            }
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
            cancelBtnAction={() => this.handleDialogToggle()}
            confirmBtnAction={() => this.handleDeleteItem(customerId, selected)}
          />
          <EnhancedTableToolbar
            numSelected={0}
            subheading="Certificates"
            toolTipTitle="Add certificate"
            handleAddClick={() => this.handleFormDialogToggle("edit")}
            hideDefaultOptions={false}
          />
          {certificates === null && tableLoaderEmpty}
          {!!certificates && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <RenderSearchTableInput
                  onChange={e =>
                    this.handleSearchTable(
                      e,
                      certificates,
                      "GET_CERTIFICATE_LIST"
                    )
                  }
                  onClear={name => {
                    this.handleClearSearch(name, "GET_CERTIFICATE_LIST");
                  }}
                  className="table-search-field certificates"
                  value={tableSearchText}
                />
                <Table
                  className={classes.table + " table"}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    showSelectAll={false}
                    hideCheckBox={false}
                    rows={configurationTableRows}
                    onRequestSort={this.handleRequestSort}
                    rowCount={certificates.length}
                    hideNumbers={true}
                  />
                  <TableBody>
                    {stableSort(
                      certificateSorted,
                      getSorting(order, orderBy, {
                        name: "info",
                        sortBy: "valid"
                      })
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((n, index) => {
                        return (
                          <TableRow tabIndex={-1} key={n.id + index}>
                            <TableCell>{n.info.commonName}</TableCell>
                            <TableCell>{this.isValid(n.info.valid)}</TableCell>
                            <TableCell>
                              {n.info.daysLeft}{" "}
                              {parseInt(n.info.daysLeft) > 1 ? "days" : "day"}
                            </TableCell>
                            <TableCell>{n.description}</TableCell>
                            <TableCell className="actions">
                              <IconButton
                                aria-label="Edit"
                                onClick={() =>
                                  this.getCertificate(customerId, n.id)
                                }
                              >
                                <VisibilityIcon color="secondary" />
                              </IconButton>
                              <IconButton
                                aria-label="Delete"
                                onClick={event => {
                                  this.handleDialogToggle(n.id);
                                  this.setSelected(n.id);
                                }}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {!certificates.length && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={6}>{NORESPONSE}</TableCell>
                      </TableRow>
                    )}
                    {certificates === null && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={6}>{INPROGRESS}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              {!!certificates.length && (
                <TablePagination
                  rowsPerPageOptions={pageOptions}
                  component="div"
                  count={certificates.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              )}
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  certificates: state.configuration.certificates,
  switchUserData: state.user.switchUserData
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    getCertificates,
    deleteCertificate,
    addCertificate,
    getCertificate,
    resetCertification,
    applyFilteredData
  }
)(withStyles(tableStyles)(Certificates));
