import { session as userSession } from '../common/helpers/sessionValues';
import {
    ERROR_IN_API_VERSION,
    GET_API_VERSION
} from './types';
import authClient from '../common/helpers/auth';
import { apiGetCall } from '../actions/baseActions';


export function getAPIVersion() {
    let url = userSession.getSessionItem('apiUrl') + 'version';
    const token = authClient.getBearerToken(),
    typeError = ERROR_IN_API_VERSION, typeSuccess = GET_API_VERSION, method = "GET";
    const callback = (response) => {}
    return apiGetCall(url, token, typeError, typeSuccess, method, "data", callback);
}