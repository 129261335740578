import { RouteWithSubRoutes } from "../../common/helpers/routeService";
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
export default class Statistics extends Component {
  checkForValidPath(cPath, vRoutes) {
    const pathArray = cPath.split("/");
    if (pathArray.length !== 2 && pathArray[pathArray.length - 1] !== "") {
      return true;
    }
    return false;
  }

  render() {
    const { label, userData, role, routes } = this.props;
    const { pathname } = this.props.location;

    const isValidpath = this.checkForValidPath(pathname, routes);

    if (!isValidpath) {
      return <Route render={() => <Redirect to={{ pathname: "/" }} />} />;
    }

    return (
      <div>
        {!!routes &&
          !!isValidpath &&
          routes.map((route, i) => (
            <RouteWithSubRoutes
              key={i}
              {...route}
              label={label}
              userData={userData}
              role={role}
              hideSideMenu={true}
              parent={true}
            />
          ))}
      </div>
    );
  }
}
