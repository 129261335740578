import { GET_TILES, ERROR_IN_TILES } from "./types";

import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";

import { apiGetCall, encodeMap } from "../actions/baseActions";

export function resetTilesData() {
  return function(dispatch) {
    dispatch({
      type: GET_TILES,
      data: null
    });
  };
}

export function getFrontPageTiles(
  customerId,
  customerType,
  payload,
  endpoint,
  callback
) {
  const query = encodeMap(payload).join("&");
  const url =
    userSession.getSessionItem("apiUrl") +
    customerId +
    "/statistics/" +
    customerType +
    endpoint +
    "?" +
    query;
  const token = authClient.getBearerToken(),
    method = "GET",
    typeError = ERROR_IN_TILES,
    typeSuccess = GET_TILES;
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}
