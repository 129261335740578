import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";

export default class FixedNotification extends Component {
  action = onClose => {
    if (!onClose) return "";
    return (
      <IconButton
        key="closeBtn"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  render() {
    let { message, variant, onClose, open, fullwidth } = this.props;
    if (!open) {
      return false;
    }

    return (
      <SnackbarContent
        className={variant}
        message={message}
        fullWidth={fullwidth}
        action={this.action(onClose)}
      />
    );
  }
}
