import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = {
  root: {
    flexGrow: 1
  }
};

function LinearIndeterminate(props) {
  const { color, className, completed } = props;
  return !!completed && <LinearProgress color={color} className={className} />;
}

export default withStyles(styles)(LinearIndeterminate);
