import {
  GET_CERTIFICATE_LIST,
  ERROR_IN_CONFIGURATION,
  UPDATE_CERTIFICATE,
  DELETE_CERTIFICATE,
  GET_CERTIFICATE,
  SET_PARAMS,
} from "./types";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import { apiPostCall, apiGetCall } from "../actions/baseActions";

export function resetCertification() {
  return function (dispatch) {
    dispatch({
      type: GET_CERTIFICATE_LIST,
      data: null,
    });
  };
}
export function setParams(params) {
  return function (dispatch) {
    dispatch({
      type: SET_PARAMS,
      locParams: params,
    });
  };
}

export function getCertificates(customerId) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/certificates";

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_CONFIGURATION,
    typeSuccess = GET_CERTIFICATE_LIST,
    method = "GET";
  const callback = () => {};
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function getCertificate(customerId, certificateId, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/certificates/" +
    certificateId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_CONFIGURATION,
    typeSuccess = GET_CERTIFICATE,
    method = "GET";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function deleteCertificate(customerId, certificateId, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/certificates/" +
    certificateId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_CONFIGURATION,
    typeSuccess = DELETE_CERTIFICATE,
    method = "DELETE";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function addCertificate(customerId, payload, callback) {
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/certificates";

  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_CONFIGURATION,
    typeSuccess = UPDATE_CERTIFICATE,
    contentType = "application/json";
  const postObject = JSON.stringify(payload);

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}
