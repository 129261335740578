import authClient from "../common/helpers/auth";
import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

export const DEFAULT_ERROR_MESSAGE = "Oops!! something went wrong, try again.";

export const encodeMap = function(data) {
  var query = [];
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      var value = data[key];
      if (!!value && value.constructor === Array) {
        for (var arr in value) {
          if (value.hasOwnProperty(arr)) {
            query.push(
              encodeURIComponent(key) + "=" + encodeURIComponent(value[arr])
            );
          }
        }
      } else {
        query.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
      }
    }
  }
  return query;
};

export const createPostObject = function(start, end, queries) {
  var postObject = { start: start, end: end, target: [] };
  if (!!queries && queries.constructor === Array) {
    for (var target in queries) {
      if (queries.hasOwnProperty(target)) {
        postObject.target.push(queries[target]);
      }
    }
  } else {
    postObject.target.push(queries);
  }
  return postObject;
};

export function timeInAMandPMFormat(hour) {
  // Check correct hour format
  hour = hour.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    hour
  ];
  if (hour.length > 1) {
    // if correct format
    hour = hour.slice(1);
    hour[5] = +hour[0] < 12 ? "AM" : "PM";
    hour[0] = +hour[0] % 12 || 12;
  }
  return hour.join("");
}

export function apiPostCall(
  url,
  token,
  method,
  payload,
  typeError,
  typeSuccess,
  contentType,
  data,
  callback
) {
  if (!authClient.isAuthenticated() || !localStorage.length) {
    localStorage.clear();
    return <Redirect to="/" />;
  }

  return function(dispatch) {
    axios({
      method: method.toLowerCase(),
      url: url,
      data: payload,
      headers: {
        "Content-Type": contentType,
        Authorization: token
      }
    })
      .then(result => {
        if (!!result && (result.status === 200 || result.status === 201)) {
          dispatch({
            type: typeSuccess,
            [data]: result.data.response
          });
          callback(result.data.response);
        } else {
          dispatch({
            type: typeSuccess,
            [data]: []
          });
          callback([]);
        }
      })
      .catch(error => {
        console.log(error);
        const message = error.response.data.message;
        dispatch({
          type: typeError,
          [data]: message
        });
        callback(message);
      });
  };
}

export function apiGetCall(
  url,
  token,
  typeError,
  typeSuccess,
  method,
  data,
  callback
) {
  if (!authClient.isAuthenticated() || !localStorage.length) {
    return <Redirect to="/" />;
  }

  return function(dispatch) {
    axios({
      method: method.toLowerCase(),
      url: url,
      headers: {
        Authorization: token
      }
    })
      .then(result => {
        if (!!result && result.status === 200) {
          dispatch({
            type: typeSuccess,
            [data]: result.data.response
          });
          callback(result.data.response);
        } else {
          dispatch({
            type: typeSuccess,
            [data]: []
          });
          callback([]);
        }
      })
      .catch(error => {
        console.log(error);
        setErrorResponse(dispatch, callback, error, typeSuccess, data);
      });
  };
}

function setErrorResponse(dispatch, callback, error, typeSuccess, data) {
  let response = error.response;
  let message = DEFAULT_ERROR_MESSAGE;
  if (response.status === 404) {
    message = [];
    dispatch({
      type: typeSuccess,
      [data]: message
    });
  } else {
    if (!!response) {
      message = response.data.message;
    }
    dispatch({
      type: typeSuccess,
      [data]: message
    });
  }
  callback(message);
}

export function apiGetCallAndMerge(
  url,
  token,
  typeError,
  typeSuccess,
  method,
  data,
  obj,
  callback
) {
  let merged;
  return function(dispatch) {
    axios({
      method: method.toLowerCase(),
      url: url,
      headers: {
        Authorization: token
      }
    })
      .then(result => {
        if (!!result && result.status === 200) {
          merged = obj.concat(result.data.response);
          dispatch({
            type: typeSuccess,
            [data]: merged
          });
          callback(merged);
        } else {
          merged = obj.concat([]);
          dispatch({
            type: typeSuccess,
            [data]: merged
          });
          callback(merged);
        }
      })
      .catch(error => {
        merged = obj.concat([]);
        dispatch({
          type: typeSuccess,
          [data]: merged
        });
        callback(merged);
      });
  };
}

export function apiGetCallWithoutDispatch(url, token, method) {
  return new Promise(resolve => {
    axios({
      method: method.toLowerCase(),
      url: url,
      headers: {
        Authorization: token
      }
    }).then(result => {
      if (!!result && result.status === 200) {
        resolve(result.data.response);
      } else {
        resolve([]);
      }
    });
  });
}
