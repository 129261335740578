import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  RenderInputWithToolTip,
  ClickableTooltip
} from "../../common/helpers/renderElement";
import { getISOStringMoment } from "../../common/helpers/dateHelper";
import { phoneValidator } from "../helpers/stringHelper";

const styles = {
  rightDomains: {
    right: "-37px"
  }
};

const USER_TOOLTIP = {
  ENABLED: "Enable / disable the user.",
  USERROLE: "The role applicable to this user.",
  EMAIL: "Email address of the user, this is the username.",
  PASSWORD: "The desired password for this user.",
  FIRSTNAME: "First name of the user.",
  LASTNAME: "Last name of the user.",
  PHONENUMBER: "The phone number for this user."
};

export const getLoggedInUserDetails = (res, email) => {
  if (!!res && typeof res === "object") {
    const loggedInUser = res.filter(item => item.email === email);
    return loggedInUser[0] || null;
  }
};

export const userDataMapper = (self, customerId) => {
  return {
    email: self.state.emailEmail,
    firstName: self.state.firstNameFirstName,
    ip: self.state.ipIp,
    lastName: self.state.lastNameLastName,
    password: self.state.passwordPassword || generateRandomPassword(),
    phone: self.state.phonePhone,
    updateDate: getISOStringMoment(),
    ip: self.state.userIP,
    customerId: customerId,
    enabled: self.state.enabled
  };
};

export const generateRandomPassword = () => {
  let length = 8,
    randomChar =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789",
    ranValue = "";
  for (let i = 0, n = randomChar.length; i < length; ++i) {
    ranValue += randomChar.charAt(Math.floor(Math.random() * n));
  }
  return ranValue;
};

export function checkForPasswordValidation(values, length) {
  if (!values) {
    return true;
  } else if (values.length < length) {
    return true;
  } else {
    return false;
  }
}

export function userFormControls(self) {
  const { classes } = self.props;

  return (
    <div className="margin-top-16 react-scroll-view-container">
      <div className="background-none">
        <label className="regular-font fullWidth display-block-inline position-relative">
          User role
          <div className="toolTip margin-none" style={styles.rightDomains}>
            <ClickableTooltip toolTipTitle={USER_TOOLTIP.USERROLE} />
          </div>
        </label>
        <br />
        <RadioGroup
          aria-label="User role"
          name="roles"
          required
          className="display-block"
          value={self.state["roles"]}
          onChange={event => self.handleRadioGroupChange("roles", event)}
        >
          <FormControlLabel
            value="CUSTOMER_ADMIN"
            control={<Radio color="primary" />}
            label="Admin"
          />
          <FormControlLabel
            value="INVALIDATE_USER"
            control={<Radio color="primary" />}
            label="Invalidate"
          />
          <FormControlLabel
            value="READ_ONLY"
            control={<Radio color="primary" />}
            label="Read only"
          />
        </RadioGroup>

        {self.state.dialogFormName !== "Edit user" && (
          <RenderInputWithToolTip
            placeholder="Type your email"
            id="emailEmail"
            name="emailEmail"
            type="email"
            required
            error={!self.state.emailEmail}
            value={self.state.emailEmail}
            label="Email"
            handleChange={self.handleChange}
            toolTipTitle={USER_TOOLTIP.EMAIL}
          />
        )}
        {self.state.dialogFormName === "Edit user" && (
          <RenderInputWithToolTip
            placeholder="Type your email"
            id="emailEmail"
            name="emailEmail"
            type="email"
            disabled
            value={self.state.emailEmail}
            label="Email"
            handleChange={self.handleChange}
            toolTipTitle={USER_TOOLTIP.EMAIL}
          />
        )}

        <RenderInputWithToolTip
          placeholder="Type your first name"
          name="firstNameFirstName"
          id="firstNameFirstName"
          type="text"
          required
          error={!self.state.firstNameFirstName}
          value={self.state.firstNameFirstName}
          label="First name"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.FIRSTNAME}
        />
        <RenderInputWithToolTip
          placeholder="Type your last name"
          name="lastNameLastName"
          id="lastNameLastName"
          type="text"
          required
          error={!self.state.lastNameLastName}
          value={self.state.lastNameLastName}
          label="Last name"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.LASTNAME}
        />
        <RenderInputWithToolTip
          placeholder="Type your phone number"
          name="phonePhone"
          id="phonePhone"
          type="text"
          value={self.state.phonePhone}
          label="Phone number"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.PHONENUMBER}
          error={!phoneValidator(self.state.phonePhone)}
          min={10}
        />
      </div>
    </div>
  );
}

export function userFormViewControls(self) {
  const { classes } = self.props;

  return (
    <div className="margin-top-16 react-scroll-view-container">
      <div className="background-none">
        <label className="regular-font fullWidth display-block-inline position-relative">
          User role
          <div className="toolTip margin-none" style={styles.rightDomains}>
            <ClickableTooltip toolTipTitle={USER_TOOLTIP.USERROLE} />
          </div>
        </label>
        <br />
        <RadioGroup
          aria-label="User role"
          name="roles"
          required
          className="display-block"
          value={self.state["roles"]}
          onChange={event => self.handleRadioGroupChange("roles", event)}
        >
          <FormControlLabel
            value="CUSTOMER_ADMIN"
            control={<Radio color="primary" />}
            label="Admin"
            disabled
          />
          <FormControlLabel
            value="INVALIDATE_USER"
            control={<Radio color="primary" />}
            label="Invalidate"
            disabled
          />
          <FormControlLabel
            value="READ_ONLY"
            control={<Radio color="primary" />}
            label="Read only"
            disabled
          />
        </RadioGroup>

        <RenderInputWithToolTip
          placeholder="Type your email"
          id="emailEmail"
          name="emailEmail"
          type="email"
          required
          disabled
          error={!self.state.emailEmail}
          value={self.state.emailEmail}
          label="Email"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.EMAIL}
        />

        <RenderInputWithToolTip
          placeholder="Type your first name"
          name="firstNameFirstName"
          id="firstNameFirstName"
          type="text"
          disabled
          value={self.state.firstNameFirstName}
          label="First name"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.FIRSTNAME}
        />
        <RenderInputWithToolTip
          placeholder="Type your last name"
          name="lastNameLastName"
          id="lastNameLastName"
          type="text"
          disabled
          value={self.state.lastNameLastName}
          label="Last name"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.LASTNAME}
        />
        <RenderInputWithToolTip
          placeholder="Type your phone number"
          name="phonePhone"
          id="phonePhone"
          type="text"
          disabled
          value={self.state.phonePhone}
          label="Phone number"
          handleChange={self.handleChange}
          toolTipTitle={USER_TOOLTIP.PHONENUMBER}
          min={10}
        />
      </div>
    </div>
  );
}
