export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const RECIEVED_MENU_ITEMS = "RECIEVED_MENU_ITEMS";
export const REQUEST_MENU_ITEMS = "REQUEST_MENU_ITEMS";
export const ERROR_IN_MENU_ITEMS = "ERROR_IN_MENU_ITEMS";
export const RECEIVED_CUSTOMER_OVERVIEW = "RECEIVED_CUSTOMER_OVERVIEW";
export const DELETE_CUSTOMER_SUCCESSFUL = "DELETE_CUSTOMER_SUCCESSFUL";
export const ADD_CUSTOMER_SUCCESSFUL = "ADD_CUSTOMER_SUCCESSFUL";
export const ERROR_IN_CUSTOMERS = "ERROR_IN_CUSTOMERS";
export const REQUEST_CUSTOMER_OVERVIEW = "REQUEST_CUSTOMER_OVERVIEW";
export const ERROR_IN_USER_DETAILS = "ERROR_IN_USER_DETAILS";
export const SWITCH_USER_DETAILS = "SWITCH_USER_DETAILS";
export const GET_SPECIFIC_USER_DETAILS = "GET_SPECIFIC_USER_DETAILS";
export const GET_SPECIFIC_CUSTOMER_DETAIL = "GET_SPECIFIC_CUSTOMER_DETAIL";
export const USER_2FA_TOGGLE = "USER_2FA_TOGGLE";
export const USER_ENABLED_TOGGLE = "USER_2FA_TOGGLE";
export const GET_USERS = "GET_USERS";
export const ADD_USERS = "GET_USERS";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_INVALIDATION_LIST = "GET_INVALIDATION_LIST";
export const GET_INVALIDATION = "GET_INVALIDATION";
export const ERROR_IN_INVALIDATION = "ERROR_IN_INVALIDATION";

export const GET_CERTIFICATE_LIST = "GET_CERTIFICATE_LIST";
export const GET_CERTIFICATE = "GET_CERTIFICATE";
export const ERROR_IN_CONFIGURATION = "ERROR_IN_CONFIGURATION";
export const DELETE_CERTIFICATE = "DELETE_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";

export const GET_SIMPLE_ORIGIN = "GET_SIMPLE_ORIGIN";
export const GET_ADVANCED_ORIGIN = "GET_ADVANCED_ORIGIN";
export const GET_OBJECTSTORAGE_ORIGIN = "GET_OBJECTSTORAGE_ORIGIN";
export const GET_GROUP_ORIGIN = "GET_GROUP_ORIGIN";
export const ERROR_IN_ORIGIN = "ERROR_IN_ORIGIN";
export const DELETE_ORIGIN = "DELETE_ORIGIN";
export const GET_ORIGIN = "GET_ORIGIN";
export const GET_ORIGINS = "GET_ORIGINS";
export const UPDATE_ORIGIN = "UPDATE_ORIGIN";
export const ADD_ORIGIN = "ADD_ORIGIN";
export const RESET_ORIGINS = "RESET_ORIGINS";

export const ERROR_IN_DISTRIBUTION = "ERROR_IN_DISTRIBUTION";
export const DELETE_DISTRIBUTION = "DELETE_DISTRIBUTION";
export const GET_DISTRIBUTION = "GET_DISTRIBUTION";
export const GET_DISTRIBUTIONS = "GET_DISTRIBUTIONS";
export const GET_ALL_DISTRIBUTIONS = "GET_ALL_DISTRIBUTIONS";
export const UPDATE_DISTRIBUTION = "UPDATE_ORIGIN";
export const ADD_DISTRIBUTION = "ADD_ORIGIN";
export const RESET_DISTRIBUTIONS = "RESET_ORIGINS";
export const TOP_DIST_IN_MONTH = "TOP_DIST_IN_MONTH";

export const ERROR_IN_POLICY = "ERROR_IN_POLICY";
export const DELETE_POLICY = "DELETE_POLICY";
export const GET_POLICY = "GET_POLICY";
export const GET_POLICIES = "GET_POLICIES";
export const UPDATE_POLICY = "UPDATE_POLICY";
export const ADD_POLICY = "ADD_POLICY";
export const RESET_POLICY = "RESET_POLICY";
export const IS_POLICY_MATCH = "IS_POLICY_MATCH";
export const CDN_TAB = "CDN_TAB";

export const SET_PARAMS = "SET_PARAMS";

export const GET_CHART = "GET_CHART";
export const GET_HOURLY_CHART = "GET_HOURLY_CHART";
export const GET_MULTILINE_CHART = "GET_MULTILINE_CHART";
export const DELETE_CHART = "DELETE_CHART";
export const ADD_CHART = "ADD_CHART";
export const EDIT_CHART = "EDIT_CHART";
export const ERROR_IN_CHART = "ERROR_IN_CHART";

export const GET_TILES = "GET_TILES";
export const ERROR_IN_TILES = "ERROR_IN_TILES";

export const GET_API_VERSION = "GET_API_VERSION";
export const ERROR_IN_API_VERSION = "ERROR_IN_API_VERSION";

export const GET_STATISTICS_TOTAL = "GET_STATISTICS_TOTAL";

export const AVAILABLE_COUNTRYLIST = "AVAILABLE_COUNTRYLIST";
export const SELECTED_COUNTRYLIST = "SELECTED_COUNTRYLIST";

export const TOGGLE_CUSTOMER = "TOGGLE_CUSTOMER";

export const GET_STATUS = "GET_STATUS";
export const ERROR_IN_STATUS = "ERROR_IN_STATUS";

export const RECEIVED_MENU_ENTITLEMENT = "RECEIVED_MENU_ENTITLEMENT";
export const ERROR_IN_ENTITLEMENT = "ERROR_IN_ENTITLEMENT";

export const RECEIVED_INTERFACE = "RECEIVED_INTERFACE";
export const ERROR_IN_INTERFACE = "ERROR_IN_INTERFACE";
