import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  stableSort,
  getSorting,
  tableStyles,
  scrollInToView
} from "../../common/helpers/tableRulesAndStyles";
import {
  INPROGRESS,
  RELOADPAGE,
  NORESPONSE
} from "../../common/helpers/loader";
import { valueFormatter } from "../../common/constants/chart";

const ORDERBY = "value";

class TableCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: ORDERBY,
      selected: [],
      page: 0,
      rowsPerPage: this.props.rowsPerPage || 5,
      pageOptions: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!!nextProps.orderBy && nextProps.orderBy !== ORDERBY) {
      this.setState({
        orderBy: this.props.newOrderBy
      });
    }

    if (!!nextProps.order && nextProps.order !== "desc") {
      this.setState({
        orderBy: this.props.newOrder
      });
    }
  }

  isSelected = (index, obj) => {
    return this.state[obj].indexOf(index) !== -1;
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    return page;
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    scrollInToView(event.target.value);
    return event.target.value;
  };

  handleCheckBoxClick = (event, id, name, obj) => {
    this.selectItem(id, name, obj);
  };

  handleClick = (event, id, obj) => {
    if (event.target.nodeName.toLowerCase() !== "input") {
      this.selectItem(id, obj);
    }
    return true;
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
    return property;
  };

  checkForCustomUnit(name, value, array) {
    if (!array) return value;

    let finalValue = "";

    for (let ii = 0, jj = array.length; ii < jj; ii++) {
      if (name === array[ii].name) {
        const formattedValue = valueFormatter([value], array[ii].unitCategory);
        finalValue = formattedValue.join(" ").replace(",", " ");
      } else {
        finalValue = value;
      }
    }

    return finalValue;
  }

  render() {
    const {
      classes,
      tableRows,
      data,
      checkbox,
      cardHeader,
      className,
      total,
      customUnit,
      hideNumbers
    } = this.props;
    const {
      selected,
      order,
      orderBy,
      page,
      rowsPerPage,
      pageOptions
    } = this.state;

    return (
      <Card className={"margin-top-16 " + className}>
        <CardContent>
          {!!cardHeader.length && (
            <Typography gutterBottom component="h3" className="table-heading">
              {cardHeader}
            </Typography>
          )}
          {!!data ? (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <Table
                  className={classes.table}
                  size="small"
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    showSelectAll={false}
                    hideCheckBox={!checkbox}
                    rows={tableRows}
                    onRequestSort={this.handleRequestSort}
                    rowCount={data.length}
                    hideNumbers={hideNumbers}
                  />
                  <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((n, index) => {
                        const isSelected = this.isSelected(n.id, "selected");
                        return (
                          <TableRow
                            role={!!checkbox ? "radio" : "row"}
                            aria-checked={isSelected}
                            tabIndex={-1}
                            key={"dynTable" + index}
                            selected={isSelected}
                          >
                            {!hideNumbers && <TableCell>{index + 1}</TableCell>}
                            {tableRows.map((value, ind) => (
                              <TableCell key={"cell" + ind}>
                                <span
                                  title={this.checkForCustomUnit(
                                    tableRows[ind].id,
                                    n[tableRows[ind].id],
                                    customUnit
                                  )}
                                >
                                  {this.checkForCustomUnit(
                                    tableRows[ind].id,
                                    n[tableRows[ind].id],
                                    customUnit
                                  )}
                                </span>
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                    {!!total && (
                      <TableRow className="table-row-total">
                        <TableCell colSpan={tableRows.length} align="right">
                          <strong>Totals:</strong>
                        </TableCell>
                        <TableCell>{total}</TableCell>
                      </TableRow>
                    )}
                    {data === null && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={tableRows.length}>
                          {INPROGRESS}
                        </TableCell>
                      </TableRow>
                    )}
                    {!!data && !data.length && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={tableRows.length}>
                          {NORESPONSE}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={pageOptions}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                  className: "btn-prev"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                  className: "btn-next"
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </React.Fragment>
          ) : (
            { RELOADPAGE }
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(tableStyles)(TableCard);
