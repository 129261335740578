import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  RenderInputWithOutLabel,
  RenderReadOnlyInput,
  RenderSwitchWithToolTip
} from "../../common/helpers/renderElement";

const CERTIFICATE_TOOLTIP = {
  VALIDATION:
    "Enable or disable verification of the validity of the provided data. Note that when the provided data is not valid and this option is disabled, these certificates will still not be uploaded to external CDN's in the Multi-CDN."
};

export const certificateDataMapper = (self, customerId) => {
  return {
    caBundle: self.state.caBundle,
    certificate: self.state.certificate,
    customerId: customerId,
    id: "temporary-id",
    description: self.state.certificateDescription,
    validation: self.state.validation,
    privateKey: self.state.privateKey
  };
};

export const certificateVMMapper = (self, data) => {
  self.setState({
    caBundle: data.caBundle,
    certificate: data.certificate,
    created: data.created,
    customerId: data.customerId,
    certificateDescription: data.description,
    fullyAvailable: data.fullyAvailable,
    id: data.id,
    commonName: data.info.commonName,
    daysLeft: data.info.daysLeft,
    issuer: data.info.issuer,
    trusted: data.info.trusted,
    valid: data.info.valid,
    validFrom: data.info.validFrom,
    validUntil: data.info.validUntil,
    privateKey: data.privateKey,
    validation: data.validation
  });
};

export function certificateEditFormControls(self, viewSource) {
  const { classes } = self.props;

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RenderInputWithOutLabel
        placeholder="The description of the certificate, for easier reference."
        id="certificateDescription"
        name="certificateDescription"
        type="text"
        required
        error={!self.state.certificateDescription}
        value={self.state.certificateDescription}
        label="Description"
        handleChange={self.handleChange}
      />
      <RenderInputWithOutLabel
        placeholder="The actual certificate data."
        id="certificate"
        name="certificate"
        type="text"
        multiline={true}
        rows={4}
        required
        error={!self.state.certificate}
        value={self.state.certificate}
        label="Certificate"
        handleChange={self.handleChange}
      />
      <RenderInputWithOutLabel
        placeholder="The private key matching the certificate."
        id="privateKey"
        name="privateKey"
        type="text"
        multiline={true}
        rows={4}
        required
        error={!self.state.privateKey}
        value={self.state.privateKey}
        label="Key"
        handleChange={self.handleChange}
      />
      <RenderInputWithOutLabel
        placeholder="Certificate bundle to complete the full chain."
        id="caBundle"
        name="caBundle"
        type="text"
        multiline={true}
        rows={4}
        required
        error={!self.state.caBundle}
        value={self.state.caBundle}
        label="Bundle"
        handleChange={self.handleChange}
      />

      {!viewSource && (
        <FormControl component="fieldset" className="margin-top-default">
          <RenderSwitchWithToolTip
            label="Certificate verification"
            name="validation"
            checked={self.state.validation}
            id="validation"
            handleRadioChange={event =>
              self.handleRadioChange("validation", event)
            }
            value={self.state.validation}
            className="switch-ios"
            classes={classes}
            toolTipTitle={CERTIFICATE_TOOLTIP.VALIDATION}
          />
        </FormControl>
      )}
    </FormControl>
  );
}

export function certificateViewFormControls(self) {
  const { classes } = self.props;

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <RenderReadOnlyInput
        id="certificateDescription"
        name="certificateDescription"
        label="Description"
        value={self.state.certificateDescription}
      />
      <RenderReadOnlyInput
        id="certificate"
        name="certificate"
        label="Certificate"
        value={self.state.certificate}
      />
      <RenderReadOnlyInput
        id="privateKey"
        name="privateKey"
        label="Key"
        value={self.state.privateKey}
      />
      <RenderReadOnlyInput
        id="caBundle"
        name="caBundle"
        label="Bundle"
        value={self.state.caBundle}
      />

      <FormControl component="fieldset" className="margin-top-default">
        <RenderSwitchWithToolTip
          label="Certificate verification"
          name="validation"
          checked={self.state.validation}
          id="validation"
          disabled={true}
          handleRadioChange={event =>
            self.handleRadioChange("validation", event)
          }
          value={self.state.validation}
          className="switch-ios"
          classes={classes}
          toolTipTitle={CERTIFICATE_TOOLTIP.VALIDATION}
        />
      </FormControl>
      <RenderReadOnlyInput
        id="commonName"
        name="commonName"
        label="Common Name"
        value={self.state.commonName}
      />
      <RenderReadOnlyInput
        id="issuer"
        name="issuer"
        label="Issuer"
        value={self.state.issuer}
      />
      <RenderReadOnlyInput
        id="daysLeft"
        name="daysLeft"
        label="Days left"
        value={self.state.daysLeft}
      />
      <div className="margin-top-16">
        <label className="label-default">Trusted</label>
        <br />
        {self.isValid(self.state.trusted)}
      </div>
      {/* <RenderReadOnlyInput id="trusted" name="trusted" label="Trusted"
            value={self.isValid(self.state.trusted)} /> */}
      <div className="margin-top-16">
        <label className="label-default">Valid</label>
        <br />
        {self.isValid(self.state.valid)}
      </div>
    </FormControl>
  );
}
