import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getMenuList,
  fallBackMenuItem,
  getEntitlements,
  getInterfaceFromLocalStorage
} from "../../actions/menuItemAction";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import { INPROGRESS } from "../../common/helpers/loader";
import classNames from "classnames";
import {
  addClass,
  addClassUsingQuery,
  removeClassUsingQuery
} from "../../common/helpers/stringHelper";

class SideMenu extends Component {
  prevElm = null;
  nestedPrevElm = null;
  fallBackCalledOnce = false;

  constructor() {
    super();
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    const { userDetails } = this.props;
    if (!!userDetails) {
      this.props.getEntitlements(userDetails.customerId, res => {
        this.setEntitlements(res);
      });
    }
  }

  componentDidMount() {
    this.expandMenuOnLoad();
    const { userDetails } = this.props;
    if (!!userDetails) {
      this.props.getMenuList(userDetails.customerId, userDetails.id, res => {
        this.applyEntitlement(res);
        this.props.getInterfaceFromLocalStorage();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { userData } = this.props;
    if (
      !!userData &&
      !!prevProps.userData &&
      prevProps.userData.customerId !== userData.customerId
    ) {
      this.refreshMenu(userData.customerId, userData.id);
    }
  }

  setFallBackMenu(menuItem) {
    if (!menuItem.length && !this.fallBackCalledOnce) {
      this.fallBackCalledOnce = true;
      fallBackMenuItem();
    }
  }

  applyEntitlement(res) {
    if (!res) this.setFallBackMenu(res);
    if (this.hasMatchingEntitlement("mcdn-premium")) {
      addClassUsingQuery("nav.sidebar", "hidden");
      setTimeout(() => {
        addClass("status-codes", "hidden");
        removeClassUsingQuery("nav.sidebar", "hidden");
      }, 1000);
    }
  }

  refreshMenu(customerId, userId) {
    // must have SwitchUserDetail
    if (!!customerId && !!userId) {
      this.props.getMenuList(customerId, userId, res => {
        this.applyEntitlement(res);
      });
      this.props.getEntitlements(customerId, res => {
        this.setEntitlements(res);
      });
    }
  }

  hasMatchingEntitlement(argument) {
    const arg = typeof argument === "object" ? argument : [argument];
    const entitlements = localStorage.getItem("entitlements");
    if (!!entitlements && !!arg.length) {
      for (let ii = 0, jj = arg.length; ii < jj; ii++) {
        const matched =
          localStorage.getItem("entitlements").indexOf(arg[ii]) >= 0;
        if (!!matched) return matched;
      }
    } else {
      return false;
    }
  }

  setEntitlements(res) {
    const entitlements = [];
    for (let ii = 0, jj = res.length; ii < jj; ii++) {
      entitlements.push(res[ii]["interface"]);
    }
    localStorage.removeItem("entitlements");
    localStorage.setItem("entitlements", entitlements);
    // TODO: Remove this code.
    // localStorage.setItem("entitlements", "cdn-all");
    // localStorage.setItem("entitlements", "mcdn-volume");
    // localStorage.setItem("entitlements", "mcdn-premium");
    // localStorage.setItem("entitlements", "cdn-shield");
    // localStorage.setItem("entitlements", "cdn-shield,mcdn-premium");
    // localStorage.setItem("entitlements", "cdn-shield,mcdn-volume");
    // localStorage.setItem("entitlements", "cdn-shield,cdn-all");
    // localStorage.setItem("entitlements", "mcdn-volume");
    // localStorage.setItem("entitlements", "cdn-all,mcdn-premium,cdn-shield");
  }

  createSingalWordWithUnderScore = string => {
    const newString = string.replace(/[^A-Z0-9]/gi, "_");
    return newString;
  };

  handleClick = (e, name, path) => {
    if (!!this.props.mini) {
      this.setState({
        [name]: !this.state[name]
      });
    }
  };

  handleHover = (e, name, path) => {
    if (!this.props.mini) {
      this.setState({
        [name]: !this.state[name]
      });
    }
  };

  collapseSubmenu() {
    for (var key in this.state) {
      this.setState({
        [key]: false
      });
    }
  }

  selectItemAndStoreValue(event) {
    localStorage.setItem("nested_menu", this.nestedPrevElm);
    localStorage.setItem("menu", this.prevElm);
    this.switchSelectedClass(event);
  }

  expandMenuOnLoad() {
    const pathname = window.location.pathname;
    const strArr = pathname.split("/");
    if (!!strArr && strArr.length >= 4) {
      let nestedMenu =
          "nested_" + strArr[2].charAt(0).toUpperCase() + strArr[2].substr(1),
        menu;
      if (strArr[3].indexOf("multi-cdn") !== -1) {
        menu = "Multi_CDN";
      }
      if (nestedMenu === "nested_Distribution") {
        nestedMenu = "nested_Distributions";
      }
      localStorage.setItem("menu", menu);
      localStorage.setItem("nested_menu", nestedMenu);

      this.expandMenu();
    }
  }

  expandMenu() {
    const menu = localStorage.getItem("menu");
    const nestedMenu = localStorage.getItem("nested_menu");
    if (!!menu || !!nestedMenu) {
      this.nestedPrevElm = nestedMenu;
      this.prevElm = menu;
      this.setState({
        [nestedMenu]: true,
        [menu]: true
      });
    }
  }

  hasPathMatch(href, path) {
    const pageOrigin = href.substr(href.lastIndexOf("/") + 1);
    return path.indexOf(pageOrigin) !== -1;
  }

  switchSelectedClass(event) {
    const selectedElement = document.querySelector("a.selected");
    if (!!selectedElement) {
      selectedElement.classList.remove("selected");
    }
    if (!!event && !!event.target) {
      event.target.parentNode.parentNode.parentNode.classList.add("selected");
    }
  }

  createSubmenu(sublist, permission, path) {
    if (!sublist) return false;
    sublist = this.checkForPermission(sublist, permission);
    let viewlist = sublist.map((item, index) => {
      if (!!item.group) {
        let nestedValue = item.hasOwnProperty("collapseParent")
          ? false
          : "nested";
        return this.createMenuItems([item], permission, nestedValue, path);
      }
      return (
        <React.Fragment key={item.id + "-submenu"}>
          <Link
            id={item.id}
            to={item.href}
            className={this.hasPathMatch(item.href, path) ? "selected" : ""}
            onClick={e => this.selectItemAndStoreValue(e, item.href)}
          >
            <ListItem button key={item.id} className="list-item">
              {!!item.icon && (
                <ListItemIcon>{this.setListIcon(item.icon)}</ListItemIcon>
              )}
              <ListItemText
                className="text"
                inset
                primary={item.name}
                key={item.id + "nested-text-" + index}
              />
            </ListItem>
          </Link>
        </React.Fragment>
      );
    });
    return (
      <List component="div" disablePadding>
        {" "}
        {viewlist}{" "}
      </List>
    );
  }

  checkForPermission(list, role) {
    let newlist = list.filter(item => {
      if (typeof item.roles == "object" && !!role) {
        return this.validateFromPermissionList(item, role);
      } else {
        return item.roles === role[0];
      }
    });
    return this.changeOrderByPriority(newlist);
  }

  validateFromPermissionList(list, userRole) {
    for (let key in list.roles) {
      if (list.roles[key] === userRole) {
        return !!list.roles[key];
      }
    }
  }
  createMenuItems(menuItem, permission, nested, path) {
    return this.checkForPermission(menuItem, permission).map((item, index) => {
      const newString = !!nested
        ? this.createSingalWordWithUnderScore("nested." + item.name)
        : this.createSingalWordWithUnderScore(item.name);
      if (!item.group) {
        return (
          <React.Fragment key={item.id + "-framgment"}>
            <Link
              id={item.id}
              to={item.href}
              className={
                this.hasPathMatch(item.href, path, newString) ? "selected" : ""
              }
              onClick={e => this.selectItemAndStoreValue(e, item.href)}
            >
              <ListItem
                className={classNames({
                  "list-item": true,
                  opened: this.state[newString],
                  "nav-primary": item.href.lastIndexOf("/") === 0,
                  "nav-secondary": item.href.lastIndexOf("/") !== 0,
                  nested: !!nested
                })}
                button
                key={item.id}
                onClick={e =>
                  this.handleClick(e, newString, item.href.lastIndexOf("/"))
                }
                onMouseOver={e =>
                  this.handleHover(e, newString, item.href.lastIndexOf("/"))
                }
              >
                {!!item.icon && (
                  <ListItemIcon>{this.setListIcon(item.icon)}</ListItemIcon>
                )}
                <ListItemText className="text" inset primary={item.name} />
              </ListItem>
            </Link>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={item.id + "-framgment-submenu"}>
            <ListItem
              className={classNames({
                "list-item": true,
                opened: this.state[newString],
                ["listMenu_" + index]: !nested,
                "nav-primary": item.href.lastIndexOf("/") === 0,
                "nav-secondary": item.href.lastIndexOf("/") !== 0,
                nested: !!nested
              })}
              button={item.href.lastIndexOf("/") > 0}
              component="div"
              onClick={e =>
                this.handleClick(e, newString, item.href.lastIndexOf("/"))
              }
              onMouseOver={e =>
                this.handleHover(e, newString, ".listMenu_" + index)
              }
            >
              {!!item.icon && (
                <ListItemIcon>{this.setListIcon(item.icon)}</ListItemIcon>
              )}
              <ListItemText
                className={!!item.icon ? "text-icon" : "text"}
                inset
                primary={item.name}
              />
              {this.state[newString] && item.href.lastIndexOf("/") > 0 && (
                <i className="material-icons">expand_less</i>
              )}
              {!this.state[newString] && item.href.lastIndexOf("/") > 0 && (
                <i className="material-icons">expand_more</i>
              )}
              {!this.props.mini && (
                <Collapse
                  className={classNames({
                    top100: item.submenu[0].name !== "Debug",
                    top212: item.submenu[0].name === "Debug",
                    submenu: !nested,
                    "submenu nested": !!nested
                  })}
                  in={true}
                  timeout="auto"
                >
                  {this.createSubmenu(item.submenu, permission, path)}
                </Collapse>
              )}
            </ListItem>
            {!!this.props.mini && (
              <Collapse
                className={!nested ? "submenu" : "submenu nested"}
                in={this.state[newString] || item.href.lastIndexOf("/") === 0}
                timeout="auto"
              >
                {this.createSubmenu(item.submenu, permission, path)}
              </Collapse>
            )}
          </React.Fragment>
        );
      }
    });
  }

  setListIcon(icon) {
    if (!!icon) {
      return (
        <i className="material-icons" color="primary">
          {icon}
        </i>
      );
    }
    return "";
  }

  changeOrderByPriority(list) {
    if (!list) return false;
    return list.sort((a, b) => a.priority - b.priority);
  }

  render() {
    const { menuItem, permission, fallBackMenuItem, entitlements } = this.props;

    const path = window.location.pathname;

    if (!menuItem && !permission) {
      return <div className="text-center">{INPROGRESS}</div>;
    }

    if (
      !!menuItem &&
      !!menuItem.menuitems &&
      !!menuItem.menuitems.length &&
      !!permission
    ) {
      this.menuItem = this.createMenuItems(
        menuItem.menuitems,
        permission,
        false,
        path
      );
    }

    return (
      <List component="nav" className="sidebar nav">
        {this.menuItem}
      </List>
    );
  }
}

const mapStateToProps = state => ({
  menuItem: state.menuItem.data,
  entitlements: state.menuItem.entitlements,
  entinterface: state.menuItem.entinterface
});

export default connect(
  mapStateToProps,
  {
    getMenuList,
    fallBackMenuItem,
    getEntitlements,
    getInterfaceFromLocalStorage
  }
)(SideMenu);
