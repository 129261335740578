import React from "react";
import Select from "@material-ui/core/Select";
import { ClickableTooltip } from "../../common/helpers/renderElement";

const MultiSelect = props => {
  const {
    selectedValues,
    selectItems,
    handleChangeMultiple,
    selectLabel,
    toolTipText
  } = props;

  return (
    <div className="form-group">
      <label className="fullWidth display-block-inline position-relative overflow-hidden">
        <span className="pull-left margin-right-16">{selectLabel}</span>
        <div className="margin-none pull-left">
          <ClickableTooltip place="right" toolTipTitle={toolTipText} />
        </div>
      </label>
      <Select
        className="clear-both margin-top-16 display-block"
        multiple
        native
        value={selectedValues}
        onChange={event => handleChangeMultiple(event)}
        inputProps={{
          id: "select-multiple-native"
        }}
      >
        {selectItems.map((item, index) => (
          <option
            key={item.value + "_" + index}
            data-text={item.text}
            value={item.value}
          >
            {item.text}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default MultiSelect;
