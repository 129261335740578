import React, { Component } from "react";
import { RenderAutoSelect } from "../../common/helpers/renderElement";
import Button from "@material-ui/core/Button";
import DatePickerRange from "../DatePicker/DatePicker";
import { getUTCString } from "../../common/helpers/dateHelper";
import { Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./dateRange.scss";

export default class DateRange extends Component {
  dateRange = {
    startDate: this.props.dateRangeStartDate,
    endDate: this.props.dateRangeEndDate
  };
  state = {
    definitionIndex: this.props.definitionIndex,
    changeDateExpand: this.props.expandView,
    dateRangeSelection: "Last 48 hours"
  };

  constructor(props) {
    super(props);
    this.handleUpdateUnitDefComponent = this.handleUpdateUnitDefComponent.bind(
      this
    );
    this.handleUpdateDefinitionComponent = this.handleUpdateDefinitionComponent.bind(
      this
    );
  }

  handleUpdateDefinitionComponent(selected, name, dateRangeDefinition) {
    if (!!selected && !!selected.value) {
      const value = selected.value;
      const { dateRangeDefinitionObject, onCategoryChange } = this.props;
      if (!!dateRangeDefinitionObject && !!dateRangeDefinitionObject.length) {
        const { definitionIndex } = this.state;

        let machedSelection = dateRangeDefinitionObject[definitionIndex].filter(
          item => item.value === value
        );

        if (!!machedSelection) {
          this.setState({
            dateRangeSelection: machedSelection[0].text
          });
        }
        onCategoryChange(selected, name, dateRangeDefinition);
      }
    }
  }

  handleUpdateUnitDefComponent(selected, name, object, cKey, cObject) {
    if (!!selected && !!selected.value) {
      const value = selected.value;
      if (value !== "custom") {
        const matched = object.filter(item => item.value === value);
        if (!!matched.length) {
          this.setState({
            definitionIndex: matched[0].relIndex,
            dateRangeSelection: cObject[matched[0].relIndex][0].text
          });
          this.props.onUnitChange(
            selected,
            name,
            cKey,
            cObject[matched[0].relIndex][0].value
          );
        }
      } else {
        this.props.onUnitChange(selected, name);
      }
    }
  }

  updateStartDate(date) {
    this.dateRange.startDate = date;
    this.props.onCustomDateChange(this.dateRange);
  }

  updateEndDate(date) {
    this.dateRange.endDate = date;
    this.props.onCustomDateChange(this.dateRange);
  }

  render() {
    const {
      dateRangeUnit,
      dateRangeUnitValue,
      dateRangeUnitObject,
      dateRangeUnitLabel,
      dateRangeDefinition,
      dateRangeDefinitionValue,
      dateRangeDefinitionObject,
      dateRangeDefinitionLabel,
      onClickBtnDateRange,
      setExpandView,
      expandView,
      dateRangeStartDate,
      dateRangeEndDate
    } = this.props;

    const { definitionIndex, dateRangeSelection } = this.state;

    return (
      <div className="date-range-container">
        <div className="margin-top-16">
          <Typography
            variant="h4"
            className="margin-right-16 display-block-inline"
          >
            {getUTCString(dateRangeStartDate)}
          </Typography>
          {!!dateRangeEndDate && (
            <Typography variant="h4" className="display-block-inline">
              - &nbsp;{getUTCString(dateRangeEndDate)}
            </Typography>
          )}
          <Typography variant="h4" className="display-block-inline">
            &nbsp;<strong>({dateRangeSelection})</strong>
          </Typography>
          <Typography
            variant="h4"
            className="margin-left-16 display-block-inline expand-date-range margin-right-16 link-default-paper"
            onClick={() => setExpandView()}
          >
            Change date range{" "}
            {!expandView && (
              <ChevronRightIcon className="icon" color="primary" />
            )}
            {!!expandView && (
              <ExpandMoreIcon className="icon" color="primary" />
            )}
          </Typography>
        </div>
        {!!expandView && (
          <div className="margin-top-16">
            <div className="date-range-container-controls">
              <span className="auto-complete order1 border-effect">
                {!!dateRangeUnitObject && !!dateRangeUnitObject.length && (
                  <RenderAutoSelect
                    className="width-200"
                    id={dateRangeUnit}
                    name={dateRangeUnit}
                    value={dateRangeUnitValue}
                    defaultValue={"0"}
                    placeholderSuffix={dateRangeUnitLabel.toLowerCase()}
                    handleChange={(selected, name) =>
                      this.handleUpdateUnitDefComponent(
                        selected,
                        name,
                        dateRangeUnitObject,
                        dateRangeDefinition,
                        dateRangeDefinitionObject
                      )
                    }
                    items={dateRangeUnitObject}
                    label={dateRangeUnitLabel}
                  />
                )}
              </span>
              {dateRangeUnitValue !== "custom" && (
                <span className="auto-complete order2 border-effect">
                  <RenderAutoSelect
                    className="width-200"
                    id={dateRangeDefinition}
                    name={dateRangeDefinition}
                    value={dateRangeDefinitionValue}
                    defaultValue="0"
                    placeholderSuffix={dateRangeDefinitionLabel.toLowerCase()}
                    handleChange={(selection, name) =>
                      this.handleUpdateDefinitionComponent(
                        selection,
                        name,
                        dateRangeDefinition
                      )
                    }
                    items={dateRangeDefinitionObject[definitionIndex]}
                    label={dateRangeDefinitionLabel}
                  />
                </span>
              )}
            </div>
            {dateRangeUnitValue === "custom" && (
              <div className="datepicker-container">
                <DatePickerRange
                  onChangeStartDate={startDate =>
                    this.updateStartDate(startDate)
                  }
                  onChangeEndDate={endDate => this.updateEndDate(endDate)}
                  startDate={dateRangeStartDate}
                  endDate={dateRangeEndDate}
                />
              </div>
            )}
            <Button
              color="primary"
              onClick={() => onClickBtnDateRange()}
              variant="contained"
              size="medium"
              className="btn-apply "
            >
              Apply
            </Button>
          </div>
        )}
      </div>
    );
  }
}
