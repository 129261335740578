import {
    ERROR_IN_POLICY,
    DELETE_POLICY,
    GET_POLICY,
    GET_POLICIES,
    UPDATE_POLICY,
    IS_POLICY_MATCH,
    CDN_TAB
} from '../actions/types';

const initialState = {
    policies: null,
    policy: null,
    deletePolicy: false,
    policyError: "",
    distributionId: null,
    cdnTab: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_POLICIES:
            return {
                ...state,
                policies: action.data
            }
        case UPDATE_POLICY:
            return {
                ...state,
                policy: action.data
            }
        case DELETE_POLICY:
            return {
                ...state,
                deletePolicy: action.data
            }
        case GET_POLICY:
            return {
                ...state,
                policy: action.data
            }
        case ERROR_IN_POLICY:
            return {
                ...state,
                policyError: action.error,
                policies: action.data
            }
        case IS_POLICY_MATCH:
            return {
                ...state,
                distributionId: action.data
            }
        case CDN_TAB:
            return {
                ...state,
                cdnTab: action.tab
            }
        default:
            return state;
    }
}

