export const passwordCheck = {
  strongPassword: (value, rule) => {
    const definedRule =
      rule ||
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\[\]:{};,."()=!#$%&'*+\/?@^_|~-])[A-Za-z\d\[\]:{};,."()=!#$%&'*+\/?@^_|~-]{8,}$/;
    return definedRule.test(value);
  },
  hasCharacters: (value, length = 7) => {
    return value.length > length;
  },
  hasNumbers: (value) => {
    return /\d/.test(value);
  },
  hasSpecialChar: (value) => {
    let specialCharRegex = /[\[\]:}{;,."()=!#$%&'*+\/?@^_|~-]/;
    return specialCharRegex.test(value) && value.length > 11;
  },
};
