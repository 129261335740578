import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./forms.scss";

class FormFieldsEmbeded extends PureComponent {
  render() {
    let {
      cancelFormText,
      confirmFormText,
      cancelFormAction,
      submitDataInProgress,
      dialogContent,
      linearProgress,
      formSubmit,
      hasFormError,
      nextFormAction,
      previousFormAction,
      tabValue,
      handleTabChange,
      tabs,
      onScroll,
      readOnlyClass,
      hideTab
    } = this.props;

    return (
      <form
        id="dialogForm"
        className="dialog-form fullwidth embeded"
        onSubmit={formSubmit}
      >
        <div className="dialog-form-heading">
          {!!linearProgress && (
            <LinearProgress
              color="primary"
              className="form-status"
              variant="determinate"
              value={linearProgress}
            />
          )}
          <Divider />
        </div>
        <div
          className={"dialog-form-body" + readOnlyClass}
          onScroll={!!onScroll ? e => onScroll(e) : () => {}}
        >
          {!!tabs && !!tabs.length && (
            <React.Fragment>
              <AppBar position="sticky" color="default">
                {!hideTab && (
                  <Tabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    onChange={handleTabChange}
                    scrollButtons="on"
                  >
                    {tabs.map((value, index) => (
                      <Tab
                        key={"tab_" + index}
                        className={"tab-item tab_" + index}
                        label={value.label}
                      />
                    ))}
                  </Tabs>
                )}
              </AppBar>
            </React.Fragment>
          )}
          <FormControl className="fullwidth">{dialogContent}</FormControl>
        </div>
        <div className="dialog-form-footer margin-left-16 margin-right-16">
          <Divider />
          {!tabs && (
            <React.Fragment>
              <Button
                variant="contained"
                size="large"
                onClick={cancelFormAction}
                color="secondary"
              >
                {cancelFormText}
              </Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {!!confirmFormText && (
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  color="primary"
                  autoFocus
                  disabled={
                    (linearProgress === 100 && submitDataInProgress) ||
                    !!hasFormError
                  }
                >
                  {!submitDataInProgress ? (
                    confirmFormText
                  ) : (
                    <CircularProgress
                      size={22}
                      thickness={6}
                      color="secondary"
                    />
                  )}
                </Button>
              )}
              &nbsp;&nbsp;
            </React.Fragment>
          )}
          {!!tabs && !!tabs.length && (
            <React.Fragment>
              {!!previousFormAction && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={previousFormAction}
                  color="secondary"
                  disabled={tabValue === 0}
                >
                  Back
                </Button>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {!!nextFormAction && (
                <Button
                  disabled={tabValue === tabs.length - 1}
                  variant="contained"
                  size="large"
                  onClick={nextFormAction}
                  color="primary"
                  autoFocus
                >
                  Next
                </Button>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
              {!!confirmFormText && (
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  type="submit"
                  color="primary"
                  autoFocus
                  disabled={submitDataInProgress}
                >
                  {!submitDataInProgress ? (
                    confirmFormText
                  ) : (
                    <CircularProgress
                      size={22}
                      thickness={6}
                      color="secondary"
                    />
                  )}
                </Button>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </React.Fragment>
          )}
        </div>
      </form>
    );
  }
}

export default withMobileDialog()(FormFieldsEmbeded);
