import React, { Component } from "react";
import "./App.scss";
import RouteService from "./common/helpers/routeService";
import { withStyles } from "@material-ui/core/styles";
import { globalStyles } from "./common/branding/brandconfig";
import Footer from "./components/footer/footer";
import LocationChangeListener from "./common/helpers/locationChangeListener";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  getUserDetails,
  switchUserDetails,
  resetUsers
} from "./actions/userDetailsAction";
import { resetHourlyChart, resetMonthlyChart } from "./actions/chartsActions";
import {
  resetDistribution,
  resetTopDistribution,
  resetAllDistribution
} from "./actions/distributionAction";
import { resetTilesData } from "./actions/tilesActions";
import { connect } from "react-redux";
import { session as userSession } from "./common/helpers/sessionValues";
import Button from "@material-ui/core/Button";
import {
  resetCustomerOverview,
  getCustomerOverviewDetails
} from "./actions/customerOverviewAction";
import { resetList } from "./actions/originsAction";
import { resetCertification } from "./actions/configurationAction";
import { resetPolicies } from "./actions/policyAction";
import { getAPIVersion } from "./actions/versionDetailAction";
import LinearIndeterminate from "./components/Loader/LinearLoader";
import { BrowserRouter as Router } from "react-router-dom";
import authClient from "./common/helpers/auth";
import Sidebar from "./components/Sidebar/Sidebar";
import logo from "./assets/image/male_80x80.png";

let styles = () => globalStyles();

class App extends Component {
  constructor() {
    super();
    this.state = {
      pendingRequests: 1,
      className: "loader-horizontal",
      role: ""
    };
  }

  componentDidMount() {
    this.props.getAPIVersion();
    this.setRole();
  }

  componentWillUnmount() {
    authClient.clearRenewInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      customerData,
      switchUserData,
      getCustomerOverviewDetails
    } = this.props;

    if (!customerData && !switchUserData) {
      getCustomerOverviewDetails();
    }
    if (!customerData && !!switchUserData) {
      getCustomerOverviewDetails(null, null, switchUserData.id);
    }
    this.setRole();
  }

  setRole() {
    const { role } = this.state;
    const { customerData, switchUserData } = this.props;

    this.getRole();

    if (!switchUserData && !!role && role != this.role) {
      this.setState({
        role: this.getRole() || localStorage.role
      });
    }

    if (!!switchUserData) {
      console.log(switchUserData.id);
    }

    if ((!!switchUserData || !!customerData) && this.role !== role) {
      this.setState({
        role: this.getRole() || localStorage.role
      });
    }
  }

  findFirstResellerMatch(customerData, match) {
    for (let ii = 0, jj = customerData.length; ii < jj; ii++) {
      if (customerData[ii].type === match) {
        return customerData[ii];
      }
    }
    return customerData[0];
    // return JSON.parse(localStorage.customerDetails).roleName
  }

  resetView() {
    this.props.switchUserDetails(null);
    this.props.resetUsers();
    this.props.resetCustomerOverview();
    this.props.resetList();
    this.props.resetCertification();
    this.props.resetPolicies();
    this.props.resetMonthlyChart();
    this.props.resetHourlyChart();
    this.props.resetDistribution();
    this.props.resetAllDistribution();
    this.props.resetTopDistribution();
    this.props.resetTilesData();
    userSession.removeSessionItem("SwitchCustomerDetail");
    userSession.removeSessionItem("SwitchUserDetail");
    const { getUserDetails } = this.props;
    if (!!getUserDetails) {
      getUserDetails();
    }
  }

  getRole() {
    const { customerData, switchUserData } = this.props;
    if (!!customerData) {
      const currentCustomer = this.findFirstResellerMatch(
        customerData,
        "RESELLER_ADMIN"
      );
      this.role =
        currentCustomer.type === "reseller"
          ? "RESELLER_ADMIN"
          : "CUSTOMER_ADMIN";
    }

    if (!!switchUserData) {
      this.role =
        switchUserData.type === "reseller"
          ? "RESELLER_ADMIN"
          : "CUSTOMER_ADMIN";
    }

    localStorage.setItem("role", [this.role]);

    return this.role;
  }
  render() {
    const { classes, label, userData, switchUserData } = this.props;
    const { pendingRequests, role } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Router>
          <LocationChangeListener>
            <LinearIndeterminate
              color="primary"
              completed={pendingRequests}
              className="loader-horizontal"
            />
            <div className={classes.backgroundElm}>
              <div className={classes.container}>
                {!!userData && (
                  <Sidebar
                    logo={logo}
                    label={label}
                    userData={userData}
                    role={role}
                  />
                )}
                <RouteService label={label} userData={userData} role={role} />
                {!!switchUserData ? (
                  <Button
                    variant="text"
                    className="btn-reset pull-right position-sticky warning"
                    onClick={() => this.resetView()}
                  >
                    You are viewing as "{switchUserData.name}". Click to reset
                    the view.
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Footer label={label} />
          </LocationChangeListener>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user.data,
  switchUserData: state.user.switchUserData,
  customerData: state.customerOverview.data
});

export default connect(
  mapStateToProps,
  {
    getUserDetails,
    switchUserDetails,
    resetUsers,
    resetCustomerOverview,
    resetDistribution,
    resetList,
    resetCertification,
    resetPolicies,
    getAPIVersion,
    resetMonthlyChart,
    resetHourlyChart,
    resetTopDistribution,
    resetTilesData,
    resetAllDistribution,
    getCustomerOverviewDetails
  }
)(withStyles(styles)(App));
