const REQUEST_COUNT = "Request count"; // requests
const TRANSFER_RATE = "Transfer rate"; // bandwidth
const TRANSFERRED_DATA = "Transferred data"; // datatransfer
const CACHEHIT_PERCENTAGE = "Cache-hit percentage"; // cachehit

export const unitCategoryObject = [
  { text: REQUEST_COUNT, metric: "requests", unit: "number" },
  { text: TRANSFER_RATE, metric: "bandwidth", unit: "datathroughput" },
  { text: TRANSFERRED_DATA, metric: "datatransfer", unit: "datatransfer" },
  { text: CACHEHIT_PERCENTAGE, metric: "cachehit", unit: "percentage" }
];

export const dateRangeUnitObject = [
  {
    value: "minutes",
    text: "By minutes",
    relIndex: 0
  },
  {
    value: "hours",
    text: "By hours",
    relIndex: 1
  },
  {
    value: "days",
    text: "By days",
    relIndex: 2
  },
  {
    value: "weeks",
    text: "By weeks",
    relIndex: 3
  },
  {
    value: "months",
    text: "By months",
    relIndex: 4
  },
  {
    value: "years",
    text: "By years",
    relIndex: 5
  },
  {
    value: "custom",
    text: "Custom",
    relIndex: 6
  }
];
export const minutesCollection = [
  {
    value: "-5 minutes",
    text: "Last 5 minutes"
  },
  {
    value: "-15 minutes",
    text: "Last 15 minutes"
  },
  {
    value: "-30 minutes",
    text: "Last 30 minutes"
  }
];

export const hoursCollection = [
  {
    value: "-1 hour",
    text: "Last 1 hour"
  },
  {
    value: "-3 hours",
    text: "Last 3 hours"
  },
  {
    value: "-6 hours",
    text: "Last 6 hours"
  },
  {
    value: "-12 hours",
    text: "Last 12 hours"
  },
  {
    value: "-24 hours",
    text: "Last 24 hours"
  },
  {
    value: "-48 hours",
    text: "Last 48 hours"
  }
];

export const daysCollection = [
  {
    value: "0 day full end 0 day full",
    text: "Today"
  },
  {
    value: "0 day full end 0 day current",
    text: "Today so far"
  },
  {
    value: "-1 day current end 0 day current",
    text: "Yesterday"
  },
  {
    value: "-2 days full end -1 day full",
    text: "Day before yesterday"
  },
  {
    value: "-7 days current end 0 days current",
    text: "Last 7 days"
  },
  {
    value: "-15 days current end 0 days current",
    text: "Last 15 days"
  },
  {
    value: "-30 days current end 0 days current",
    text: "Last 30 days"
  },
  {
    value: "-45 days current end 0 days current",
    text: "Last 45 days"
  },
  {
    value: "-60 days current end 0 days current",
    text: "Last 60 days"
  },
  {
    value: "-90 days current end 0 days current",
    text: "Last 90 days"
  }
];

export const weeksCollection = [
  {
    value: "0 week full end 7 week full",
    text: "This week"
  },
  {
    value: "0 week full end 0 week current",
    text: "This week so far"
  },
  {
    value: "-7 week full end -7 week full",
    text: "Last week"
  },
  {
    value: "-7 week full end -7 week full last",
    text: "This day last week"
  }
];

export const monthsCollection = [
  {
    value: "0 month full end 30 month full",
    text: "This month"
  },
  {
    value: "0 month full end 0 month full",
    text: "This month so far"
  },
  {
    value: "-30 month full end -30 month full",
    text: "Last month"
  },
  {
    value: "-90 month full end 0 month current",
    text: "Last 3 months"
  },
  {
    value: "-180 month full end 0 month current",
    text: "Last 6 months"
  },
  {
    value: "-360 month full end 0 month current",
    text: "Last 12 months"
  }
];

export const yearsCollection = [
  {
    value: "0 year full end 0 year current",
    text: "This year so far"
  },
  {
    value: "-365 year full end -365 year full",
    text: "Last year"
  }
];

export const dateRangeDefinitionObject = [
  minutesCollection,
  hoursCollection,
  daysCollection,
  weeksCollection,
  monthsCollection,
  yearsCollection
];

export const metricsDropdown = [
  {
    value: "datatransfer",
    text: "Datatransfer"
  },
  { value: "requests", text: "Requests" },
  { value: "cachehit", text: "Cachehit" },
  { value: "bandwidth", text: "Bandwidth" }
];

export const granularityDropdown = [
  {
    value: "auto",
    text: "Auto"
  },
  {
    value: "5m",
    text: "5 Minutes"
  },
  { value: "15m", text: "15 Minutes" },
  { value: "1h", text: "1 Hour" },
  { value: "1d", text: "1 Day" }
];

export const groupByDropdownMcdnPremium = [
  {
    value: "none",
    text: "None"
  },
  {
    value: "distribution",
    text: "Distribution"
  },
  { value: "provider", text: "Provider" }
];

export const groupByDropdown = [
  {
    value: "none",
    text: "None"
  },
  {
    value: "distribution",
    text: "Distribution"
  },
  { value: "provider", text: "Provider" },
  { value: "region", text: "Region" }
];
