import React, { PureComponent } from "react";
import MultiSelect from "../Selects/MultiSelect";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Paper from "@material-ui/core/Paper";

class CountryListSelection extends PureComponent {
  leftSideList = [];
  rightSideList = [];

  state = {
    leftSideSelectedValues: [],
    rightSideSelectedValues: []
  };

  constructor(props) {
    super(props);
    this.handleChangeLeftMultiSelect = this.handleChangeLeftMultiSelect.bind(
      this
    );
    this.handleChangeRightMultiSelect = this.handleChangeRightMultiSelect.bind(
      this
    );
  }

  moveAllToRight() {
    let leftSideList = this.props.leftSideMultiSelect;
    let rightSideList = this.props.rightSideMultiSelect;
    let newArray = rightSideList.concat(leftSideList);
    newArray.sort(this.sortStringFunction);
    this.props.setLeftSideValues([]);
    this.props.setRightSideValues(newArray);
    return true;
  }

  moveAllToLeft() {
    let leftSideList = this.props.leftSideMultiSelect;
    let rightSideList = this.props.rightSideMultiSelect;
    let newArray = leftSideList.concat(rightSideList);
    newArray.sort(this.sortStringFunction);
    this.props.setLeftSideValues(newArray);
    this.props.setRightSideValues([]);
    return true;
  }

  moveToRight() {
    let rightSideArray = this.props.rightSideMultiSelect;
    let newLeftSideArray = this.props.leftSideMultiSelect;
    for (let ii = 0, jj = this.leftSideList.length; ii < jj; ii++) {
      let isUnique = true;
      for (
        let vv = 0, cs = this.props.rightSideMultiSelect.length;
        vv < cs;
        vv++
      ) {
        if (
          this.leftSideList[ii].value ===
          this.props.rightSideMultiSelect[vv].value
        ) {
          isUnique = false;
        }
      }
      if (!!isUnique) {
        this.updateAvailableCountryList(rightSideArray, newLeftSideArray, ii);
      }
    }
    return true;
  }

  moveToLeft() {
    if (!this.rightSideList.length) return false;
    let leftSideList = this.props.leftSideMultiSelect;
    leftSideList = leftSideList.concat(this.rightSideList);
    leftSideList.sort(this.sortFunction);
    this.props.setLeftSideValues(leftSideList);
    this.removeFromSelectedCountries();
    return true;
  }

  removeFromSelectedCountries() {
    let rightSideArray = this.props.rightSideMultiSelect;
    let getIndexCollection = [];
    for (let ii = 0, jj = this.rightSideList.length; ii < jj; ii++) {
      getIndexCollection.push(this.rightSideList[ii].refIndex);
    }

    for (let rr = getIndexCollection.length - 1; rr >= 0; rr--) {
      rightSideArray.splice(getIndexCollection[rr], 1);
    }
    this.rightSideList = [];
    this.props.setRightSideValues(rightSideArray);
    return true;
  }

  updateAvailableCountryList(rightSideArray, leftSideArray, refIndex) {
    if (!!this.leftSideList && !!this.leftSideList.length) {
      rightSideArray.push({
        text: this.leftSideList[refIndex].text,
        value: this.leftSideList[refIndex].value
      });
      leftSideArray.filter((item, index) => {
        if (item.value === this.leftSideList[refIndex].value) {
          leftSideArray.splice(index, 1);
        }
        return true;
      });

      this.props.setLeftSideValues(leftSideArray);
      this.props.setRightSideValues(rightSideArray);
      return true;
    }
    return false;
  }

  getFormatedListAndValues(event) {
    const { options } = event.target;
    const values = [];
    const selectedList = [];
    for (let ii = 0, jj = options.length; ii < jj; ii += 1) {
      if (options[ii].selected) {
        values.push(options[ii].value);
        selectedList.push({
          text: options[ii].getAttribute("data-text"),
          value: options[ii].value,
          refIndex: ii
        });
      }
    }
    return {
      formatedList: selectedList,
      values: values
    };
  }

  handleChangeLeftMultiSelect(event) {
    if (!!event) {
      const formatedListAndValues = this.getFormatedListAndValues(event);
      this.props.setLeftSideSelectedValues(formatedListAndValues.values);
      this.leftSideList = formatedListAndValues.formatedList;
      return true;
    }
    return false;
  }

  handleChangeRightMultiSelect(event) {
    if (!!event) {
      const formatedListAndValues = this.getFormatedListAndValues(event);
      this.props.setRightSideSelectedValues(formatedListAndValues.values);
      this.rightSideList = formatedListAndValues.formatedList;
      return true;
    }
    return false;
  }

  render() {
    const {
      leftSideMultiSelect,
      rightSideMultiSelect,
      leftSideSelectedValues,
      rightSideSelectedValues
    } = this.props;

    return (
      <div>
        <Grid container spacing={8} className="margin-top-16">
          <Grid item xs={5}>
            <Paper className="padding-16 select-uniform">
              {!!leftSideMultiSelect && (
                <MultiSelect
                  selectedValues={leftSideSelectedValues}
                  selectItems={leftSideMultiSelect}
                  selectLabel="Available countries"
                  toolTipText="Select from available countries."
                  handleChangeMultiple={event =>
                    this.handleChangeLeftMultiSelect(event)
                  }
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={2} className="text-center padding-16">
            <IconButton
              aria-label="move to selected"
              color="primary"
              title="Move to selected countries"
              className="arrow-double margin-top-16 buttonStyle"
              onClick={() => this.moveAllToRight()}
            >
              <NavigateNextIcon color="primary" />
              <NavigateNextIcon color="primary" />
            </IconButton>
            <br />
            <IconButton
              aria-label="move to selected"
              color="primary"
              title="Move to selected countries"
              className="margin-top-16 buttonStyle"
              onClick={() => this.moveToRight()}
            >
              <NavigateNextIcon color="primary" />
            </IconButton>
            <br />
            <IconButton
              aria-label="move to available"
              color="secondary"
              title="Move to available countries"
              className="buttonStyle margin-top-16"
              onClick={() => this.moveToLeft()}
            >
              <NavigateBeforeIcon color="secondary" />
            </IconButton>
            <br />
            <IconButton
              aria-label="move to selected"
              color="primary"
              title="Move to selected countries"
              className="arrow-double margin-top-16 buttonStyle rotate180"
              onClick={() => this.moveAllToLeft()}
            >
              <NavigateNextIcon color="secondary" />
              <NavigateNextIcon color="secondary" />
            </IconButton>
          </Grid>
          <Grid item xs={5}>
            <Paper className="padding-16 select-uniform">
              {!!rightSideMultiSelect && (
                <MultiSelect
                  selectedValues={rightSideSelectedValues}
                  selectItems={rightSideMultiSelect}
                  selectLabel="Selected countries"
                  toolTipText="Selected from available countries."
                  handleChangeMultiple={event =>
                    this.handleChangeRightMultiSelect(event)
                  }
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default CountryListSelection;
