import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddBox } from "@material-ui/icons";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { toolbarStyles } from "../../common/helpers/tableRulesAndStyles";
import { withStyles } from "@material-ui/core/styles";

export let EnhancedTableToolbar = props => {
  const {
    numSelected,
    classes,
    handleDeleteClick,
    handleAddClick,
    subheading,
    toolTipTitle,
    additionalActions,
    hideDefaultOptions,
    background,
    disabledAdd
  } = props;
  const additionalTools =
    !!additionalActions &&
    additionalActions.map((value, index) => {
      const title = value.name || "";
      return (
        <Tooltip title={title || ""} key={value.name + index}>
          <IconButton
            color="primary"
            aria-label={value.name}
            onClick={value.action}
          >
            {value.icon}
          </IconButton>
        </Tooltip>
      );
    });

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
        "background-default": !!background
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography color="inherit" variant="h3" id="tableTitle">
            {subheading}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div className="button-horizontal">
            {!hideDefaultOptions && (
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={handleDeleteClick}>
                  <DeleteIcon color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {additionalTools}
          </div>
        ) : (
          !hideDefaultOptions &&
          !disabledAdd && (
            <React.Fragment>
              <Tooltip title={toolTipTitle}>
                <IconButton aria-label={toolTipTitle} onClick={handleAddClick}>
                  <AddBox color="primary" />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
