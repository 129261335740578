import { AVAILABLE_COUNTRYLIST, SELECTED_COUNTRYLIST } from "../actions/types";

const initialState = {
    availableCountryList: null, 
    selectedCountryList: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AVAILABLE_COUNTRYLIST:
            return {
                ...state,
                availableCountryList: action.availableCountryList
            }
        case SELECTED_COUNTRYLIST:
            return {
                ...state,
                selectedCountryList: action.selectedCountryList
            }
        default:
            return state;
    }
}

