import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { apiDataMapper } from "../../common/helpers/ApiDataMapper";
import { contactDataMapper } from "../../common/constants/customer";
import Notification from "../../common/helpers/notification";
import "./forms.scss";
import Grid from "@material-ui/core/Grid";
import { emailValidation } from "../../common/helpers/sharedHelper";
import {
  RenderInputWithToolTip,
  RenderSwitch
} from "../../common/helpers/renderElement";

const FORMDIALOG = {
  CUSTOMERNAME: "The account name for easy reference and name should be less than or equal to 255 characters",
  CUSTOMERDESC: "The description of the account should be less than or equal to 512 characters",
  EMAIL:
    "The email address of the admin user, this will also be the username to login with.",
  PHONENUMBER: "The phone number of the admin user.",
  FIRSTNAME: "The first name of the admin user.",
  LASTNAME: "The last name of the admin user."
};

const MANDATORY = "Please fill the required field(s)!";

export const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

class FormDialog extends Component {
  formElementsValue = {};
  contactDataArray = [];
  callOnce = false;

  cutomerDetails = {
    name: "",
    description: "",
    email: "",
    customerType: "reseller-customer",
    firstName: "",
    lastName: "",
    phoneNumber: ""
  };

  contactData = {};

  initState = {
    progressStatus: 0,
    value: 0,
    openFixedNotification: false,
    isMandatoryView: false
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.closeFixedNotification = this.closeFixedNotification.bind(this);
    this.loadContactData = this.loadContactData.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.flattenObject = this.flattenObject.bind(this);
  }

  componentWillReceiveProps(props) {
    if (!this.callOnce && !!props.contactData) {
      this.flattenObject(props.contactData);
      this.callOnce = true;
    }

    if (!props.openFormDialog) {
      this.resetState();
    }
  }
  state = {
    ...this.initState,
    ...this.cutomerDetails,
    ...this.contactData
  };

  resetState() {
    let merged = {
      ...this.initState,
      ...this.cutomerDetails,
      ...this.contactData
    };
    this.setState(merged);
    this.formElementsValue = {};
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
    const container = document.querySelector(".react-scroll-view-container");
    if (!!container) {
      const topPos = container.querySelector("[tab = '" + value + "']")
        .offsetTop;
      document.querySelector(".dialog-form-body").scrollTop = topPos;
    }
  };

  loadContactData = (event, value) => {
    this.flattenObject(this.props.contactData);
    this.handleTabChange(event, value);
  };

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.checked });
  };

  handleFieldChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.changeProgressStatus(e.target.name, e.target.value);
  }

  getTotalFormFields() {
    const fixedFields = 7;
    // const contactFields = this.contactDataArray.length;
    return fixedFields;
  }

  changeProgressStatus(name, value) {
    if (!!value) {
      this.formElementsValue[name] = 1;
    } else {
      delete this.formElementsValue[name];
    }
    let percentage =
      (Object.keys(this.formElementsValue).length / this.getTotalFormFields()) *
      100;
    this.setState({
      progressStatus: percentage
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = {
      name: this.state.name,
      description: this.state.description,
      email: this.state.email,
      customerType: this.state.customerType,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber
    };

    const payload = {
      userDetails: this.getValidUserDetails(data),
      customerContactDetails: contactDataMapper(this)
    };
    if (!Object.keys(payload.userDetails).length) {
      this.setState({ value: 0, openFixedNotification: true });
      return false;
    }
    this.props.confirmFormAction(payload);
  }

  getValidUserDetails(data) {
    for (let key in data) {
      if (data[key] === "") {
        return {};
      }
    }

    return data;
  }

  flattenObject(contactData, parent) {
    for (let key in contactData) {
      if (typeof contactData[key] !== "object" || contactData[key] === null) {
        let keyName = !!parent
          ? key + apiDataMapper.changeCase(parent, 0)
          : key + apiDataMapper.changeCase(key, 0);
        this.contactDataArray.push({ [keyName]: contactData[key] });
        this.setState({ [keyName]: contactData[key] });
      } else {
        this.flattenObject(contactData[key], key);
      }
    }
  }

  closeFixedNotification() {
    this.setState({ openFixedNotification: false });
  }

  onScroll(e) {
    const { value } = this.state;
    // const container = document.querySelector('.react-scroll-view-container');

    if (e.target.scrollTop > 0 && e.target.scrollTop < 365 && value !== 0) {
      this.setState({ value: 0 });
    }

    if (e.target.scrollTop > 380 && value !== 1) {
      this.setState({ value: 1 });
    }
  }

  switchView() {
    this.setState({
      isMandatoryView: !this.state.isMandatoryView
    });
  }

  render() {
    let {
      openFormDialog,
      formHeading,
      cancelFormText,
      confirmFormText,
      cancelFormAction,
      submitDataInProgress,
      contactData,
      progressStatus,
      paddingNode,
      tabs,
      classes
    } = this.props;
    let { value, isMandatoryView, openFixedNotification } = this.state;

    const mandatoryRadioField = (
      <div className="advance-view pull-right">
        <span className="margin-right-16 inactive">
          Enable this option to see only required fields.
        </span>
        <label>Advanced</label>
        <RenderSwitch
          label="Advanced"
          name="advanced"
          checked={isMandatoryView}
          id="advanced"
          handleRadioChange={() => this.switchView()}
          value={isMandatoryView}
          className="switch-ios"
          classes={classes}
        />
      </div>
    );

    return (
      <div>
        <Dialog
          open={openFormDialog}
          onClose={cancelFormAction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
        >
          <form id="dialogForm" className="dialog-form">
            <div className="dialog-form-heading">
              <Grid container>
                <Grid item xs={12}>
                  <DialogTitle
                    id="alert-dialog-title"
                    className="form-heading heading-title"
                  >
                    {formHeading}
                  </DialogTitle>
                  <IconButton
                    className="heading-icon margin-16 pull-right"
                    aria-label="Back"
                    color="primary"
                    onClick={cancelFormAction}
                    xs={2}
                  >
                    <i className="material-icons">close</i>
                  </IconButton>
                  &nbsp;&nbsp;
                </Grid>
              </Grid>
              {progressStatus && (
                <LinearProgress
                  color="primary"
                  className="form-status"
                  variant="determinate"
                  value={progressStatus}
                />
              )}
              <Divider />
            </div>
            <div className="dialog-form-body" onScroll={this.onScroll}>
              {!isMandatoryView && (
                <AppBar position="sticky" color="default">
                  {!!tabs && (
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      onChange={this.handleTabChange}
                      scrollButtons="on"
                    >
                      {tabs.map((value, index) => (
                        <Tab
                          key={"tab_" + index}
                          className={"tab-item tab_" + index}
                          label={value.label}
                        />
                      ))}
                    </Tabs>
                  )}
                </AppBar>
              )}
              <DialogContent className={!!paddingNode ? "padding-none " : ""}>
                {mandatoryRadioField}
                <FormControl className="react-scroll-view-container fullWidth">
                  <div className="fullwidth" tab="0">
                    <Typography variant="h6" align="left">
                      {tabs[0].label}
                    </Typography>
                    <Notification
                      close={this.closeFixedNotification}
                      open={openFixedNotification}
                      variant="error"
                      description={MANDATORY}
                      buttontype="default"
                      buttonlabel="Close"
                      buttonsize="small"
                    />

                    <RenderInputWithToolTip
                      placeholder="Account name to use."
                      id="name"
                      name="name"
                      type="text"
                      value={this.state.name}
                      label="Account name, for easy reference."
                      required
                      error={!this.state.name}
                      handleChange={this.handleChange}
                      tab="0"
                      toolTipTitle={FORMDIALOG.CUSTOMERNAME}
                      maxLength={255}
                    />

                    <RenderInputWithToolTip
                      placeholder="Description of the account."
                      id="description"
                      name="description"
                      type="text"
                      value={this.state.description}
                      label="Account description."
                      required
                      error={!this.state.description}
                      handleChange={this.handleChange}
                      tab="0"
                      toolTipTitle={FORMDIALOG.CUSTOMERDESC}
                    />

                    <RenderInputWithToolTip
                      placeholder="Type customer email address"
                      id="email"
                      name="email"
                      type="email"
                      value={this.state.email}
                      label="Email address"
                      required
                      error={emailValidation(this.state.email)}
                      handleChange={this.handleChange}
                      tab="0"
                      toolTipTitle={FORMDIALOG.EMAIL}
                    />

                    <RadioGroup
                      aria-label="customer-type"
                      name="customerType"
                      className="display-block margin-top-16"
                      value={this.state.customerType}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value="reseller"
                        control={<Radio color="primary" />}
                        label="Reseller account"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="reseller-customer"
                        control={<Radio color="primary" />}
                        label="Reseller customer"
                        labelPlacement="end"
                      />
                    </RadioGroup>

                    <RenderInputWithToolTip
                      placeholder="First name of the admin user."
                      id="firstName"
                      name="firstName"
                      type="text"
                      value={this.state.firstName}
                      label="First name"
                      required
                      error={!this.state.firstName}
                      handleChange={this.handleChange}
                      tab="0"
                      toolTipTitle={FORMDIALOG.FIRSTNAME}
                    />

                    <RenderInputWithToolTip
                      placeholder="Last name of the admin user."
                      id="lastName"
                      name="lastName"
                      type="text"
                      value={this.state.lastName}
                      tab="0"
                      label="Last name"
                      required
                      error={!this.state.lastName}
                      handleChange={this.handleChange}
                      toolTipTitle={FORMDIALOG.LASTNAME}
                    />

                    <RenderInputWithToolTip
                      placeholder="Type phone number"
                      id="phoneNumber"
                      name="phoneNumber"
                      type="text"
                      value={this.state.phoneNumber}
                      tab="0"
                      label="Phone number"
                      required
                      error={!this.state.phoneNumber}
                      handleChange={this.handleChange}
                      minLength="10"
                      toolTipTitle={FORMDIALOG.PHONENUMBER}
                    />
                  </div>
                  {!isMandatoryView && (
                    <div className="fullwidth" tab="1">
                      <Typography variant="h6" align="left">
                        {tabs[1].label}
                      </Typography>
                      <FormGroup row>
                        {Object.keys(contactData).map((key, index) =>
                          apiDataMapper.createFields(
                            this,
                            key,
                            contactData[key],
                            index
                          )
                        )}
                      </FormGroup>
                    </div>
                  )}
                </FormControl>
              </DialogContent>
            </div>
            <div className="dialog-form-footer">
              <Divider />
              <DialogActions>
                <LinearProgress
                  color="secondary"
                  variant="determinate"
                  value={100}
                />
                <Button
                  variant="contained"
                  size="large"
                  onClick={cancelFormAction}
                  color="secondary"
                  autoFocus
                >
                  {cancelFormText}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  color="primary"
                  disabled={progressStatus === 100 && submitDataInProgress}
                  onClick={this.handleSubmit}
                >
                  {!submitDataInProgress ? (
                    confirmFormText
                  ) : (
                    <CircularProgress
                      size={22}
                      thickness={6}
                      color="secondary"
                    />
                  )}
                </Button>
              </DialogActions>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default FormDialog;
