import React, { Component } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import authClient from "../../common/helpers/auth";
import {
  showNotification,
  hideNotification,
} from "../../actions/notificationAction";
import { getUserDetails } from "../../actions/userDetailsAction";
import Notification, {
  initialState,
  setNotificationVisibility,
} from "../../common/helpers/notification";
import { connect } from "react-redux";
import { INPROGRESSSECONDARY } from "../../common/helpers/loader";
import { selectText, copyToClipboard } from "../../common/helpers/sharedHelper";
import { deserializeQueryString } from "../../common/helpers/stringHelper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";

window.Buffer = window.Buffer || require("buffer").Buffer;

const SWAGGER_ROOT_PATH = localStorage.getItem("apiUrl") + "openapi.json";

class Swagger extends Component {
  role = "";

  componentWillMount() {
    document.body.className = "swaggerUi-container";
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.props.hideNotification();
    document.body.className = "swaggerUi-container";
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  captureELement(e) {
    if (e.target.getAttribute("class") === "btn try-out__btn cancel") {
      const customerDetails = JSON.parse(localStorage.customerDetails);
      if (!!customerDetails && !!customerDetails.customerId) {
        let elm = document.querySelector('[placeholder="customerId"]');
        elm.setAttribute("value", customerDetails.customerId);
      }
    }
  }

  addDocumentEventListener() {
    document.addEventListener("click", this.captureELement);
  }

  removeDocumentEventListener() {
    document.removeEventListener("click", this.captureELement);
  }

  state = {
    supportedSubmitMethods: ["get", "post", "put", "delete", "patch"],
    apisSorter: "alpha",
    showRequestHeaders: false,
    validatorUrl: null,
    deepLinking: true,
    docExpansion: "none",
    onComplete: function () {},
    url: SWAGGER_ROOT_PATH,
    ...initialState(),
  };

  getCustomerId(defaultCustomerId) {
    const { location } = this.props;
    const search = !!location ? location.search : "";
    const customerId = !!deserializeQueryString(search)
      ? deserializeQueryString(search).customerId
      : defaultCustomerId;

    return customerId;
  }

  responseInterceptor = (res) => {
    if (res.state !== 200) {
      this.setState({ docExpansion: "none" });
    }
  };

  requestInterceptor = (req) => {
    const Url = req.url;
    if (Url !== SWAGGER_ROOT_PATH) {
      const token = authClient.getBearerToken();
      req.headers["Authorization"] = token;
    }
    return req;
  };

  render() {
    let { open, userData, role } = this.props;
    let { error, variant, label, color, docExpansion } = this.state;

    if (!!userData) {
      this.role = role;
    }

    if (!userData) {
      return <div className="text-center">{INPROGRESSSECONDARY}</div>;
    }

    return (
      <React.Fragment>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        <SwaggerUI
          requestInterceptor={this.requestInterceptor}
          responseInterceptor={this.responseInterceptor}
          docExpansion={docExpansion}
          {...this.state}
        />
        {userData.customerId && (
          <div id="clipboard" className="warning">
            <span className="icon">
              <ArrowBackIcon color="secondary" />
            </span>
            <span className="text label inactive">
              {" "}
              <b>Customer id:</b>
              <br />
              <input
                onClick={(e) => selectText(e)}
                id="clipboard-text"
                readOnly
                defaultValue={this.getCustomerId(userData.customerId)}
              />
              <br />
              <Button
                variant="contained"
                color="primary"
                className="clipboard-btn height-8"
                onClick={() => copyToClipboard("#clipboard-text")}
              >
                Click to copy
              </Button>
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.notification.items,
});

export default connect(mapStateToProps, {
  showNotification,
  hideNotification,
  getUserDetails,
})(Swagger);
