import {
    ERROR_IN_ORIGIN,
    DELETE_ORIGIN, GET_ORIGIN, GET_ORIGINS, UPDATE_ORIGIN, ADD_ORIGIN,
    RESET_ORIGINS
} from '../actions/types';

const initialState = {
    origins: null,
    origin: null,
    deleteResponse: false,
    originError:""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ORIGINS:
            return {
                ...state,
                origins: action.data
            }
        case UPDATE_ORIGIN:
            return {
                ...state,
                origin: action.data
            }
        case ADD_ORIGIN:
            return {
                ...state,
                origin: action.data
            }
        case DELETE_ORIGIN:
            return {
                ...state,
                deleteResponse: action.data
            }
        case GET_ORIGIN:
            return {
                ...state,
                origin: action.data
            }
        case RESET_ORIGINS:
            return {
                ...state,
                origins: action.data
            }
        case ERROR_IN_ORIGIN:
            return {
                ...state,
                originError: action.error
            }
        default:
            return state;
    }
}

