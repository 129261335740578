import {
  RECIEVED_MENU_ITEMS,
  RECEIVED_MENU_ENTITLEMENT,
  RECEIVED_INTERFACE
} from "../actions/types";

const initialState = {
  data: "",
  isFetching: true,
  entitlements: null,
  entinterface: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVED_INTERFACE:
      return {
        ...state,
        entinterface: action.entinterface,
        isFetching: false
      };
    case RECIEVED_MENU_ITEMS:
      return {
        ...state,
        data: action.data,
        isFetching: false
      };
    case RECEIVED_MENU_ENTITLEMENT:
      return {
        ...state,
        entitlements: action.entitlements,
        isFetching: false
      };
    default:
      return state;
  }
}
