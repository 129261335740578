import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./login.scss";
import { Redirect } from "react-router-dom";
import "redux";
import Notification from "../../common/helpers/notification";
import authClient from "../../common/helpers/auth";
import {
  RenderInput,
  RenderButton,
  RenderSubmitButton,
} from "../../common/helpers/renderElement";
import AppBar from "@material-ui/core/AppBar";
import Header from "../../components/header/header";
import axios from "axios";

var pendingXHRRequests = 0;
axios.interceptors.request.use(
  function (config) {
    const elm = document.querySelector(".loader-horizontal");
    pendingXHRRequests++;
    if (pendingXHRRequests !== 0 && !!elm) {
      elm.style.display = "block";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    pendingXHRRequests--;
    removeLoader();
    return response;
  },
  function (error) {
    pendingXHRRequests--;
    removeLoader();
    return Promise.reject(error);
  }
);

window.onbeforeunload = beforeUnload();

function beforeUnload() {
  localStorage.removeItem("SwitchCustomerDetail");
  localStorage.removeItem("SwitchUserDetail");
}

function removeLoader() {
  const elm = document.querySelector(".loader-horizontal");

  if (pendingXHRRequests === 0 && !!elm) {
    elm.style.display = "none";
  }
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      redirectToReferrer: !!localStorage.length ? true : false,
      isLoaded: true,
      items: {},
      error: "",
      variant: "",
      open: false,
      username: "",
      password: "",
      additional: "",
      duration: 5000,
      btnLabel: "Close",
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const values = {
      username: this.state.username,
      password: this.state.password,
      additional: this.state.additional,
    };

    let _self = this;
    _self.setState({ isLoaded: false });
    authClient.signIn(values, function (result) {
      if (!!result && typeof result === "object") {
        _self.setState({
          isLoaded: true,
          items: result,
          open: false,
          variant: "",
          redirectToReferrer: true,
        });
      } else {
        _self.setFormState(result);
      }
    });
  }

  setFormState(error) {
    return this.setState({
      redirectToReferrer: false,
      isLoaded: true,
      error: error,
      open: true,
      variant: "error",
    });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      redirectToReferrer: false,
      open: false,
      error: "",
      variant: "",
    });
  };

  render() {
    let { from } = this.props.location.state || {
      from: { pathname: "/dashboard", state: this.state },
    };
    let {
      redirectToReferrer,
      open,
      error,
      variant,
      isLoaded,
      btnLabel,
      duration,
    } = this.state;
    let { label } = this.props;

    if (!!redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <React.Fragment>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype="primary"
          buttonlabel={btnLabel}
          buttonsize="small"
        />
        <AppBar
          position="fixed"
          color="default"
          className="appBar"
          elevation={0}
        >
          <div className="height-6">
            <Header toggle={true} label={this.props.label} />
          </div>
        </AppBar>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} className="login">
            <Paper xs={12} className="login-paper">
              <form onSubmit={this.handleSubmit} className="login-form">
                <Typography variant="h3" align="center">
                  Log in to your {label} CDN account
                </Typography>
                <RenderInput
                  placeholder="Type your username/email"
                  id="username"
                  type="text"
                  required
                  label="Email/username"
                  icon="account_circle"
                  value={this.state.username}
                  onChange={this.handleChange}
                />

                <RenderInput
                  placeholder="Type your password"
                  id="password"
                  type="password"
                  required
                  label="Password"
                  icon="lock"
                  value={this.state.password}
                  onChange={this.handleChange}
                />

                <RenderButton
                  name="forgot"
                  pushlink="/forgot-password"
                  color="default"
                  type="button"
                  size="small"
                  align="right"
                  label="Forgot password?"
                />

                <RenderInput
                  placeholder="Type your 2FA code"
                  id="additional"
                  className="margin-top-32"
                  type="text"
                  label="2FA code (if active)"
                  icon="vpn_key"
                  value={this.state.additional}
                  onChange={this.handleChange}
                />

                <RenderButton
                  name="forgot"
                  pushlink="/forgot-2fa"
                  color="default"
                  type="button"
                  size="small"
                  align="right"
                  label="Forgot 2FA?"
                />

                <RenderSubmitButton
                  name="login"
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="medium"
                  align="left"
                  label="Login"
                  disabled={!isLoaded}
                  fullWidth
                />
              </form>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default Login;
