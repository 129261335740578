import React from 'react';
import "./datePicker.scss";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";

export default function DatePickerRange(props) {
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date(props.startDate));
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(props.endDate));

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    props.onChangeStartDate(date._d);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    props.onChangeEndDate(date._d);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} className="datepicker-container-mui">
        <DateTimePicker
          label="Start date"
          inputVariant="outlined"
          id="date-picker-dialog"
          value={selectedStartDate}
          autoOk={false}
          onChange={handleStartDateChange}
        />

        <DateTimePicker
          label="End date"
          inputVariant="outlined"
          id="date-picker-dialog2"
          value={selectedEndDate}
          autoOk={false}
          onChange={handleEndDateChange}
        />
    </MuiPickersUtilsProvider>
   );
}