import {
    GET_API_VERSION
} from '../actions/types';

const initialState = {
    appVersion: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_API_VERSION:
            return {
                ...state,
                appVersion: action.data
            }
        default:
            return state;
    }
}

