import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0,0,0,.125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,.03)",
    borderBottom: "1px solid rgba(0,0,0,.125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(props => <MuiAccordionSummary {...props} />);

AccordionSummary.muiName = "AccordionSummary";

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(1) * 2
  }
}))(MuiAccordionDetails);

class CustomizedExpansionPanel extends React.Component {
  state = {
    expanded: this.props.defaultPanel
  };

  componentDidUpdate(prevProps, prevState){
    // console.log(this.props.defaultPanel);
    if(prevProps.defaultPanel !== this.props.defaultPanel){
        this.setState({
          expanded: this.props.defaultPanel
        });
        this.props.updateSelectedPanel(this.props.defaultPanel);
    }
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : true
    });
    this.props.updateSelectedPanel(panel);
  };

  render() {
    const { expansionItems } = this.props;
    const { expanded } = this.state;
    return (
      <div className="expansion-container">
        {expansionItems.map((item, index) => {
          return (
            <Accordion
              key={"expPanel" + index}
              square
              expanded={expanded === item.panelName}
              onChange={this.handleChange(item.panelName)}
            >
              <AccordionSummary>
                <Typography>{item.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>{item.value}</AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
}

export default CustomizedExpansionPanel;
