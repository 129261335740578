import { GET_CERTIFICATE_LIST, DELETE_CERTIFICATE, UPDATE_CERTIFICATE, SET_PARAMS } from '../actions/types';

const initialState = {
    certificates: null,
    deletedItem: null,
    updatedCertificate: null,
    locParams: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PARAMS:
            return {
                ...state,
                locParams: action.locParams
            }
        case GET_CERTIFICATE_LIST:
            return {
                ...state,
                certificates: action.data
            }
        case UPDATE_CERTIFICATE:
            return {
                ...state,
                updatedCertificate: action.data
            }
        case DELETE_CERTIFICATE:
            return {
                ...state,
                deletedItem: action.data
            }
        default:
            return state;
    }
}

