import React from "react";
import Notification from "../../common/helpers/notification";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { RESELLER } from "../../common/constants/roles";
import { getUserDetails } from "../../actions/userDetailsAction";
import Typography from "@material-ui/core/Typography";
import AmChartCardMultiple from "../Cards/AMChartCardMultiple";
import Grid from "@material-ui/core/Grid";
import { COLUMNCHART, LINECHART } from "../../common/constants/chart";
import {
  drawAmLineChartRequests,
  drawAmBarChartDataTransfer,
  drawAmLineChartDataRate,
  drawAmLineChartRequests1H
} from "../../actions/chartsActions";
import { RenderAutoSelect } from "../../common/helpers/renderElement";
import { Paper } from "@material-ui/core";
import {
  placeholderChartDynMessage
} from "../../common/helpers/loader";
import { Redirect } from "react-router-dom";
import StatisticsBase from "../Statistics/StatisticsBase";
import {
  getFullNameMonth,
  getFullYear,
  getLastTwelveMonths,
  getMonthRangeFromEndDate,
  formatDateTimeWithHours
} from "../../common/helpers/dateHelper";

const DATA_TRANSFERRED = "Data transferred";
const TRANFER_RATE = "Transfer rate";

class MonthlyTotals extends StatisticsBase {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      amBarChartDataTransferArr: null,
      amLineChartDataRateArr: null,
      amLineChartRequestsArr: null,
      dateOptions: null
    };
  }

  componentWillMount() {
    const date = new Date();
    this.setStartEndDatesOfMonth(date);
    this.getDateOptions();
    this.props.getUserDetails();
  }

  setStartEndDatesOfMonth(date) {
    const firstDayOfDate = new Date(date.setDate(1));
    const dateTmpObj = { start: firstDayOfDate, end: firstDayOfDate };
    this.startDateAdjuster(dateTmpObj);
    this.endDateAdjuster(dateTmpObj);
    this.start = dateTmpObj.start;
    this.end = dateTmpObj.end;
  }

  async getDateOptions() {
    let today = new Date();
    await getLastTwelveMonths(today).then(response => {
      const autoSelectFormat = this.getDateFormatSingleArray(response);
      this.setState({
        dateOptions: autoSelectFormat
      });
    });
  }

  startDateAdjuster(payload) {
    const date = new Date(payload.start);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDay.setDate(firstDay.getDate() + 1);
    this.start = firstDay.setUTCHours(0, 0, 0);
    payload.start = formatDateTimeWithHours(this.start);
  }

  endDateAdjuster(payload) {
    const date = new Date(payload.end);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setDate(lastDay.getDate() + 1);
    this.end = lastDay.setUTCHours(23, 59, 59);
    payload.end = formatDateTimeWithHours(this.end);
  }

  async drawAmBarChartDataTransfer(customerId) {
    const _this = this,
      unitCategory = "datatransfer";
    const endpoint = _this.getCustomerType(RESELLER);
    const granularity = "1h";
    const payload = _this.setPayloadStatistics("datatransfer", granularity);

    await this.props.drawAmBarChartDataTransfer(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(_this, res, unitCategory);
        _this.setState({
          amBarChartDataTransferArr: chartItem
        });
        return true;
      }
    );
  }

  async drawAmLineChartRequests(customerId) {
    const _this = this,
      unitCategory = "number";
    const endpoint = _this.getCustomerType(RESELLER);
    const granularity = "1h";
    const payload = _this.setPayloadStatistics("datatransfer", granularity);

    await this.props.drawAmLineChartRequests(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(_this, res, unitCategory);
        _this.setState({
          amLineChartRequestsArr: chartItem
        });

        return true;
      }
    );
  }

  async drawAmLineChartDataRate(customerId) {
    const _this = this,
      unitCategory = "datathroughput";
    const endpoint = _this.getCustomerType(RESELLER);
    const granularity = "1h";
    const payload = _this.setPayloadStatistics("bandwidth", granularity);

    await this.props.drawAmLineChartDataRate(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(_this, res, unitCategory);
        _this.setState({
          amLineChartDataRateArr: chartItem
        });

        return true;
      }
    );
  }

  updateCharts(customerId) {
    this.drawAmBarChartDataTransfer(customerId);
    this.drawAmLineChartDataRate(customerId);
    this.drawAmLineChartRequests(customerId);
  }

  handleAutoSelectChange(selected, name, self, parent, customerId) {
    if (!!selected && !!selected.value) {
      parent[name] = selected.value; //updating value
      self.setState({ parent });
      const date = new Date(selected.value);
      this.setStartEndDatesOfMonth(date);
      this.updateCharts(customerId);
      return true;
    }
    return false;
  }

  getDateFormatSingleArray(obj) {
    let object = [];
    for (let ii = 0, jj = obj.length; ii < jj; ii++) {
      object.push({
        value: obj[ii],
        text: getFullNameMonth(obj[ii]) + ", " + getFullYear(obj[ii])
      });
    }
    return object;
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      dateOptions,
      amBarChartDataTransferArr,
      amLineChartRequestsArr,
      amLineChartDataRateArr
    } = this.state;
    let { open, userData, role, switchUserData } = this.props;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!this.multiCount && !!userData) {
      this.multiCount = 1;
      this.drawAmBarChartDataTransfer(customerId);
      this.drawAmLineChartDataRate(customerId);
      this.drawAmLineChartRequests(customerId);
    }

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" color="initial" className="page-title">
              Statistics
            </Typography>
            {RESELLER !== role && (
              <Typography variant="h4" color="initial">
                Customize and view performance statistics
              </Typography>
            )}
          </Grid>
        </Grid>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        {
          <React.Fragment>
            {
              <Paper className="padding-left-default padding-right-default pull-left fullwidth margin-bottom-16 margin-top-16">
                <div className="margin-top-16 margin-bottom-16 pull-left margin-top-16">
                  <Typography variant="h4" color="initial">
                    Monthly total
                  </Typography>
                </div>
                {!!dateOptions && (
                  <span className="auto-complete border-effect pull-right margin-top-16 margin-bottom-16">
                    <RenderAutoSelect
                      className="width-200"
                      id="selectedDateOption"
                      name="selectedDateOption"
                      placeholderSuffix="Month"
                      defaultValue="0"
                      handleChange={(selected, name) =>
                        this.handleAutoSelectChange(
                          selected,
                          name,
                          this,
                          this.state,
                          customerId
                        )
                      }
                      items={dateOptions}
                      label="Month"
                    />
                  </span>
                )}
              </Paper>
            }

            <div className="clear-both  margin-top-16"> </div>
            {!!amLineChartDataRateArr && !!amLineChartDataRateArr.length ? (
              <AmChartCardMultiple
                data={amLineChartDataRateArr[0].data}
                xAxisText={amLineChartDataRateArr[0].xAxisText}
                valueY={amLineChartDataRateArr[0].valueY}
                categoryX={amLineChartDataRateArr[0].categoryX}
                yAxisText={TRANFER_RATE}
                id="chartdiv_div1"
                type={LINECHART}
                unit={amLineChartDataRateArr[0].unit}
                additionalData={amLineChartDataRateArr[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(amLineChartDataRateArr, null, 540)
            )}

            <div className="margin-top-16"> </div>
            {!!amBarChartDataTransferArr &&
            !!amBarChartDataTransferArr.length ? (
              <AmChartCardMultiple
                hasMultiple={true}
                data={amBarChartDataTransferArr[0].data}
                xAxisText={amBarChartDataTransferArr[0].xAxisText}
                valueY={amBarChartDataTransferArr[0].valueY}
                categoryX={amBarChartDataTransferArr[0].categoryX}
                yAxisText={DATA_TRANSFERRED}
                id="chartdiv_div2"
                type={COLUMNCHART}
                unit={amBarChartDataTransferArr[0].unit}
                additionalData={amBarChartDataTransferArr[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(amBarChartDataTransferArr, null, 540)
            )}

            <div className="margin-top-16"> </div>
            {!!amLineChartRequestsArr && !!amLineChartRequestsArr.length ? (
              <AmChartCardMultiple
                hasMultiple={true}
                data={amLineChartRequestsArr[0].data}
                xAxisText={amLineChartRequestsArr[0].xAxisText}
                valueY={amLineChartRequestsArr[0].valueY}
                categoryX={amLineChartRequestsArr[0].categoryX}
                yAxisText="Request count"
                id="chartdiv_div4"
                type={COLUMNCHART}
                unit={amLineChartRequestsArr[0].unit}
                additionalData={amLineChartRequestsArr[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(amLineChartRequestsArr, null, 540)
            )}
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  switchUserData: state.user.switchUserData
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    drawAmLineChartRequests,
    drawAmBarChartDataTransfer,
    drawAmLineChartDataRate,
    drawAmLineChartRequests1H
  }
)(MonthlyTotals);
