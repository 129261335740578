import authClient from "../../common/helpers/auth";
import React from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { session as userSession } from "../../common/helpers/sessionValues";

const DEFAULT_ERROR_MESSAGE = "Oops!! something went wrong, try again.";

export function debuggingPostCall(payload, customerId, callback) {
  if (!authClient.isAuthenticated() || !localStorage.length) {
    return <Redirect to="/" />;
  }

  const token = authClient.getBearerToken();
  let url = userSession.getSessionItem("apiUrl") + customerId + "/debug/url";

  axios({
    method: "post",
    url: url,
    data: payload,
    headers: {
      Authorization: token
    }
  })
    .then(result => {
      if (!!result && result.status === 200) {
        callback(result.data.response);
      } else {
        callback([]);
      }
    })
    .catch(error => {
      setErrorResponse(callback, error);
    });
}

function setErrorResponse(callback, error) {
  let response = error.response;
  let message = DEFAULT_ERROR_MESSAGE;
  if (response.status === 404) {
    message = "Page not found. Try again!";
  } else {
    if (!!response) {
      message = response.data.message;
    }
  }
  callback(message);
}
