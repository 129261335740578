import React from "react";
import BaseComponent from "../../common/base/BaseComponent";
import Notification, {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import AmChartCardMultiple from "../Cards/AMChartCardMultiple";
import AMPIEChartMultiple from "../Cards/AMPIEChartMultiple";
import EnhancedTable from "../Table/EnhancedTable";
import { RESELLER } from "../../common/constants/roles";
import { getUserDetails } from "../../actions/userDetailsAction";
import { getCustomerOverviewDetails } from "../../actions/customerOverviewAction";
import { getInterfaceFromLocalStorage } from "../../actions/menuItemAction";
import Typography from "@material-ui/core/Typography";
import Tiles from "../Cards/Tiles";
import TableCard from "../Cards/TableCard";
import Grid from "@material-ui/core/Grid";
import { dashboardChartVMMapper } from "../../common/constants/chart";
import {
  tilesVMMapperNew,
  getTargetName,
  REQUESTS,
  BYTESDELIVERED,
  CACHEHIT,
  DATATRANSFER
} from "../../common/constants/tile";
import { COLUMNCHART, LINECHART } from "../../common/constants/chart";
import {
  drawMontlyChart,
  drawHourlyChart,
  resetHourlyChart,
  resetMonthlyChart,
  getTotalResults
} from "../../actions/chartsActions";

import { toggleCustomer } from "../../actions/customerOverviewAction";
import { getFrontPageTiles } from "../../actions/tilesActions";
import {
  formatDateTime,
  formatDateTimeWithHours,
  getOneMonthDateRange,
  getStartEndTimeInADay
} from "../../common/helpers/dateHelper";
import {
  getAllDistributionGroups,
  resetDistribution
} from "../../actions/distributionAction";
import { Paper } from "@material-ui/core";
import {
  tableLoaderEmpty,
  placeholderChartDynMessage,
  staticPlaceholderTableWithParam
} from "../../common/helpers/loader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "../Cards/card.scss";
import AlertDialog from "../Dialog/AlertDialog";

class Dashboard extends BaseComponent {
  role = "";
  tilesFormattedData = [];
  tableData = [];
  calledOnce = false;
  toggleMessage = "";
  selectedCustomerName = "";
  selectedCustomerId = "";
  toggleValue = "";
  metricMonthly = "datatransfer";
  metricHourly = "bandwidth";
  toggleCustomerFunction = () => {};

  tableRows = [
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Distribution"
    },
    { id: "value", numeric: true, disablePadding: false, label: "Value" }
  ];

  topListType = "";
  topListField = "";
  topLocationsUnitCategory = "";
  topLocationsUnitDesc = "";
  callActive = false;
  calledEntitlementOnce = false;
  targetMonthlyChart = null;
  targetHourlyChart = null;

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.state = {
      ...initialState(),
      monthlyChartData: null,
      monthlyChartPIEProvider: null,
      monthlyChartPIERegion: null,
      hourlyChartData: null,
      tilesObject: [],
      selectedDateOption: null,
      distGroupId: "*",
      refreshTiles: true,
      subheading: "",
      selectedDistribution: "*",
      chartDefault: tableLoaderEmpty,
      openDialog: false,
      topDistInMonth: null
    };
  }

  componentWillMount() {
    this.setSelectedDate();
    this.props.getUserDetails();
    this.updateChart("none");
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.callActive = false;
    this.calledOnce = false;
    this.props.hideNotification();
    const { getInterfaceFromLocalStorage } = this.props;
    if (!!getInterfaceFromLocalStorage) {
      getInterfaceFromLocalStorage();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !!nextProps.switchUserData &&
      nextProps.switchUserData !== this.props.switchUserData
    ) {
      this.setState({
        tilesObject: [],
        monthlyChartData: [],
        monthlyChartPIEProvider: [],
        monthlyChartPIERegion: [],
        hourlyChartData: []
      });
    }
  }

  setSelectedDate() {
    let date = new Date();
    this.setState({
      selectedDateOption: date.setHours(0, 0, 0, 0)
    });
  }

  updateChart(subheading, customerId) {
    const type = getTargetName(subheading);
    this.topListType = "main";
    this.topLocationsUnitCategory = "datatransfer";
    this.topLocationsUnitDesc = "Data Transferred";

    switch (type) {
      case BYTESDELIVERED:
        this.topListField = "transferred";
        this.metricMonthly = "datatransfer";
        this.metricHourly = "bandwidth";
        break;
      case REQUESTS:
        this.topListField = "requests";
        this.metricMonthly = "requests";
        this.metricHourly = "requests";
        break;
      case CACHEHIT:
        this.topListField = "cacheHit";
        this.metricMonthly = "cachehit";
        this.metricHourly = "cachehit";
        break;
      default:
        this.topListField = "transferred";
        this.metricMonthly = "datatransfer";
        this.metricHourly = "bandwidth";
    }
    // reset chart data
    if (!!customerId && this.props.role !== RESELLER) {
      this.setState({
        chartDefault: tableLoaderEmpty,
        subheading: subheading,
        monthlyChartPIEProvider: [],
        monthlyChartPIERegion: []
      });
      this.drawMontlyChartCommon(customerId, true, null);

      this.checkForValidEntitlement(["cdn-shield", "cdn-all"]).then(
        entitlementBool => {
          if (!entitlementBool) {
            this.drawMontlyChartCommon(
              customerId,
              false,
              "provider",
              "monthlyChartPIEProvider"
            );
          }
        }
      );

      this.checkForValidEntitlement("mcdn-premium").then(entitlementBool => {
        if (!entitlementBool) {
          this.drawMontlyChartCommon(
            customerId,
            false,
            "region",
            "monthlyChartPIERegion",
            true
          );
        }
      });
    }
  }

  getTilesData(customerId, metricInput, lastTile, callback) {
    const { selectedDateOption } = this.state,
      { role } = this.props,
      _this = this,
      date = this.getStartEndDates(selectedDateOption, true);

    const endpoint = "/totals";
    const customerType = role === RESELLER ? "reseller" : "customer";
    const payload = {
      start: date.startDate,
      end: date.endDate,
      metric: metricInput
    };

    this.props.getFrontPageTiles(
      customerId,
      customerType,
      payload,
      endpoint,
      function(response) {
        _this.formatDataForTiles(
          response,
          "value",
          "target",
          metricInput,
          obj => {
            _this.tilesFormattedData.push(
              tilesVMMapperNew(_this, obj[0], metricInput)
            );
          }
        );

        if (!!callback) {
          callback();
        }

        if (!!lastTile) {
          _this.setState({
            tilesObject: _this.tilesFormattedData
          });
        }
      }
    );
  }

  getStartEndDates(selectedDateOption) {
    const range = getOneMonthDateRange(selectedDateOption);
    const startDate = formatDateTime(new Date(range.monthStart));
    const endDate = formatDateTimeWithHours(new Date().getTime());
    let date = {
      startDate: startDate,
      endDate: endDate
    };

    return date;
  }

  getTopDistribution(customerId, metric, unitCategory, isReseller) {
    const _this = this;
    const endpoint = _this.getCustomerType(isReseller);
    let granularity;
    this.hasMatchingEntitlement("mcdn-premium").then(entitlements => {
      granularity = !!entitlements ? "1h" : _this.setStartEndDates(true);
      this.groupBy = "distribution";
      const payload = _this.setPayloadStatistics(
        metric,
        granularity,
        this.groupBy
      );

      _this.props.getTotalResults(customerId, payload, endpoint, function(res) {
        const masterArray = _this.getTotalResultsView(_this, res);
        if (!!masterArray && !!Object.keys(masterArray).length) {
          let topDistInMonth = _this.mergeKeysWithValue(masterArray, [
            "value",
            "description",
            "entity"
          ]);

          _this.setState({
            topDistInMonth: topDistInMonth
          });
        } else {
          _this.setState({
            topDistInMonth: []
          });
        }
      });
    });
  }

  getTableData(customerId) {
    const { selectedDateOption } = this.state;
    const date = this.getStartEndDates(selectedDateOption, true);
    this.start = date.startDate;
    this.end = date.endDate;

    this.getTopDistribution(
      customerId,
      "datatransfer",
      "datatransfer",
      RESELLER
    );
  }

  getTitleAndUnitPerMetric(metric, type) {
    let titleAndUnit = null;
    if (type === "monthly" && metric === "datatransfer") {
      titleAndUnit = {
        titleText: "Data transferred",
        unitCategory: "datatransfer"
      };
    }

    if (type === "hourly" && metric === "bandwidth") {
      titleAndUnit = {
        titleText: "Bandwidth transfer rate",
        unitCategory: "datathroughput"
      };
    }

    if (metric === "requests") {
      titleAndUnit = {
        titleText: "Requests",
        unitCategory: "number"
      };
    }

    if (metric === "cachehit") {
      titleAndUnit = {
        titleText: "cache hit",
        unitCategory: "percentage"
      };
    }
    return titleAndUnit;
  }

  drawMontlyChartCommon(
    customerId,
    refreshTiles,
    groupBy,
    stateObjectName,
    formatText
  ) {
    const { selectedDateOption } = this.state;
    let endpoint = "/customer";
    const date = this.getStartEndDates(selectedDateOption, true);
    this.hasMatchingEntitlement("mcdn-premium").then(entitlements => {
      const granularity = !!entitlements ? "1h" : "1d",
        metric = this.metricMonthly;

      const payload = {
        metric: metric,
        start: date.startDate,
        end: date.endDate
      };

      if (!groupBy) {
        payload.granularity = granularity;
      }

      if (!!groupBy) {
        payload.groupBy = groupBy;
        endpoint = "/customer/totals";
      }

      this.drawMontlyChart(
        customerId,
        refreshTiles,
        endpoint,
        payload,
        stateObjectName,
        formatText
      );
    });
  }

  getChartTitle(groupBy) {
    if (groupBy === "region") {
      return "Data transferred per region";
    } else {
      return "Data transferred per CDN";
    }
  }

  drawMontlyChart(
    customerId,
    refreshTiles,
    endpoint,
    payload,
    stateObjectName,
    formatText
  ) {
    const _this = this;
    this.props.drawMontlyChart(customerId, payload, endpoint, function(res) {
      let chartItem1;
      let response = res[0] || res;
      if (
        typeof response === "object" &&
        !!response.series &&
        !!response.series.length
      ) {
        const dataAndKey = !!payload.groupBy
          ? _this.formatDataForChartPIE(response, payload.groupBy, formatText)
          : _this.formatDataForChart(response, "value", "timestamp");
        const getTitleAndUnit = _this.getTitleAndUnitPerMetric(
          _this.metricMonthly,
          "monthly"
        );
        const additionalData = {
          titleText: getTitleAndUnit.titleText,
          unitCategory: getTitleAndUnit.unitCategory,
          singleTarget: false,
          page: "dashboard",
          type: COLUMNCHART
        };

        chartItem1 = dashboardChartVMMapper(
          _this,
          dataAndKey.data,
          !payload.groupBy
            ? "Data transferred this month"
            : _this.getChartTitle(payload.groupBy),
          getTitleAndUnit.titleText,
          !payload.groupBy ? "timestamp" : payload.groupBy,
          "value",
          COLUMNCHART,
          dataAndKey.keys,
          "",
          additionalData
        );
      } else {
        chartItem1 = [];
      }
      if (!payload.groupBy) {
        _this.setState({
          monthlyChartData: chartItem1
        });
      }
      if (!!payload.groupBy) {
        _this.setState({
          [stateObjectName]: chartItem1
        });
      }
      if (!payload.groupBy) {
        _this.drawHourlyChart(customerId, refreshTiles);
      }
    });
  }

  drawHourlyChart(customerId, refreshTiles) {
    const { tilesObject } = this.state;
    const endpoint = "/customer";
    const date = getStartEndTimeInADay(new Date().getTime(), true);
    this.hasMatchingEntitlement("mcdn-premium").then(entitlements => {
      const granularity = !!entitlements ? "1h" : "5m",
        metric = this.metricHourly;
      let groupBy;

      const payload = {
        metric: metric,
        start: date.startDate,
        end: date.endDate,
        granularity: granularity
      };

      if (!!groupBy) {
        payload.groupBy = groupBy;
      }

      const _this = this;

      this.props.drawHourlyChart(customerId, payload, endpoint, function(res) {
        let chartItem2;
        let response = res[0] || res;
        if (
          typeof response === "object" &&
          !!response.series &&
          !!response.series.length
        ) {
          const dataAndKey = _this.formatDataForChart(
            response,
            "value",
            "timestamp"
          );
          const getTitleAndUnit = _this.getTitleAndUnitPerMetric(
            _this.metricHourly,
            "hourly"
          );

          const additionalData = {
            titleText: getTitleAndUnit.titleText,
            unitCategory: getTitleAndUnit.unitCategory,
            singleTarget: false,
            page: "dashboard",
            type: LINECHART
          };

          chartItem2 = dashboardChartVMMapper(
            _this,
            dataAndKey.data,
            "Bandwidth usage",
            getTitleAndUnit.titleText,
            "timestamp",
            "value",
            LINECHART,
            dataAndKey.keys,
            "",
            additionalData
          );
        } else {
          chartItem2 = [];
        }
        _this.setState({
          hourlyChartData: chartItem2
        });
        if (!!refreshTiles && !!tilesObject && !tilesObject.length) {
          _this.tilesFormattedData = [];
          _this.getTilesData(customerId, "datatransfer", false, () => {
            _this.getTilesData(customerId, "requests", false, () => {
              _this.getTilesData(customerId, "cachehit", false, () => {
                _this.getTilesData(customerId, "95th", true);
              });
            });
          });
        }
      });
    });
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  getStaticTiles() {
    return (
      <Grid container className="tile-container">
        <Grid item xs={3}>
          <Card className="tile-arrow button margin-top-16 height-69">
            <CardContent>
              <div className="pull-left">
                <div className="tile-heading"></div>
                <div className="tile-subheading margin-top-8">Loading...</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className="tile-arrow button margin-top-16 height-69">
            <CardContent>
              <div className="pull-left">
                <div className="tile-heading"></div>
                <div className="tile-subheading margin-top-8">Loading...</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className="tile-arrow button margin-top-16 height-69">
            <CardContent>
              <div className="pull-left">
                <div className="tile-heading"></div>
                <div className="tile-subheading margin-top-8">Loading...</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className="tile-arrow button margin-top-16 height-69">
            <CardContent>
              <div className="pull-left">
                <div className="tile-heading"></div>
                <div className="tile-subheading margin-top-8">Loading...</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  setConfirmationForDelete(toggleValue, customerId, customerName, callback) {
    const action = !!toggleValue ? "enable" : "disable";
    this.toggleMessage =
      `Are you sure, you want to ${action} customer: "` + customerName + '"';
    this.selectedCustomerName = customerName;
    this.selectedCustomerId = customerId;
    this.toggleValue = toggleValue;
    this.toggleCustomerFunction = callback;
    this.handleDialogToggle();
  }

  handleToggleCustomer(toggleValue, customerId, customerName) {
    const _this = this;
    this.props.toggleCustomer(toggleValue, customerId, function(result) {
      if (typeof result === "boolean") {
        _this.setNotificationMessage("success", toggleValue, customerName);
        _this.toggleCustomerFunction();
        _this.handleDialogToggle();
      } else {
        _this.setNotificationMessage("error", toggleValue, customerName);
        _this.handleDialogToggle();
      }
    });
  }

  setNotificationMessage(responseType, toggleValue, customerName) {
    let message = '"' + customerName + '" has been';
    if (toggleValue === true) {
      message += " enabled.";
    } else {
      message += " disabled.";
    }
    this.toggleNotification(message, responseType, "Close");
  }

  getStaticPlaceHolder(chartDefault) {
    return (
      <Grid container className="placeholder__chart">
        <Grid item xs={12} md={6} className="margin-top-16">
          <Paper>{chartDefault}</Paper>
        </Grid>
        <Grid item xs={12} md={6} className="margin-top-16">
          <Paper>{chartDefault}</Paper>
        </Grid>
      </Grid>
    );
  }

  getUserId() {
    const impUserDetail = JSON.parse(localStorage.getItem("SwitchUserDetail"));
    if (!!impUserDetail) {
      return impUserDetail.id;
    }
  }

  checkForValidEntitlement(values) {
    // values could be string or array
    return new Promise((resolve, reject) => {
      resolve(this.hasMatchingEntitlement(values));
    });
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      tilesObject,
      refreshTiles,
      openDialog,
      monthlyChartData,
      monthlyChartPIEProvider,
      monthlyChartPIERegion,
      hourlyChartData,
      topDistInMonth
    } = this.state;

    let {
      open,
      userData,
      role,
      allDistributions,
      switchUserData,
      entinterface
    } = this.props;

    let staticTiles = this.getStaticTiles();

    if (!userData || !role) {
      return this.getSecordaryLoader();
    }

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!!userData && role !== RESELLER && !this.callActive) {
      const _this = this;
      if (!allDistributions) {
        this.getAllDistributionGroupsLocal(customerId, () => {
          _this.getTableData(customerId);
        });
      } else {
        _this.getTableData(customerId);
      }
      this.callActive = true;
    }

    if (
      entinterface !== null &&
      entinterface !== undefined &&
      !this.calledEntitlementOnce
    ) {
      this.drawMontlyChartCommon(customerId, refreshTiles, null);
      this.checkForValidEntitlement(["cdn-shield", "cdn-all"]).then(
        entitlementBool => {
          if (!entitlementBool) {
            this.drawMontlyChartCommon(
              customerId,
              false,
              "provider",
              "monthlyChartPIEProvider"
            );
          }
        }
      );

      this.checkForValidEntitlement("mcdn-premium").then(entitlementBool => {
        this.calledEntitlementOnce = true;
        if (!entitlementBool) {
          this.drawMontlyChartCommon(
            customerId,
            false,
            "region",
            "monthlyChartPIERegion"
          );
        }
      });
    }

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={RESELLER !== role ? 6 : 12}>
            {RESELLER !== role && (
              <Typography
                variant="h4"
                className="page-title-sub"
                color="initial"
              >
                Overview of your statistics
              </Typography>
            )}
          </Grid>
        </Grid>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        <AlertDialog
          openDialog={openDialog}
          heading="Confirm"
          message={this.toggleMessage}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          cancelBtnAction={() => this.handleDialogToggle()}
          confirmBtnAction={() =>
            this.handleToggleCustomer(
              this.toggleValue,
              this.selectedCustomerId,
              this.selectedCustomerName
            )
          }
        />
        {RESELLER === role ? (
          <EnhancedTable
            customerId={customerId}
            notification={obj => this.notificationVisibility(obj)}
            role={role}
            activateImpersonate={customerId =>
              this.activateImpersonate(customerId)
            }
            setConfirmationForDelete={(
              value,
              selectedCustomerId,
              selectedCustomerName,
              callback
            ) =>
              this.setConfirmationForDelete(
                value,
                selectedCustomerId,
                selectedCustomerName,
                callback
              )
            }
          />
        ) : (
          <React.Fragment>
            {!!tilesObject && !!tilesObject.length ? (
              <Tiles
                items={tilesObject}
                onClick={subheading => this.updateChart(subheading, customerId)}
              />
            ) : (
              staticTiles
            )}
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                className="padding-right-default margin-top-16"
              >
                {!!monthlyChartData && !!monthlyChartData.length ? (
                  <AmChartCardMultiple
                    hasMultiple={true}
                    chartTitle={monthlyChartData[0].title}
                    data={monthlyChartData[0].data}
                    xAxisText={monthlyChartData[0].xAxisText}
                    valueY={monthlyChartData[0].valueY}
                    categoryX={monthlyChartData[0].categoryX}
                    yAxisText={monthlyChartData[0].yAxisText}
                    id="chartdiv_multiple"
                    unit={monthlyChartData[0].unit}
                    additionalData={monthlyChartData[0].additionalData}
                  />
                ) : (
                  placeholderChartDynMessage(monthlyChartData, null, 569.59)
                )}
              </Grid>

              <Grid item xs={12} md={6} className="margin-top-16 ">
                {!!hourlyChartData && !!hourlyChartData.length ? (
                  <AmChartCardMultiple
                    hasMultiple={true}
                    chartTitle={hourlyChartData[0].title}
                    data={hourlyChartData[0].data}
                    xAxisText={hourlyChartData[0].xAxisText}
                    valueY={hourlyChartData[0].valueY}
                    categoryX={hourlyChartData[0].categoryX}
                    yAxisText={hourlyChartData[0].yAxisText}
                    id="chartdiv_multiple1"
                    unit={hourlyChartData[0].unit}
                    additionalData={hourlyChartData[0].additionalData}
                  />
                ) : (
                  placeholderChartDynMessage(hourlyChartData, null, 569.59)
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                className="padding-right-default margin-top-16 position-relative"
              >
                {!!monthlyChartPIEProvider &&
                  !!monthlyChartPIEProvider.length && (
                    <AMPIEChartMultiple
                      className={
                        "float-left box-shadow__none-right fullheight" +
                        (!!this.hasMatchingEntitlementWithoutPromise(
                          "mcdn-premium"
                        )
                          ? " fullwidth"
                          : " ")
                      }
                      hasMultiple={true}
                      data={monthlyChartPIEProvider[0].data}
                      xAxisText={monthlyChartPIEProvider[0].xAxisText}
                      valueY={monthlyChartPIEProvider[0].valueY}
                      categoryX={monthlyChartPIEProvider[0].categoryX}
                      yAxisText="Data transferred"
                      chartTitle={monthlyChartPIEProvider[0].title}
                      id="chartdiv_multiple3"
                      unit={monthlyChartPIEProvider[0].unit}
                      additionalData={monthlyChartPIEProvider[0].additionalData}
                    />
                  )}
                {!!monthlyChartPIERegion &&
                  !!monthlyChartPIERegion.length &&
                  !!monthlyChartPIEProvider &&
                  !!monthlyChartPIEProvider.length &&
                  !this.hasMatchingEntitlementWithoutPromise([
                    "mcdn-premium",
                    "cdn-shield",
                    "cdn-all"
                  ]) && <div className="overlay-white"></div>}
                {!!monthlyChartPIERegion && !!monthlyChartPIERegion.length ? (
                  <AMPIEChartMultiple
                    className="margin box-shadow__none-left fullheight"
                    hasMultiple={true}
                    data={monthlyChartPIERegion[0].data}
                    xAxisText={monthlyChartPIERegion[0].xAxisText}
                    valueY={monthlyChartPIERegion[0].valueY}
                    categoryX={monthlyChartPIERegion[0].categoryX}
                    yAxisText="Data transferred"
                    chartTitle={monthlyChartPIERegion[0].title}
                    id="chartdiv_multiple4"
                    unit={monthlyChartPIERegion[0].unit}
                    additionalData={monthlyChartPIERegion[0].additionalData}
                  />
                ) : (
                  (monthlyChartPIERegion === null ||
                    (!!monthlyChartPIERegion &&
                      !monthlyChartPIERegion.length)) &&
                  (monthlyChartPIEProvider === null ||
                    (!!monthlyChartPIEProvider &&
                      !monthlyChartPIEProvider.length)) &&
                  placeholderChartDynMessage(
                    !!monthlyChartPIERegion || !!monthlyChartPIEProvider,
                    null,
                    317.25
                  )
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {!topDistInMonth &&
                  staticPlaceholderTableWithParam("auto", 317.25)}
                {!!topDistInMonth && (
                  <TableCard
                    data={topDistInMonth}
                    className="padding-bottom-default-child fullheight-16"
                    tableRows={this.tableRows}
                    checkbox={false}
                    hideNumbers={true}
                    cardHeader="Top distributions"
                    newOrderBy={"value"}
                    customUnit={[
                      {
                        name: "value",
                        unitCategory: DATATRANSFER
                      }
                    ]}
                  />
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  monthlyChart: state.charts.monthlyChart,
  hourlyChart: state.charts.hourlyChart,
  tilesData: state.tiles.data,
  allDistributions: state.distributions.allDistributions,
  switchUserData: state.user.switchUserData,
  entitlements: state.menuItem.entitlements,
  entinterface: state.menuItem.entinterface
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    getCustomerOverviewDetails,
    drawMontlyChart,
    drawHourlyChart,
    getFrontPageTiles,
    resetHourlyChart,
    resetMonthlyChart,
    toggleCustomer,
    resetDistribution,
    getAllDistributionGroups,
    getTotalResults,
    getInterfaceFromLocalStorage
  }
)(Dashboard);
