import _ from "lodash";

export function changeToMultiArray(data, keys) {
  return new Promise(resolve => {
    let multiArr = [];
    for (let ii = 0, jj = data.length; ii < jj; ii++) {
      multiArr[ii] = {
        [keys[0]]: data[ii],
        [keys[1]]: data[ii]
      };
    }

    resolve(multiArr);
  });
}

export function emailValidation(email) {
  var regExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  if (regExp.test(email) === false) {
    return true;
  }

  return false;
}

export function getTextValueFormatForCoutries(obj) {
  if (!obj) return;
  let object = [];
  for (let ii = 0, jj = obj.length; ii < jj; ii++) {
    object.push({
      value: obj[ii].alpha2,
      text: obj[ii].name
    });
  }
  return object;
}

export function sortFunction(a, b) {
  if (parseInt(a, 10) === parseInt(b, 10)) {
    return 0;
  } else {
    return parseInt(a, 10) < parseInt(b, 10) ? -1 : 1;
  }
}

export function checkMinimumLength(str, limit) {
  if (!str) return true;
  if (str.length >= limit) {
    return false;
  }
  return true;
}

export function checkMaximumLength(str, limit) {
  if (!str) return true;
  if (str.length <= limit) {
    return false;
  }
  return true;
}

export function checkMinAndMaxLength(str, minLimit, maxLimit) {
  if (!str) return true;
  if (str.length >= minLimit && str.length <= maxLimit) {
    return false;
  }
  return true;
}

export function sortStringFunction(a, b) {
  return a.text.localeCompare(b.text);
}

export function sortStringCountryFunction(a, b) {
  let aText = a.text || a.name;
  let bText = b.text || b.name;
  return aText.localeCompare(bText);
}

export function selectText(e) {
  if (document.selection) {
    // IE
    let range = document.body.createTextRange();
    range.moveToElementText(e.target);
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(e.target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
}

export function copyToClipboard(elm) {
  var copyElm = document.querySelector(elm);
  if (!!copyElm) {
    copyElm.select();
    copyElm.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }
}

export function deepCompareObjects(obj1, obj2) {
  return _.isEqual(obj1, obj2);
}

export function getErroredField(message, callback) {
  if (message.indexOf(":") === -1) {
    return callback([]);
  }
  let object;
  if (typeof message === "string") {
    object = message.replace(/'/g, '"');
    object = JSON.parse(object);
  } else {
    object = JSON.parse(message);
  }
  let keys = [];
  for (let key in object) {
    keys.push(key);
  }

  return callback(keys);
}

export function removeSpaceAndLowercase(str) {
  str = str.replace(/\s+/g, "-").toLowerCase();
  return str;
}
