import React, { Component } from "react";
import Notification, {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { getUserDetails } from "../../actions/userDetailsAction";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import {
  RenderInputWithOutLabel,
  RenderButtonClick
} from "../../common/helpers/renderElement";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import Checkbox from "@material-ui/core/Checkbox";
import TablePaginationFirstAndLast from "../Table/TablePaginationFirstAndLast";
import {
  getInvalidationList,
  Invalidate,
  getInvalidation,
  resetInvalidationList
} from "../../actions/invalidateActions";
import Divider from "@material-ui/core/Divider";
import FormDialogBase from "../Dialog/FormDialogBase";
import AlertDialog from "../Dialog/AlertDialog";
import classNames from "classnames";

import {
  stableSort,
  getSorting,
  tableStyles
} from "../../common/helpers/tableRulesAndStyles";

import { Redirect } from "react-router-dom";

import { RESELLER, RESELLERCUSTOMER } from "../../common/constants/roles";
import { tableLoaderEmpty, NORESPONSE } from "../../common/helpers/loader";
import BaseComponent from "../../common/base/BaseComponent";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/More";
import SendIcon from "@material-ui/icons/Send";

let invalidateTableConfig = {
  order: "desc",
  orderBy: "submitted",
  selected: [],
  page: 0,
  rowsPerPage: 10,
  progressStatus: 0,
  isLoading: false,
  pageOptions: [10],
  pageStart: 0,
  pageCount: 10,
  totalCount: 0,
  invalidationList: [],
  value: "",
  selectedUrls: [],
  openFormDialog: false,
  openDialog: false,
  invalidateListMapped: []
};

export const invalidateTableRows = [
  {
    id: "invalidationId",
    numeric: false,
    disablePadding: true,
    label: "Invalidation ID"
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "submitted",
    numeric: false,
    disablePadding: false,
    label: "Submitted"
  },
  { id: "completed", numeric: true, disablePadding: false, label: "Completed" },
  { id: "id", numeric: false, disablePadding: false, label: "Action(s)" }
];

export const invalidateTableURLsRows = [
  { id: "url", numeric: false, disablePadding: true, label: "URL(s)" }
];

export const invalidateSpecificRows = [
  { id: "url", numeric: false, disablePadding: false, label: "URL" },
  {
    id: "distributionGroupId",
    numeric: false,
    disablePadding: false,
    label: "Distribution group"
  },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "message", numeric: true, disablePadding: false, label: "Message" }
];

class Invalidation extends BaseComponent {
  calledOnce = false;

  constructor(props) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
    this.getInvalidationList = this.getInvalidationList.bind(this);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleInvalidationChange = this.handleInvalidationChange.bind(this);
    this.addUrl = this.addUrl.bind(this);
    this.state = { ...initialState(), ...invalidateTableConfig };
  }

  componentWillMount() {
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.calledOnce = false;
    this.props.hideNotification();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.pageStart !== this.state.pageStart) {
      this.calledOnce = false;
    }
    let { invalidateListMapped } = this.state,
      _this = this;
    if (
      !!nextProps.invalidateList &&
      !!nextProps.invalidateList.status &&
      !invalidateListMapped.length &&
      !!nextProps.invalidateList.status.length
    ) {
      this.setState(
        {
          invalidateListMapped: []
        },
        () => {
          const invalidateFormattedList = _this.createMapperForTable(
            nextProps.invalidateList.status,
            "submitted",
            "time"
          );
          this.setState({
            invalidateListMapped: invalidateFormattedList
          });
        }
      );
    }
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  isSelected = (index, obj) => {
    return this.state[obj].indexOf(index) !== -1;
  };

  creatTableList(list, rows, page) {
    if (!page) return list;
    const tmpList = [];
    const totalBlankRecord = rows * page;
    for (let ii = 0, jj = list.length; ii < jj; ii++) {
      tmpList[ii] = list[ii];
    }

    for (let ii = totalBlankRecord, jj = 0; ii > jj; ii--) {
      tmpList.push({
        invalidationId: ii,
        status: "pending",
        submitted: 35652162600000
      });
    }
    return tmpList;
  }

  handleURLsClick = (event, id) => {
    const { selectedUrls } = this.state;
    const selectedIndex = selectedUrls.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUrls, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUrls.slice(1));
    } else if (selectedIndex === selectedUrls.length - 1) {
      newSelected = newSelected.concat(selectedUrls.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUrls.slice(0, selectedIndex),
        selectedUrls.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedUrls: newSelected });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({
        selectedUrls: state.invalidationList.map(n => n)
      }));
      return true;
    }
    this.setState({ selectedUrls: [] });
    return false;
  };

  handleChangePage = (event, page) => {
    this.setState({
      page,
      pageStart: page,
      selected: [],
      invalidateListMapped: []
    });
    if (!!event) {
      this.props.resetInvalidationList();
    }
  };

  handleChange(e) {
    this.setState({ value: e.target.value });
  }

  handleDetails(invalidationId, customerId) {
    this.setState({ isLoading: true });
    const _this = this;
    if (!!invalidationId) {
      this.props.getInvalidation(customerId, invalidationId, function(data) {
        _this.setState({ isLoading: false, dialogData: data });
        _this.handleFormDialogToggle();
      });
    }
  }

  getInvalidation(invalidationId, customerId) {
    const _this = this;
    if (!!invalidationId) {
      this.props.getInvalidation(customerId, invalidationId, function(data) {
        _this.setState({ isLoading: false, dialogData: data });
        _this.handleDialogToggle();
      });
    }
  }

  handleSend(dialogData, customerId) {
    const _this = this;
    let list = [];
    for (let ii = 0, jj = dialogData.urlStatus.length; ii < jj; ii++) {
      list.push(dialogData.urlStatus[ii].url);
    }
    const payload = {
      customerId: customerId,
      files: list
    };
    if (!!customerId) {
      this.invalidate(_this, customerId, payload, "resubmit");
    }
  }

  handleInvalidationChange(e) {
    const regex = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&=]*)/
    );
    const val = e.target.value;
    this.setState({ value: val });
    if (val.match(regex)) {
      this.setState({ invalidUrl: false });
    } else {
      this.setState({ invalidUrl: true });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  getInvalidationList(customerId) {
    this.props.getInvalidationList(
      customerId,
      this.state.pageStart,
      this.state.pageCount
    );
  }

  addUrl(url) {
    if (!!url && !this.checkForUniqueUrl(url)) {
      this.setState(prevState => ({
        invalidationList: [...prevState.invalidationList, url]
      }));
    } else {
      this.toggleNotification(
        `URL: "${url}" is already in invalidation list.`,
        "warning",
        "Close"
      );
    }
  }

  checkForUniqueUrl(url) {
    return !!this.state.invalidationList.filter(value => value === url).length;
  }

  removeUrl(list, counter, urls) {
    if (counter === list.length) {
      this.setState({ selectedUrls: [], invalidationList: urls });
      return false;
    }
    let indexArr = urls.findIndex((value, index) => value === list[counter]);
    if (indexArr > -1) {
      urls.splice(indexArr, 1);
      counter += 1;
    }

    this.removeUrl(list, counter, urls);
  }

  findIndexFromOtherArray(target, source, counter, indexArray) {
    for (let ii = 0, jj = target.length; ii < jj; ii++) {
      if (source[counter] === target[ii] && counter < source.length) {
        indexArray.push(ii);
        counter = counter + 1;
        this.findIndexFromOtherArray(target, source, counter, indexArray);
      }
    }
    if (counter === source.length) {
      this.removeChildren(indexArray, target);
    }
    return false;
  }

  removeChildren(indexArray, target) {
    for (let ii = 0, jj = indexArray.length; ii <= jj; ii++) {
      target.splice(indexArray[ii], 1);
    }

    this.setState({ invalidationList: target, selectedUrls: target });
  }

  submitInvalidation(list, customerId) {
    if (!list.length) {
      const message =
        "Nothing to add. Please first add URLs which you want to invalidate!";
      this.toggleNotification(message, "warning", "Close");
      return false;
    }
    const payload = {
      customerId: customerId,
      files: list
    };
    const _this = this;
    this.invalidate(_this, customerId, payload);
  }

  invalidate(_this, customerId, payload, diffrentiator) {
    this.props.Invalidate(
      customerId,
      payload,
      function(message, type) {
        _this.toggleNotification(message, type, "Close");
        _this.setState({
          openDialog: false,
          invalidationList: [],
          invalidateListMapped: [],
          value: "",
          selectedUrls: []
        });
        _this.props.getInvalidationList(
          customerId,
          _this.state.pageStart,
          _this.state.pageCount
        );
      },
      diffrentiator
    );
  }

  setToLocaleString(value) {
    if (!value) return "-";
    return new Date(value).toLocaleString();
  }

  handleFormDialogToggle() {
    const invalidationId = this.state.selected.toString();
    this.setState({
      openFormDialog: !this.state.openFormDialog,
      dialogFormName: `Invalidation details for: ${invalidationId}`
    });
  }
  getItemNumber(page, index, rowsPerPage) {
    const pageNumber = !!page ? rowsPerPage * page + index + 1 : index + 1;
    return pageNumber;
  }

  render() {
    const {
      open,
      userData,
      role,
      classes,
      invalidateList,
      switchUserData
    } = this.props;

    const {
      error,
      variant,
      color,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      selectedUrls,
      invalidationList,
      invalidUrl,
      isLoading,
      dialogFormName,
      openFormDialog,
      dialogData,
      openDialog,
      pageOptions,
      invalidateListMapped
    } = this.state;

    let dialogContent,
      customerId,
      invalidateBoolean =
        !!invalidateListMapped && !!invalidateListMapped.length;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!!customerId && !this.calledOnce) {
      this.calledOnce = true;
      this.getInvalidationList(customerId);
    }

    if (!!dialogData) {
      dialogContent = (
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={0}
            order={order}
            orderBy={orderBy}
            hideNumbers={true}
            hideCheckBox={true}
            rows={invalidateSpecificRows}
            showSelectAll={false}
            onRequestSort={this.handleRequestSort}
            rowCount={dialogData.urlStatus.length}
          />
          <TableBody>
            {dialogData.urlStatus.map((value, index) => {
              return (
                <TableRow key={"row" + index} tabIndex={-1} role="none">
                  <TableCell>{value.url}</TableCell>
                  <TableCell>{value.distributionGroupId}</TableCell>
                  <TableCell>{value.status}</TableCell>
                  <TableCell>{value.message}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }

    return (
      <div className="margin-top-16">
        <Typography variant="h3" color="initial" className="page-title">
          Invalidation
        </Typography>
        <Typography variant="h4" color="initial">
          Manage your invalidations
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel="Close"
          buttonsize="small"
        />
        <Paper
          className={classNames(classes.alignCenter, {
            "margin-bottom-default": true,
            "margin-top-default": true
          })}
        >
          <AlertDialog
            openDialog={openDialog}
            heading="Confirm"
            message={
              "Are you sure you want to re-submit invalidation: " +
              selected.toString() +
              "?"
            }
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
            cancelBtnAction={() => this.handleDialogToggle()}
            confirmBtnAction={() => this.handleSend(dialogData, customerId)}
          />
          <FormDialogBase
            openFormDialog={openFormDialog}
            formHeading={dialogFormName}
            cancelFormText="Close"
            formSubmit={this.handleSubmit}
            maxWidth="lg"
            submitDataInProgress={isLoading}
            cancelFormAction={() => this.handleFormDialogToggle()}
            dialogContent={dialogContent}
          />
          <Grid container className="grid-container">
            <Grid
              item
              xs={12}
              sm={10}
              className="grid-item padding-left-default padding-right-default"
            >
              <RenderInputWithOutLabel
                placeholder="http(s)://"
                id="invalidateUrl"
                name="invalidateUrl"
                type="text"
                required
                value={this.state.value}
                label="Invalidate URL's"
                handleChange={this.handleInvalidationChange}
                error={invalidUrl}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className="grid-item button-mobile padding-right-default"
            >
              <RenderButtonClick
                name="addurl"
                color="primary"
                onClick={() => this.addUrl(this.state.value)}
                variant="contained"
                type="button"
                size="large"
                fullWidth
                className="btn-update"
                align="right"
                disabled={invalidUrl}
                label="Add URL"
              />
            </Grid>
            <Grid
              item
              xs={12}
              className="grid-item"
              style={{ padding: 0, margin: 0 }}
            >
              {!!invalidationList.length && (
                <EnhancedTableToolbar
                  subheading="Invalidation list"
                  hideDefaultOptions={true}
                  numSelected={selectedUrls.length}
                  additionalActions={[
                    {
                      name: "Remove",
                      icon: (
                        <i className="material-icons icon-delete">delete</i>
                      ),
                      action: () =>
                        this.removeUrl(selectedUrls, 0, invalidationList)
                    }
                  ]}
                />
              )}
            </Grid>
          </Grid>
          {!!invalidationList.length && (
            <Grid container>
              <Grid item xs={12}>
                <Divider />
                <div
                  className={classNames(classes.tableWrapper, {
                    "margin-bottom-default": true
                  })}
                >
                  <Table className={classes.table} aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      numSelected={selectedUrls.length}
                      rows={invalidateTableURLsRows}
                      showSelectAll={true}
                      hideNumbers={true}
                      selectAllAction={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={invalidationList.length}
                      hideCheckBox={false}
                    />
                    <TableBody>
                      {invalidationList.map((value, index) => {
                        const isURLsSelected = this.isSelected(
                          value,
                          "selectedUrls"
                        );
                        return (
                          <TableRow
                            key={"row" + index}
                            aria-checked={isURLsSelected}
                            tabIndex={-1}
                            selected={isURLsSelected}
                          >
                            <TableCell padding="none">
                              <Checkbox
                                onClick={event =>
                                  this.handleCheckBoxClick(
                                    event,
                                    value,
                                    "selectedUrls"
                                  )
                                }
                                checked={isURLsSelected}
                              />
                            </TableCell>
                            <TableCell padding="none">{value}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <div className="margin-bottom-default">
                  <RenderButtonClick
                    name="submitInvalidations"
                    color="primary"
                    onClick={() =>
                      this.submitInvalidation(selectedUrls, customerId)
                    }
                    variant="contained"
                    size="medium"
                    align="center"
                    label="Submit invalidations"
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </Paper>
        <Paper>
          <EnhancedTableToolbar
            numSelected={0}
            subheading="Invalidations"
            toolTipTitle="Add user"
            handleAddClick={() => this.handleAdd()}
            hideDefaultOptions={true}
          />
          {invalidateList == null && (
            <Table className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>{tableLoaderEmpty}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}

          {!!invalidateList && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                <Table
                  className={classes.table + " table"}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    hideNumbers={true}
                    rows={invalidateTableRows}
                    hideSortIcon={true}
                    rowCount={invalidateListMapped.length}
                  />
                  <TableBody>
                    {stableSort(
                      this.creatTableList(
                        invalidateListMapped,
                        rowsPerPage,
                        page
                      ),
                      getSorting(order, orderBy)
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((n, index) => {
                        return (
                          <TableRow
                            tabIndex={-1}
                            key={n[invalidateTableRows[0].id] + index}
                          >
                            <TableCell padding="none">
                              {n[invalidateTableRows[0].id]}
                            </TableCell>
                            <TableCell>
                              {n[invalidateTableRows[1].id]}
                            </TableCell>
                            <TableCell>
                              {this.setToLocaleString(
                                n[invalidateTableRows[2].id]
                              )}
                            </TableCell>
                            <TableCell>
                              {this.setToLocaleString(
                                n[invalidateTableRows[3].id]
                              )}
                            </TableCell>
                            <TableCell className="actions">
                              <IconButton
                                aria-label="More"
                                onClick={() =>
                                  this.handleDetails(
                                    n.invalidationId,
                                    customerId
                                  )
                                }
                              >
                                <MoreIcon color="primary" />
                              </IconButton>
                              <IconButton
                                aria-label="Send"
                                onClick={() => {
                                  this.setSelected(n.invalidationId);
                                  this.getInvalidation(
                                    n.invalidationId,
                                    customerId
                                  );
                                }}
                              >
                                <SendIcon color="secondary" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {!invalidateListMapped.length && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={6}>{NORESPONSE}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              {!!invalidateList && (
                <TablePaginationFirstAndLast
                  rowsPerPageOptions={pageOptions}
                  component="div"
                  count={invalidateList.total || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  className="table-pagination"
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              )}
            </React.Fragment>
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  invalidateList: state.invalidate.data,
  switchUserData: state.user.switchUserData
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    getInvalidationList,
    Invalidate,
    getInvalidation,
    resetInvalidationList
  }
)(withStyles(tableStyles)(Invalidation));
