import {
  ERROR_IN_CUSTOMERS,
  RECEIVED_CUSTOMER_OVERVIEW,
  DELETE_CUSTOMER_SUCCESSFUL,
  GET_SPECIFIC_CUSTOMER_DETAIL,
  ADD_CUSTOMER_SUCCESSFUL,
  GET_STATISTICS_TOTAL,
  TOGGLE_CUSTOMER
} from "./types";

import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import {
  apiPostCall,
  apiGetCall,
  DEFAULT_ERROR_MESSAGE
} from "../actions/baseActions";

export function resetCustomerOverview() {
  return function(dispatch) {
    dispatch({
      type: RECEIVED_CUSTOMER_OVERVIEW,
      data: null
    });
  };
}

export function toggleCustomer(toggleValue, customerId, callback) {
  let endpoint = "disable";
  if (!!toggleValue) {
    endpoint = "enable";
  }
  const url =
    userSession.getSessionItem("apiUrl") +
    "customers/" +
    customerId +
    "/" +
    endpoint;

  const token = authClient.getBearerToken(),
    method = "PUT",
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = TOGGLE_CUSTOMER,
    contentType = "application/json";
  const postObject = JSON.stringify({});

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}
export function getCustomerOverviewDetails(
  apiUrl,
  toolingToken,
  customerId,
  Callback
) {
  const apiURL = apiUrl || userSession.getSessionItem("apiUrl");
  let url = apiURL + "customers";
  if (!!customerId) {
    url = apiURL + customerId + "/sub_customers";
  }
  const token = !!toolingToken ? toolingToken : authClient.getBearerToken(),
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = RECEIVED_CUSTOMER_OVERVIEW,
    method = "GET";
  const callback = Callback || function() {};
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function updateCustomerProfile(customerId, payload, callback) {
  const url = userSession.getSessionItem("apiUrl") + "customers/" + customerId;

  const token = authClient.getBearerToken(),
    method = "PUT",
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = ADD_CUSTOMER_SUCCESSFUL,
    contentType = "application/json";
  const postObject = JSON.stringify(payload);

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

export function getStatisticsTotalsData(customerId, query, callback) {
  const url =
    userSession.getSessionItem("apiUrl") +
    customerId +
    "/statistics/reseller/totals" +
    query;
  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = GET_STATISTICS_TOTAL,
    method = "GET";

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function deleteSeletedCustomer(selectedId, customerId, callback) {
  const url = userSession.getSessionItem("apiUrl") + "customers/" + selectedId;

  // if (!!customerId) {
  //   url =
  //     userSession.getSessionItem("apiUrl") +
  //     "customers/" +
  //     customerId +
  //     "/subcustomer/" +
  //     selectedId;
  // }

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = DELETE_CUSTOMER_SUCCESSFUL,
    method = "DELETE";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function fetchCustomerContactData() {
  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const url =
    userSession.getSessionItem("apiUrl") +
    "customers/" +
    customerDetails.customerId;

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = GET_SPECIFIC_CUSTOMER_DETAIL,
    method = "GET";
  const callback = function() {};
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function addSubCustomer(payload, customerId, callback) {
  let url = userSession.getSessionItem("apiUrl") + "customers/subcustomer";
  //customers/{customer_id}/subcustomer
  if (!!customerId) {
    url =
      userSession.getSessionItem("apiUrl") +
      "customers/" +
      customerId +
      "/subcustomer";
  }

  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = DELETE_CUSTOMER_SUCCESSFUL,
    contentType = "application/json";
  payload.customerContactDetails.name = payload.userDetails.name;
  payload.customerContactDetails.description = payload.userDetails.description;
  payload.customerContactDetails.type = payload.userDetails.customerType;
  const postObject = JSON.stringify(payload.customerContactDetails);
  const callbackWrapper = function(response) {
    callback(payload.userDetails, response.id);
  };
  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callbackWrapper
  );
}

export function addCustomer(subcustomerId, data, callback) {
  let customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  if (!customerDetails) {
    callback(DEFAULT_ERROR_MESSAGE);
    return;
  }

  data.customerId = subcustomerId;
  data.ip = customerDetails.ip;
  let payload = prepareAndCreateNewUser(data);

  const url =
    userSession.getSessionItem("apiUrl") +
    customerDetails.customerId +
    "/users";

  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_CUSTOMERS,
    typeSuccess = DELETE_CUSTOMER_SUCCESSFUL,
    contentType = "application/json";
  const postObject = JSON.stringify(payload);

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

function prepareAndCreateNewUser(data) {
  let mappedData = {
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    password: data.password,
    phone: data.phoneNumber,
    ip: data.ip,
    customerId: data.customerId,
    roleName: data.roleName
  };
  return mappedData;
}
