import {
  GET_USER_DETAILS,
  ERROR_IN_USER_DETAILS,
  SWITCH_USER_DETAILS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_PASSWORD,
  USER_2FA_TOGGLE,
  USER_ENABLED_TOGGLE,
  GET_USERS,
  DELETE_USER,
  ADD_USERS,
  EDIT_USER,
} from "./types";
import React from "react";
import authClient from "../common/helpers/auth";
import { Redirect } from "react-router-dom";
import { session as userSession } from "../common/helpers/sessionValues";
import { apiPostCall, apiGetCall } from "../actions/baseActions";
import axios from "axios";

export function resetUserDetails() {
  return function (dispatch) {
    dispatch({
      type: GET_USER_DETAILS,
      data: null,
    });
  };
}

export function getUserDetailsWithoutLogin(toolingToken, callback) {
  axios.defaults.headers.common["Authorization"] = toolingToken;
  const url = userSession.getSessionItem("apiUrl") + "self";
  return function (dispatch) {
    axios
      .get(url)
      .then((result) => {
        if (!!result && !!result.data.response.roleName) {
          userSession.setSessionItem(
            "customerDetails",
            JSON.stringify(result.data.response)
          );
          userSession.setSessionItem("role", [result.data.response.roleName]);
        }
        dispatch({
          type: GET_USER_DETAILS,
          data: result.data.response,
        });
        callback(result.data.response);
      })
      .catch((error) => {
        dispatch({
          type: ERROR_IN_USER_DETAILS,
          data: error,
        });
        callback(error);
      });
  };
}

export function getUserDetails() {
  if (!authClient.isAuthenticated() || !localStorage.length) {
    return <Redirect to="/" />;
  }
  //cu-1bfc4b5c/users
  axios.defaults.headers.common["Authorization"] = authClient.getBearerToken();
  if (!userSession.getSessionItem("apiUrl")) {
    window.location.reload();
  }
  let url = userSession.getSessionItem("apiUrl") + "self",
    customerId;
  if (!!localStorage.SwitchCustomerDetail) {
    customerId = JSON.parse(localStorage.SwitchCustomerDetail).id;
    url = userSession.getSessionItem("apiUrl") + customerId + "/users";
  }
  return function (dispatch) {
    axios
      .get(url)
      .then((result) => {
        const response = !!customerId
          ? result.data.response[0]
          : result.data.response;

        if (
          !!response &&
          !!response.roleName &&
          !localStorage.SwitchCustomerDetail
        ) {
          userSession.setSessionItem(
            "customerDetails",
            JSON.stringify(response)
          );
          userSession.setSessionItem("role", [response.roleName]);
        }
        if (!!result && !!localStorage.SwitchCustomerDetail) {
          userSession.setSessionItem(
            "SwitchUserDetail",
            JSON.stringify(response)
          );
        }

        dispatch({
          type: GET_USER_DETAILS,
          data: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: ERROR_IN_USER_DETAILS,
          data: error,
        });
      });
  };
}

export function applyFilteredUsers(object, typeSuccess) {
  return function (dispatch) {
    dispatch({
      type: typeSuccess,
      users: object,
    });
  };
}

export function getUsers(customerId, Callback) {
  const url = userSession.getSessionItem("apiUrl") + customerId + "/users";
  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = GET_USERS,
    method = "GET";
  const callback = Callback || function Callback(response) {};

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "users",
    callback
  );
}

export function resetUsers() {
  return function (dispatch) {
    dispatch({
      type: GET_USERS,
      users: null,
    });
  };
}

export function addUsers(customerId, payload, role, callback) {
  const url = userSession.getSessionItem("apiUrl") + customerId + "/users";
  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = ADD_USERS;
  payload.roleName = role;
  const postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "addedUser",
    function (response) {
      response = !Object.keys(response).length
        ? "OOPs somthing went wrong!"
        : response;
      callback(response);
    }
  );
}

export function deleteSelectedUser(customerId, username, callback) {
  const url =
    userSession.getSessionItem("apiUrl") + customerId + "/users/" + username;
  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = DELETE_USER,
    method = "DELETE";
  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "deletedSelectedUser",
    callback
  );
}

export function editSelectedUser(customerId, username, payload, callback) {
  const url =
    userSession.getSessionItem("apiUrl") + customerId + "/users/" + username;
  const token = authClient.getBearerToken(),
    method = "PUT",
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = EDIT_USER;
  const postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "editSelectedUser",
    callback
  );
}

export function userEnabledToggle(customerId, userId, action, callback) {
  const endpoint = !action
    ? customerId + "/users/" + userId + "/disable"
    : customerId + "/users/" + userId + "/enable";
  const url = userSession.getSessionItem("apiUrl") + endpoint;
  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = USER_ENABLED_TOGGLE;
  const postObject = "",
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}

export function enable2FA(customerId, action, callback) {
  const endpoint = action === "enable" ? "self/2fa/enable" : "self/2fa/disable";
  const url = userSession.getSessionItem("apiUrl") + endpoint;
  const token = authClient.getBearerToken(),
    method = "POST",
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = USER_2FA_TOGGLE;
  const postObject = "",
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    callback
  );
}
export function updateUserDetails(payload, callback) {
  let url = userSession.getSessionItem("apiUrl") + "self",
    username,
    customerId;
  //​/{customer}​/users​/{username}
  if (!!localStorage.SwitchUserDetail) {
    username = JSON.parse(localStorage.SwitchUserDetail).id;
    customerId = JSON.parse(localStorage.customerDetails).id;
    //
    url =
      userSession.getSessionItem("apiUrl") + customerId + "/users/" + username;
  }

  const token = authClient.getBearerToken(),
    method = "PUT",
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = UPDATE_USER_DETAILS;
  const postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "data",
    function (response) {
      if (!localStorage.SwitchUserDetail) {
        userSession.setSessionItem("customerDetails", JSON.stringify(response));
      }
      callback(response);
    }
  );
}

export function updateUserPassword(payload, callback) {
  let url = userSession.getSessionItem("apiUrl") + "self/password";

  const token = authClient.getBearerToken(),
    method = "PUT",
    typeError = ERROR_IN_USER_DETAILS,
    typeSuccess = UPDATE_USER_PASSWORD;
  const postObject = JSON.stringify(payload),
    contentType = "application/json";

  return apiPostCall(
    url,
    token,
    method,
    postObject,
    typeError,
    typeSuccess,
    contentType,
    "userPassword",
    function (response) {
      callback(response);
    }
  );
}

export function switchUserDetails(data) {
  return function (dispatch) {
    dispatch({
      type: SWITCH_USER_DETAILS,
      switchUserData: data,
    });
  };
}
