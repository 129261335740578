import React from "react";
import Notification from "../../common/helpers/notification";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { RESELLER } from "../../common/constants/roles";
import { getUserDetails } from "../../actions/userDetailsAction";
import Typography from "@material-ui/core/Typography";
import AmChartCardMultiple from "../Cards/AMChartCardMultiple";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {
  drawMultiLineRequestsChart,
  getTotalResults
} from "../../actions/chartsActions";
import {
  getAllDistributionGroups,
  topDistributionInMonth,
  resetTopDistribution
} from "../../actions/distributionAction";
import { Paper } from "@material-ui/core";
import {
  placeholderChartDynMessage,
  staticPlaceholderTable
} from "../../common/helpers/loader";
import { Redirect } from "react-router-dom";
import TableCard from "../Cards/TableCard";
import StatisticsBase from "../Statistics/StatisticsBase";
import {
  groupByDropdown,
  groupByDropdownMcdnPremium,
  metricsDropdown,
  granularityDropdown,
  dateRangeDefinitionObject,
  dateRangeUnitObject
} from "../Statistics/statisticsConstants";

class RequestStatistics extends StatisticsBase {
  tableRows = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Distribution"
    },
    { id: "value", numeric: true, disablePadding: false, label: "Bandwidth" }
  ];

  callActive = false;

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      multiChartRequestsItems: null,
      totalsResult: null,
      grandTotal: 0
    };
  }

  componentDidUpdate(prevProps) {
    let { allDistributions, switchUserData, userData } = this.props;
    if (!!userData) {
      let customerId = !!switchUserData
        ? switchUserData.id
        : userData.customerId;
      if (!this.multiCount && !!allDistributions) {
        this.multiCount = 1;
        setTimeout(() => {
          this.drawMultiLineRequestsChart(customerId);
          const tableDetails = this.updateTableHeadingAndColumn();
          this.getTotalResults(
            customerId,
            tableDetails.metric,
            tableDetails.unit,
            RESELLER
          );
        }, 3000);
      }
    }
  }

  drawMultiLineRequestsChart(customerId) {
    const metric = this.state.metric || "bandwidth";
    this.unitAndgranphDisplayText = this.getUnitAndGraphTextAsPerSelection(
      metric
    );
    const _this = this,
      unitCategory = _this.unitAndgranphDisplayText[0].unit;
    const endpoint = _this.getCustomerType(RESELLER);
    let granularity = _this.setGranularityAsPerDate(
      this.hasDateUnChangedButFormatted
    );

    const entitlements = this.hasMatchingEntitlementWithoutPromise(
      "mcdn-premium"
    );
    granularity = !!entitlements ? "1h" : granularity;
    this.hasDateUnChangedButFormatted = true;
    const payload = _this.setPayloadStatistics(metric, granularity);
    _this.props.drawMultiLineRequestsChart(
      customerId,
      payload,
      endpoint,
      function(res) {
        let chartItem = _this.getMultiChartView(
          _this,
          res,
          unitCategory,
          _this.unitAndgranphDisplayText[0].text
        );
        _this.setState({
          multiChartRequestsItems: chartItem
        });
      }
    );
  }

  resetChart() {
    this.setState({
      multiChartRequestsItems: null
    });

    return true;
  }

  updateCharts(customerId) {
    this.resetChart();
    this.drawMultiLineRequestsChart(customerId);
    const tableDetails = this.updateTableHeadingAndColumn();
    this.getTotalResults(
      customerId,
      tableDetails.metric,
      tableDetails.unit,
      RESELLER
    );
    return true;
  }

  render() {
    let {
      error,
      variant,
      label,
      color,
      selectedDistribution,
      multiChartRequestsItems,
      totalsResult,
      grandTotal,
      metric,
      groupBy,
      granularity,
      clearValue,
      dateRangeUnit,
      dateRangeDefinition,
      expandedDateRange,
      dateRangeStartDate,
      dateRangeEndDate
    } = this.state;
    let { open, userData, role, allDistributions, switchUserData } = this.props;

    if (role === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    if (!!userData && role !== RESELLER && !this.callActive) {
      this.getAllDistributionGroupsLocal(customerId);
      this.callActive = true;
    }

    const dateRangeObject = {
      dateRangeUnit: dateRangeUnit,
      dateRangeUnitObject: dateRangeUnitObject,
      dateRangeDefinition: dateRangeDefinition,
      dateRangeDefinitionObject: dateRangeDefinitionObject,
      expandedDateRange: expandedDateRange,
      dateRangeStartDate: dateRangeStartDate,
      dateRangeEndDate: dateRangeEndDate
    };
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" color="initial" className="page-title">
              Statistics
            </Typography>
            {RESELLER !== role && (
              <Typography variant="h4" color="initial">
                Customize and view performance statistics
              </Typography>
            )}
          </Grid>
        </Grid>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        {
          <React.Fragment>
            {
              <Paper className="padding-left-default padding-right-default padding-bottom-default margin-bottom-16 margin-top-16">
                <div className="margin-top-16 margin-bottom-16 fullwidth pull-left">
                  <Typography variant="h3" color="initial">
                    Analytics
                  </Typography>
                </div>
                <div className="margin-top-16 margin-bottom-16">
                  {this.getDateRangeComponent(dateRangeObject, customerId)}
                </div>
                <div className="clear-both">
                  {!!allDistributions &&
                    this.getDistributionDropDown(
                      selectedDistribution,
                      allDistributions,
                      customerId
                    )}
                  {this.getDropdownComponent(
                    metricsDropdown,
                    "metric",
                    metric,
                    "Metric",
                    "3",
                    customerId
                  )}
                  {!this.hasMatchingEntitlementWithoutPromise("mcdn-premium") &&
                    this.getDropdownComponent(
                      this.checkForEntitlementGroupBy(
                        groupByDropdown,
                        ["cdn-all", "cdn-shield"],
                        4
                      ),
                      "groupBy",
                      groupBy,
                      "Group by",
                      "0",
                      customerId
                    )}
                  {!!this.hasMatchingEntitlementWithoutPromise(
                    "mcdn-premium"
                  ) &&
                    this.getDropdownComponent(
                      this.checkForEntitlementGroupBy(
                        groupByDropdownMcdnPremium,
                        ["cdn-all", "cdn-shield"],
                        3
                      ),
                      "groupBy",
                      groupBy,
                      "Group by",
                      "0",
                      customerId
                    )}
                  {this.getDropdownComponent(
                    this.checkForEntitlementGranularity(
                      granularityDropdown,
                      "mcdn-premium"
                    ),
                    "granularity",
                    granularity,
                    "Granularity",
                    "0",
                    customerId,
                    "margin-left__device-4",
                    clearValue
                  )}
                  {!expandedDateRange && (
                    <Button
                      color="primary"
                      onClick={() => this.updateCharts(customerId)}
                      variant="contained"
                      size="large"
                      className="btn-update"
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </Paper>
            }
            {!!multiChartRequestsItems && !!multiChartRequestsItems.length ? (
              <AmChartCardMultiple
                hasMultiple={true}
                data={multiChartRequestsItems[0].data}
                xAxisText={multiChartRequestsItems[0].xAxisText}
                valueY={multiChartRequestsItems[0].valueY}
                categoryX={multiChartRequestsItems[0].categoryX}
                yAxisText={multiChartRequestsItems[0].yAxisText}
                id="chartdiv_multiple"
                unit={multiChartRequestsItems[0].unit}
                additionalData={multiChartRequestsItems[0].additionalData}
              />
            ) : (
              placeholderChartDynMessage(multiChartRequestsItems, null, 540)
            )}
            <Grid
              container
              className={
                !!totalsResult && !!totalsResult.length
                  ? "placeholder__chart height-auto"
                  : ""
              }
            >
              <Grid item xs={12} className="margin-bottom-default">
                {!!totalsResult ? (
                  <TableCard
                    data={totalsResult}
                    tableRows={this.tableRows}
                    checkbox={false}
                    hideNumbers={true}
                    cardHeader={this.getTableTitle(this.tableRows[1].label)}
                    newOrderBy={"desc"}
                    newOrder={"value"}
                    className="width-min-auto-table table-cell-trunket"
                    total={grandTotal}
                    customUnit={[
                      {
                        name: "value",
                        unitCategory: this.unitAndgranphDisplayText[0].unit
                      }
                    ]}
                  />
                ) : (
                  staticPlaceholderTable
                )}
              </Grid>
            </Grid>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  allDistributions: state.distributions.allDistributions,
  switchUserData: state.user.switchUserData
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails,
    getAllDistributionGroups,
    topDistributionInMonth,
    resetTopDistribution,
    drawMultiLineRequestsChart,
    getTotalResults
  }
)(RequestStatistics);
