import React from "react";
import {
  RenderInputWithOutLabel,
  RenderButtonClick,
  RenderSwitchWithToolTip,
  RenderSwitch,
  RenderInputWithToolTip,
  ClickableTooltip,
  RenderSwitchWithOutToolTip,
  RenderAutoSelectWithToolTip,
} from "../../common/helpers/renderElement";
import { session as userSession } from "../helpers/sessionValues";
import authClient from "../helpers/auth";
import {
  MULTI_CDN_VOLUME,
  SINGLE_CDN,
  SHIELD_CDN,
  PRIVATE_CDN,
  MULTI_CDN_PREMIUM,
} from "../../common/constants/policies";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";

const DISTRIBUTION = {
  ENABLED: "Enable/disable this distribution.",
  SUPPORTOLDERCIPHERS:
    "Use older Ciphers and TLS protocols for legacy device support",
  EDIT_VIEW: "Click to switch to edit view.",
  ONLY_VIEW: "Click to switch to read only.",
  ONLY_MANDATORY: "Disable this option to see only required fields.",
  DISABLE_MANDATORY: "Enable this option to see expanded view.",
  DESCRIPTION: "Description of this distribution for easier reference.",
  CNAME: "Target to CNAME CDN domain(s) to.",
  IPV6: "Enable or disable IPv6 delivery.",
  DOMAINS: "Domain(s) used for CDN delivery.",
  TLSSETINGS:
    "Enable SNI SSL/TLS to use your custom certificate or select Shared TLS/SSL to use our. " +
    "Note, using ours will require you to use the CNAME target as the exposed domain to your users. ",
  LOGGING:
    "Enable or disable raw access logging, these are stored on our FTP server for you to download and parse.",
  CERTIFICATE:
    "An existing certificate that was uploaded to the platform can be selected here to be coupled with this distribution.",
};

const styles = {
  rightDomains: {
    right: "-37px",
  },
};

export const setEmptyDistributionObject = (self, type) => {
  const data = [
    {
      custom: false,
      enabled: true,
      endpoint: "",
      status: {
        deployed: false,
        details: [],
        status: "pending",
        success: false,
      },
      updated: null,
    },
  ];

  if (type !== SHIELD_CDN) {
    data[0].domains = [];
    data[0].tlsSettings = {
      certificateId: null,
      tlsType: null,
    };
    data[0].logging = false;
    data[0].loggingSettings = {
      ftpPassword: null,
    };
  }

  if (type === SINGLE_CDN) {
    data[0].description = "New single CDN distribution " + Date.now();
    data[0].type = "single-cdn";
    data[0].tls = false;
    data[0].tier = "single-standard";
    cdnVMMapper(self, data[0]);
  }

  if (type === PRIVATE_CDN) {
    data[0].description = "New private CDN distribution " + Date.now();
    data[0].type = "private-cdn";
    data[0].tls = false;
    data[0].tier = "standard-private";
    cdnVMMapper(self, data[0]);
  }

  if (type === MULTI_CDN_VOLUME) {
    data[0].description = "New multi CDN volume distribution " + Date.now();
    data[0].type = "multi-cdn";
    data[0].tls = false;
    data[0].tier = "multi-volume";
    cdnVMMapper(self, data[0]);
  }

  if (type === MULTI_CDN_PREMIUM) {
    data[0].description = "New multi CDN premium distribution " + Date.now();
    data[0].type = "multi-cdn";
    data[0].tls = false;
    data[0].tier = "multi-premium";
    data[0].tlsSettings = {
      certificateId: null,
      tlsType: null,
    };
    cdnVMMapper(self, data[0]);
  }

  if (type === SHIELD_CDN) {
    data[0].description = "New shield CDN distribution " + Date.now();
    data[0].type = "shield-cdn";
    data[0].tier = "shield-standard";
    cdnShieldVMMapper(self, data[0]);
  }
};

export const cdnVMMapper = (self, data) => {
  self.setState({
    created: data.created,
    custom: data.custom,
    customerId: data.customerId,
    description: data.description,
    domains: data.domains,
    enabled: data.enabled,
    endpoint: data.endpoint,
    id: data.id,
    logging: data.logging,
    loggingSettings: data.loggingSettings,
    profileId: data.profileId,
    status: data.status,
    tier: data.tier,
    tls: data.tls,
    tlsSettings: {
      certificateId: checkForNull(data.tlsSettings, "certificateId"),
      tlsType: checkForNull(data.tlsSettings, "tlsType"),
      supportOlderCiphers: !!data.tlsSettings
        ? data.tlsSettings.supportOlderCiphers
        : false,
    },
    type: data.type,
    updated: data.updated,
  });
};

export const cdnShieldVMMapper = (self, data) => {
  self.setState({
    created: data.created,
    custom: data.custom,
    customerId: data.customerId,
    description: data.description,
    enabled: data.enabled,
    endpoint: data.endpoint,
    id: data.id,
    profileId: data.profileId,
    status: data.status,
    tier: data.tier,
    tls: data.tls,
    type: data.type,
    updated: data.updated,
  });
};

function checkForNull(object, property) {
  if (!object) {
    return "none";
  }
  return object[property];
}

function checkForNone(value) {
  if (!!value && value.toLowerCase() === "none") {
    return null;
  }
  return value;
}

export const commonDataMapper = (self, type) => {
  const obj = {
    custom: self.state.custom,
    description: self.state.description,
    domains: self.state.domains,
    enabled: self.state.enabled,
    endpoint: self.state.endpoint,
    logging: self.state.logging,
    customerId: self.state.customerId,
    id: self.state.id,
    loggingSettings: {
      ftpPassword: !!self.state.logging
        ? self.state.loggingSettings.ftpPassword || ""
        : "",
    },
    status: self.state.status,
    tier: self.state.tier,
    tls: self.state.tls,
    tlsSettings: {
      certificateId: checkForNone(self.state.tlsSettings.certificateId),
      tlsType: checkForNone(self.state.tlsSettings.tlsType),
    },
    type: self.state.type,
    updated: self.state.updated,
    created: self.state.created,
  };

  if (type === SINGLE_CDN || type === PRIVATE_CDN) {
    obj["tlsSettings"]["supportOlderCiphers"] =
      self.state.tlsSettings.supportOlderCiphers;
  }
  console.log(obj);
  return obj;
};

export const shieldcdnDataMapper = (self) => {
  return {
    custom: self.state.custom,
    description: self.state.description,
    enabled: self.state.enabled,
    endpoint: self.state.endpoint,
    status: self.state.status,
    tier: self.state.tier,
    type: self.state.type,
    updated: self.state.updated,
    customerId: self.state.customerId,
    id: self.state.id,
  };
};

const tlsTypes = [
  {
    value: "none",
    text: "No TLS/SSL",
  },
  {
    value: "custom",
    text: "SNI TLS/SSL",
  },
  {
    value: "shared",
    text: "Shared TLS/SSL",
  },
];

function getTlsSettingsName(value) {
  if (!value) {
    return "TLS disabled";
  }

  const tlsType = tlsTypes.filter((tlsType) => tlsType.value === value);
  return !!tlsType[0] && !!tlsType[0].text ? tlsType[0].text : "";
}

export function getsupportOlderCiphersControl(self, classes, isEditFormView) {
  return (
    <RenderSwitchWithToolTip
      label="Support Older Ciphers"
      name="supportOlderCiphers"
      checked={self.state.tlsSettings.supportOlderCiphers}
      id="supportOlderCiphers"
      required={true}
      handleRadioChange={(event) =>
        self.handleRadioNestedChange(
          "supportOlderCiphers",
          event,
          self.state.tlsSettings
        )
      }
      value={self.state.tlsSettings.supportOlderCiphers}
      disabled={!!isEditFormView}
      className="switch-ios"
      toolTipTitle={DISTRIBUTION.SUPPORTOLDERCIPHERS}
      classes={classes}
    />
  );
}

export function getTLSSettingsControl(self) {
  return (
    <div className="auto-complete order2 auto-complete__tooltip">
      <RenderAutoSelectWithToolTip
        id="tlsSettings"
        name="tlsSettings"
        value={self.state.tlsSettings.tlsType}
        defaultValue="0"
        label="TLS Settings"
        className={"fixed-height"}
        placeholderSuffix="TLS Settings"
        disabled={!self.state.enabled}
        toolTipTitle={DISTRIBUTION.TLSSETINGS}
        handleChange={(selection, name) =>
          updateTLSByAutoComponent(
            selection,
            "tlsType",
            self,
            self.state.tlsSettings
          )
        }
        items={tlsTypes}
      />
    </div>
  );
}

export function getCertificateControl(self) {
  return (
    <div className="auto-complete margin-top-16 auto-complete__tooltip">
      <RenderAutoSelectWithToolTip
        id="certificate"
        name="certificate"
        className="margin-top-16"
        value={self.state.tlsSettings.certificateId}
        label="Certificate"
        className={"fixed-height"}
        placeholderSuffix="certificate"
        defaultValue="0"
        handleChange={(selection, name) =>
          updateTLSByAutoComponent(
            selection,
            "certificateId",
            self,
            self.state.tlsSettings
          )
        }
        items={getTextValueFormatCertificate(self.state.certificates)}
        required
        error={!checkForNone(self.state.tlsSettings.certificateId)}
        toolTipTitle={DISTRIBUTION.CERTIFICATE}
        disabled={!self.state.enabled}
      />
    </div>
  );
}

export function cdnEditFormControls(self, type, isReadOnly) {
  const { classes } = self.props;
  const { advancedForm } = self.state;
  const enabledRadioField = !isReadOnly && (
    <div className="advance-view pull-right fullwidth">
      <span className="margin-right-16 inactive">{DISTRIBUTION.ENABLED}.</span>
      <label>Enabled</label>
      <RenderSwitch
        label="Enabled"
        name="enabled"
        checked={self.state.enabled}
        id="enabled"
        required={true}
        handleRadioChange={(event) => self.handleRadioChange("enabled", event)}
        value={self.state.enabled}
        className="switch-ios"
        classes={classes}
      />
    </div>
  );

  return (
    <React.Fragment>
      <Grid container className="padding-none">
        <Grid item xs={12}>
          {enabledRadioField}
        </Grid>
      </Grid>

      <div className="clear-both">
        <Typography variant="h3" align="left">
          Basic Settings
        </Typography>
        <RenderInputWithToolTip
          placeholder="Description of this distribution."
          id="description"
          name="description"
          type="text"
          required
          error={!self.state.description}
          disabled={!self.state.enabled}
          value={self.state.description || ""}
          label="Description"
          handleChange={self.handleChange}
          toolTipTitle={DISTRIBUTION.DESCRIPTION}
        />
        {!advancedForm && (
          <RenderInputWithToolTip
            placeholder="Generated CNAME target will be shown here."
            id="cnameTarget"
            name="cnameTarget"
            type="text"
            disabled
            value={self.state.endpoint}
            label="CNAME target"
            handleChange={self.handleChange}
            toolTipTitle={DISTRIBUTION.CNAME}
          />
        )}
        {type !== SHIELD_CDN && (
          <div className="position-relative ">
            <label className="fullWidth display-block-inline position-relative domains">
              Domain(s)
              <div className="toolTip margin-none" style={styles.rightDomains}>
                <ClickableTooltip toolTipTitle={DISTRIBUTION.DOMAINS} />
              </div>
            </label>
            <br />
            {!!self.state.domains &&
              self.state.domains.map((val, index) => {
                return (
                  <Grid container spacing={10} key={"domain" + index}>
                    <Grid item xs={11}>
                      <RenderInputWithOutLabel
                        placeholder="Domain. Example: cdn.yourdomain.com"
                        id={"domain" + index}
                        name={"domain" + index}
                        type="text"
                        value={self.state.domains[index]}
                        handleChange={(e) =>
                          handleStateChange(e, self, self.state.domains, index)
                        }
                        helperText="Type valid domain name. e.g: cdn.yourdomain.com!"
                        disabled={!self.state.enabled}
                        required
                        error={checkForValidDomain(self, index)}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="Delete"
                        title="Delete domain"
                        className="margin-top-16"
                        onClick={() =>
                          removeItem(self, self.state.domains, index)
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
              })}
            {type !== MULTI_CDN_PREMIUM && (
              <RenderButtonClick
                name="btndomain"
                color="primary"
                className="margin-top-16"
                onClick={() => addItem(self, self.state.domains)}
                variant="contained"
                type="button"
                size="large"
                align="right"
                disabled={!self.state.enabled}
                label="Add"
              />
            )}
            {type === MULTI_CDN_PREMIUM &&
              checkForNullAndValidLength(self.state.domains, 1) && (
                <RenderButtonClick
                  name="btndomain"
                  color="primary"
                  className="margin-top-16"
                  onClick={() => addItem(self, self.state.domains)}
                  variant="contained"
                  type="button"
                  size="large"
                  align="right"
                  disabled={!self.state.enabled}
                  label="Add"
                />
              )}
            <br />
          </div>
        )}
      </div>
      <div>
        {!advancedForm && !!self.state.tlsSettings && type !== SHIELD_CDN && (
          <Typography
            variant="h3"
            className="position-relative margin-bottom-16 tlsSettings"
          >
            TLS Settings
          </Typography>
        )}
        {!advancedForm &&
          !!self.state.tlsSettings &&
          type !== SHIELD_CDN &&
          getTLSSettingsControl(self)}
        {!advancedForm &&
          type !== SHIELD_CDN &&
          self.state.tlsSettings.tlsType === "custom" &&
          !!self.state.certificates &&
          getCertificateControl(self)}
        {!advancedForm &&
          !!self.state.tlsSettings &&
          (type === SINGLE_CDN || type === PRIVATE_CDN) &&
          getsupportOlderCiphersControl(self, classes)}
      </div>
      <div>
        {!advancedForm &&
          type !== SHIELD_CDN && (
            <Typography variant="h3" align="left">
              Logging Settings
            </Typography>
          )}
        {!advancedForm &&
          type !== SHIELD_CDN && (
            <RenderSwitchWithToolTip
              label="Logging"
              classes={classes}
              name="logging"
              checked={self.state.logging}
              handleRadioChange={(event) =>
                self.handleRadioChange("logging", event)
              }
              className={!self.state.enabled ? "disable__control" : ""}
              value={self.state.logging}
              disabled={!self.state.enabled}
              toolTipTitle={DISTRIBUTION.LOGGING}
            />
          )}
        {!advancedForm &&
          type !== SHIELD_CDN &&
          !!self.state.logging && (
            <RenderInputWithOutLabel
              placeholder="Type FTP password here. Authentication to the FTP server is in the format <distribution id>:<password>"
              id="FTPPassword"
              name="FTPPassword"
              type="password"
              value={self.state.loggingSettings.ftpPassword}
              label="FTP Password"
              disabled={!self.state.enabled}
              handleChange={(e) =>
                handleStateChange(
                  e,
                  self,
                  self.state.loggingSettings,
                  "ftpPassword"
                )
              }
            />
          )}
      </div>
    </React.Fragment>
  );
}

export function readWriteRadioControl(self, isReadOnly) {
  const { classes } = self.props;

  const editRadioField = !isReadOnly && (
    <div className="advance-view pull-right fullwidth">
      <span className="margin-right-16 inactive">
        {!self.state.isEditFormView
          ? DISTRIBUTION.EDIT_VIEW
          : DISTRIBUTION.ONLY_VIEW}
        .
      </span>
      <label>{!self.state.isEditFormView ? "Read only" : "Edit view"}</label>
      <RenderSwitch
        label="Edit form"
        name="isEditFormView"
        checked={self.state.isEditFormView}
        id="isEditFormView"
        handleRadioChange={(event) =>
          self.handleRadioChange("isEditFormView", event)
        }
        value={self.state.isEditFormView}
        className="switch-ios"
        classes={classes}
      />
    </div>
  );
  return editRadioField;
}

export function cdnViewFormControls(self, type, isReadOnly) {
  const { classes } = self.props;

  return (
    <React.Fragment>
      <div className="background-none padding-none" style={{ height: 0 }}></div>
      <div className="clear-both">
        <Typography variant="h3" align="left">
          Basic Settings
        </Typography>
        <RenderInputWithOutLabel
          placeholder="The description for this distribution."
          id="description"
          name="description"
          type="text"
          disabled={!self.state.isEditFormView}
          value={self.state.description || "No value"}
          label="Description"
          variant="standard"
          toolTipTitle={DISTRIBUTION.DESCRIPTION}
        />
        <RenderInputWithOutLabel
          placeholder="The CNAME target will be shown here."
          id="cnameTarget"
          name="cnameTarget"
          type="text"
          disabled
          variant="standard"
          value={self.state.endpoint || "No value"}
          label="CNAME target"
          toolTipTitle={DISTRIBUTION.CNAME}
        />

        {!!self.state.domains &&
          self.state.domains.length !== 0 &&
          type !== SHIELD_CDN && (
            <div className="position-relative ">
              <label className="fullWidth display-block-inline position-relative">
                Domain(s)
              </label>
              <br />
              {!!self.state.domains &&
                self.state.domains.map((val, index) => {
                  return (
                    <Grid container key={"domain" + index}>
                      <Grid item xs={12}>
                        <RenderInputWithOutLabel
                          placeholder="Domain. Example: cdn.yourdomain.com"
                          id={"domain" + index}
                          name={"domain" + index}
                          type="text"
                          value={self.state.domains[index] || "No value"}
                          disabled={!self.state.isEditFormView}
                          variant="standard"
                          required
                          error={checkForValidDomain(self, index)}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
            </div>
          )}
      </div>
      <div>
        {!!self.state.tlsSettings && type !== SHIELD_CDN && (
          <Typography variant="h3" align="left" className="position-relative">
            TLS Settings
          </Typography>
        )}
        {!!self.state.tlsSettings && type !== SHIELD_CDN && (
          <RenderInputWithOutLabel
            placeholder="TLS settings"
            id="tlsSettings"
            name="tlsSettings"
            type="text"
            className="margin-top-16"
            value={getTlsSettingsName(self.state.tlsSettings.tlsType)}
            variant="standard"
            label="TLS Settings"
            disabled={!self.state.isEditFormView}
          />
        )}
        {type !== SHIELD_CDN &&
          self.state.tlsSettings.tlsType === "custom" &&
          !!self.state.certificates && (
            <RenderInputWithOutLabel
              placeholder="Certificate"
              id="certificate"
              name="certificate"
              type="text"
              className="margin-top-16"
              value={self.getCertificateName(
                self.state.tlsSettings.certificateId
              )}
              variant="standard"
              label="Certificate"
              disabled={!self.state.isEditFormView}
            />
          )}

        {!!self.state.tlsSettings &&
          (type === SINGLE_CDN || type === PRIVATE_CDN) &&
          getsupportOlderCiphersControl(
            self,
            classes,
            !self.state.isEditFormView
          )}
      </div>
      <div>
        {type !== SHIELD_CDN && (
            <Typography variant="h3" align="left">
              Logging Settings
            </Typography>
          )}
        {type !== SHIELD_CDN && (
            <RenderSwitchWithOutToolTip
              label="Logging"
              classes={classes}
              name="logging"
              checked={self.state.logging}
              className={!self.state.isEditFormView ? "disable__control" : ""}
              value={self.state.logging}
              disabled={!self.state.isEditFormView}
              toolTipTitle={DISTRIBUTION.LOGGING}
            />
          )}
        {type !== SHIELD_CDN &&
          !!self.state.logging && (
            <RenderInputWithOutLabel
              placeholder="Type FTP password here. Authentication to the FTP server is in the format <distribution id>:<password>"
              id="FTPPassword"
              name="FTPPassword"
              type="password"
              variant="standard"
              value={self.state.loggingSettings.ftpPassword || "No value"}
              label="FTP Password"
              disabled={!self.state.isEditFormView}
            />
          )}
      </div>
    </React.Fragment>
  );
}

export function checkForValidDomain(self, index) {
  const regex = /^([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  const domain = self.state.domains[index];
  return !regex.test(domain);
}

function hasSelectedValueNone(value) {
  if (!!value && value.toLowerCase() === "none") {
    return true;
  }
  return false;
}

export function updateTLSByAutoComponent(selection, name, self, parent) {
  if (!!selection) {
    const value = selection.value;
    if (name === "tlsType") {
      const hasNone = hasSelectedValueNone(value);
      parent.tlsType = !!value ? value : null;
      self.state.tls = !hasNone ? true : false;
      parent.certificateId = value === "custom" ? parent.certificateId : null;
    }
    if (name === "certificateId") {
      parent.certificateId = !!value ? value : null;
    }
    self.setState({ parent });
  }
}

function handleStateChange(e, self, parent, key) {
  parent[key] = e.target.value; //updating value
  self.setState({ parent });
}

function removeItem(self, obj, index) {
  if (obj.length >= 1) {
    obj.splice(index, 1);
  }
  self.setState({ domains: obj });
}

function addItem(self, obj) {
  if (obj.length < 10) {
    obj.push("");
  }
  self.setState({ domains: obj });
}

function getTextValueFormatCertificate(obj) {
  let object = [{ value: "none", text: "None" }];
  if (!obj) {
    return object;
  }
  for (let ii = 0, jj = obj.length; ii < jj; ii++) {
    object.push({
      value: obj[ii].id,
      text: obj[ii].description,
    });
  }
  return object;
}

function checkForNullAndValidLength(obj, length) {
  if (!obj) {
    return true;
  }

  if (!!obj && obj.length < length) {
    return true;
  }

  return false;
}

export function getCertificates(customerId, callback) {
  axios.defaults.headers.common["Authorization"] = authClient.getBearerToken();
  let url =
    userSession.getSessionItem("apiUrl") +
    "configurations/" +
    customerId +
    "/certificates";

  axios.get(url).then((result) => {
    if (!!result) {
      callback(result.data.response);
    }
  });
}
