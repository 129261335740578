import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./forgot.scss";
import Notification, { initialState } from "../../common/helpers/notification";
import { Redirect } from "react-router-dom";
import {
  RenderInput,
  ClickableTooltip,
  RenderSubmitButton,
} from "../../common/helpers/renderElement";
import classNames from "classnames";
import { setApiUrl } from "../../common/branding/brandconfig";
import AppBar from "@material-ui/core/AppBar";
import Header from "../../components/header/header";
import { passwordCheck } from "../../common/helpers/passwordCheck";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { Link } from "react-router-dom";
import { deserializeQueryString } from "../../common/helpers/stringHelper";
import { RenderButtonClick } from "../../common/helpers/renderElement";
import TwoFactorAuthComp from "../TwoFactorAuthComp/TwoFactorAuthComp";
import { Divider } from "@material-ui/core";

class ForgotPassword extends Component {
  keyValuePair = {};
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleMatchPassword = this.handleMatchPassword.bind(this);
    this.keyValuePair = deserializeQueryString(this.props.location.search);
    this.navigateToLogin = this.navigateToLogin.bind(this);
    const { title } = props;
    this.state = {
      isLoaded: false,
      error: "",
      variant: "",
      passwordWeak: false,
      passwordGood: false,
      passwordStrong: false,
      email: this.keyValuePair.email || "",
      password: "",
      confirmPassword: "",
      bothPasswordMatched: title === "Forgot 2FA" ? true : false,
      redirectToLogin: false,
      resetToken: this.keyValuePair.token || false,
      hasRedirectMessage: "",
      enable2FA: false,
    };
  }

  resetState() {
    let state = Object.assign(
      {
        isLoaded: true,
        email: this.state.email,
      },
      initialState()
    );
    return this.setState(state);
  }

  navigateToLogin() {
    this.setState({
      redirectToLogin: true,
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return false;
    }
    this.resetState();
  };

  showNotification(variant, open, error) {
    this.setState({
      error: error,
      open: open,
      variant: variant,
      isLoaded: true,
    });
  }

  handleFallBack(message) {
    this.resetState();
    this.showNotification("error", true, message);
  }

  handleSubmit(e) {
    if (typeof e === "object") {
      e.preventDefault();
      if (!!this.state.resetToken) {
        this.handleReset();
      } else {
        this.handleForgotPassword();
      }
    }
  }

  async handleReset() {
    const { password } = this.state;
    const { title } = this.props;
    const endpoint = title === "Forgot 2FA" ? "forgot2fa" : "forgotpassword";
    const payload = {
      password,
    };
    let _self = this;
    _self.setState({ isLoaded: false });
    let search = window.location.search.substring(1),
      playTokenObject = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
    let rtoken = "Bearer " + playTokenObject.token;
    await setApiUrl().then((values) => {
      let url = values.apiUrl + endpoint;
      let method = "put";
      localStorage.clear();

      if (title === "Activate user") {
        url = values.apiUrl + "activate-user";
        method = "post";
      }

      axios({
        method: method,
        url: url,
        data: payload,
        headers: {
          Accept: "application/json, text/plain, */*",
          ContentType: "application/json;charset=UTF-8",
          Authorization: rtoken,
        },
      }).then(
        (result) => {
          let successMsg = !!title
            ? "Your account activation is successfully completed, now enabling 2 factor."
            : "You password reset is successfully completed. Please navigate to login page!";
          _self.showNotification("success", true, successMsg);
          !!title &&
            _self.setState({
              enable2FA: true,
              fa2Secret: result.data.response.fa2Secret,
            });
        },
        (error) => {
          const message = error.response.data.message;
          _self.deleteKeyFromObject(this.keyValuePair, "token");
          _self.handleFallBack(message);
          !!title &&
            _self.setState({
              enable2FA: false,
              fa2Secret: null,
            });
        }
      );
    });
  }

  setAutoRedirect() {
    const _this = this;
    _this.setState({
      hasRedirectMessage:
        "Congratulations! Your new password has been resetted successfully. Now you can go to login page by clicking on the button below or you will be redirected in 10 seconds.",
    });

    setTimeout(() => {
      this.setState({
        redirectToLogin: true,
      });
    }, 10000);
  }

  deleteKeyFromObject(obj, key) {
    if (!obj.hasOwnProperty(key)) {
      delete obj[key];
    }
    this.setState({
      resetToken: "",
    });
  }
  async handleForgotPassword() {
    let _self = this;
    _self.setState({ isLoaded: false, hasRedirectMessage: "" });
    const { email } = this.state;
    const endpoint = "forgotpassword?email=";

    await this.userResetOrActivationCommon(endpoint, email, null);
  }

  async userResetOrActivationCommon(endpoint, email, type) {
    await setApiUrl().then((values) => {
      const url = values.apiUrl + endpoint + email;
      axios({
        method: "post",
        url: url,
        headers: {
          Accept: "application/json, text/plain, */*",
          ContentType: "application/json;charset=UTF-8",
        },
      }).then(
        (result) => {
          let successMsg = result.data.response + ".";
          this.showNotification("success", true, successMsg);
          type !== "activateUser" && this.redirectToPage(10000, "login");
        },
        (error) => {
          const message = error.response.data.message;
          this.deleteKeyFromObject(this.keyValuePair, "token");
          this.handleFallBack(message);
        }
      );
    });
  }

  async handleInitiateEmail(type) {
    const { email } = this.state;
    const endpoint =
      type === "reset2FA" ? "forgot2fa?email=" : "validate-email?email=";

    await this.userResetOrActivationCommon(endpoint, email, type);
  }

  redirectToPage(msTime, page) {
    const timeout = msTime || 5000,
      _this = this;
    setTimeout(() => {
      _this.setState({
        redirectToLogin: true,
      });
    }, timeout);
  }

  handlePasswordChange(e, name) {
    const { title } = this.props;
    let strength = passwordCheck.strongPassword(e.target.value);
    this.setState({
      passwordWeak: passwordCheck.hasCharacters(e.target.value),
      passwordGood: passwordCheck.hasNumbers(e.target.value),
      passwordStrong:
        title === "Forgot 2FA"
          ? true
          : passwordCheck.hasSpecialChar(e.target.value) && strength,
    });
    this.handleMatchPassword(e, name);
  }

  handleMatchPassword(e, name) {
    const { title } = this.props;
    const matchFlag = e.target.value === this.state[name];
    title !== "Forgot 2FA" &&
      this.setState({
        bothPasswordMatched: matchFlag,
      });
    this.handleChange(e);
  }

  render() {
    let {
      open,
      error,
      variant,
      isLoaded,
      resetToken,
      bothPasswordMatched,
      password,
      email,
      fa2Secret,
      confirmPassword,
      passwordWeak,
      passwordGood,
      passwordStrong,
      redirectToLogin,
      hasRedirectMessage,
      enable2FA,
    } = this.state;

    const { title, label } = this.props;

    if (!!redirectToLogin) {
      return <Redirect to="/" />;
    }

    return (
      <React.Fragment>
        <Notification
          open={open}
          description={error}
          variant={variant}
          duration={5000}
          close={this.handleClose}
          buttontype="default"
          buttonlabel="Close"
          buttonsize="small"
        />
        <AppBar
          position="fixed"
          color="default"
          className="appBar"
          elevation={0}
        >
          <div className="height-6">
            <Header toggle={true} label={label} />
          </div>
        </AppBar>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={8} md={6} className="forgot">
            {!!hasRedirectMessage && (
              <Paper xs={12} className="forgot-paper">
                <Typography variant="h4" align="center">
                  {hasRedirectMessage}
                </Typography>
                <Link to="/" className="link-default">
                  Go back to Login page
                </Link>
              </Paper>
            )}
            {!hasRedirectMessage && (
              <Paper xs={12} className="forgot-paper">
                <form onSubmit={this.handleSubmit} className="forgot-form">
                  <Typography variant="h3" align="center">
                    {!!resetToken && !title && "Set password"}
                    {!!resetToken &&
                      title === "Activate user" &&
                      "Email successfully verified, Please set your password! "}
                    {!resetToken && !title && "Forgot password"}
                    {!resetToken &&
                      title === "Activate user" &&
                      "Something went wrong when trying to save your password."}
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    {!!resetToken &&
                      "Please enter your desired password and click on the 'Set password' button"}
                    {!resetToken &&
                      !title &&
                      "To set your password, fill in the form below"}
                    {!resetToken &&
                      !!title &&
                      title === "Forgot 2FA" &&
                      "To set your 2FA, fill in the form below"}
                    {!resetToken &&
                      !!title &&
                      title === "Activate user" &&
                      "Your email address has been validated, but your token is most likely expired."}
                  </Typography>
                  {!resetToken && title === "Activate user" && (
                    <Typography variant="subtitle2" align="center">
                      Please initiate 'Activate my account' in order to receive
                      a new token and follow the provided steps to set your new
                      password.
                    </Typography>
                  )}
                  {!resetToken && title === "Activate user" && (
                    <Typography variant="subtitle2" align="center">
                      And, now you will be automatically redirected in 10
                      seconds.
                    </Typography>
                  )}
                  <RenderInput
                    placeholder="Type your email"
                    id="email"
                    type="email"
                    readOnly={!!resetToken}
                    required
                    label="Email address"
                    icon="account_circle"
                    value={email}
                    onChange={this.handleChange}
                  />
                  {!!resetToken && (
                    <React.Fragment>
                      <RenderInput
                        placeholder="Type your password"
                        id="password"
                        type="password"
                        required
                        label="Password"
                        icon="lock"
                        value={password}
                        onChange={(e) =>
                          this.handlePasswordChange(e, "confirmPassword")
                        }
                      />
                      <div className="password-container  height-8">
                        <ClickableTooltip
                          toolTipClassName="password-toolTip"
                          toolTipTitle={`<strong>Password guide:</strong> <br /><ul>
                        <li>At least 12 characters long but 14 or more is better.</li> 
                        <li>A combination of uppercase letters, lowercase letters, numbers, and symbols.</li>
                        <li>Allowed special characters: !#$%&'*+-/=?^_|~ </li>
                        <li>Not a word that can be found in a dictionary or the name of a person, character, product, or organization.</li>
                        <li>Significantly different from your previous passwords.</li>
                        <li>Easy for you to remember but difficult for others to guess.</li></ul>`}
                        />
                        {title !== "Forgot 2FA" && (
                          <>
                            <LinearProgress
                              className={classNames({
                                week: true,
                                filled: passwordWeak,
                              })}
                              variant="determinate"
                              value={100}
                            />
                            <LinearProgress
                              className={classNames({
                                good: true,
                                filled: passwordGood,
                              })}
                              variant="determinate"
                              value={100}
                            />
                            <LinearProgress
                              className={classNames({
                                strong: true,
                                filled: passwordStrong,
                              })}
                              variant="determinate"
                              value={100}
                            />
                          </>
                        )}
                      </div>

                      {title !== "Forgot 2FA" && (
                        <RenderInput
                          placeholder="Type your password"
                          id="confirmPassword"
                          type="password"
                          required
                          label="Confirm Password"
                          icon="lock"
                          helperText={
                            bothPasswordMatched
                              ? false
                              : "Passwords do not match"
                          }
                          value={confirmPassword}
                          onChange={(e) =>
                            this.handleMatchPassword(e, "password")
                          }
                        />
                      )}
                    </React.Fragment>
                  )}
                  <div className="margin-top-16"></div>
                  {!!resetToken &&
                    (title !== "Forgot 2FA" ? (
                      <>
                        <span
                          className={
                            (!!passwordStrong ? "text-success" : "text-error") +
                            " text-left help-text password-padding"
                          }
                        >
                          <strong>Password rule:</strong>
                          <br />
                          At least 12 characters long.
                          <br />A combination of uppercase letters, lowercase
                          letters, numbers, and symbols.
                        </span>
                        <RenderSubmitButton
                          name="submit"
                          color="primary"
                          variant="contained"
                          type="submit"
                          size="medium"
                          align="left"
                          label="Set password"
                          disabled={
                            !(
                              !isLoaded &&
                              passwordStrong &&
                              bothPasswordMatched
                            )
                          }
                          fullWidth
                        />
                      </>
                    ) : (
                      <RenderSubmitButton
                        name="submit"
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="medium"
                        align="left"
                        label="Generate new 2FA"
                        disabled={
                          !(!isLoaded && passwordStrong && bothPasswordMatched)
                        }
                        fullWidth
                      />
                    ))}
                  {!resetToken && !title && (
                    <React.Fragment>
                      <RenderSubmitButton
                        name="submit"
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="medium"
                        align="left"
                        label="Forgot password"
                        disabled={!email}
                        fullWidth
                      />
                      {!email ||
                        (isLoaded && (
                          <Link to="/" className="link-default">
                            Go back to Login page
                          </Link>
                        ))}
                    </React.Fragment>
                  )}
                  {!resetToken && title === "Activate user" && (
                    <React.Fragment>
                      <RenderButtonClick
                        name="submit"
                        color="primary"
                        variant="contained"
                        type="submit"
                        size="medium"
                        align="left"
                        label="Activate me"
                        fullWidth
                        onClick={() => this.handleInitiateEmail("activateUser")}
                      />
                      <br />
                    </React.Fragment>
                  )}
                  {!resetToken && title === "Forgot 2FA" && (
                    <React.Fragment>
                      <RenderButtonClick
                        name="submit"
                        color="primary"
                        variant="contained"
                        type="button"
                        size="medium"
                        align="left"
                        label="Reset 2FA"
                        disabled={!email}
                        fullWidth
                        onClick={() => this.handleInitiateEmail("reset2FA")}
                      />
                      <br />
                      {!email ||
                        (isLoaded && (
                          <Link to="/" className="link-default">
                            Go back to Login page
                          </Link>
                        ))}
                    </React.Fragment>
                  )}
                  {!!resetToken && (
                    <>
                      {!!title && (
                        <Divider className="margin-top-default margin-bottom-default" />
                      )}
                      {!!title && (
                        <TwoFactorAuthComp
                          isActive={enable2FA}
                          details={{
                            email,
                            password,
                            label,
                            fa2Secret,
                          }}
                        />
                      )}
                      {!!title && (
                        <Divider className="margin-top-default margin-bottom-default" />
                      )}
                      {enable2FA ? (
                        <span className="redirect-link">
                          Your 2FA has been set, If you have already captured it
                          then we advice you to{" "}
                          <Link
                            color="primary"
                            to="/"
                            className="link-default link decoration"
                          >
                            click here
                          </Link>{" "}
                          to navigate to login screen.
                        </span>
                      ) : (
                        <Link to="/" className="link-default">
                          Go back to Login page
                        </Link>
                      )}
                    </>
                  )}
                </form>
              </Paper>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
