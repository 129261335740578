import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import store from "./store";
import { filterPalette } from "./common/constants/configBrand";
import { setApiUrl } from "./common/branding/brandconfig";

class Index extends Component {
  constructor() {
    super();
    this.state = {
      theme: null
    };
  }

  componentWillMount() {
    this.loadTheme();
  }

  loadTheme() {
    setApiUrl()
      .then(data => {
        this.setTheme(data.label);
      })
      .catch(error => {
        this.setTheme(undefined);
      });
  }

  setTheme(label) {
    this.setState({
      theme: createTheme({
        palette: filterPalette(label)
      }),
      label: label
    });
  }

  render() {
    let { theme, label } = this.state;

    if (!theme) {
      return <div />;
    }
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App label={label} />
        </Provider>
      </ThemeProvider>
    );
  }
}

function renderToDOM() {
  ReactDOM.render(<Index />, document.getElementById("root"));
}
renderToDOM();

export { renderToDOM };

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
