import React, { Component } from "react";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification
} from "../../actions/notificationAction";
import { getUserDetails } from "../../actions/userDetailsAction";
import { RESELLER } from "../../common/constants/roles";
import {
  staticPlaceholderTableWithParamNoPadding,
  staticPlaceholderTableWithDynMsg,
  styles
} from "../../common/helpers/loader";
import { Redirect } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  initialState,
  setNotificationVisibility
} from "../../common/helpers/notification";
import Notification from "../../common/helpers/notification";
import { Paper } from "@material-ui/core";
import {
  RenderInputWithOutLabel,
  RenderButtonClick
} from "../../common/helpers/renderElement";
import TableCard from "../Cards/TableCard";
import { debuggingPostCall } from "./DebuggingService";
import { formatCamelcaseAndRemoveSpeacialChar } from "../../common/helpers/stringHelper";
import StatisticsBase from "../Statistics/StatisticsBase";

class Debugging extends StatisticsBase {
  tableHeader = [];
  tableNames = [];
  constructor(props) {
    super(props);
    this.handleInvalidationChange = this.handleInvalidationChange.bind(this);
    this.state = {
      ...initialState(),
      cdnURL: "",
      tableBodyData: null,
      invalidUrl: true,
      tableHeaderData: [],
      inProgressTableLoad: null
    };
  }
  componentDidMount() {
    const { role } = this.props;
    if (this.getLoggedInCustomerRole(role) === RESELLER) {
      return <Redirect to="/" />;
    }
    this.idleTimeCheck();
  }

  componentDidUpdate(nextProps) {
    if (!nextProps.userData) {
      this.props.getUserDetails();
    }
  }

  componentWillUnmount() {
    const { hideNotification } = this.props;
    hideNotification();
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  flattenObject(object, index, tableBody) {
    for (let key in object[index]) {
      if (typeof object[index][key] !== "object") {
        const value = this.getValidCellValue(object[index][key]);
        Object.defineProperty(tableBody[index], [key], {
          value: value,
          enumerable: true
        });
      }
      if (!object[index][key] && object[index][key] !== false) {
        const value = this.getValidCellValue(object[index][key]);
        Object.defineProperty(tableBody[index], [key], {
          value: value,
          enumerable: true
        });
      }

      if (
        typeof object[index][key] === "object" &&
        !!object[index][key] &&
        !!object[index][key].length
      ) {
        this.newKeyValueMapping(object[index][key], tableBody[index]);
      }
    }
    return tableBody;
  }

  newKeyValueMapping(object, refobject) {
    let simpleObject = [];
    let keepOnlyKeys = [];
    let keepOnlyValues = [];

    for (let ii = 0, jj = object.length; ii < jj; ii++) {
      for (let key in object[ii]) {
        simpleObject.push(object[ii][key]);
      }
      keepOnlyValues.push(simpleObject[1]);
      keepOnlyKeys.push(simpleObject[0]);
      simpleObject = [];
    }
    this.setValuesAsRefObjectSeries(keepOnlyValues, keepOnlyKeys, refobject);
  }

  setValuesAsRefObjectSeries(objectValues, objectkeys, refobject) {
    for (let ii = 0, jj = objectValues.length; ii < jj; ii++) {
      Object.defineProperty(refobject, [objectkeys[ii]], {
        value: objectValues[ii],
        enumerable: true
      });
    }
  }

  createEmptyObject(length) {
    let emptyObject = [];
    for (let ii = 0, jj = length; ii < jj; ii++) {
      emptyObject.push({});
    }
    return emptyObject;
  }
  createTableHeaderAndBody(res, endpoint, callback) {
    const resObject = res[0][endpoint];

    let flattenObject = this.createEmptyObject(resObject.length);
    let tableHeaders = [
      {
        id: "attr",
        numeric: false,
        disablePadding: false,
        label: "attributes"
      }
    ];
    let tableBody = [];
    let finalObject = [];

    for (let ii = 0, jj = resObject.length; ii < jj; ii++) {
      finalObject = this.flattenObject(resObject, ii, flattenObject);
    }

    for (let ii = 0, jj = finalObject.length; ii < jj; ii++) {
      tableBody = this.pushNames(finalObject[ii], ii);
      tableHeaders.push({
        id: "name" + ii,
        numeric: false,
        disablePadding: false,
        label: formatCamelcaseAndRemoveSpeacialChar(
          finalObject[ii].providerType
        ).toLowerCase()
      });
    }

    // deleting provider type, assumption that its dataProvider
    tableBody.shift();

    callback(tableBody, tableHeaders);
  }

  isValidStatus(value) {
    if (!!value) {
      return (
        <i className="material-icons" style={styles.success}>
          check_circle_outline
        </i>
      );
    } else {
      return (
        <i className="material-icons" style={styles.error}>
          error
        </i>
      );
    }
  }

  spliceNewNames(array, value, key, index) {
    let hasMatchingKey = array.filter(item => item["attr"] === key);
    let matchIndex = array.findIndex(item => item["attr"] === key);

    if (!!hasMatchingKey.length && matchIndex >= 0) {
      let object = array[matchIndex];
      Object.defineProperty(object, ["name" + index], {
        enumerable: true,
        value: value
      });
    } else {
      array.push({ ["name" + index]: value, attr: key });
    }

    return array;
  }

  pushNames(singleArray, index) {
    let lastIndex = 0;
    for (const key in singleArray) {
      const value = this.getValidCellValue(singleArray[key]);
      if (index === 0) {
        this.tableNames.push({ ["name" + index]: value, attr: key });
      } else {
        this.spliceNewNames(this.tableNames, value, key, index);
      }
    }
    return this.tableNames;
  }

  getValidCellValue(value) {
    if (typeof value === "boolean") {
      return this.isValidStatus(value);
    }

    if (!value) {
      return "-";
    }

    return value;
  }

  handleInvalidationChange(e) {
    const regex = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&=]*)/
    );
    const val = e.target.value;
    this.setState({ cdnURL: val });
    if (val.match(regex)) {
      this.setState({ invalidUrl: false });
    } else {
      this.setState({ invalidUrl: true });
    }
    return true;
  }

  validateResponse(url, customerId) {
    let payload = {
      url: url
    };
    this.setState({
      inProgressTableLoad: true,
      tableBodyData: null
    });

    this.tableHeader = [];
    this.tableNames = [];
    debuggingPostCall(payload, customerId, response => {
      if (typeof response === "object") {
        let _this = this;
        this.createTableHeaderAndBody(
          [response],
          "providerResponses",
          (tableBody, tableHeader) => {
            _this.setState({
              tableBodyData: tableBody,
              tableHeaderData: tableHeader,
              inProgressTableLoad: false
            });
          }
        );
      } else {
        this.setState({
          tableBodyData: response,
          inProgressTableLoad: false
        });
      }
    });
  }

  render() {
    const {
      error,
      variant,
      color,
      label,
      invalidUrl,
      cdnURL,
      tableBodyData,
      tableHeaderData,
      inProgressTableLoad
    } = this.state;
    const { open, userData, role, switchUserData } = this.props;

    if (this.getLoggedInCustomerRole(role) === RESELLER) {
      return <Redirect to="/" />;
    }

    if (!userData) {
      return this.getSecordaryLoader();
    }

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" color="initial" className="page-title">
              Debugging
            </Typography>
            <Typography variant="h4" color="initial">
              Test the responses from the various CDN.
            </Typography>
          </Grid>
        </Grid>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel={label}
          buttonsize="small"
        />
        <Paper className="padding-left-default padding-right-default padding-bottom-default margin-bottom-16 margin-top-16">
          <Grid container className="grid-container">
            <Grid item xs={12} sm={10} className="grid-item">
              <RenderInputWithOutLabel
                placeholder="http(s)://"
                id="cdnURL"
                name="cdnURL"
                type="text"
                required
                value={cdnURL}
                helperText="Test CDN url here. Input in above field."
                label="CDN URL"
                handleChange={this.handleInvalidationChange}
                error={invalidUrl}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className="grid-item button-mobile padding-left-default"
            >
              <RenderButtonClick
                name="cdnURLBtn"
                color="primary"
                onClick={() => this.validateResponse(cdnURL, customerId)}
                variant="contained"
                type="button"
                size="large"
                className="btn-update"
                fullWidth
                align="right"
                disabled={invalidUrl}
                label="Test response"
              />
            </Grid>
          </Grid>
        </Paper>
        {inProgressTableLoad === true &&
          staticPlaceholderTableWithParamNoPadding("auto")}
        {inProgressTableLoad === false &&
          typeof tableBodyData === "string" &&
          staticPlaceholderTableWithDynMsg("grid-msg", tableBodyData)}
        {!!tableBodyData && typeof tableBodyData !== "string" && (
          <Paper>
            <TableCard
              data={tableBodyData}
              className="padding-bottom-default-child limit-cell-width"
              tableRows={tableHeaderData}
              checkbox={false}
              cardHeader="Provider responses"
              newOrderBy={"value"}
              rowsPerPage={tableBodyData.length}
              hasSearch={false}
              hideNumbers={true}
            />
          </Paper>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  open: state.notification.items,
  switchUserData: state.user.switchUserData,
  userData: state.user.data
});

export default connect(
  mapStateToProps,
  {
    showNotification,
    hideNotification,
    getUserDetails
  }
)(Debugging);
