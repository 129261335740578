import { GET_CHART, GET_HOURLY_CHART, GET_MULTILINE_CHART } from '../actions/types';

const initialState = {
    monthlyChart: null,
    hourlyChart: null
}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CHART:
            return {
                ...state,
                monthlyChart: action.data
            }
        case GET_HOURLY_CHART:
            return {
                ...state,
                hourlyChart: action.data
            }
        case GET_MULTILINE_CHART:
            return {
                ...state,
                multiLineChart: action.data
            }
        default:
            return state;
    }
}