import React from "react";
import Typography from "@material-ui/core/Typography";
import Notification, {
  initialState,
  setNotificationVisibility,
} from "../../common/helpers/notification";
import { connect } from "react-redux";
import {
  showNotification,
  hideNotification,
} from "../../actions/notificationAction";
import {
  RenderSearchTableInput,
  RenderSwitch,
} from "../../common/helpers/renderElement";
import PageviewIcon from "@material-ui/icons/Pageview";
import {
  getUserDetails,
  applyFilteredUsers,
  getUsers,
  deleteSelectedUser,
  addUsers,
  editSelectedUser,
  switchUserDetails,
  resetUsers,
  userEnabledToggle,
} from "../../actions/userDetailsAction";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { EnhancedTableToolbar } from "../Table/EnhancedTableToolbar";
import EnhancedTableHead from "../Table/EnhancedTableHead";
import { shortByDate } from "../../common/helpers/dateHelper";
import AlertDialog from "../Dialog/AlertDialog";
import FormDialogBase from "../Dialog/FormDialogBase";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Create from "@material-ui/icons/Create";
import {
  userFormControls,
  userFormViewControls,
  getLoggedInUserDetails,
  userDataMapper,
} from "../../common/constants/user";
import { NORESPONSE, tableLoaderEmpty } from "../../common/helpers/loader";
import BaseComponent from "../../common/base/BaseComponent";
import {
  stableSort,
  getSorting,
  tableStyles,
  scrollInToView,
} from "../../common/helpers/tableRulesAndStyles";

let userTableConfig = {
  order: "desc",
  orderBy: "createDate",
  selected: [],
  page: 0,
  rowsPerPage: 10,
  openDialog: false,
  openFormDialog: false,
  progressStatus: 0,
  isLoading: false,
  pageOptions: [],
  passwordWeak: 0,
  passwordGood: 0,
  passwordStrong: 0,
  isValidPassword: true,
  dialogFormName: "Add user",
  tableSearchText: "",
  userIP: null,
  usersList: null,
};

export const userTableRows = [
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  {
    id: "enabled",
    numeric: true,
    disablePadding: false,
    label: "Enable/Disable user",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "Action(s)",
  },
];
var rows = userTableRows;

class UserManagement extends BaseComponent {
  formElementsValue = {};
  tableData = [];
  oldCustomerId = "";
  tableListFull;
  calledOnce = false;
  avoidDoubleCall = false;

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.idleTimeCheck = this.idleTimeCheck.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { ...initialState(), ...userTableConfig };
  }

  componentWillMount() {
    this.props.getUserDetails();
    this.idleTimeCheck();
  }

  componentWillUnmount() {
    this.props.hideNotification();
    this.props.resetUsers();
    this.oldCustomerId = null;
  }

  componentWillReceiveProps(nextProps) {
    const { userData } = this.props;

    if (
      !!this.oldCustomerId &&
      (!nextProps.users ||
        userData.customerId !== nextProps.userData.customerId)
    ) {
      this.oldCustomerId = null;
    }

    if (!!nextProps.userData && !this.oldCustomerId) {
      let customerId = nextProps.userData.customerId;
      let email = nextProps.userData.email;

      if (!!email && !!customerId && !this.avoidDoubleCall) {
        this.oldCustomerId = customerId;
        this.getUsers(email, customerId);
      }
    }

    if (!!nextProps.users) {
      this.setPageOption(nextProps.users);
    }
  }

  getUsers(email, customerId) {
    this.setState({
      page: 0,
    });
    this.props.getUsers(customerId, (res) => {
      this.setState({
        userIP: this.getLoggedInUserIp(res, email),
      });
    });
  }

  getLoggedInUserIp(res, email) {
    const loggedUser = getLoggedInUserDetails(res, email);
    if (!!loggedUser && typeof loggedUser === "object") {
      return loggedUser.ip;
    } else {
      return { ip: null };
    }
  }

  handleClearSearch(name, typeSuccess) {
    this.setState({
      [name]: "",
    });
    this.props.applyFilteredUsers(this.tableListFull, typeSuccess);
  }

  async handleSearchTable(e, object, typeSuccess) {
    if (!this.tableListFull) {
      this.tableListFull = object;
    }

    const value = e.target.value;

    this.setState({
      [e.target.name]: value,
    });

    if (value.length > 2) {
      await this.props.applyFilteredUsers(
        this.getFilterBy(object, value),
        typeSuccess
      );
    } else {
      await this.props.applyFilteredUsers(this.tableListFull, typeSuccess);
    }
  }

  setPageOption(tableData) {
    if (tableData.length > 24) {
      this.setState({ pageOptions: [5, 10, 25] });
    } else if (tableData.length < 9) {
      this.setState({ pageOptions: [5] });
    } else {
      this.setState({ pageOptions: [5, 10] });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!!this.checkForRequiredFieldsAllViews()) {
      const _this = this;
      _this.setState({ isLoading: true });

      let customerId = !!_this.props.switchUserData
        ? _this.props.switchUserData.id
        : _this.props.userData.customerId;

      const payload = userDataMapper(_this, customerId);

      const role = _this.state.roles;
      this.avoidDoubleCall = true;

      if (_this.state.submitAction !== "PUT") {
        _this.props.addUsers(customerId, payload, role, function (result) {
          if (typeof result === "object") {
            _this.setState({ isLoading: false, openFormDialog: false });
            const message = `User: ${payload.firstName} has been successfully added.`;
            _this.toggleNotification(message, "success", "Close");
            _this.getUserData();
          } else {
            _this.setState({ isLoading: false });
            _this.toggleNotification(result, "error", "Close");
          }
        });
      } else {
        const selected = this.state.selected.join();
        payload.roleName = this.state.roles;
        _this.props.editSelectedUser(
          customerId,
          selected,
          payload,
          function (result) {
            if (typeof result === "object") {
              _this.setState({
                selected: "",
                isLoading: false,
                openFormDialog: false,
                pageOptions: [],
              });
              const message = `User: ${result.firstName} has been successfully edited.`;
              _this.toggleNotification(message, "success", "Close");
              _this.getUserData();
            } else {
              _this.setState({ isLoading: false });
              _this.toggleNotification(result, "error", "Close");
            }
          }
        );
      }
    }
  }

  handleToggleCustomer = (radio, event, userId, customerId) => {
    const toggleValue = event.target.checked,
      _this = this;

    this.props.userEnabledToggle(customerId, userId, toggleValue, function () {
      _this.setState({ [radio]: toggleValue });
    });
  };

  setStringValue(emailValidated, radio, userId, enabled, customerId) {
    if (!emailValidated) return "Not validated";
    return (
      <RenderSwitch
        name="enabled"
        id="enabled"
        handleRadioChange={(event) =>
          this.handleToggleCustomer(radio, event, userId, customerId)
        }
        checked={this.state[radio] === undefined ? enabled : this.state[radio]}
        value={this.state[radio] === undefined ? enabled : this.state[radio]}
        className="switch-ios"
        classes={{}}
      />
    );
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChange(e) {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
    this.changeProgressStatus(name, e.target.value);
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({
        selected: this.props.users.map((n) => n.id),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  setUserNameAndId = (id, name) => {
    this.setState({ selected: [id], selectUserName: name });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    scrollInToView(event.target.value);
  };

  selectItem(id, name) {
    const { selected } = this.state;
    let newSelected = [];
    if (id === selected.toString()) {
      newSelected.splice(0, 1);
    } else {
      newSelected = [id];
    }

    this.setState({ selected: newSelected, selectUserName: name });
  }

  idleTimeCheck() {
    setNotificationVisibility.apply(this);
  }

  handleFormDialogToggle() {
    this.setState({ openFormDialog: !this.state.openFormDialog });
    if (!!this.state.openFormDialog) {
      this.setState({ dialogFormName: "Add user" });
    }
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return false;
    }
    this.props.hideNotification();
  };

  notificationVisibility(obj) {
    this.setState(obj);
    this.props.showNotification();
  }

  handleRadioGroupChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.value });
    this.changeProgressStatus(radio, event.target.value);
  };

  handleRadioChange = (radio, event) => {
    event.stopPropagation();
    this.setState({ [radio]: event.target.checked });
    this.changeProgressStatus(radio, event.target.checked);
  };

  handleFieldChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  changeProgressStatus(name, value) {
    if (!!value) {
      this.formElementsValue[name] = 1;
    } else {
      delete this.formElementsValue[name];
    }
    let percentage =
      (Object.keys(this.formElementsValue).length / this.getTotalFormFields()) *
      100;
    this.setState({
      progressStatus: percentage,
    });
  }

  getTotalFormFields() {
    const fixedFields = 7;
    return fixedFields;
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleDialogToggle() {
    this.setState({ openDialog: !this.state.openDialog });
  }

  async handleDeleteItem(selected) {
    if (!selected) return false;
    const userId = selected.join();
    const { switchUserData, userData } = this.props;
    const customerId = !!switchUserData
      ? switchUserData.id
      : userData.customerId;
    const _this = this;
    _this.setState({
      isLoading: true,
      submitAction: "DELETE",
    });
    await _this.props.deleteSelectedUser(customerId, userId, function (result) {
      if (typeof result === "boolean" || typeof result === "object") {
        _this.tableData = [];
        _this.setState({
          selected: "",
          isLoading: false,
          openDialog: false,
          pageOptions: [],
        });
        _this.getUserData();
        const message = `User: ${_this.state.selectUserName} has been successfully deleted.`;
        _this.toggleNotification(message, "success", "Close");
      } else {
        _this.setState({ isLoading: false });
        _this.toggleNotification(result, "error", "Close");
      }
    });
  }

  toggleNotification(message, type, label) {
    this.notificationVisibility({
      error: message,
      variant: type,
      label: label,
      color: "inherit",
    });
  }

  handleAdd() {
    this.setState({
      submitAction: "POST",
      dialogFormName: "Add user",
      progressStatus: 0,
      isLoading: false,
      emailEmail: "",
      firstNameFirstName: "",
      lastNameLastName: "",
      enabledEnabled: true,
      phonePhone: "",
      id: "",
      roles: "CUSTOMER_ADMIN",
      passwordPassword: "",
    });
    this.handleFormDialogToggle();
  }

  handleView(userId) {
    this.setState({
      isLoading: true,
      progressStatus: 90,
      dialogFormName: "View user",
    });
    const selectedUser = this.props.users.filter(
      (value) => value.id === userId
    );

    this.setFormVisibility(selectedUser, userId);
  }

  handleEdit(userId) {
    this.setState({
      isLoading: true,
      progressStatus: 90,
      dialogFormName: "Edit user",
    });
    const selectedUser = this.props.users.filter(
      (value) => value.id === userId
    );
    this.setFormVisibility(selectedUser, userId);
  }

  setFormVisibility(selectedUser, userId) {
    this.setState({
      submitAction: "PUT",
      isLoading: false,
      selected: [userId],
      emailEmail: selectedUser[0].email,
      firstNameFirstName: selectedUser[0].firstName,
      lastNameLastName: selectedUser[0].lastName,
      enabled: selectedUser[0].enabled,
      phonePhone: selectedUser[0].phone,
      customerId: selectedUser[0].id,
      ip: selectedUser[0].ip,
      passwordPassword: selectedUser[0].password,
      roles: selectedUser[0].roleName,
    });

    this.formElementsValue = {
      email: 1,
      firstName: 1,
      lastName: 1,
      enabled: 1,
      phone: 1,
      id: 1,
      roles: 1,
    };

    this.handleFormDialogToggle();
  }

  getUserData() {
    const { switchUserData, userData } = this.props;

    if (!!switchUserData) {
      const customerId = switchUserData.id;
      const email = switchUserData.email;
      this.props.getUsers(customerId, (res) => {
        this.setState({
          userIP: this.getLoggedInUserIp(res, email),
        });
      });
    } else {
      const email = userData.email;
      const customerId = userData.customerId;

      this.props.getUsers(customerId, (res) => {
        this.setState({
          userIP: this.getLoggedInUserIp(res, email),
        });
      });
    }
  }

  checkForSelfScreen(self) {
    const { switchUserData, userData } = this.props;
    if (!!switchUserData) {
      return self.customerId !== switchUserData.id;
    } else {
      return self.id !== userData.id;
    }
  }

  getClassName(emailValidated) {
    let className = "";
    if (!emailValidated) {
      className = "read-only";
    }
    return className;
  }

  render() {
    const { open, userData, users, classes, switchUserData } = this.props;
    const {
      error,
      variant,
      color,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      isLoading,
      tableSearchText,
      dialogFormName,
      openFormDialog,
    } = this.state;
    let dialogContent;

    if (!userData) {
      return this.getSecordaryLoader();
    }

    dialogContent =
      dialogFormName === "View user"
        ? userFormViewControls(this)
        : userFormControls(this);

    let usersSorted = !!users
      ? shortByDate(users, "createDate", "updateDate")
      : null;

    let customerId = !!switchUserData ? switchUserData.id : userData.customerId;

    return (
      <React.Fragment>
        <Typography variant="h4" color="initial">
          User management
        </Typography>
        <Notification
          open={open}
          description={error}
          variant={variant}
          close={this.handleClose}
          buttontype={color}
          buttonlabel="Close"
          buttonsize="small"
        />
        <Paper className={classes.root}>
          <FormDialogBase
            openFormDialog={openFormDialog}
            formHeading={dialogFormName}
            cancelFormText={dialogFormName !== "View user" ? "Cancel" : ""}
            confirmFormText={dialogFormName !== "View user" ? "Save" : ""}
            formSubmit={this.handleSubmit}
            submitDataInProgress={isLoading}
            cancelFormAction={() => this.handleFormDialogToggle()}
            confirmFormAction={(payload) => this.handleAddItem(payload)}
            dialogContent={dialogContent}
            paddingNode={true}
            maxWidth="lg"
          />
          <AlertDialog
            openDialog={this.state.openDialog}
            heading="Confirm"
            message={
              "Are you sure you want to remove user: " +
              this.state.selected +
              "?"
            }
            cancelBtnText="Cancel"
            confirmBtnText="Confirm"
            cancelBtnAction={() => this.handleDialogToggle()}
            confirmBtnAction={() => this.handleDeleteItem(this.state.selected)}
          />
          <EnhancedTableToolbar
            numSelected={0}
            subheading="Manage your users"
            toolTipTitle="Add user"
            handleAddClick={() => this.handleAdd()}
          />
          {users === null && tableLoaderEmpty}
          {!!users && (
            <React.Fragment>
              <div className={classes.tableWrapper}>
                {typeof users === "object" && (
                  <RenderSearchTableInput
                    onChange={(e) =>
                      this.handleSearchTable(e, users, "GET_USERS")
                    }
                    onClear={(name) => {
                      this.handleClearSearch(name, "GET_USERS");
                    }}
                    className="table-search-field users"
                    value={tableSearchText}
                  />
                )}
                <Table
                  className={classes.table + " table"}
                  aria-labelledby="tableTitle"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    rows={rows}
                    hideNumbers={true}
                    onRequestSort={this.handleRequestSort}
                    rowCount={users.length}
                  />
                  <TableBody>
                    {typeof users === "object" &&
                      stableSort(usersSorted, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((n, index) => {
                          let radio = n.id + "_switch";
                          return !!users.length ? (
                            <TableRow
                              tabIndex={-1}
                              key={n.id + index}
                              className={this.getClassName(n.emailValidated)}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                {n[userTableRows[0].id]}
                              </TableCell>
                              <TableCell>
                                {this.setStringValue(
                                  n.emailValidated,
                                  radio,
                                  n.id,
                                  n.enabled,
                                  customerId
                                )}
                              </TableCell>
                              <TableCell className="actions">
                                {this.checkForSelfScreen(n) && (
                                  <React.Fragment>
                                    {!n.emailValidated && (
                                      <IconButton
                                        aria-label="View details"
                                        onClick={() => this.handleView(n.id)}
                                      >
                                        <PageviewIcon
                                          title="View details"
                                          color="primary"
                                        />
                                      </IconButton>
                                    )}
                                    {!!n.emailValidated && (
                                      <IconButton
                                        aria-label="Edit"
                                        onClick={() => this.handleEdit(n.id)}
                                      >
                                        <Create color="secondary" />
                                      </IconButton>
                                    )}
                                    <IconButton
                                      aria-label="Delete"
                                      onClick={(event) => {
                                        this.setUserNameAndId(
                                          n.id,
                                          n.firstName + " " + n.lastName
                                        );
                                        this.handleDialogToggle(n.id);
                                      }}
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </React.Fragment>
                                )}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell>There is no user data.</TableCell>
                            </TableRow>
                          );
                        })}
                    {(!users.length || typeof users === "string") && (
                      <TableRow style={{ height: 49 }}>
                        <TableCell colSpan={6}>{NORESPONSE}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                rowsPerPageOptions={this.state.pageOptions}
                component="div"
                count={typeof users === "object" ? users.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page",
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page",
                }}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.notification.items,
  users: state.user.users,
  switchUserData: state.user.switchUserData,
});

export default connect(mapStateToProps, {
  showNotification,
  hideNotification,
  getUserDetails,
  getUsers,
  switchUserDetails,
  deleteSelectedUser,
  addUsers,
  editSelectedUser,
  resetUsers,
  applyFilteredUsers,
  userEnabledToggle,
})(withStyles(tableStyles)(UserManagement));
