import {
  RECIEVED_MENU_ITEMS,
  ERROR_IN_MENU_ITEMS,
  RECEIVED_MENU_ENTITLEMENT,
  ERROR_IN_ENTITLEMENT,
  RECEIVED_INTERFACE
} from "./types";
import { apiGetCall } from "../actions/baseActions";
import { session as userSession } from "../common/helpers/sessionValues";
import authClient from "../common/helpers/auth";
import axios from "axios";

export function getEntitlements(customerId, Callback) {
  const url =
    userSession.getSessionItem("apiUrl") + customerId + "/entitlements";

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_ENTITLEMENT,
    typeSuccess = RECEIVED_MENU_ENTITLEMENT,
    method = "GET";
  const callback = !!Callback ? Callback : () => {};

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "entitlements",
    callback
  );
}

export function getInterfaceFromLocalStorage() {
  return function(dispatch) {
    dispatch({
      type: RECEIVED_INTERFACE,
      entinterface: localStorage.entitlements
    });
  };
}

export function getMenuList(customerId, userId, Callback) {
  const url =
    userSession.getSessionItem("apiUrl") +
    customerId +
    "/users/" +
    userId +
    "/menu";

  const token = authClient.getBearerToken(),
    typeError = ERROR_IN_MENU_ITEMS,
    typeSuccess = RECIEVED_MENU_ITEMS,
    method = "GET";
  const callback = !!Callback ? Callback : () => {};

  return apiGetCall(
    url,
    token,
    typeError,
    typeSuccess,
    method,
    "data",
    callback
  );
}

export function fallBackMenuItem() {
  return function(dispatch) {
    axios.get("/json/menuItems.json").then(result => {
      dispatch({
        type: RECIEVED_MENU_ITEMS,
        data: result.data
      });
    });
  };
}
