import { session as userSession } from "../helpers/sessionValues";
import { logoCollection } from "../../common/constants/configBrand";
import axios from "axios";

let apiUrl = userSession.getSessionItem("apiUrl");
let label = userSession.getSessionItem("label");

const TEST_BASEURL = "http://synedgeapi.test.mesos.ams1.synedge.net/brand/";
const PROD_BASEURL = "https://api.synedge.com/brand/";
//portal.leasewebultracdn.com
//dashboard.synedge.com
//portal.eurovisionflow.com
//portal.cdn.continent8.com
export function setApiUrl() {
  removeFaviconIcon();
  let promise = new Promise((resolve, reject) => {
    if (!!apiUrl) {
      resolve({ label: label, apiUrl: apiUrl });
    }

    let url =
      window.location.hostname === "localhost" ||
      window.location.hostname.includes(".test.") ||
      window.location.hostname.includes("127.0.0.1") ||
      window.location.hostname.includes(".dev.") ||
      window.location.hostname.includes("10.")
        ? TEST_BASEURL + window.location.hostname
        : PROD_BASEURL + window.location.hostname;

    axios.get(url).then((result) => {
      if (!result.error) {
        assignToStorage(result.data.apiUrl, result.data.companyName);
        setFaviconIcon(result.data.companyName);
        resolve({ label: result.data.companyName, apiUrl: result.data.apiUrl });
      } else {
        reject(
          "Something went wrong. Please reload the page. If the issue persists, contact support"
        );
      }
    });
  });
  return promise;
}

function removeFaviconIcon() {
  var link = document.querySelector("link[rel*='icon']");
  if (!!link) {
    document.getElementsByTagName("head")[0].removeChild(link);
  }
}

function setFaviconIcon(orgName) {
  const hasFavicon = logoCollection(orgName).faviconPath;
  if (!!hasFavicon) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = hasFavicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}

function assignToStorage(url, value) {
  userSession.setSessionItem("apiUrl", url);
  userSession.setSessionItem("label", value);
  label = value;
}

export function globalStyles() {
  // Has dependecy on index.js state change
  let styles = {
    backgroundElm: {
      height: "100%",
      top: "5px",
      flex: "1 0 auto",
      display: "flex",
      outline: "none",
      overflowY: "auto",
      flexDirection: "column",
      width: "100%",
      backgroundColor: "#f5f5f5",
    },
    container: {
      height: "calc(100% - 49px)", // height of header and footer
      fontSize: "1rem",
    },
    "@global": {
      "html, body, #root": {
        height: "100%",
      },
      ".company-logo": {
        width: logoCollection(label).logoWidth,
        height: logoCollection(label).logoHeight,
        background: "url(" + logoCollection(label).logoPath + ") no-repeat",
        backgroundSize: "100% 50px",
        marginLeft: logoCollection(label).marginLeft,
        display: "inline-block",
      },
    },
  };
  return styles;
}
