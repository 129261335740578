export const contactDataMapper = (self) => {
    return {
        active: self.state.activeActive,
        billingContact: {
            email:self.state.emailBillingContact,
            fax:self.state.faxBillingContact, 
            firstName:self.state.firstNameBillingContact,
            lastName:self.state.lastNameBillingContact,
            phone:self.state.phoneBillingContact,
            address: {
                city: self.state.cityAddress,
                country: self.state.countryAddress,
                country2letter:self.state.country2letterAddress,
                postCode:self.state.postCodeAddress,
                state:self.state.stateAddress,
                street: self.state.streetAddress
            }
        },
        description:self.state.descriptionDescription,
        id:self.state.idId,
        name:self.state.nameName,
        primaryContact: {
            email:self.state.emailPrimaryContact,
            firstName:self.state.firstNamePrimaryContact,
            lastName:self.state.lastNamePrimaryContact,
            phone:self.state.phonePrimaryContact
        },
        technicalContact: {
            email:self.state.emailTechnicalContact,
            firstName:self.state.firstNameTechnicalContact,
            lastName:self.state.lastNameTechnicalContact,
            phone:self.state.phoneTechnicalContact
        },
        type:self.state.typeType
    }
}