import { GET_TILES, ERROR_IN_TILES } from '../actions/types';

const initialState = {
    tiles: null,
}
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TILES:
            return {
                ...state,
                data: action.data
            }
        case ERROR_IN_TILES:
            return {
                ...state,
                error: action.data
            }
        default:
            return state;
    }
}