import React, { PureComponent } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { tableLoaderEmpty } from "../../common/helpers/loader";
import "./forms.scss";

export const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

class FormDialogBase extends PureComponent {
  render() {
    let {
      fullScreen,
      openFormDialog,
      formHeading,
      cancelFormText,
      confirmFormText,
      cancelFormAction,
      submitDataInProgress,
      dialogContent,
      linearProgress,
      formSubmit,
      maxWidth,
      hasFormError,
      nextFormAction,
      previousFormAction,
      tabValue,
      handleTabChange,
      tabs,
      paddingNode,
      onScroll,
      hideTab
    } = this.props;

    return (
      <Dialog
        open={openFormDialog}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth
        className="dialog-container"
        onClose={cancelFormAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <form id="dialogForm" className="dialog-form" onSubmit={formSubmit}>
          <div className="dialog-form-heading">
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component="h3"
                  id="alert-dialog-title"
                  className="padding-16 pull-left"
                >
                  {formHeading}
                </Typography>
                <IconButton
                  className="heading-icon margin-16 pull-right"
                  aria-label="Back"
                  color="primary"
                  onClick={cancelFormAction}
                  xs={2}
                >
                  <i className="material-icons">close</i>
                </IconButton>
                &nbsp;&nbsp;
              </Grid>
            </Grid>
            {!!linearProgress && (
              <LinearProgress
                color="primary"
                className="form-status"
                variant="determinate"
                value={linearProgress}
              />
            )}
            <Divider />
          </div>
          <div
            className="dialog-form-body"
            onScroll={!!onScroll ? e => onScroll(e) : () => {}}
          >
            {!!tabs && !!tabs.length && (
              <React.Fragment>
                <AppBar position="sticky" color="default">
                  {!hideTab && (
                    <Tabs
                      value={tabValue}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      onChange={handleTabChange}
                      scrollButtons="on"
                    >
                      {tabs.map((value, index) => (
                        <Tab
                          key={"tab_" + index}
                          className={"tab-item tab_" + index}
                          label={value.label}
                        />
                      ))}
                    </Tabs>
                  )}
                </AppBar>
              </React.Fragment>
            )}
            <DialogContent className={!!paddingNode ? "padding-none" : ""}>
              <FormControl>
                {!!dialogContent ? dialogContent : tableLoaderEmpty}
              </FormControl>
            </DialogContent>
          </div>
          <div className="dialog-form-footer">
            <Divider />
            <DialogActions>
              {/* <LinearProgress color="secondary" variant="determinate" value={100} /> */}
              {!tabs && (
                <React.Fragment>
                  {!!cancelFormText && (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={cancelFormAction}
                      color="secondary"
                    >
                      {cancelFormText}
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {!!confirmFormText && (
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submitDataInProgress || !!hasFormError}
                    >
                      {!submitDataInProgress ? (
                        confirmFormText
                      ) : (
                        <CircularProgress
                          size={22}
                          thickness={6}
                          color="secondary"
                        />
                      )}
                    </Button>
                  )}
                  &nbsp;&nbsp;
                </React.Fragment>
              )}
              {!!tabs && !!tabs.length && (
                <React.Fragment>
                  {!!previousFormAction && (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={previousFormAction}
                      color="secondary"
                      disabled={tabValue === 0}
                    >
                      Back
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {!!nextFormAction && (
                    <Button
                      disabled={tabValue === tabs.length - 1}
                      variant="contained"
                      size="large"
                      onClick={nextFormAction}
                      color="primary"
                      autoFocus
                    >
                      Next
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {!!confirmFormText && (
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      color="primary"
                      autoFocus
                      disabled={submitDataInProgress}
                    >
                      {!submitDataInProgress ? (
                        confirmFormText
                      ) : (
                        <CircularProgress
                          size={22}
                          thickness={6}
                          color="secondary"
                        />
                      )}
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </React.Fragment>
              )}
            </DialogActions>
          </div>
        </form>
      </Dialog>
    );
  }
}

export default FormDialogBase;
