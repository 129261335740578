export const session = {
  getSessionItem(key) {
    return localStorage.getItem(key);
  },
  removeSessionItem(key) {
    return localStorage.removeItem(key);
  },
  setSessionItem(key, value) {
    localStorage.setItem(key, value);
  },
  clearSession() {
    localStorage.clear();
  },
  setSessionItems(values) {
    if (typeof values == "object" && values.hasOwnProperty("access_token")) {
      for (let value in values) {
        localStorage.setItem(value, values[value]);
      }
    }
  }
};
