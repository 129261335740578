import React, { Component } from "react";
import "./breadcrums.scss";
import { Link } from "react-router-dom";
import { INPROGRESS } from "../../common/helpers/loader";
import { countryNameFormatting } from "../../common/helpers/stringHelper";

const DASHBOARD = "Dashboard";
const DISTRIBUTION = "distribution";
const TYPE = "type";

export default class NormalBreadcrumbs extends Component {
  invalidLinks = [
    "Configuration",
    "Distributions",
    "Origins",
    "Distribution",
    "Statistics"
  ];
  specialTexts = {
    "Multi cdn volume": "Multi CDN > Volume",
    "Multi cdn premium": "Multi CDN > Premium"
  };

  constructor(props) {
    super(props);
    this.applyFilterTextFormatting = this.applyFilterTextFormatting.bind(this);
  }

  setPageName(pageName, company) {
    if (!!pageName && !!company) {
      document.title = pageName + " - " + company + " CDN";
      return true;
    }
    return false;
  }
  createBreadcrums(path) {
    let pathSplit = path || window.location.pathname.split("/");
    let breadcrums = [];
    for (let ii = 0, jj = pathSplit.length; ii < jj; ii++) {
      let noParams = this.hasNoParams(pathSplit[ii]);
      if (!!pathSplit[ii] && noParams) {
        breadcrums.push({
          text: this.formatText(pathSplit[ii]),
          href: this.applyLinkFilters(
            pathSplit[ii],
            this.formatLink(pathSplit, ii)
          )
        });
      }
    }

    if (breadcrums.length > 1 && !!breadcrums[0].href && !!breadcrums[1].href) {
      breadcrums[0].href = breadcrums[1].href;
    }

    if (!!breadcrums.length && breadcrums[0].text !== DASHBOARD) {
      breadcrums.unshift({
        text: DASHBOARD,
        href: "/"
      });
    }
    return breadcrums;
  }

  applyLinkFilters(text, callback) {
    for (let ii = 0, jj = this.invalidLinks.length; ii < jj; ii++) {
      if (text.toLowerCase() === this.invalidLinks[ii].toLowerCase()) {
        return "";
      }
    }
    return callback;
  }

  formatText(text) {
    if (text === DISTRIBUTION) {
      text = "Distributions";
    }
    return countryNameFormatting(text, this.applyFilterTextFormatting, "cdn");
  }

  applyFilterTextFormatting(text) {
    for (let key in this.specialTexts) {
      if (key.toLowerCase() === text.toLowerCase()) {
        return this.specialTexts[key];
      }
    }
    return text;
  }

  formatLink(pathSplit, index) {
    let finalPath = "/";
    for (let ii = 1, jj = index + 1; ii < jj; ii++) {
      if (pathSplit[ii] === DISTRIBUTION) {
        pathSplit[ii] = "distributions";
      }
      finalPath += pathSplit[ii];
      if (ii !== index) {
        finalPath += "/";
      }
    }
    return finalPath;
  }

  hasNoParams(text) {
    for (let key in this.props.locParams) {
      if (this.props.locParams[key] === text && key !== TYPE) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { impersonate, label } = this.props;
    const impersonateValue = !!impersonate ? "impersonate" : "original";

    let links = this.createBreadcrums();

    if (!links) {
      return INPROGRESS;
    }

    if (!!links.length) {
      const pageName = links[links.length - 1].text;
      this.setPageName(pageName, label);
    }

    return (
      <ul className={"breadcrums " + impersonateValue}>
        <li>
          {links.map((node, index) => {
            return (
              <React.Fragment key={"breadcrum_" + index}>
                {index !== links.length - 1 && !!links[index].href && (
                  <Link to={node.href}>{node.text}</Link>
                )}
                {index !== links.length - 1 && !links[index].href && node.text}
                {index === links.length - 1 && <span>{node.text}</span>}
                {index !== links.length - 1 && <span> > </span>}
              </React.Fragment>
            );
          })}
        </li>
      </ul>
    );
  }
}
