import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import classNames from 'classnames';


const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    height: 64
  }
});

class Header extends Component {
  render() {
    const { label, sidebar } = this.props;
    return (
        <Link to="/dashboard" className={
          classNames({
            'company-logo': true,
            'company-logo-sidebar': sidebar
          })
        }>{label} logo</Link>
    )
  }
}



export default withStyles(styles)(Header);
