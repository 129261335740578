import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import "./stepper.scss";

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
});

class HorizontalStepper extends React.Component {
  state = {
    activeStep: 0
  };

  render() {
    const {
      classes,
      getSteps,
      getStepContent,
      getStepClassName,
      stepIndex
    } = this.props;
    const steps = getSteps();

    return (
      <div className={classes.root + " fullheight"}>
        <Stepper
          activeStep={stepIndex}
          alternativeLabel
          className="advance-view stepper sticky padding-16"
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel className={"height-8 " + getStepClassName(stepIndex)}>
                {" "}
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="stepper-body">{getStepContent(stepIndex)}</div>
      </div>
    );
  }
}

export default withStyles(styles)(HorizontalStepper);
