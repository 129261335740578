import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

class AlertDialog extends React.Component {
  render() {
    let {
      fullScreen,
      openDialog,
      heading,
      message,
      cancelBtnText,
      confirmBtnText,
      cancelBtnAction,
      confirmBtnAction
    } = this.props;

    return (
      <div>
        <Dialog
          open={openDialog}
          fullScreen={fullScreen}
          onClose={cancelBtnAction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" className="dialog-title">
            {heading}
          </DialogTitle>
          <DialogContent className="dialog-content">
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelBtnAction} color="primary" autoFocus>
              {cancelBtnText}
            </Button>
            <Button onClick={confirmBtnAction} color="primary">
              {confirmBtnText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(AlertDialog);
