import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import HorizontalStepper from "../Stepper/HorizontalStepper";
import { tableLoaderEmpty } from "../../common/helpers/loader";
import "./forms.scss";

export const Transition = React.forwardRef((props, ref) => (
  <Slide {...props} direction="up" ref={ref} />
));
class FormDialogStepper extends React.Component {
  state = {
    stepIndex: 0
  };

  actionsNext = [];

  componentWillReceiveProps(nextProps) {
    if (nextProps.stepperSwitchViewTo !== null) {
      this.setState({
        stepIndex: nextProps.stepperSwitchViewTo
      });
    }
  }

  getActionNext() {
    const { dialogContent } = this.props;
    if (!!dialogContent) {
      let actionNext = dialogContent.map(item => {
        return item.actionNext;
      });
      return actionNext;
    }
  }

  getSteps() {
    const { dialogContent } = this.props;
    let stepsName = !!dialogContent.length
      ? this.getNames(dialogContent)
      : ["Step 1"];
    return stepsName;
  }

  getNames(array) {
    let names = array.map(item => {
      return item.name;
    });
    return names;
  }

  getContent(object) {
    return object["content"];
  }

  getClassName(object) {
    return object["class"];
  }

  handleNext = e => {
    this.setState(state => ({
      stepIndex: state.stepIndex + 1
    }));
    return this.actionsNext[this.state.stepIndex](e);
  };

  handleBack = () => {
    this.setState(state => ({
      stepIndex: state.stepIndex - 1
    }));
  };

  handleReset = () => {
    this.setState({
      stepIndex: 0
    });
  };

  getStepContent(stepIndex) {
    const { dialogContent } = this.props;
    if (typeof stepIndex === "number") {
      return this.getContent(dialogContent[stepIndex]);
    } else {
      return "Unknown stepIndex";
    }
  }

  getStepClassName(stepIndex) {
    const { dialogContent } = this.props;
    if (typeof stepIndex === "number") {
      return this.getClassName(dialogContent[stepIndex]);
    } else {
      return "Unknown stepIndex";
    }
  }

  render() {
    let {
      fullScreen,
      openFormDialog,
      formHeading,
      confirmFormText,
      cancelFormAction,
      submitDataInProgress,
      linearProgress,
      formSubmit,
      maxWidth,
      onScroll,
      classes,
      dialogContent,
      cancelFormText
    } = this.props;
    let { stepIndex } = this.state;

    let steps = this.getSteps();
    this.actionsNext = this.getActionNext();

    return (
      <Dialog
        open={openFormDialog}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        fullWidth
        className="dialog-container"
        onClose={cancelFormAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
      >
        <form id="dialogForm" className="dialog-form" onSubmit={formSubmit}>
          <div className="dialog-form-heading">
            <Grid container>
              <Grid item xs={12}>
                <DialogTitle
                  id="alert-dialog-title"
                  className="form-heading heading-title"
                >
                  {formHeading}
                </DialogTitle>
                {!submitDataInProgress && <IconButton
                  className="heading-icon margin-16 pull-right"
                  aria-label="Back"
                  color="primary"
                  onClick={cancelFormAction}
                  xs={2}
                >
                  <i className="material-icons">close</i>
                </IconButton>}
                &nbsp;&nbsp;
              </Grid>
            </Grid>
            {!!linearProgress && (
              <LinearProgress
                color="primary"
                className="form-status"
                variant="determinate"
                value={linearProgress}
              />
            )}
            <Divider />
          </div>
          {!!openFormDialog && !!dialogContent.length && (
            <div
              className="dialog-form-body"
              onScroll={!!onScroll ? e => onScroll(e) : () => {}}
            >
              
              <HorizontalStepper
                classes={classes}
                getSteps={() => this.getSteps()}
                getStepContent={stepIndex => this.getStepContent(stepIndex)}
                stepIndex={stepIndex}
                handleReset={this.handleReset}
                getStepClassName={stepIndex => this.getStepClassName(stepIndex)}
              />
            </div>
          )}
          {!!openFormDialog && !dialogContent.length && (
            <div className="margin-top-16 padding-16">{tableLoaderEmpty}</div>
          )}

          <div className="dialog-form-footer">
            <Divider />
            <DialogActions>
              <React.Fragment>
                {!!steps && stepIndex === steps.length-1 && !!cancelFormAction ? (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={cancelFormAction}
                    >
                      {!submitDataInProgress ? cancelFormText : <CircularProgress
                        size={22}
                        thickness={6}
                        color="secondary"
                      />}
                    </Button>
                ) : (
                  !submitDataInProgress && stepIndex !== steps.length-1 && <Button
                    variant="contained"
                    size="large"
                    disabled={stepIndex === 0}
                    onClick={this.handleBack}
                    color="secondary"
                  >
                    Back
                  </Button>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;
                {!!steps && stepIndex !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={this.handleNext}
                  >
                    Next
                  </Button>
                )}
                {!!steps &&
                  stepIndex === steps.length - 1 &&
                  submitDataInProgress  && !!confirmFormText && (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      autoFocus
                      disabled={true}
                    >
                      <CircularProgress
                        size={22}
                        thickness={6}
                        color="secondary"
                      />
                    </Button>
                  )}
                {!!steps &&
                  stepIndex === steps.length - 1 &&
                  !submitDataInProgress && !!confirmFormText && (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      autoFocus
                    >
                      {confirmFormText}
                    </Button>
                  )}
                &nbsp;&nbsp;
              </React.Fragment>
            </DialogActions>
          </div>
        </form>
      </Dialog>
    );
  }
}

export default FormDialogStepper;
