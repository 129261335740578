import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    const { defaultValue, options, value } = this.props;
    this.setDefaultOrValueObject(value, defaultValue, options);
  }

  componentDidUpdate(prevProp, prevState) {
    const { options } = this.props;
    if (
      !!prevProp.options &&
      !!options &&
      !!prevProp.options.length &&
      prevProp.options[0].label !== options[0].label
    ) {
      this.setState({
        value: options[0]
      });
    }
  }

  setDefaultOrValueObject(value, defaultValue, options) {
    this.state = {
      value: !!defaultValue && !!options && !value ? defaultValue : null
    };
    this.getSeletionObject(value, options);
  }

  getSeletionObject(value, options) {
    let index;
    if (!!value && !!options && !!options.length) {
      index = options.findIndex(item => item.value === value);
      if (typeof parseInt(index) === "number") {
        this.state = {
          value: options[index]
        };
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.value !== this.props.value && !nextProps.value) {
      this.setState({
        value: null
      });
    }
  }

  filterData(value) {
    const { options } = this.props;
    if (!value && !options) return null;
    const val = !!value && typeof value === "object" ? value.value : value;
    const item = options.filter(item => item.value === val);
    return item[0];
  }

  handleChange = (event, values) => {
    this.setState({ values, value: values }, () =>
      this.props.onChange(values, this.props.name)
    );
  };

  checkForValidSelection(selected) {
    const value = !!selected ? selected.value : null;
    return !value || value === "none" || value === "auto";
  }

  formFinalStyleClass(invalid) {
    let finalClass = "";
    const { className } = this.props;
    if (!!invalid) {
      finalClass += "invalid ";
    }
    if (!!className) {
      finalClass += " " + className;
    }

    return finalClass;
  }
  render() {
    const {
      name,
      options,
      placeholder,
      label,
      required,
      value,
      defaultValue
    } = this.props;

    return (
      <div className="position-relative">
        <span className="auto-complete__label">{label}</span>
        <Autocomplete
          name={name}
          id={name}
          className={this.formFinalStyleClass(
            this.checkForValidSelection(this.filterData(value)) && !!required
          )}
          options={options}
          placeholder={placeholder}
          onChange={this.handleChange}
          defaultValue={defaultValue || this.state.value}
          value={this.state.value}
          getOptionLabel={option => String(option.label)}
          renderInput={params => (
            <TextField {...params} label="Select" variant="outlined" />
          )}
          disableClearable={options.length < 10 ? true : false}
        />
      </div>
    );
  }
}
